<template>
      <transition name="popup-fade">
        <div class="popup-backdrop">
            <div class="popup"  role="dialog" aria-labelledby="PopupTitulo" aria-describedby="PopupDescripcion" :class="[size != '' ? 'w-'+size : '']">
                <header class="popup-header">
                    <slot name="header"></slot>
                    <button type="button" class="btn-close" @click="close" aria-label="Cerrar">🗙</button>
                </header>

                <section class="popup-body" id="PopupDescripcion">
                    <slot name="body">
                        This is the default body!
                    </slot>
                </section>

                <footer class="popup-footer">
                    <slot name="footer"></slot>
                    <div class="row">
                        <div class="col-12 text-end">
                            <button type="button" class="btn btn-sm btn-outline-success" aria-label="Ok" @click="close">Ok</button>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
      </transition>
</template>

<script>
  export default {
    name: 'InfoPopUp',
    props:['size'],
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>