<template>
      <transition name="popup-fade">
        <div class="popup-backdrop">
            <div class="popup popup-xs" role="dialog" aria-labelledby="PopupTitulo" aria-describedby="PopupDescripcion">
                <header class="popup-header">
                    <slot name="header"></slot>
                    <button type="button" class="btn-close" @click="close" aria-label="Cerrar">🗙</button>
                </header>

                <section class="popup-body" id="PopupDescripcion">
                    <slot name="body"></slot>
                </section>

                <footer class="popup-footer">
                    <slot name="footer"></slot>
                    <div class="row">
                        <div class=" text-start" :class="[shownoaceptar ? 'col-4' : 'col-6']">
                            <button type="button" class="btn btn-block btn-sm btn-outline-danger" aria-label="No" @click="close">
                              <slot name="btn-close">No</slot>
                            </button>
                        </div>
                        <div class="text-end" :class="[shownoaceptar ? 'col-4' : 'col-6']">
                            <button type="button" class="btn btn-block btn-sm btn-outline-success" aria-label="Si" @click="aceptar">
                              <slot name="btn-aceptar">Si</slot>
                            </button>
                        </div>
                        <div class="col-4 text-end" v-if="shownoaceptar ==true">
                            <button type="button" class="btn btn-block btn-sm btn-outline-success" aria-label="Cancelar" @click="noaceptar">
                              <slot name="btn-noaceptar">Cancelar</slot>
                            </button>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
      </transition>
</template>

<script>
  export default {
    name: 'PopupYesNo',
    props:['shownoaceptar'],
    methods: {
      close() {
        this.$emit('close');
      },
      aceptar(){
        this.$emit('aceptar');  
      },
      noaceptar(){
        this.$emit('noaceptar');  

      }
    },
  };
</script>