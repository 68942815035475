import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index'
import jwt_decode from 'jwt-decode'

import LoginView from '@/LoginView.vue'
import Dashboard from '@/pages/DashBoard.vue'
import Notificaciones from '@/pages/browse/WIN_Notificaciones.vue'
import WIN_Traficos from '@/pages/browse/WIN_Traficos.vue'
import FRM_Traficos from '@/pages/form/FRM_Traficos.vue'
import WIN_CpProveedores from '@/pages/browse/WIN_CpProveedores.vue'
import WIN_LigarRemFac from '@/pages/browse/WIN_LigarRemFac.vue'
import WIN_Clientes from '@/pages/browse/WIN_Clientes.vue'
import FRM_Clientes from '@/pages/form/FRM_Clientes.vue'
import WIN_Liquidaciones from '@/pages/browse/WIN_Liquidaciones.vue'
import FRM_Liquidaciones from '@/pages/form/FRM_Liquidaciones.vue'
import WIN_Operadores from '@/pages/browse/WIN_Operadores.vue'
import FRM_Operadores from '@/pages/form/FRM_Operadores.vue'
import WIN_Vehiculos from '@/pages/browse/WIN_Vehiculos.vue'
import FRM_Vehiculos from '@/pages/form/FRM_Vehiculos.vue'
import WIN_Casetas from '@/pages/browse/WIN_Casetas.vue'
import FRM_Casetas from '@/pages/form/FRM_Casetas.vue'
import WIN_Distancias from '@/pages/browse/WIN_Distancias.vue'
import FRM_Distancias from '@/pages/form/FRM_Distancias.vue'
import WIN_TCHistoria from '@/pages/browse/WIN_TCHistoria.vue'
import FRM_TCHistoria from '@/pages/form/FRM_TCHistoria.vue'
import WIN_Ciudades from '@/pages/browse/WIN_Ciudades.vue'
import FRM_Ciudades from '@/pages/form/FRM_Ciudades.vue'
import WIN_faServicios from '@/pages/browse/WIN_faServicios.vue'
import FRM_faServicios from '@/pages/form/FRM_faServicios.vue'
import WIN_FichasHead from '@/pages/browse/WIN_FichasHead.vue'
import FRM_FichasHead from '@/pages/form/FRM_FichasHead.vue'
import WIN_TrDestinos from '@/pages/browse/WIN_TrDestinos.vue'
import FRM_TrDestinos from '@/pages/form/FRM_TrDestinos.vue'
import WIN_Monedas from '@/pages/browse/WIN_Monedas.vue'
import FRM_Moneda from '@/pages/form/FRM_Moneda.vue'
import WIN_NCredito from '@/pages/browse/WIN_NCredito.vue'
import FRM_NCredito from '@/pages/form/FRM_NCredito.vue'
import WIN_Propietarios from '@/pages/browse/WIN_Propietarios.vue'
import FRM_Propietarios from '@/pages/form/FRM_Propietarios.vue'
import WIN_gpsDevices from '@/pages/browse/WIN_gpsDevices.vue'
import WIN_gpsEventos from '@/pages/browse/WIN_gpsEventos.vue'
import WIN_gpsGeoZones from '@/pages/browse/WIN_gpsGeoZones.vue'
import FRM_gpsGeoZones from '@/pages/form/FRM_gpsGeoZones.vue'
import WIN_faGrupos from '@/pages/browse/WIN_faGrupos.vue'
import WIN_TrGruposVehiculos from '@/pages/browse/WIN_TrGruposVehiculos.vue'
import FRM_TrGruposVehiculos from '@/pages/form/FRM_TrGruposVehiculos.vue'
import WIN_UnidadesEmb from '@/pages/browse/WIN_UnidadesEmb.vue'
import FRM_UnidadesEmb from '@/pages/form/FRM_UnidadesEmb.vue'
import WIN_Credenciales from '@/pages/browse/WIN_Credenciales.vue'
import FRM_Credenciales from '@/pages/form/FRM_Credenciales.vue'
import WIN_Contenidos from '@/pages/browse/WIN_Contenidos.vue'
import FRM_Contenidos from '@/pages/form/FRM_Contenidos.vue'
import WIN_Polizas from '@/pages/browse/WIN_Polizas.vue'
import FRM_Polizas from '@/pages/form/FRM_Polizas.vue'
import WIN_Fianzas from '@/pages/browse/WIN_Fianzas.vue'
import FRM_Fianzas from '@/pages/form/FRM_Fianzas.vue'
import WIN_TpoUnidad from '@/pages/browse/WIN_TpoUnidad.vue'
import FRM_TpoUnidad from '@/pages/form/FRM_TpoUnidad.vue'
import WIN_faAnticipos from '@/pages/browse/WIN_faAnticipos.vue'
import FRM_faAnticipos from '@/pages/form/FRM_faAnticipos.vue'
import WIN_TpoComb from '@/pages/browse/WIN_TpoComb.vue'
import FRM_TpoComb from '@/pages/form/FRM_TpoComb.vue'
import WIN_TpoContenedor from '@/pages/browse/WIN_TpoContenedor.vue'
import FRM_TpoContenedor from '@/pages/form/FRM_TpoContenedor.vue'
import WIN_TiposTra from '@/pages/browse/WIN_TiposTra.vue'
import FRM_TiposTra from '@/pages/form/FRM_TiposTra.vue'
import WIN_Condiciones from '@/pages/browse/WIN_Condiciones.vue'
import FRM_Condiciones from '@/pages/form/FRM_Condiciones.vue'
import WIN_InSucursales from '@/pages/browse/WIN_InSucursales.vue'
import FRM_InSucursales from '@/pages/form/FRM_InSucursales.vue'
import WIN_glBancos from '@/pages/browse/WIN_glBancos.vue'
import FRM_glBancos from '@/pages/form/FRM_glBancos.vue'
import WIN_TipoDocs from '@/pages/browse/WIN_TipoDocs.vue'
import FRM_TipoDocs from '@/pages/form/FRM_TipoDocs.vue'
import WIN_Relacion from '@/pages/browse/WIN_Relacion.vue'
import FRM_Relacion from '@/pages/form/FRM_Relacion.vue'
import WIN_faConceptosFactura from '@/pages/browse/WIN_faConceptosFactura.vue'
import FRM_faConceptosFactura from '@/pages/form/FRM_faConceptosFactura.vue'
import WIN_faFoliosE from '@/pages/browse/WIN_faFoliosE.vue'
import FRM_faFoliosE from '@/pages/form/FRM_faFoliosE.vue'
import WIN_Facturas from '@/pages/browse/WIN_Facturas.vue'
import FRM_Facturas from '@/pages/form/FRM_Facturas.vue'
import WIN_Remision from '@/pages/browse/WIN_Remision.vue'
import FRM_Remision from '@/pages/form/FRM_Remision.vue'
import WIN_OrdEmbarque from '@/pages/browse/WIN_OrdEmbarque.vue'
import FRM_OrdEmbarque from '@/pages/form/FRM_OrdEmbarque.vue'
import WIN_Lineas from '@/pages/browse/WIN_Lineas.vue'
import FRM_Lineas from '@/pages/form/FRM_Lineas.vue'
import WIN_faCajaChica from '@/pages/browse/WIN_faCajaChica.vue'
import FRM_faCajaChica from '@/pages/form/FRM_faCajaChica.vue'

import WIN_satEstados from '@/pages/browse/WIN_satEstados.vue'
import WIN_satUsoCFDI from '@/pages/browse/WIN_satUsoCFDI.vue'
import WIN_satRFiscal from '@/pages/browse/WIN_satRFiscal.vue'
import WIN_satCPostal from '@/pages/browse/WIN_satCPostal.vue'

Vue.use(VueRouter);

export const routes = [
    {path: '/login',                            name: 'login', component: LoginView, meta: {requiresAuth: false}},
    {path: '/',                                 name: 'Tablero',  component: Dashboard, meta: {requiresAuth: true}},

    {path: '/notificaciones',                   name: 'Notificaciones' , component: Notificaciones,  meta: {requiresAuth: true}},

    {path: '/traficos',                         name: 'Trafico' , component: WIN_Traficos, meta: {requiresAuth: true}},
    {path: '/traficos/agregar',                 name: 'ATrafico' , component: FRM_Traficos,  meta: {requiresAuth: true}},
    {path: '/traficos/modificar/:trafico',      name: 'CTrafico', component: FRM_Traficos, meta: {requiresAuth: true}},

    {path: '/proveedores',                      name: 'Proveedores' , component: WIN_CpProveedores,  meta: {requiresAuth: true}},
    {path: '/ligarremfac',                      name: 'LigarRemFac' , component: WIN_LigarRemFac,  meta: {requiresAuth: true}},

    {path: '/clientes',                         name: 'Clientes' , component: WIN_Clientes,  meta: {requiresAuth: true}},
    {path: '/clientes/agregar',                 name: 'AClientes' , component: FRM_Clientes,  meta: {requiresAuth: true}},
    {path: '/clientes/modificar/:cliente',      name: 'CClientes', component: FRM_Clientes,  meta: {requiresAuth: true}},

    {path: '/liquidaciones',                         name: 'Liquidaciones' , component: WIN_Liquidaciones,  meta: {requiresAuth: true}},
    {path: '/liquidaciones/agregar',                 name: 'ALiquidaciones' , component: FRM_Liquidaciones,  meta: {requiresAuth: true}},
    {path: '/liquidaciones/modificar/:liquidacion',  name: 'CLiquidaciones', component: FRM_Liquidaciones,  meta: {requiresAuth: true}},

    {path: '/operadores',                       name: 'Operadores' , component: WIN_Operadores,  meta: {requiresAuth: true}},
    {path: '/operadores/agregar',               name: 'AOperadores' , component: FRM_Operadores,  meta: {requiresAuth: true}},
    {path: '/operadores/modificar/:operador',   name: 'COperadores', component: FRM_Operadores,  meta: {requiresAuth: true}},

    {path: '/vehiculos',                        name: 'Vehiculos' , component: WIN_Vehiculos,  meta: {requiresAuth: true}},
    {path: '/vehiculos/agregar',                name: 'AVehiculos' , component: FRM_Vehiculos,  meta: {requiresAuth: true}},
    {path: '/vehiculos/modificar/:vehiculo',    name: 'CVehiculos', component: FRM_Vehiculos,  meta: {requiresAuth: true}},

    {path: '/casetas',                        name: 'Casetas' ,  component: WIN_Casetas,  meta: {requiresAuth: true}},
    {path: '/casetas/agregar',                name: 'ACasetas' , component: FRM_Casetas,  meta: {requiresAuth: true}},
    {path: '/casetas/modificar/:caseta',      name: 'CCasetas',  component: FRM_Casetas,  meta: {requiresAuth: true}},

    {path: '/distancias',                        name:  'Distancias' ,  component: WIN_Distancias,  meta: {requiresAuth: true}},
    {path: '/distancias/agregar',                name: 'ADistancias' , component: FRM_Distancias,  meta: {requiresAuth: true}},
    {path: '/distancias/modificar/:distancia',   name: 'CDistancias',  component: FRM_Distancias,  meta: {requiresAuth: true}},

    {path: '/historiatcambio',                    name: 'TCHistoria' , component: WIN_TCHistoria,  meta: {requiresAuth: true}},
    {path: '/historiatcambio/agregar',            name: 'ATCHistoria' , component: FRM_TCHistoria,  meta: {requiresAuth: true}},
    {path: '/historiatcambio/modificar/:tcambio', name: 'CTCHistoria', component: FRM_TCHistoria,  meta: {requiresAuth: true}},

    {path: '/ciudades',                         name: 'Ciudades' , component: WIN_Ciudades,  meta: {requiresAuth: true}},
    {path: '/ciudades/agregar',                 name: 'ACiudades' , component: FRM_Ciudades,  meta: {requiresAuth: true}},
    {path: '/ciudades/modificar/:ciudad',       name: 'CCiudades', component: FRM_Ciudades,  meta: {requiresAuth: true}},
    
    {path: '/servicios',                       name: 'faServicios' , component: WIN_faServicios,  meta: {requiresAuth: true}},
    {path: '/servicios/agregar',               name: 'AfaServicios' , component: FRM_faServicios,  meta: {requiresAuth: true}},
    {path: '/servicios/modificar/:servicio',   name: 'CfaServicios', component: FRM_faServicios,  meta: {requiresAuth: true}},
    
    {path: '/fichasdeposito',                      name:  'fichasdeposito' , component: WIN_FichasHead,  meta: {requiresAuth: true}},
    {path: '/fichasdeposito/agregar',              name: 'Afichasdeposito' , component: FRM_FichasHead,  meta: {requiresAuth: true}},
    {path: '/fichasdeposito/modifica/:deposito',   name: 'Cfichasdeposito' , component: FRM_FichasHead,  meta: {requiresAuth: true}},
    
    {path: '/trdestinos',                       name: 'trDestinos' , component: WIN_TrDestinos,  meta: {requiresAuth: true}},
    {path: '/trdestinos/agregar',               name: 'AtrDestinos' , component: FRM_TrDestinos,  meta: {requiresAuth: true}},
    {path: '/trdestinos/modifica/:destino',     name: 'CtrDestinos' , component: FRM_TrDestinos,  meta: {requiresAuth: true}},

    {path: '/moneda',                           name: 'Moneda' , component: WIN_Monedas,  meta: {requiresAuth: true}},
    {path: '/moneda/agregar',                   name: 'AMoneda' , component: FRM_Moneda,  meta: {requiresAuth: true}},
    {path: '/moneda/modifica/:moneda',          name: 'CMoneda' , component: FRM_Moneda,  meta: {requiresAuth: true}},
   
    {path: '/ncredito',                         name:  'NCredito' , component: WIN_NCredito,  meta: {requiresAuth: true}},
    {path: '/ncredito/agregar',                 name: 'ANCredito' , component: FRM_NCredito,  meta: {requiresAuth: true}},
    {path: '/ncredito/modifica/:ncredito',      name: 'CNCredito' , component: FRM_NCredito,  meta: {requiresAuth: true}},
   
    {path: '/propietarios',                         name:  'Propietarios' , component: WIN_Propietarios,  meta: {requiresAuth: true}},
    {path: '/propietarios/agregar',                 name: 'APropietarios' , component: FRM_Propietarios,  meta: {requiresAuth: true}},
    {path: '/propietarios/modifica/:propietario',   name: 'CPropietarios' , component: FRM_Propietarios,  meta: {requiresAuth: true}},

    {path: '/gpsdevices',                      name: 'GeoDevices' , component: WIN_gpsDevices,  meta: {requiresAuth: true}},
    {path: '/gpseventos',                      name: 'GeoEventos' , component: WIN_gpsEventos,  meta: {requiresAuth: true}},

    {path: '/gpsgeozonas',                      name: 'GeoZonas' , component: WIN_gpsGeoZones,  meta: {requiresAuth: true}},
    {path: '/gpsgeozonas/agregar',              name: 'AGeoZonas' , component: FRM_gpsGeoZones,  meta: {requiresAuth: true}},
    {path: '/gpsgeozonas/modifica/:zona',       name: 'CGeoZonas' , component: FRM_gpsGeoZones,  meta: {requiresAuth: true}},
  
    {path: '/faGrupos',                         name: 'faGrupos' , component: WIN_faGrupos,  meta: {requiresAuth: true}},
    {path: '/faGrupos/agregar',                 name: 'AfaGrupos' , component: FRM_Clientes,  meta: {requiresAuth: true}},
    {path: '/faGrupos/modificar/:grupo',        name: 'CfaGrupos', component: FRM_Clientes,  meta: {requiresAuth: true}},
    
    {path: '/gpovehiculos',                         name:  'TrGruposVehiculos' , component: WIN_TrGruposVehiculos,  meta: {requiresAuth: true}},
    {path: '/gpovehiculos/agregar',                 name: 'ATrGruposVehiculos' , component: FRM_TrGruposVehiculos ,  meta: {requiresAuth: true}},
    {path: '/gpovehiculos/modificar/:grupo',        name: 'CTrGruposVehiculos', component:  FRM_TrGruposVehiculos,  meta: {requiresAuth: true}},
    
    {path: '/unembalaje',                         name:   'UnidadesEmb' , component: WIN_UnidadesEmb,  meta: {requiresAuth: true}},
    {path: '/unembalaje/agregar',                 name:  'AUnidadesEmb' , component: FRM_UnidadesEmb ,  meta: {requiresAuth: true}},
    {path: '/unembalaje/modificar/:unidad',        name: 'CUnidadesEmb', component: FRM_UnidadesEmb,  meta: {requiresAuth: true}},
    
    {path: '/credenciales',                         name:  'Credenciales' , component: WIN_Credenciales,  meta: {requiresAuth: true}},
    {path: '/credenciales/agregar',                 name: 'ACredenciales' , component: FRM_Credenciales,  meta: {requiresAuth: true}},
    {path: '/credenciales/modificar/:contenido',    name: 'CCredenciales',  component: FRM_Credenciales,  meta: {requiresAuth: true}},
    
    {path: '/contenidos',                         name:  'Contenidos' , component: WIN_Contenidos,  meta: {requiresAuth: true}},
    {path: '/contenidos/agregar',                 name: 'AContenidos' , component: FRM_Contenidos,  meta: {requiresAuth: true}},
    {path: '/contenidos/modificar/:contenido',    name: 'CContenidos',  component: FRM_Contenidos,  meta: {requiresAuth: true}},
    
    {path: '/polizas',                          name: 'Polizas' , component: WIN_Polizas,  meta: {requiresAuth: true}},
    {path: '/polizas/agregar',                  name: 'APolizas' , component: FRM_Polizas,  meta: {requiresAuth: true}},
    {path: '/polizas/modificar/:poliza',        name: 'CPolizas', component: FRM_Polizas,  meta: {requiresAuth: true}},
    
    {path: '/fianzas',                          name: 'Fianzas' , component: WIN_Fianzas,  meta: {requiresAuth: true}},
    {path: '/fianzas/agregar',                  name: 'AFianzas' , component: FRM_Fianzas,  meta: {requiresAuth: true}},
    {path: '/fianzas/modificar/:fianza',        name: 'CFianzas', component: FRM_Fianzas,  meta: {requiresAuth: true} },
   
    {path: '/tpounidad',                        name: 'TpoUnidad' , component: WIN_TpoUnidad,  meta: {requiresAuth: true}},
    {path: '/tpounidad/agregar',                name: 'ATpoUnidad' , component: FRM_TpoUnidad,  meta: {requiresAuth: true}},
    {path: '/tpounidad/modificar/:tipo',        name: 'CTpoUnidad', component: FRM_TpoUnidad,  meta: {requiresAuth: true} },
   
    {path: '/anticipos',                        name:  'faAnticipos' , component: WIN_faAnticipos,  meta: {requiresAuth: true}},
    {path: '/anticipos/agregar',                name: 'AfaAnticipos' , component: FRM_faAnticipos,  meta: {requiresAuth: true}},
    {path: '/anticipos/modificar/:anticipo',    name: 'CfaAnticipos', component: FRM_faAnticipos,  meta: {requiresAuth: true} },
   
    {path: '/tpocombustible',                   name:  'TpoComb' , component: WIN_TpoComb,  meta: {requiresAuth: true}},
    {path: '/tpocombustible/agregar',           name: 'ATpoComb' , component: FRM_TpoComb,  meta: {requiresAuth: true}},
    {path: '/tpocombustible/modificar/:combustible',   name: 'CTpoComb', component: FRM_TpoComb,  meta: {requiresAuth: true} },
   
    {path: '/tpocontenedores',                  name:  'TpoContenedor' , component: WIN_TpoContenedor,  meta: {requiresAuth: true}},
    {path: '/tpocontenedores/agregar',          name: 'ATpoContenedor' , component: FRM_TpoContenedor,  meta: {requiresAuth: true}},
    {path: '/tpocontenedores/modificar/:contenedor',  name: 'CTpoContenedor', component: FRM_TpoContenedor,  meta: {requiresAuth: true} },
   
    {path: '/tpotransacciones',                 name: 'TiposTra' , component: WIN_TiposTra,  meta: {requiresAuth: true}},
    {path: '/tpotransacciones/agregar',         name: 'ATiposTra' , component: FRM_TiposTra,  meta: {requiresAuth: true}},
    {path: '/tpotransacciones/modificar/:tipo', name: 'CTiposTra', component:FRM_TiposTra,  meta: {requiresAuth: true} },
   
    {path: '/condiciones',                      name: 'Condiciones' , component: WIN_Condiciones,  meta: {requiresAuth: true}},
    {path: '/condiciones/agregar',              name: 'ACondiciones' , component: FRM_Condiciones,  meta: {requiresAuth: true}},
    {path: '/condiciones/modificar/:condicion', name: 'CCondiciones', component: FRM_Condiciones,  meta: {requiresAuth: true} },
   
    {path: '/sucursales',                       name: 'InSucursales' , component: WIN_InSucursales,  meta: {requiresAuth: true}},
    {path: '/sucursales/agregar',               name: 'AInSucursales' , component: FRM_InSucursales,  meta: {requiresAuth: true}},
    {path: '/sucursales/modificar/:sucursal',   name: 'CInSucursales', component: FRM_InSucursales,  meta: {requiresAuth: true} },
   
    {path: '/bancos',                           name: 'glBancos'  , component: WIN_glBancos,  meta: {requiresAuth: true}},
    {path: '/bancos/agregar',                   name: 'AglBancos' , component: FRM_glBancos,  meta: {requiresAuth: true}},
    {path: '/bancos/modificar/:banco',          name: 'CglBancos' , component: FRM_glBancos,  meta: {requiresAuth: true} },
   
    {path: '/tipodocumentos',                   name: 'TipoDocs'  , component: WIN_TipoDocs,  meta: {requiresAuth: true}},
    {path: '/tipodocumentos/agregar',           name: 'ATipoDocs' , component: FRM_TipoDocs,  meta: {requiresAuth: true}},
    {path: '/tipodocumentos/modificar/:doc',    name: 'CTipoDocs' , component: FRM_TipoDocs,  meta: {requiresAuth: true} },
   
    {path: '/relacion',                         name:  'Relacion'  ,component: WIN_Relacion,  meta: {requiresAuth: true}},
    {path: '/relacion/agregar',                 name: 'ARelacion' , component: FRM_Relacion,  meta: {requiresAuth: true}},
    {path: '/relacion/modificar/:doc',          name: 'CRelacion' , component: FRM_Relacion,  meta: {requiresAuth: true} },
   
    {path: '/conceptos',                        name: 'CfaConceptosFactura'  , component: WIN_faConceptosFactura,  meta: {requiresAuth: true}},
    {path: '/conceptos/agregar',                name: 'ACfaConceptosFactura' , component: FRM_faConceptosFactura,  meta: {requiresAuth: true}},
    {path: '/conceptos/modificar/:banco',       name: 'CCfaConceptosFactura' , component: FRM_faConceptosFactura,  meta: {requiresAuth: true} },
   
    {path: '/folios',                           name: 'faFoliosE'  , component: WIN_faFoliosE,  meta: {requiresAuth: true}},
    {path: '/folios/agregar',                   name: 'AfaFoliosE' , component: FRM_faFoliosE,  meta: {requiresAuth: true}},
    {path: '/folios/modificar/:folio',          name: 'CfaFoliosE' , component: FRM_faFoliosE,  meta: {requiresAuth: true} },

    {path: '/facturas',                         name: 'Facturas', component: WIN_Facturas,  meta: {requiresAuth: true}},
    {path: '/facturas/agregar',                 name: 'AFacturas' , component: FRM_Facturas,  meta: {requiresAuth: true}},
    {path: '/facturas/modificar/:factura',      name: 'CFacturas' , component: FRM_Facturas,  meta: {requiresAuth: true}},
    {path: '/facturas/duplicar/:factura',       name: 'DFacturas' , component: FRM_Facturas,  meta: {requiresAuth: true}},

    {path: '/remisiones',                         name:  'Remision',  component: WIN_Remision,  meta: {requiresAuth: true}},
    {path: '/remisiones/agregar',                 name: 'ARemision' , component: FRM_Remision,  meta: {requiresAuth: true}},
    {path: '/remisiones/modificar/:factura',      name: 'CRemision' , component: FRM_Remision,  meta: {requiresAuth: true}},
    {path: '/remisiones/duplicar/:factura',       name: 'DRemision' , component: FRM_Remision,  meta: {requiresAuth: true}},
    
    {path: '/embarques',                         name:  'OrdEmbarque',  component: WIN_OrdEmbarque,  meta: {requiresAuth: true}},
    {path: '/embarques/agregar',                 name: 'AOrdEmbarque' , component: FRM_OrdEmbarque,  meta: {requiresAuth: true}},
    {path: '/embarques/modificar/:embarque',     name: 'COrdEmbarque' , component: FRM_OrdEmbarque,  meta: {requiresAuth: true}},
    
    {path: '/lineas',                         name:  'Lineas',  component: WIN_Lineas,  meta: {requiresAuth: true}},
    {path: '/lineas/agregar',                 name: 'ALineas' , component: FRM_Lineas,  meta: {requiresAuth: true}},
    {path: '/lineas/modificar/:embarque',     name: 'CLineas' , component: FRM_Lineas,  meta: {requiresAuth: true}},
    
    {path: '/cajachica',                     name:  'faCajaChica',  component: WIN_faCajaChica,  meta: {requiresAuth: true}},
    {path: '/cajachica/agregar',             name: 'AfaCajaChica' , component: FRM_faCajaChica,  meta: {requiresAuth: true}},
    {path: '/cajachica/modificar/:caja',     name: 'CfaCajaChica' , component: FRM_faCajaChica,  meta: {requiresAuth: true}},

    {path: '/sat-estados',                      name: 'satEstados', component: WIN_satEstados,  meta: {requiresAuth: true}},
    {path: '/sat-usocfdi',                      name: 'satUsoCFDI', component: WIN_satUsoCFDI,  meta: {requiresAuth: true}},
    {path: '/sat-rfiscal',                      name: 'satRFiscal', component: WIN_satRFiscal,  meta: {requiresAuth: true}},
    {path: '/sat-cpostal',                      name: 'satCPostal', component: WIN_satCPostal,  meta: {requiresAuth: true}}
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.VUE_APP_BASE_SUBFOLDER,
    routes
  })
  
  router.beforeEach((to, from, next) => {
    if(to.matched.some(item=>item.meta.requiresAuth)){
      if(!store.state.iasctratkn){
        next({name: 'login'})
      }
      try{
        jwt_decode(store.state.iasctratkn)
      }catch(error){
        next({name: 'login'})
      }
      
      if(jwt_decode(store.state.iasctratkn).exp*1000 <= new Date().getTime()){
        next({name: 'login'})
      }
      next()
    }else{
      next()
    }
  })
  

export default router