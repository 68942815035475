<template>
    <section>
        <SideBar v-if="!OpenMode" />
        <TopBar v-if="!OpenMode" />
        <div :class="[!OpenMode ? 'content' : '' ]">   
            <div class="main-content-header">
                <h1>Liquidaciones a Operadores</h1>
            </div>
            <LoadingContent v-if="isLoading" />
            <div class="row">
                <div class="col-lg-12 col-md-6">
                    <div class="card" :style="{height:[contentHeight+'px']}">
                        <div class="row " :class="[!OpenMode ? 'p-4' : '']">
                            <div class="content-header col-12"> 
                                <div class="row">
                                    <div class="col-md-2 col-12"><p class="p-1 pe-4 mb-0 text-end">Página {{ paginaActual }} de {{ totalPaginas }} </p></div>
                                    <div class=" col-12 col-md-2 offset-md-10 ps-2 pe-5 text-end pb-0 pt-0">
                                        <ul class="list-unstyled mb-0 d-flex justify-content-between pager-btns float-right">
                                            <li><button @click="setPaginacion('first')" type="button" class="pager pager-first btn btn-sm btn-outline-secondary"><ChevronDoubleLeftIcon /></button></li>
                                            <li><button @click="setPaginacion('prev')"  type="button" class="pager pager-prev  btn btn-sm btn-outline-secondary"><ChevronLeftIcon /></button></li>
                                            <li><button @click="setPaginacion('next')"  type="button" class="pager pager-next  btn btn-sm btn-outline-secondary"><ChevronRightIcon /></button></li>
                                            <li><button @click="setPaginacion('last')"  type="button" class="pager pager-last  btn btn-sm btn-outline-secondary"><ChevronDoubleRightIcon /></button></li> 
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-10 col-12 pt-2">
                                <div class="table-responsive ps-2">
                                    <b-table-simple class="browse" hover small caption-top responsive >
                                        <b-thead head-variant="light" class="table-header">
                                            <b-tr>
                                                <b-th class="text-center text-nowrap">Suc</b-th>
                                                <b-th class="text-center text-nowrap">Folio</b-th>
                                                <b-th class="text-end text-nowrap">Fecha</b-th>
                                                <b-th class="text-nowrap">Operador</b-th>
                                                <b-th class="text-nowrap">Unidad</b-th>
                                                <b-th class="text-nowrap">Nombre</b-th>
                                                <b-th class="text-end text-nowrap">Prestamo</b-th>
                                                <b-th class="text-endtext-nowrap">Anticipos</b-th>
                                                <b-th class="text-endtext-nowrap">Gastos</b-th>
                                                <b-th class="text-endtext-nowrap">Litros</b-th>
                                                <b-th class="text-endtext-nowrap">Guías</b-th>
                                                <b-th class="text-endtext-nowrap">Guias Dls</b-th>
                                                <b-th class="text-endtext-nowrap">Kms</b-th>
                                                <b-th class="text-endtext-nowrap">Rendim</b-th>
                                                <b-th class="text-endtext-nowrap">A Pagar</b-th>
                                                <b-th class="text-endtext-nowrap">Utilidad</b-th>
                                                <b-th class="text-nowrap">Póliza</b-th>
                                                <b-th class="text-nowrap">Observaciones</b-th>
                                                <b-th class="text-nowrap">Autorizo</b-th>
                                                <b-th class="text-nowrap">Periodo</b-th>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr   v-for="(liquidacion,indx) in liquidaciones" 
                                                    :key="liquidacion.folio" 
                                                    @click="selectedRegistro=liquidacion;selectedRegistro.indx = indx" 
                                                    @dblclick=" (!OpenMode ? modificar() : exportRegistry()) " 

                                                    :class="[{  'son-text-danger': (liquidacion.chequeno != '' && liquidacion.utilidad  <= 50),
                                                                'son-text-yellow': (liquidacion.chequeno != '' && liquidacion.utilidad  > 50 && liquidacion.utilidad  <= 55),
                                                                'son-text-dark-green': (liquidacion.chequeno != '' && liquidacion.utilidad  > 55  && liquidacion.utilidad  <= 60 ),
                                                                'son-text-blue': (liquidacion.chequeno != '' && liquidacion.utilidad  > 60 ),
                                                                'selected-row': (selectedRegistro.folio == liquidacion.folio) }]"> 
                                                <b-td class="text-center text-nowrap">{{ liquidacion.sucursal| leadingZeros(3)}}</b-td>
                                                <b-td class="text-center text-nowrap" >{{ liquidacion.folio | leadingZeros(5)}}</b-td>
                                                <b-td class="text-end text-nowrap">{{ formato(liquidacion.fecha,'MMM-DD-YYYY')}} </b-td>
                                                <b-td class="text-nowrap">{{ liquidacion.operador }}</b-td>
                                                <b-td class="text-nowrap">{{ liquidacion.unidad }}</b-td>
                                                <b-td class="text-nowrap">{{ liquidacion.nombre_oper}}</b-td>
                                                <b-td class="text-end text-nowrap">{{ liquidacion.rebaja}}</b-td>
                                                <b-td class="text-end text-nowrap">{{ liquidacion.anticipos}}</b-td>
                                                <b-td class="text-end text-nowrap">{{ liquidacion.gastos}}</b-td>
                                                <b-td class="text-end text-nowrap">{{ liquidacion.litros }}</b-td>
                                                <b-td class="text-end text-nowrap">{{ liquidacion.guias}}</b-td>
                                                <b-td class="text-end text-nowrap">{{ liquidacion.guiasdls }}</b-td>
                                                <b-td class="text-end text-nowrap">{{ liquidacion.kms}}</b-td>
                                                <b-td class="text-end text-nowrap">{{ liquidacion.rendimiento}}</b-td>
                                                <b-td class="text-end text-nowrap">{{ liquidacion.apagar}}</b-td>
                                                <b-td class="text-end text-nowrap">{{ liquidacion.utilidad}}</b-td>
                                                <b-td class="text-nowrap">{{ liquidacion.chequeno}}</b-td>
                                                <b-td class="text-nowrap">{{ liquidacion.observaciones}}</b-td>
                                                <b-td class="text-nowrap">{{ liquidacion.autorizousuario}}</b-td>
                                                <b-td class="text-nowrap">{{ liquidacion.preiodo}}</b-td>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </div>
                            </div> 
                            <div class="col-2 ps-2 pe-5 btn-manager" >
                                <ul class="d-block list-unstyled action-block">
                                    <li v-if="!OpenMode"><router-link to="/liquidaciones/agregar" class="btn btn-xs btn-success btn-block">Agregar</router-link></li>
                                    <li v-if="!OpenMode"><button @click="modificar()" :disabled="disableBtn" type="button" class="btn btn-xs btn-primary btn-block">Cambiar</button></li>
                                    <li v-if="!OpenMode"><button @click="ask()" :disabled="disableBtn" class="btn btn-xs btn-danger btn-block">Borrar</button></li>
                                    <li v-if="!OpenMode"><button @click="imprimir()" class="btn btn-xs btn-secondary btn-block">Imprimir</button></li>
                                    <li v-if="OpenMode"><button @click="exportRegistry()" class="btn btn-xs btn-success btn-block">Elegir</button></li>
                                </ul>
                            </div>
                            
                            <div class="content-footer col-12 text-start"></div>
                        </div>
                    </div>
                </div>
            </div>
            <BusquedaVue v-show="busquedaVisible" v-hotkey="keymap" :campos="camposFiltro" @applyFilter="aplicaFiltro()"></BusquedaVue>
            <PrintComponentVue v-if="print" @close="closePopup">
                <template  v-slot:title >Reporte de Operadores</template>
            </PrintComponentVue>

            <DeleteReg  v-show="showDelete" @close="closeDelete" @aceptar="liquidaciones = borrar('Operadores',liquidaciones)">
                <template v-slot:header>
                    <h6 class="text-uppercase">{{notificationTitle}}</h6>
                </template>
                <template v-slot:body>
                    {{notificationText}}
                </template>
            </DeleteReg>

            <InfoPopUp  v-show="showNotification" @close="closeInfo" >
                <template v-slot:header>
                    <h6 class="text-uppercase">{{notificationTitle}}</h6>
                </template>
                <template v-slot:body>
                    {{notificationText}}
                </template>
            </InfoPopUp>
            
        </div>
    </section>
</template>

<script>
import InfoPopUp from '@/components/InfoPopUp.vue';
import DeleteReg from '@/components/YesNoPopup.vue';
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronDoubleRightIcon from 'vue-material-design-icons/ChevronDoubleRight.vue';
import ChevronDoubleLeftIcon from 'vue-material-design-icons/ChevronDoubleLeft.vue';
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'
import handleBrowse from '@/mixins/handleBrowse'
import handlePopUp from '@/mixins/handlePopUp'
import PrintComponentVue from '@/components/PrintComponent.vue'; 
import BusquedaVue from '@/components/Busqueda.vue'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    name:'WIN_Liquidaciones',
    props:['mode','perpage'],
    mixins:[handleGlobals,handleBrowse,handlePopUp],
    components:{SideBar,TopBar,ChevronLeftIcon,InfoPopUp,DeleteReg,BusquedaVue,ChevronDoubleRightIcon,ChevronDoubleLeftIcon,
        ChevronRightIcon, LoadingContent,PrintComponentVue},
    data(){
        return{
            liquidaciones: [],
            filtroMostrar:'T'
        }
    },
    created(){
        if(this.OpenMode == false){
            this.getFiltros()
        } 
    },
    methods:{
        async getFiltros(){
                await fetch(this.$api+'/rdx', {    
                    method: "POST",
                    headers: {
                        'Content-Type' : 'application/json',
                        'Authorization' : this.$store.state.iasctratkn
                    },
                    body: JSON.stringify({accion: "filters"})
                }).then((response)=>{
                    if(response.ok){
                        return response.json()
                    }
                }).then((data)=>{
                    let reg = data['SUC']
                    if(reg){
                        this.filtroMostrar = reg['chequeno'].dato
                        this.paginaActual  = reg['paginaActual'].dato
                        if(this.paginaActual == '') this.paginaActual = 0
                    }else{
                        this.filtroMostrar = 'T'
                        this.paginaActual  = 1
                    }
                }).catch((err)=>{
                this.falseToken(err)
            });
        },
        consultar(){
            this.isLoading = true

            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "browse", 
                                        archivo: "Liquidaciones", 
                                        activarPaginas: true,
                                        orden: "folio",
                                        filtro: {chequeno:{  dato:this.filtroMostrar, inactivo: this.filtroMostrar == 'T'  },
                                                 paginaActual: {dato: 'NA'}},
                                        pagina: this.paginaActual, 
                                        paginacion: this.paginacion,
                                        registrosxpagina: this.rows })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.liquidaciones     = data['registros'];
                this.totalRegistros = data['totalRegistros'];
                this.totalPaginas   = data['totalPaginas'];
                this.paginaActual   = data['paginaActual'];
                this.savePage('liquidaciones',this.paginaActual)
            }).finally(()=>{
                this.isLoading = false;
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        imprimir(){
            this.print = true
        },
        modificar(){
            this.$router.push({ name: 'CLiquidaciones', params: {liquidacion: this.selectedRegistro.folio}});
        },
    }
}
</script>

