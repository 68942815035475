<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Anticipos</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row w-65">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-10">
                                            <form>
                                                <LookUpCtrl :objeto="anticipo" :label="'Cliente'" :cols="[3,3,1]" :archivo="'Clientes'" :campo="'clienteid'" :descripcion="'nombre'" :model="'cliente'" :reference="'cliente'" v-model="anticipo.cliente" />
                                                <div class="row ">
                                                    <div class="col-6 ">
                                                        <label class="col-2 text-nowrap">Consecutivo</label>
                                                        <div class="col-10 d-flex">
                                                            <input type="number" ref="consecutivo" class="form-control form-control-sm"  v-model="anticipo.consecutivo" />													
                                                        </div>
                                                    </div>	
                                                    <div class="col-6 ">
                                                        <div class="row ">
                                                            <label class="col-2 text-nowrap">Sucursal</label>
                                                            <div class="col-6 d-flex">
                                                                <input type="number" class="form-control form-control-sm"  v-model="anticipo.sucursal" />													
                                                            </div>
                                                        </div>	
                                                    </div>	
                                                </div>	
                                                <div class="row ">
                                                    <div class="col-6 ">
                                                        <label class="col-2 text-nowrap">Valor</label>
                                                        <div class="col-10 d-flex">
                                                            <input type="number" class="form-control form-control-sm"  v-model="anticipo.valor" />													
                                                        </div>
                                                    </div>	
                                                    <div class="col-6 ">
                                                        <div class="row ">
                                                            <label class="col-2 text-nowrap">Tipo</label>
                                                            <div class="col-6 d-flex">
                                                                <input type="text" class="form-control form-control-sm" maxlength="3"  v-model="anticipo.tipo" />													
                                                            </div>
                                                        </div>	
                                                    </div>	
                                                </div>	
                                                <div class="row ">
                                                    <div class="col-6 ">
                                                        <label class="col-2 text-nowrap">Fecha</label>
                                                        <div class="col-10 d-flex">
                                                            <input type="date" class="form-control form-control-sm"  v-model="anticipo.fecha" />													
                                                        </div>
                                                    </div>	
                                                </div>	
                                                <div class="row ">
                                                    <div class="col-6 ">
                                                        <label class="col-2 text-nowrap">Valor Asignado</label>
                                                        <div class="col-10 d-flex">
                                                            <input type="number" class="form-control form-control-sm"  v-model="anticipo.valorasignado" />													
                                                        </div>
                                                    </div>	
                                                    <div class="col-6 ">
                                                        <div class="row ">
                                                            <label class="col-2 text-nowrap">Referencia</label>
                                                            <div class="col-6 d-flex">
                                                                <input type="text" class="form-control form-control-sm" maxlength="7"  v-model="anticipo.referencia" />													
                                                            </div>
                                                        </div>	
                                                    </div>	
                                                </div>	
                                                <div class="row ">
                                                    <div class="col-6 ">
                                                        <label class="col-2 text-nowrap">Saldo</label>
                                                        <div class="col-10 d-flex">
                                                            <input type="number" class="form-control form-control-sm"  v-model="anticipo.saldo" />													
                                                        </div>
                                                    </div>	
                                                    <div class="col-6 ">
                                                        <div class="row ">
                                                            <label class="col-2 text-nowrap">Folio</label>
                                                            <div class="col-6 d-flex">
                                                                <input type="text" class="form-control form-control-sm" maxlength="7" v-model="anticipo.folio" />													
                                                            </div>
                                                        </div>	
                                                    </div>	
                                                </div>	
                                                <div class="row ">
                                                    <LookUpCtrl :objeto="anticipo" :label="'Moneda'" :cols="[3,3,1]" :archivo="'Monedas'" :campo="'clave'" :descripcion="'descripcion'" :model="'moneda'" :reference="'moneda'" v-model="anticipo.moneda" />

                                                    <div class="col-6 ">
                                                        <div class="row ">
                                                            <label class="col-2 text-nowrap">T.Cambio</label>
                                                            <div class="col-6 d-flex">
                                                                <input type="number" step="0.1" class="form-control form-control-sm"  v-model="anticipo.tipocambio" />													
                                                            </div>
                                                        </div>	
                                                    </div>	
                                                </div>	
                                            </form>
                                        </div>
                                        <div class="col-2">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li><button @click="setAnticipo()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li><router-link to="/anticipoes" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import LookUpCtrl from '@/components/LookUpCtrl.vue';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    mixins:[handleGlobals],
    components:{SideBar,TopBar, LookUpCtrl,LoadingContent },
    data(){
        return{
            id: this.$route.params.anticipo,
            modo: (this.$route.params.anticipo > 0 ? 'Modificando' : 'Creacion'), 
            anticipo: [],
            titulo: 'Agregando un Anticipo',
        }
    },
    mounted(){
        this.llenaForma();
    },
    methods:{
        async llenaForma(){
            this.Get_SAT_ClavesEstados()
            if(this.$route.params.anticipo > 0){
                await this.getAnticipo();
                this.titulo = 'Cambiando un Anticipo';
            }else{
                this.titulo = 'Agregando un Anticipo';
            }
        },
        getAnticipo(){
            this.isLoading = true
             fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "inicializar", modo:this.modo, archivo: "FaAnticipos", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.anticipo = data
                this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setAnticipo(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: 'validar', archivo: "FaAnticipos", modo:this.modo, record: this.anticipo }) 
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['res'] == 'error'){
                    this.showNotification = true
                    this.notificationTitle =  'Error'
                    this.notificationText = data['msg']
                    this.notificationRef = 'consecutivo'
                }else{
                    this.$router.push('/anticipoes')
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        afterClose(){
           
            this.$refs[this.notificationRef].focus()
        }
    }
}
</script>