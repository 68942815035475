<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Bancos</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row w-60">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-9 col-12">
                                            <form>
                                                <nav>
                                                    <div class="nav nav-underline" id="nav-tab" role="tablist">
                                                        <li class="nav-item"><a class="nav-link active" @click="tab='general'"  id="nav-general-tab" data-bs-toggle="tab" href="#nav-general" role="tab" aria-controls="nav-general" aria-selected="true">General</a></li>
                                                        <li class="nav-item"><a class="nav-link" @click="tab='saldos'"  id="nav-saldos-tab" data-bs-toggle="tab" href="#nav-saldos" role="tab" aria-controls="nav-saldos" aria-selected="false">Saldos</a></li>
                                                        <li class="nav-item"><a class="nav-link" @click="tab='conciliacion'" ref="conciliacion" id="nav-conciliacion-tab" data-bs-toggle="tab" href="#nav-conciliacion" role="tab" aria-controls="nav-conciliacion" aria-selected="false">Conciliación</a></li>
                                                    </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="container p-1 tab-pane fade show active" id="nav-general" role="tabpanel" aria-labelledby="nav-general-tab">
                                                        <div class="row ">
                                                            <lookUpCtrl v-if="banco.clave" :objeto="banco" :label="'Sucursal'" :cols="[3,3,6]" :archivo="'InSucursales'" :campo="'sucursal'" :modal="'sucursal'" :descripcion="'nombre'" v-model="banco.sucursal" />
                                                            
                                                            <div class="col-12">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Clave del Banco</label>
                                                                    <div class="col-4 ">
                                                                        <input type="text" ref="clave" class="form-control form-control-sm " :disabled="modo == 'Modificando'" maxlength="4"  v-model="banco.clave" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <lookUpCtrl v-if="banco.clave" :objeto="banco" :label="'Cuenta'" :cols="[3,3,6]" :archivo="'glCatalogo'" :campo="'cuenta'" :modal="'cuenta'" :descripcion="'descripcion'" v-model="banco.cuenta" />
                                                            
                                                            <div class="col-12">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Cuenta Bancaria</label>
                                                                    <div class="col-7">
                                                                        <input type="text" ref="cuenta" class="form-control form-control-sm "  maxlength="25" v-model="banco.cuenta" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-8 col-12">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap">Nombre del Banco</label>
                                                                            <div class="col-8">
                                                                                <input type="text" ref="banco" class="form-control form-control-sm " maxlength="20"   v-model="banco.banco" />		
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap">Nombre de Sucursal</label>
                                                                            <div class="col-8">
                                                                                <input type="text" ref="nsucursal" class="form-control form-control-sm " maxlength="20" v-model="banco.nsucursal" />		
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row ">
                                                                    <div class="col-12">
                                                                        <div class="row">
                                                                            <label class="col-4 text-nowrap">Uso de la Cuenta</label>
                                                                            <div class="col-8">
                                                                                <input type="text" ref="uso" class="form-control form-control-sm " maxlength="23" v-model="banco.uso" />		
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row ">
                                                                    <div class="col-12">
                                                                        <div class="row">
                                                                            <label class="col-4 text-nowrap text-right">Fmto Cheque</label>
                                                                            <div class="col-8">
                                                                                <select class="form-control form-control-sm" v-model="banco.formato">
                                                                                    <option v-for="(formato, key) in formatos" :key="key" :value="formato.clave">{{formato.clave}}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row ">
                                                                    <div class="col-12">
                                                                        <div class="row">
                                                                            <label class="col-4 text-nowrap text-right">Layout Transferencia</label>
                                                                            <div class="col-8">
                                                                                <select class="form-control form-control-sm" v-model="banco.formato">
                                                                                    <option v-for="(formato, key) in formatos" :key="key" :value="formato.clave">{{formato.clave}}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap">Moneda</label>
                                                                            <div class="col-5">
                                                                                <input type="text" ref="moneda" class="form-control form-control-sm" maxlength="3" v-model="banco.moneda" />		
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row ">
                                                                    <div class="col-12">
                                                                        <div class="row">
                                                                            <label class="col-4 text-nowrap text-right">Banco Clave SAT</label>
                                                                            <div class="col-8">
                                                                                <select class="form-control form-control-sm" v-model="banco.cvesat">
                                                                                    <option v-for="(formato, key) in formatos" :key="key" :value="formato.clave">{{formato.clave}}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap">RFC</label>
                                                                            <div class="col-8">
                                                                                <input type="text" ref="rfc" class="form-control form-control-sm" maxlength="15" v-model="banco.rfc" />		
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap">CLABE</label>
                                                                            <div class="col-8">
                                                                                <input type="text" ref="clabe" class="form-control form-control-sm" maxlength="18" v-model="banco.clabe" />		
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 col-12" v-show="gr_auxiliar_visible">
                                                                <div class="row">
                                                                    <div class="col-md-6 col-12">
                                                                        <div class=" row">
                                                                            <label class="col-5 text-nowrap">Auxiliares</label>
                                                                            <div class="col-7 d-flex">
                                                                                <input type="text" ref="banco" class="form-control form-control-sm" maxlength="1" v-model="banco.letra" />		
                                                                            </div>
                                                                        </div>

                                                                        <b-table-simple hover small caption-top responsive>
                                                                            <b-thead  head-variant="light"  class="table-header">
                                                                                <b-tr class="table-header">
                                                                                    <b-th>Descripción</b-th>
                                                                                    <b-th>Tipo</b-th>
                                                                                </b-tr>
                                                                            </b-thead>
                                                                            <b-tbody v-if="tipos.length > 0">
                                                                                <b-tr v-for="(tpo,x) in tipos"  
                                                                                        :key="x" 
                                                                                        :class="{'selected-row': (selectedTipo.indx == x) }" 
                                                                                        @click="selectedTipo=tpo;selectedTipo.indx = x " >
                                                                                    <b-td class="text-center">{{ tpo.descripcion }}</b-td>
                                                                                    <b-td class="text-center">{{ tpo.tipo}}</b-td>
                                                                                </b-tr>
                                                                            </b-tbody>
                                                                            <b-tbody v-else>
                                                                                <b-tr>
                                                                                    <b-td class="text-center" :variant="'secondary'" colspan="16"><p class="p-4">NO HAY DATOS</p></b-td>
                                                                                </b-tr>
                                                                            </b-tbody>
                                                                        </b-table-simple>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <fieldset>
                                                                    <div class="row">
                                                                        <div class="col-6">
                                                                            <div class=" row">
                                                                                <label class="col-6 text-nowrap">Ultimo No de Cheque</label>
                                                                                <div class="col-6">
                                                                                    <input type="number" ref="ultfolio" class="form-control form-control-sm" v-model="banco.ultfolio" />		
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <div class=" row">
                                                                                <label class="col-5 text-nowrap">Avisar antes de</label>
                                                                                <div class="col-4">
                                                                                    <input type="number" ref="cantaviso" class="form-control form-control-sm" v-model="banco.cantaviso" />		
                                                                                </div>
                                                                                <label class="col-3 text-nowrap">Cheques</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="container p-1 tab-pane fade" id="nav-saldos" role="tabpanel" aria-labelledby="nav-saldos-tab">
                                                        <div class="row">
                                                            <div class=" col-md-6 col-12">
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap">Inicial</label>
                                                                    <div class="col-8 d-flex">
                                                                        <input type="number" ref="sal_ini" step="any" min="0" class="form-control form-control-sm"  v-model="banco.sal_ini" />													
                                                                    </div>
                                                                </div>
                                                            </div>	
                                                            <div class=" col-md-6 col-12">
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap text-blue">{{banco.saldo_ini}}</label>
                                                                </div>
                                                            </div>	
                                                            <div class="col-md-6 col-12">
                                                                <div class=" row">
                                                                    <label class="col-4 offset-4">Sdo Bancos</label>
                                                                    <label class="col-4 text-nowrap">Sdo Contable</label>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap">Enero</label>
                                                                    <div class="col-8 d-flex">
                                                                        <input type="number" ref="sal_ene" step="any" min="0" class="form-control form-control-sm"  v-model="banco.sal_ene" />													
                                                                        <input type="number" ref="sal_ene2" step="any" min="0" class="form-control form-control-sm"  v-model="banco.sal_ene2" />													
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap">Febrero</label>
                                                                    <div class="col-8 d-flex">
                                                                        <input type="number" ref="sal_feb" step="any" min="0" class="form-control form-control-sm"   v-model="banco.sal_feb" />													
                                                                        <input type="number" ref="sal_feb2" step="any" min="0" class="form-control form-control-sm"  v-model="banco.sal_feb2" />													
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap">Marzo</label>
                                                                    <div class="col-8 d-flex">
                                                                        <input type="number" ref="sal_mar" step="any" min="0" class="form-control form-control-sm"   v-model="banco.sal_mar" />													
                                                                        <input type="number" ref="sal_mar2" step="any" min="0" class="form-control form-control-sm"  v-model="banco.sal_mar2" />													
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap">Abril</label>
                                                                    <div class="col-8 d-flex">
                                                                        <input type="number" ref="sal_abr" step="any" min="0" class="form-control form-control-sm"   v-model="banco.sal_abr" />													
                                                                        <input type="number" ref="sal_abr2" step="any" min="0" class="form-control form-control-sm"  v-model="banco.sal_abr2" />													
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap">Mayo</label>
                                                                    <div class="col-8 d-flex">
                                                                        <input type="number" ref="sal_may" step="any" min="0" class="form-control form-control-sm"   v-model="banco.sal_may" />													
                                                                        <input type="number" ref="sal_may2" step="any" min="0" class="form-control form-control-sm"  v-model="banco.sal_may2" />													
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap">Junio</label>
                                                                    <div class="col-8 d-flex">
                                                                        <input type="number" ref="sal_jun" step="any" min="0" class="form-control form-control-sm"   v-model="banco.sal_jun" />													
                                                                        <input type="number" ref="sal_jun2" step="any" min="0" class="form-control form-control-sm"  v-model="banco.sal_jun2" />													
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-12">
                                                                <div class=" row">
                                                                    <label class="col-4 offset-4">Sdo Bancos</label>
                                                                    <label class="col-4 text-nowrap">Sdo Contable</label>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap">Julio</label>
                                                                    <div class="col-8 d-flex">
                                                                        <input type="number" ref="sal_jul" step="any" min="0" class="form-control form-control-sm"   v-model="banco.sal_jul" />													
                                                                        <input type="number" ref="sal_jul2" step="any" min="0" class="form-control form-control-sm"  v-model="banco.sal_jul2" />													
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap">Agosto</label>
                                                                    <div class="col-8 d-flex">
                                                                        <input type="number" ref="sal_ago" step="any" min="0" class="form-control form-control-sm"   v-model="banco.sal_ago" />													
                                                                        <input type="number" ref="sal_ago2" step="any" min="0" class="form-control form-control-sm"  v-model="banco.sal_ago2" />													
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap">Septiembre</label>
                                                                    <div class="col-8 d-flex">
                                                                        <input type="number" ref="sal_sep" step="any" min="0" class="form-control form-control-sm"   v-model="banco.sal_sep" />													
                                                                        <input type="number" ref="sal_sep2" step="any" min="0" class="form-control form-control-sm"  v-model="banco.sal_sep2" />													
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap">Octubre</label>
                                                                    <div class="col-8 d-flex">
                                                                        <input type="number" ref="sal_oct" step="any" min="0" class="form-control form-control-sm"   v-model="banco.sal_oct" />													
                                                                        <input type="number" ref="sal_oct2" step="any" min="0" class="form-control form-control-sm"  v-model="banco.sal_oct2" />													
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap">Noviembre</label>
                                                                    <div class="col-8 d-flex">
                                                                        <input type="number" ref="sal_nov" step="any" min="0" class="form-control form-control-sm"   v-model="banco.sal_nov" />													
                                                                        <input type="number" ref="sal_nov2" step="any" min="0" class="form-control form-control-sm"  v-model="banco.sal_nov2" />													
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap">Diciembre</label>
                                                                    <div class="col-8 d-flex">
                                                                        <input type="number" ref="sal_dic" step="any" min="0" class="form-control form-control-sm"   v-model="banco.sal_dic" />													
                                                                        <input type="number" ref="sal_dic2" step="any" min="0" class="form-control form-control-sm"  v-model="banco.sal_dic2" />													
                                                                    </div>
                                                                </div>
                                                            </div>
                                                           
                                                        </div>	
                                                                                                    
                                                    </div>
                                                    <div class="container p-1 tab-pane fade" id="nav-conciliacion" role="tabpanel" aria-labelledby="nav-conciliacion-tab">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <h4 class="text-center">SALDOS CONCILIADOS</h4>
                                                            </div>	
                                                               
                                                            <div class="col-md-6 col-12 ">
                                                                <div class=" row">
                                                                    <div class="col-12 ">
                                                                        <div class=" row">
                                                                            <label class="col-5 text-nowrap">Sdo Inicial</label>
                                                                            <div class="col-7">
                                                                                <input type="number" step="any" min="0" class="form-control text-end form-control-sm" v-model="banco.sal_ini1" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>	
                                                            </div>
                                                            <div class="col-md-6 col-12 "></div>
                                                            <div class="col-md-6 col-12 ">
                                                                <div class=" row">
                                                                    <div class="col-12 ">
                                                                        <div class=" row">
                                                                            <label class="col-5 text-nowrap">Sdo Enero</label>
                                                                            <div class="col-7">
                                                                                <input type="number" step="any" min="0" class="form-control text-end form-control-sm" v-model="banco.sal_ene1" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>	
                                                                <div class=" row">
                                                                    <div class="col-12 ">
                                                                        <div class=" row">
                                                                            <label class="col-5 text-nowrap">Sdo Febrero</label>
                                                                            <div class="col-7">
                                                                                <input type="number" step="any" min="0" class="form-control text-end form-control-sm" v-model="banco.sal_feb1" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>	
                                                                <div class=" row">
                                                                    <div class="col-12 ">
                                                                        <div class=" row">
                                                                            <label class="col-5 text-nowrap">Sdo Marzo</label>
                                                                            <div class="col-7">
                                                                                <input type="number" step="any" min="0" class="form-control text-end form-control-sm" v-model="banco.sal_mar1" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>	
                                                                <div class=" row">
                                                                    <div class="col-12 ">
                                                                        <div class=" row">
                                                                            <label class="col-5 text-nowrap">Sdo Abril</label>
                                                                            <div class="col-7">
                                                                                <input type="number" step="any" min="0" class="form-control text-end form-control-sm" v-model="banco.sal_abr1" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>	
                                                                <div class=" row">
                                                                    <div class="col-12 ">
                                                                        <div class=" row">
                                                                            <label class="col-5 text-nowrap">Sdo Mayo</label>
                                                                            <div class="col-7">
                                                                                <input type="number" step="any" min="0" class="form-control text-end form-control-sm" v-model="banco.sal_may1" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>	
                                                                <div class=" row">
                                                                    <div class="col-12 ">
                                                                        <div class=" row">
                                                                            <label class="col-5 text-nowrap">Sdo Junio</label>
                                                                            <div class="col-7">
                                                                                <input type="number" step="any" min="0" class="form-control text-end form-control-sm" v-model="banco.sal_jun1" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>	
                                                            </div>
                                                            <div class="col-md-6 col-12 ">
                                                                <div class=" row">
                                                                    <div class="col-12 ">
                                                                        <div class=" row">
                                                                            <label class="col-5 text-nowrap">Sdo Julio</label>
                                                                            <div class="col-7">
                                                                                <input type="number" step="any" min="0" class="form-control text-end form-control-sm" v-model="banco.sal_jul1" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>	
                                                                <div class=" row">
                                                                    <div class="col-12 ">
                                                                        <div class=" row">
                                                                            <label class="col-5 text-nowrap">Sdo Agosto</label>
                                                                            <div class="col-7">
                                                                                <input type="number" step="any" min="0" class="form-control text-end form-control-sm" v-model="banco.sal_ago1" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>	
                                                                <div class=" row">
                                                                    <div class="col-12 ">
                                                                        <div class=" row">
                                                                            <label class="col-5 text-nowrap">Sdo Septiembre</label>
                                                                            <div class="col-7">
                                                                                <input type="number" step="any" min="0" class="form-control text-end form-control-sm" v-model="banco.sal_sep1" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>	
                                                                <div class=" row">
                                                                    <div class="col-12 ">
                                                                        <div class=" row">
                                                                            <label class="col-5 text-nowrap">Sdo Octubre</label>
                                                                            <div class="col-7">
                                                                                <input type="number" step="any" min="0" class="form-control text-end form-control-sm" v-model="banco.sal_oct1" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>	
                                                                <div class=" row">
                                                                    <div class="col-12 ">
                                                                        <div class=" row">
                                                                            <label class="col-5 text-nowrap">Sdo Noviembre</label>
                                                                            <div class="col-7">
                                                                                <input type="number" step="any" min="0" class="form-control text-end form-control-sm" v-model="banco.sal_nov1" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>	
                                                                <div class=" row">
                                                                    <div class="col-12 ">
                                                                        <div class=" row">
                                                                            <label class="col-5 text-nowrap">Sdo Diciembre</label>
                                                                            <div class="col-7">
                                                                                <input type="number" step="any" min="0" class="form-control text-end form-control-sm" v-model="banco.sal_dic1" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>	
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="col-md-3 col-12">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li class="mobile-float"><button @click="setBanco()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li  class="mobile-float"><router-link to="/bancos" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <InfoPopUpVue  v-show="showNotification" @close="closeInfo">
                        <template v-slot:header>
                            <h6 class="text-uppercase">{{notificationTitle}}</h6>
                        </template>
                        <template v-slot:body>
                            {{notificationText}}
                        </template>
                    </InfoPopUpVue>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import InfoPopUpVue from '@/components/InfoPopUp.vue';
import handleGlobals from '@/mixins/handleGlobals'
import handlePopUp from '@/mixins/handlePopUp'
import handleForm from '@/mixins/handleForm'
import LoadingContent from '@/components/LoadingSpinner.vue'
import lookUpCtrl from '@/components/LookUpCtrl.vue'
import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    mixins:[handleGlobals,handlePopUp,handleForm],
    components:{SideBar,TopBar,InfoPopUpVue,LoadingContent,lookUpCtrl},
    data(){
        return{
            id: this.$route.params.banco,
            modo: (this.$route.params.banco != '' && this.$route.params.banco != undefined ? 'Modificando' : 'Creacion'), 
            banco: [],
            titulo: 'Agregando un Banco',  
            tipos:[ {descripcion: 'Ingresos', tipo: 'IX'},
                    {descripcion: 'Egresos', tipo: 'EX'},
                    {descripcion: 'Transferencias', tipo: 'TX'}],
            gr_auxiliar_visible: false,
            selectedTipo:{}
        }
    },
    mounted(){
        this.getBanco();
    },
    methods:{
        async getBanco(){
            this.isLoading = true
            let req =[]
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "inicializar",modo:this.modo,  archivo: "glBancos", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.banco = data['res'];
                this.gr_auxiliar_visible = this.banco.gr_auxiliar_visible
                req = data['req']
            }).finally(()=>{
               this.setRequired(req)
               this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setBanco(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: 'validar', archivo: "glBancos", modo:this.modo, id: this.id, record: this.banco }) 
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['res'] == 'error'){
                    this.showNotification = true
                    this.notificationTitle =  'Error'
                    this.notificationText = data['msg']
                    this.notificationRef = 'clave'
                }else{
                    if(data['msg'] != ''){
                        this.showNotification = true
                        this.notificationTitle = 'Error'
                        this.notificationText = data['msg']
                        return
                    }else{
                        this.$router.push('/bancos')
                    }
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        afterClose(){
            this.$refs[this.notificationRef].focus()
        }
     
    }
}
</script>