<template>
    <section>
        <SideBar v-if="!OpenMode" />
        <TopBar v-if="!OpenMode" />
        <div :class="[!OpenMode ? 'content' : '' ]">   
        <div class="main-content-header">
            <h1>Relaciones de Entrega de Facturas</h1>
        </div>
        <LoadingContent v-if="isLoading" />
        <div class="row">
            <div class="col-lg-12 col-md-6">
                <div class="card mb-30" :style="{height:[contentHeight+'px']}">
                    <div class="row " :class="[!OpenMode ? 'p-4' : '']">
                        <div class="content-header  col-12"> 
                            <div class="row">
                                <div class="col-md-10 col-12 text-start "></div>
                                <div class="col-md-2 col-12"><p class="p-1 pe-4 mb-0 text-end">Página {{ paginaActual }} de {{ totalPaginas }} </p></div>
                                <div class="col-md-2 offset-md-10 col-12 ps-2 pe-5 text-end pb-0 pt-0">
                                    <ul class="list-unstyled mb-0 d-flex justify-content-between pager-btns float-right">
                                        <li><button @click="setPaginacion('first')" type="button" class="pager pager-first btn btn-sm btn-outline-secondary"><ChevronDoubleLeftIcon /></button></li>
                                        <li><button @click="setPaginacion('prev')"  type="button" class="pager pager-prev  btn btn-sm btn-outline-secondary"><ChevronLeftIcon /></button></li>
                                        <li><button @click="setPaginacion('next')"  type="button" class="pager pager-next  btn btn-sm btn-outline-secondary"><ChevronRightIcon /></button></li>
                                        <li><button @click="setPaginacion('last')"  type="button" class="pager pager-last  btn btn-sm btn-outline-secondary"><ChevronDoubleRightIcon /></button></li> 
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-10 col-12">
                            <div class="table-responsive ps-2">
                                <b-table-simple class="browse" hover small caption-top responsive >
                                    <b-thead head-variant="light" class="table-header">
                                        <b-tr>
                                            <b-th class="text-nowrap">Folio</b-th>
                                            <b-th class="text-nowrap">Fecha Captura</b-th>
                                            <b-th class="text-nowrap">Cliente</b-th>
                                            <b-th class="text-nowrap">Nombre</b-th>
                                            <b-th class="text-nowrap">Fecha Entrega</b-th>
                                            <b-th class="text-nowrap">F.Pago</b-th>
                                            <b-th class="text-nowrap">Pesos</b-th>
                                            <b-th class="text-nowrap">Dolares</b-th>
                                            <b-th v-if="$ext == 'HER' || $ext == 'THR' || $ext == 'TRA'"  class="text-nowrap">Folio HEB</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="(rel,indx) in relaciones" :key="rel.numid" 
                                            @click="selectedRegistro=rel;selectedRegistro.indx = indx" 
                                            @dblclick="(!OpenMode ? modificar() : exportRegistry()) " 
                                            :class="{ 'selected-row': (selectedRegistro.numid == rel.numid) }">
                                            <b-td class="text-nowrap text-center">{{ rel.numid | leadingZeros(7)}}</b-td>
                                            <b-td class="text-nowrap text-end">{{ formato( rel.fechacaptura,"MMM-DD-YYYY") }}</b-td>
                                            <b-td class="text-nowrap text-center">{{ rel.cliente | leadingZeros(5) }}</b-td>
                                            <b-td class="text-nowrap">{{ rel.cliente_nombre}}</b-td>
                                            <b-td class="text-nowrap text-end">{{ formato( rel.fechaentrega,"MMM-DD-YYYY") }}</b-td>
                                            <b-td class="text-nowrap text-end">{{ formato( rel.fechapago,"MMM-DD-YYYY") }}</b-td>
                                            <b-td class="text-nowrap text-end">{{ rel.mn}}</b-td>
                                            <b-td class="text-nowrap text-end">{{ rel.dls}}</b-td>
                                            <b-td v-if="$ext == 'HER' || $ext == 'THR' || $ext == 'TRA'"  class="text-nowrap text-center">{{ rel.folio_heb | leadingZeros(7)}}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </div>
                        </div> 
                        <div class="col-2 ps-2 pe-5 btn-manager" >
                            <ul class="d-block list-unstyled action-block">
                                <li v-if="!OpenMode"><router-link to="/relacion/agregar" class="btn btn-xs btn-success btn-block">Agregar</router-link></li>
                                <li v-if="!OpenMode"><button @click="modificar()" :disabled="disableBtn" type="button" class="btn btn-xs btn-primary btn-block">Cambiar</button></li>
                                <li v-if="!OpenMode"><button @click="ask()" :disabled="disableBtn" class="btn btn-xs btn-danger btn-block">Borrar</button></li>
                                <li v-if="!OpenMode"><button @click="imprimir()" class="btn btn-xs btn-secondary btn-block">Imprimir</button></li>
                                <li v-if="OpenMode"><button @click="exportRegistry()" class="btn btn-xs btn-success btn-block">Elegir</button></li>
                            </ul>

                            <div class="row">
                                <label for="est" class="col-5 text-nowrap text-end">Mostrar</label>
                                <div class="col-7">
                                    <select id="est" v-model="filtroFechaentrega"  @change="consultar()"  class="form-control form-control-sm">
                                        <option value="X">X Entregar</option>
                                        <option value="T">Todas</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        
                        <div class="content-footer col-12 text-start"></div>
                    </div>
                </div>
            </div>
        </div>
        <DeleteReg  v-show="showDelete" @close="closeDelete" @aceptar="relaciones = borrar('Relaciones',relaciones)">
            <template v-slot:header>
                <h6 class="text-uppercase">{{notificationTitle}}</h6>
            </template>
            <template v-slot:body>
                {{notificationText}}
            </template>
        </DeleteReg>
    </div>
    </section>
</template>

<script>
import DeleteReg from '@/components/YesNoPopup.vue';
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronDoubleRightIcon from 'vue-material-design-icons/ChevronDoubleRight.vue';
import ChevronDoubleLeftIcon from 'vue-material-design-icons/ChevronDoubleLeft.vue';
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'
import handleBrowse from '@/mixins/handleBrowse'
import handlePopUp from '@/mixins/handlePopUp'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
import axios from 'axios';
export default {
    name:'WIN_Relaciones',
    props:['mode','perpage'],
    mixins:[handleGlobals,handleBrowse,handlePopUp],
    components:{SideBar,TopBar,ChevronLeftIcon,ChevronDoubleRightIcon,ChevronDoubleLeftIcon,ChevronRightIcon, LoadingContent,DeleteReg},
    data(){
        return{
            relaciones: [],
            filtroFechaentrega:''
        }
    },
    created(){
        if(this.OpenMode == false){
            this.getFiltros()
        } 
    },
    methods:{
        async getFiltros(){
                await fetch(this.$api+'/rdx', {    
                    method: "POST",
                    headers: {
                        'Content-Type' : 'application/json',
                        'Authorization' : this.$store.state.iasctratkn
                    },
                    body: JSON.stringify({accion: "filters"})
                }).then((response)=>{
                    if(response.ok){
                        return response.json()
                    }
                }).then((data)=>{
                    let reg = data['RELF']
                    if(reg){
                        this.filtroFechaentrega = reg['fechaentrega'].dato
                        this.paginaActual       = reg['paginaActual'].dato
                        if(this.paginaActual == '') this.paginaActual = 0
                    }else{
                        this.paginaActual = 1
                        this.filtroFechaentrega = 'T'
                    }
                }).catch((err)=>{
                    this.falseToken(err)
                });
        },
        consultar(){
            this.isLoading = true
            axios.post(this.$api+'/rdx',{ accion: "browse", 
                                        archivo: "Relaciones", 
                                        activarPaginas: true,
                                        orden: "numid",
                                        filtro: {
                                            fechaentrega: {dato: this.filtroFechaentrega,inactivo: (this.filtroFechaentrega == 'T')},
                                            paginaActual: {dato: 'NA'}},
                                        pagina: this.paginaActual, 
                                        paginacion: this.paginacion,
                                        registrosxpagina: this.rows },{
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
            }).then((res)=>{
                if(res.msg){
                    this.msg_err = res.msg
                }else{
                    this.relaciones     = res.data['registros'];
                    this.totalRegistros = res.data['totalRegistros'];
                    this.totalPaginas   = res.data['totalPaginas'];
                    this.paginaActual   = res.data['paginaActual'];
                }
            }).finally(()=>{
                this.isLoading = false;
            }).catch((err)=>{
              console.log(err)
            }).finally(()=>{
                this.isLoading = false;
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        imprimir(){

        },
        modificar(){
            this.$router.push({ name: 'CRelacion', params: {relacion: this.selectedRegistro.numid}});
        }
    }
}
</script>

