import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import Notifications from 'vue-notification'
import VuePaginate from 'vue-paginate'
import { Buffer } from 'buffer';
import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import 'vue-material-design-icons/styles.css';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import VueHotkey from 'v-hotkey'
window.Buffer = Buffer;

Vue.use(VueHotkey)
Vue.component('v-select', vSelect)

Vue.use(BootstrapVue);
Vue.use(require('vue-shortkey'))
Vue.use(Notifications)

Vue.config.productionTip = false

const empresas = [{extension: 'test', url: 'https://transportes.sistemasfussion.org'}, //'https://iasc.dnsalias.net'
                  {extension: 'rhi', url: 'https://iasc.dnsalias.net'},
                  {extension: 'cst', url: 'https://iasc.dnsalias.net'},
                  {extension: 'avm', url: 'https://server.test'}]
const ext = window.location.hostname.split('-')[0]
const extension =  empresas.find(e => e.extension == ext)

Vue.prototype.$apiCte = extension.url + '/TransporteWeb'           //variable global
Vue.prototype.$token = localStorage.getItem('iasctratkn')  //variable global
Vue.prototype.$api = 'https://api-transporte.ingavanzada.com'  //variable global
Vue.prototype.$ext =  extension.extension
Vue.prototype.$sext = ''
Vue.use(VuePaginate)
Vue.use(require('vue-moment'));
require('moment/locale/es')


Vue.filter('leadingZeros', function (num,size) {
  let tnum = (num != 0 && num != undefined) ? num.toString() : '';
  while (tnum.length < size) tnum = "0" + tnum;
  return (num == 0 ? '' : tnum)
  /* use 
  <td class="text-right">
    {{ invoice.fees | leadingZeros(3) }}
  </td>*/
  
});

Vue.filter('toThousands', function (value) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 0
  })

  /* use 
  <td class="text-right"> 
    {{ invoice.fees | toCurrency }}
  </td>*/
  return (value == 0 ? '' : formatter.format(value))
});

Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('es-MX', {
      style: "decimal",
      minimumFractionDigits: 2,
  })

  /* use 
  <td class="text-right"> 
    {{ invoice.fees | toCurrency }}
  </td>*/
  return (value == 0 ? '' : formatter.format(value))
});

Vue.filter('tipoCambio', function (value) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('es-MX', {
    style: "decimal",
    minimumFractionDigits: 4
  })
  return (value == 0 ? '' : formatter.format(value))
});
  
Vue.filter('toDecimal', function (value,decimal) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('es-MX', {
    style: "decimal",
    minimumFractionDigits: 1,
    maximumFractionDigits: decimal
  })

  return (value == 0 ? '' : formatter.format(value))
});

Vue.filter('important', function (value) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('es-MX', {
    style: "decimal",
    minimumFractionDigits: 2,
  })
 
  return formatter.format(value);
});


new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')