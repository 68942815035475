<template>
    <section>
        <SideBar v-if="!OpenMode" />
        <TopBar v-if="!OpenMode" />
        <div :class="[!OpenMode ? 'content' : '' ]">   
        <div class="main-content-header">
            <h1>Control de Tráfico</h1>
        </div>
        <LoadingContent v-if="isLoading" />
        <div class="row">
            <div class="col-lg-12 col-md-6">
                <div class="card mb-30" :style="{height:[contentHeight+'px']}">
                    <div class="row " :class="[!OpenMode ? 'p-4' : '']">
                        <div class="content-header col-12"> 
                            <div class="row">
                                <div class="col-md-6 col-12 text-start"></div>
                                <div class="col-md-6 col-12"><p class="p-1 pe-4 text-end">Página {{ paginaActual }} de {{ totalPaginas }} </p></div>

                                <div class="col-md-2 col-12 ps-4 pe-4 text-end">
                                    <div class="form-group d-flex">
                                        <label class="text-nowrap mb-0 pe-1">Mostrar</label>
                                        <select @change="consultar()" v-model="filtroxestatus" class="form-control form-control-sm" >
                                            <option value="Todos">Todos</option>
                                            <option value="Abiertos">Abiertos</option>
                                            <option value="Programados">Programados</option>
                                            <option value="Activos">Activos</option>
                                            <option value="Cancelados">Cancelados</option>
                                            <option value="Desp. Hoy">Desp. Hoy</option>
                                            <option value="15 Dias">15 Días</option>
                                            <option value="Terminados">Terminados</option>
                                            <option value="Sin Fact/Rem">Sin Fact/Rem</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-2 col-6 ps-4">
                                    <div class="form-group">
                                        <div class="form-check">
                                            <input class="form-check-input" v-model="filtroSucursales" @change="consultar()" type="checkbox" id="sucursales">
                                            <label class="form-check-label" for="sucursales">
                                                Todas las Sucursales
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-6 ps-4">
                                    <div class="form-group">
                                        <div class="form-check">
                                            <input class="form-check-input" v-model="filtroSoloMisTráficos" @change="consultar()" type="checkbox" id="traficos">
                                            <label class="form-check-label" for="traficos">
                                                Solo mis Tráficos
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-6 ps-4">
                                    <div class="form-group" v-if="visible_filtroMisGrupos">
                                        <div class="form-check">
                                            <input class="form-check-input" v-model="filtroMisGrupos" @change="consultar()" type="checkbox" id="grupos">
                                            <label class="form-check-label" for="grupos">
                                                Mis Grupos
                                            </label>
                                        </div>
                                    </div>
                                </div>
                               
                                <div class="col-md-2 col-12 offset-md-2 ps-2 pe-5 text-end pb-0 pt-0">
                                    <ul class="list-unstyled mb-0 d-flex justify-content-between pager-btns float-right">
                                        <li><button @click="setPaginacion('first')" type="button" class="pager pager-first btn btn-sm btn-outline-secondary"><ChevronDoubleLeftIcon /></button></li>
                                        <li><button @click="setPaginacion('prev')"  type="button" class="pager pager-prev  btn btn-sm btn-outline-secondary"><ChevronLeftIcon /></button></li>
                                        <li><button @click="setPaginacion('next')"  type="button" class="pager pager-next  btn btn-sm btn-outline-secondary"><ChevronRightIcon /></button></li>
                                        <li><button @click="setPaginacion('last')"  type="button" class="pager pager-last  btn btn-sm btn-outline-secondary"><ChevronDoubleRightIcon /></button></li> 
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-10 col-12">
                            <div class="table-responsive ps-2">
                                <b-table-simple class="browse" hover small caption-top responsive>
                                    <b-thead head-variant="light" class="table-header">
                                        <b-tr>
                                            <b-th :colspan="(gps+parametros.bitacora_visible+parametros.GR_Transfer_visible)+7"></b-th>
                                            <b-th colspan="2" v-if="parametros.GR_Transfer_visible">Tramo 1</b-th>
                                            <b-th colspan="2" v-if="parametros.GR_Transfer_visible">Tramo 2</b-th>
                                            <b-th :colspan="((parametros.division_visible*2)+parametros.origendestino2_visible+parametros.origendestino_visible+parametros.origendestinocd_visible+parametros.Transfer_visible)+20"></b-th>
                                            <b-th colspan="3" class="text-center">S O L I C I T A D O</b-th>
                                            <b-th colspan="3" class="text-center">S A L I D A</b-th>
                                            <b-th colspan="3" class="text-center">D E S P A C H O</b-th>
                                            <b-th colspan="2" class="text-center">R E T O R N O</b-th>
                                            <b-th colspan="3" class="text-center">Kilómetros</b-th>
                                            <b-th colspan="2" class="text-center">Bitácora</b-th>
                                            <b-th colspan="8" class="text-center">L I G A S</b-th>
                                        </b-tr>
                                        <b-tr>
                                            <b-th class="text-nowrap">D</b-th>
                                            <b-th class="text-nowrap" v-if="gps">G</b-th> 
                                            <b-th class="text-nowrap">Con Cargo</b-th>
                                            <b-th class="text-nowrap">Suc</b-th> 
                                            <b-th class="text-nowrap">Folio</b-th>
                                            <b-th class="text-nowrap" v-if="parametros.bitacora_visible">Bitácora</b-th>     
                                            <b-th class="text-nowrap">Ref.#</b-th>
                                            <b-th class="text-nowrap">E</b-th>
                                            <b-th class="text-nowrap">Fecha</b-th>
                                            <b-th class="text-nowrap" v-if="parametros.GR_Transfer_visible">TV</b-th>

                                            <b-th class="text-nowrap" v-if="parametros.GR_Transfer_visible">Operador</b-th>    
                                            <b-th class="text-nowrap" v-if="parametros.GR_Transfer_visible">Unidad</b-th>      

                                            <b-th class="text-nowrap" v-if="parametros.GR_Transfer_visible">Operador</b-th>    
                                            <b-th class="text-nowrap" v-if="parametros.GR_Transfer_visible">Unidad</b-th>      


                                            <b-th class="text-nowrap">Cliente</b-th>
                                            <b-th class="text-nowrap">Nombre</b-th>
                                            <b-th class="text-nowrap" v-if="parametros.division_visible">División</b-th> 
                                            <b-th class="text-nowrap" v-if="parametros.division_visible">Nombre</b-th>
                                            <b-th class="text-nowrap">Tpo Unidad</b-th>
                                            <b-th class="text-nowrap">Ruta</b-th>
                                            <b-th class="text-nowrap">Servicio</b-th>
                                            <b-th class="text-nowrap" v-if="parametros.origendestino2_visible">Origen / Destino</b-th> 
                                            <b-th class="text-nowrap" v-if="parametros.origendestino_visible">Origen / Destino</b-th>  
                                            <b-th class="text-nowrap" v-if="parametros.origendestinocd_visible">Origen / Destino</b-th>
                                            <b-th class="text-nowrap">Estatus</b-th>
                                            <b-th class="text-nowrap">Nombre Operador</b-th>
                                            <b-th class="text-nowrap">SubContrato</b-th>
                                            <b-th class="text-nowrap">Pedimento</b-th>
                                            <b-th class="text-nowrap">Carga</b-th>
                                            <b-th class="text-nowrap">Peso</b-th>
                                            <b-th class="text-nowrap">Volumen</b-th>
                                            <b-th class="text-nowrap">Operador</b-th>
                                            <b-th class="text-nowrap">Operador 2</b-th>
                                            <b-th class="text-nowrap">Unidad</b-th>
                                            <b-th class="text-nowrap">Economico</b-th>
                                            <b-th class="text-nowrap">Placa</b-th>
                                            <b-th class="text-nowrap" v-if="parametros.Transfer_visible">Transfer</b-th> 
                                            <b-th class="text-nowrap">Remolque</b-th>
                                            <b-th class="text-nowrap">Remolque Ext.</b-th>
                                            <b-th class="text-nowrap">Fianza</b-th>

                                            <b-th class="text-nowrap">Nombre</b-th>
                                            <b-th class="text-nowrap">Fecha</b-th>
                                            <b-th class="text-nowrap">Hora</b-th>

                                            
                                            <b-th class="text-nowrap">Nombre</b-th>
                                            <b-th class="text-nowrap">Fecha</b-th>
                                            <b-th class="text-nowrap">Hora</b-th>

                                            <b-th class="text-nowrap">Nombre</b-th>
                                            <b-th class="text-nowrap">Fecha</b-th>
                                            <b-th class="text-nowrap">Hora</b-th>

                                            <b-th class="text-nowrap">Fecha</b-th>
                                            <b-th class="text-nowrap">Hora</b-th>

                                            <b-th class="text-nowrap">Salida</b-th>
                                            <b-th class="text-nowrap">Retorno</b-th>
                                            <b-th class="text-nowrap">Recorrido</b-th>

                                            <b-th class="text-nowrap">Operador</b-th>
                                            <b-th class="text-nowrap">Unidad</b-th>

                                            <b-th class="text-nowrap">Liquidación</b-th>
                                            <b-th class="text-nowrap">Suc</b-th>
                                            <b-th class="text-nowrap">Remisión</b-th>
                                            <b-th class="text-nowrap">Suc</b-th>
                                            <b-th class="text-nowrap">Factura</b-th>
                                            <b-th class="text-nowrap">Año</b-th>
                                            <b-th class="text-nowrap">Periodo</b-th>
                                            <b-th class="text-nowrap">Usuario</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="trafico in traficos" 
                                                :key="trafico.folio" 
                                                @click="selectedRegistro = trafico" 
                                                @dblclick=" (!OpenMode ? modificar() : exportRegistry()) " 
                                                :style="{backgroundColor: getColor(trafico) }"
                                                :class="{'selected-row': (selectedRegistro.folio == trafico.folio) }">

                                            <b-td class="text-nowrap" v-if="gps"><FileDocumentOutlineIcon v-if="trafico.doctos > 0" /></b-td>
                                            <b-td class="text-nowrap">
                                                <img v-if="trafico.gpsestatus >=1 && trafico.gpsestatus <= 2"  src="@/assets/no-ubicacion-no-datos.png" alt="No hay Ubicacion / No hay datos" />
                                                <img v-if="trafico.gpsestatus == 3" src="@/assets/si-ubicacion-no-datos.png" alt="Si hay Ubicacion / No hay datos" />
                                                <img v-if="trafico.gpsestatus >= 4 && trafico.gpsestatus >=6" src="@/assets/no-ubicacion-si-datos.png" alt="No hay Ubicacion / Si hay datos" />
                                                <img v-if="trafico.gpsestatus == 7" src="@/assets/si-ubicacion-si-datos.png" alt="Si hay Ubicacion / Si hay datos" />
                                            </b-td>
                                            <b-td class="text-nowrap">{{ trafico.con_cargo}}</b-td>
                                            <b-td class="text-center text-nowrap">{{ trafico.sucursal| leadingZeros(3)}}</b-td>
                                            <b-td class="text-center text-nowrap" >{{ trafico.folio | leadingZeros(7)}}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.referencia }}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.bentregada }}</b-td>
                                            <b-td class="text-nowrap">{{ formato(trafico.fecha,'MMM-DD-YYYY') }}</b-td>
                                            <b-th class="text-nowrap" v-if="parametros.GR_Transfer_visible">{{trafico.tpoviaje}}</b-th>
                                            <b-th class="text-nowrap" v-if="parametros.GR_Transfer_visible">{{trafico.opep1}}</b-th>    
                                            <b-th class="text-nowrap" v-if="parametros.GR_Transfer_visible">{{ trafico.udadp1 }}</b-th>      
                                            <b-th class="text-nowrap" v-if="parametros.GR_Transfer_visible">{{trafico.opep2}}</b-th>    
                                            <b-th class="text-nowrap" v-if="parametros.GR_Transfer_visible">{{trafico.udadp2}}</b-th>      
                                            <b-td class="text-center text-nowrap">{{ trafico.cliente | leadingZeros(5)}}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.cliente_nombre}}</b-td>
                                            <b-td class="text-nowrap" v-if="parametros.division_visible">{{ trafico.division}}</b-td>
                                            <b-td class="text-nowrap" v-if="parametros.division_visible">{{ trafico.division_nombre}}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.tpounidad}}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.ruta  | leadingZeros(7)}}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.servicio}}</b-td>
                                            <b-td class="text-nowrap" v-if="parametros.origendestino2_visible">{{ trafico.origendestino2}}</b-td>
                                            <b-td class="text-nowrap" v-if="parametros.origendestino_visible">{{ trafico.des_origendestino}}</b-td>
                                            <b-td class="text-nowrap" v-if="parametros.origendestinocd_visible">{{ trafico.des_origendestinocd}}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.estatus_desc }}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.operador_nombre}}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.subcontratado }}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.pedimento }}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.carga }}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.peso }}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.volumen }}</b-td>
                                            <b-td class="text-center text-nowrap">{{ trafico.operador | leadingZeros(5) }}</b-td>
                                            <b-td class="text-center text-nowrap">{{ trafico.operador2 | leadingZeros(5) }}</b-td>
                                            <b-td class="text-center text-nowrap">{{ trafico.unidad | leadingZeros(6) }}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.economico }}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.placa }}</b-td>
                                            <b-td class="text-nowrap" v-if="parametros.Transfer_visible">{{ trafico.transfer }}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.remolque }}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.remolqueext }}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.fianza }}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.solnombre }}</b-td>
                                            <b-td class="text-nowrap">{{ formato(trafico.solfecha,'MMM-DD-YYYY') }}</b-td>
                                            <b-td class="text-nowrap">{{ formato(trafico.solhora,'hh:mm:ss') }}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.salidanombre }}</b-td>
                                            <b-td class="text-nowrap">{{ formato(trafico.salidafecha,'MMM-DD-YYYY') }}</b-td>
                                            <b-td class="text-nowrap">{{ formato(trafico.salidahora,'hh:mm:ss') }}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.desnombre }}</b-td>
                                            <b-td class="text-nowrap">{{ formato(trafico.desfecha,'MMM-DD-YYYY') }}</b-td>
                                            <b-td class="text-nowrap">{{ formato(trafico.deshora,'hh:mm:ss') }}</b-td>
                                            <b-td class="text-nowrap">{{ formato(trafico.retornofecha,'MMM-DD-YYYY') }}</b-td>
                                            <b-td class="text-nowrap">{{ formato(trafico.retornohora,'hh:mm:ss') }}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.kmssalida }}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.kmsretorno }}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.recorridos }}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.bitacoraope }}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.bitacorauni }}</b-td>
                                            <b-td class="text-center text-nowrap">{{ trafico.liquidadcion | leadingZeros(7) }}</b-td>
                                            <b-td class="text-center text-nowrap">{{ trafico.sucremision | leadingZeros(3) }}</b-td>
                                            <b-td class="text-center text-nowrap">{{ trafico.remision | leadingZeros(7) }}</b-td>
                                            <b-td class="text-center text-nowrap">{{ trafico.sucfactura | leadingZeros(3) }}</b-td>
                                            <b-td class="text-center text-nowrap">{{ trafico.factura | leadingZeros(7) }}</b-td>
                                            <b-td class="text-center text-nowrap">{{ trafico.ano | leadingZeros(4) }}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.periodo }}</b-td>
                                            <b-td class="text-nowrap">{{ trafico.usuario }}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </div>
                        </div> 
                        <div class="col-2 ps-2 pe-5 btn-manager" >
                            <ul class="d-block list-unstyled action-block">
                                <li v-if="!OpenMode"><router-link to="/traficos/agregar" class="btn btn-xs btn-success btn-block">Agregar</router-link></li>
                                <li v-if="!OpenMode"><button @click="modificar()" :disabled="disableBtn" type="button" class="btn btn-xs btn-primary btn-block">Cambiar</button></li>
                                <li v-if="!OpenMode"><button @click="ask()" :disabled="disableBtn" class="btn btn-xs btn-danger btn-block">Borrar</button></li>
                               
                                <li v-if="!OpenMode">
                                    <div class="dropdown">
                                        <button class="btn btn-xs btn-secondary btn-block" type="button" data-toggle="dropdown" aria-expanded="false">
                                            Imprimir
                                        </button>
                                        <div class="dropdown-menu">
                                            <span class="dropdown-item" @click="isPrintPreviewVisible=true;reporte = 'Boleta'">Reporte o Factura Global</span>
                                            <span class="dropdown-item" @click="isPrintPreviewVisible=true;reporte = 'Boleta'">Facturar Tráfico</span>
                                            <span class="dropdown-item" @click="isPrintPreviewVisible=true;reporte = 'Boleta'">Factura Tráfico por Concepto</span>
                                            <span class="dropdown-item" @click="isPrintPreviewVisible=true;reporte = 'Boleta'">Genera Carta Porte</span>
                                            <span class="dropdown-item" @click="isPrintPreviewVisible=true;reporte = 'Boleta'">Imprime Boleta</span>
                                            <span class="dropdown-item" @click="isPrintPreviewVisible=true;reporte = 'Boleta'">Boleta Nueva</span>
                                            <span class="dropdown-item" @click="showMovimientosOT= true">Movimientos de OTs</span>
                                            <span class="dropdown-item" @click="isPrintPreviewVisible=true;reporte = 'Boleta'" v-if="parametros.Refacturar_Visible">Refacturar</span>
                                        </div>
                                    </div>
                                </li>
                                <li v-if="!OpenMode"><button @click="cancelar()" class="btn btn-xs btn-secondary btn-block">Cancelar</button></li>
                                <li v-if="!OpenMode"><button @click="liberar()" class="btn btn-xs btn-secondary btn-block">Liberar</button></li>
                                <li v-if="!OpenMode"><button @click="ligarRem()" class="btn btn-xs btn-secondary btn-block  text-nowrap">Ligar Rem.</button></li>
                                <li v-if="!OpenMode"><button @click="generaVacio()" class="btn btn-xs btn-secondary btn-block  text-nowrap">Gen. Vacio</button></li>
                                <li v-if="!OpenMode">
                                    <div class="dropdown">
                                        <button class="btn btn-xs btn-secondary btn-block" type="button" data-toggle="dropdown" aria-expanded="false">
                                            Vales
                                        </button>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item" @click="anticipos()" href="#">Anticipos</a>
                                            <a class="dropdown-item" @click="valesDiesel()" href="#">Vales Diesel</a>
                                        </div>
                                    </div>    
                                </li>
                                <li v-if="!OpenMode"><button @click="ControlDoc()" class="btn btn-xs btn-secondary btn-block text-nowrap">Control Doc</button></li>
                                <li v-if="!OpenMode"><button v-if="parametros.BTN_Importar_visible" @click="importar()" class="btn btn-xs btn-secondary btn-block  text-nowrap">Importar 204</button></li>
                                <li v-if="!OpenMode"><button @click="toggleDetallesTrafico()" class="btn btn-xs btn-secondary btn-block  text-nowrap">F5 - Informativo</button></li>
                                <li v-if="OpenMode"><button @click="exportRegistry()" class="btn btn-xs btn-success btn-block">Elegir</button></li>
                            </ul>
                        </div>
                        
                        <div class="content-footer col-12 text-start"></div>
                    </div>
                </div>
            </div>
        </div>
        <DeletePopUp  v-show="showDelete" @close="closeDelete()" @aceptar="traficos = borrar('Traficos',traficos)">
            <template v-slot:header>
                <h6 class="text-uppercase">{{notificationTitle}}</h6>
            </template>
            <template v-slot:body>
                {{notificationText}}
            </template>
        </DeletePopUp>
        <InformativoF5 v-if="showInformativo" v-hotkey="keymapLocal" @close="closePopup" @goAhead="setDetalles" >
            <template v-slot:title><h1>DATOS INFORMATIVOS DEL TRAFICO</h1></template>
            <template v-slot:body>
                <div class="row p-3">
                    <div class="col-6">
                        <div class="row" >
                            <label class="col-4 text-nowrap ">Folio</label>
                            <div class="col-7">
                                <input type="number" class="form-control form-control-sm " v-model="selectedTrafico.folio" />		
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row" >
                            <label class="col-5 text-nowrap ">Referencia</label>
                            <div class="col-7">
                                <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.referencia" />		
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row" >
                            <label class="col-2 text-nowrap ">Remolque Externo</label>
                            <div class="col-3">
                                <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.remolque" />		
                            </div>
                        </div>
                        <div class="row" >
                            <label class="col-2 text-nowrap ">Carrier</label>
                            <div class="col-3">
                                <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.carrier" />		
                            </div>
                        </div>
                        <div class="row" >
                            <label class="col-2 text-nowrap ">Pedimento</label>
                            <div class="col-3">
                                <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.pedimento" />		
                            </div>
                        </div>
                        <div class="row" >
                            <label class="col-2 text-nowrap ">Carga</label>
                            <div class="col-3">
                                <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.carga" />		
                            </div>
                        </div>
                        <div class="row" >
                            <label class="col-2 text-nowrap ">Peso</label>
                            <div class="col-3">
                                <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.peso" />		
                            </div>
                        </div>
                        <div class="row" >
                            <label class="col-2 text-nowrap ">Volumen</label>
                            <div class="col-3">
                                <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.volumen" />		
                            </div>
                        </div>
                        <lookUpCtrl :objeto="selectedTrafico" :label="'Fianza'" :cols="[2,2,8]" :archivo="'Fianzas'" :campo="'folio'" :descripcion="'nocaja'" v-model="selectedTrafico.fianza" />
                        <div class="row" >
                            <label class="col-2 text-nowrap ">Factura Imp.</label>
                            <div class="col-3">
                                <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.facturaimp" />		
                            </div>
                        </div>
                        <div class="row" >
                            <label class="col-2 text-nowrap ">Sellos</label>
                            <div class="col-3">
                                <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.sellos" />		
                            </div>
                        </div>
                    </div>
                  
                </div>
                
                
            </template>
        </InformativoF5>
        <Cancela  v-show="showCancela" @close="closeCancela" :shownoaceptar="true" @noaceptar="sincargo()" @aceptar="concargo()">
            <template v-slot:header>
                <h6 class="text-uppercase">{{cancelTitle}}</h6>
            </template>
            <template v-slot:body>
                {{cancelText}}
            </template>
            <template v-slot:btn-noaceptar>No</template>
            <template v-slot:btn-close>Cancelar</template>
        </Cancela>
        <LigarRem v-if="showLigarRem" @close="closePopup" @goAhead="afterLigarRem" >
            <template v-slot:title>Liga Remision a Trafico</template>
            <template v-slot:body>
                <div class="row p-3">
                    <div class="col-12">
                        <div class="row" >
                            <label class="col-4 text-nowrap ">Remisión</label>
                            <div class="col-7">
                                <input type="number" class="form-control form-control-sm " @blur="exit_remision()" v-model="remision" />		
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </LigarRem>
        <ConfirmaVacio v-if="showConfirmaVacio"  @close="closePopup" @goAhead="setDetalles" >
            <template v-slot:title>Confirma Viaje Vacio</template>
            <template v-slot:body>
                <div class="row p-3">
                    <div class="col-12">
                        <lookUpCtrl :objeto="selectedConfirmaVacio" :label="'Unidad'" :cols="[1,2,9]" :archivo="'Vehiculos'"  :campo="string" :descripcion="string" :model="'vehiculo'" v-model="selectedConfirmaVacio.unidad" />
                    </div>
                    <div class="col-12">
                        <lookUpCtrl :objeto="selectedConfirmaVacio" :label="'Remolque'" :cols="[1,2,9]" :archivo="'Vehiculos'"  :campo="string" :descripcion="string" :model="'remolque'" v-model="selectedConfirmaVacio.remolque" />
                    </div>
                    <div class="col-12">
                        <lookUpCtrl :objeto="selectedConfirmaVacio" :label="'Operador'" :cols="[1,2,9]" :archivo="'Operadores'"  :campo="string" :descripcion="string" :model="'operador'" v-model="selectedConfirmaVacio.operador" />
                    </div>
                    
                    <div class="col-4 d-flex offset-2">
                        <div class="form-check pe-3">
                            <input class="form-check-input" v-model="selectedTrafico.tipo"  type="radio" id="convacio">
                            <label class="form-check-label" for="convacio">Vacio</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input"  v-model="selectedTrafico.tipo"  type="radio" id="concruce">
                            <label class="form-check-label" for="concruce">Cruce</label>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row" >
                            <label class="col-2 text-nowrap ">Referencia</label>
                            <div class="col-10">
                                <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.referencia" />		
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <fieldset>
                            <legend>Localización Actual</legend>
                            <lookUpCtrl  :objeto="selectedConfirmaVacio" :label="'Actual'" :cols="[1,2,9]" :archivo="'Operadores'"  :campo="string" :descripcion="string" :model="'operador'" v-model="selectedConfirmaVacio.operador" />
                            
                            <div class="row" >
                                <label class="col-3 text-nowrap ">Descripción</label>
                                <div class="col-9">
                                    <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.descripcionA" />		
                                </div>
                            </div>
                            <div class="row" >
                                <label class="col-3 text-nowrap ">Ciudad</label>
                                <div class="col-9">
                                    <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.ciudadA" />		
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-12">
                        <fieldset>
                            <legend>Localización Origen</legend>
                            <lookUpCtrl   :objeto="selectedConfirmaVacio" :label="'Origen'" :cols="[1,2,9]" :archivo="'Operadores'"  :campo="string" :descripcion="string" :model="'operador'" v-model="selectedConfirmaVacio.operador" />
                            
                            <div class="row" >
                                <label class="col-3 text-nowrap ">Descripción</label>
                                <div class="col-9">
                                    <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.descripcionO" />		
                                </div>
                            </div>
                            <div class="row" >
                                <label class="col-3 text-nowrap ">Ciudad</label>
                                <div class="col-9">
                                    <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.ciudadO" />		
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-12">
                        <p>La unidad que acaba de seleccionar no se encuentra en el origen de la ruta, 
                            arriba se muestra su localización actual y el origen en donde se requiere.
                            <br>¿ Desea generar un Tráfico en VACIO ?
                        </p>
                    </div>
                </div>
            </template>
        </ConfirmaVacio>
        <InfoPopUp  v-show="showNotification" @close="closeInfo" :size="25">
            <template v-slot:header>
                <h6 class="text-uppercase">{{notificationTitle}}</h6>
            </template>
            <template v-slot:body>
                {{notificationText}}
            </template>
        </InfoPopUp>,
        <ControlDoc v-if="showControlDoc"  @close="closePopup" @goAhead="setDetalles" :size="25">
            <template v-slot:title>Control Documentos Tráfico</template>
            <template v-slot:body>
                <h3>TRAFICO : {{ selectedRegistro.folio }}</h3>
                <div class="row p-3">
                    <div class="col-12">
                        <lookUpCtrl :objeto="selectedConfirmaVacio" :label="'Operador'" :cols="[2,2,8]" :archivo="'Operadores'"  :campo="'numero'" :descripcion="'nombre'" :model="'operador'" v-model="selectedConfirmaVacio.operador" />
                    </div>
                    <div class="col-12">
                        <lookUpCtrl :objeto="selectedConfirmaVacio" :label="'Remolque'" :cols="[2,2,8]" :archivo="'Vehiculos'"  :campo="'numero'" :descripcion="string" :model="'remolque'" v-model="selectedConfirmaVacio.remolque" />
                    </div>
                    
                    <div class="col-12">
                        <div class="row">
                            <div class="col-2 offset-2">SUC</div>
                            <div class="col-2">FOLIO</div>
                        </div>
                        <div class="row">
                            <label class="col-2 text-nowrap ">Carta Porte</label>
                            <div class="col-2">
                                <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.sucursal" />		
                            </div>
                            <div class="col-2">
                                <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.folio" />	
                            </div>
                            <div class="col-2"><button class="btn btn-sm btn-outline-secondary"><PlusIcon /></button></div>
                            <div class="col-2"><button class="btn btn-sm btn-outline-secondary"><MagnifyIcon /></button></div>
                            <div class="col-2"><button class="btn btn-sm btn-outline-secondary"><PrinterIcon /></button></div>
                        </div>
                        <div class="row">
                            <label class="col-2 text-nowrap ">Liquidación</label>
                            <div class="col-2">
                                <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.sucursal" />		
                            </div>
                            <div class="col-2">
                                <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.folio" />	
                            </div>
                            <div class="col-2"><button class="btn btn-sm btn-outline-secondary"><PlusIcon /></button></div>
                            <div class="col-2"><button class="btn btn-sm btn-outline-secondary"><MagnifyIcon /></button></div>
                            <div class="col-2"><button class="btn btn-sm btn-outline-secondary"><PrinterIcon /></button></div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-2 offset-2">NUM</div>
                            <div class="col-2">TOTAL</div>
                        </div>
                        <div class="row">
                            <label class="col-2 text-nowrap ">Anticipos</label>
                            <div class="col-2">
                                <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.sucursal" />		
                            </div>
                            <div class="col-2">
                                <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.folio" />	
                            </div>
                            <div class="col-2"><button class="btn btn-sm btn-outline-secondary"><PlusIcon /></button></div>
                            <div class="col-2"><button class="btn btn-sm btn-outline-secondary"><MagnifyIcon /></button></div>
                            <div class="col-2"><button class="btn btn-sm btn-outline-secondary"><PrinterIcon /></button></div>
                        </div>
                        <div class="row">
                            <label class="col-2 text-nowrap ">Vales Diesel</label>
                            <div class="col-2">
                                <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.sucursal" />		
                            </div>
                            <div class="col-2">
                                <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.folio" />	
                            </div>
                            <div class="col-2"><button class="btn btn-sm btn-outline-secondary"><PlusIcon /></button></div>
                            <div class="col-2"><button class="btn btn-sm btn-outline-secondary"><MagnifyIcon /></button></div>
                        </div>
                        <div class="row">
                            <label class="col-2 text-nowrap ">Gastos</label>
                            <div class="col-2">
                                <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.sucursal" />		
                            </div>
                            <div class="col-2">
                                <input type="text" class="form-control form-control-sm " v-model="selectedTrafico.folio" />	
                            </div>
                            <div class="col-2"><button class="btn btn-sm btn-outline-secondary"><PlusIcon /></button></div>
                            <div class="col-2"><button class="btn btn-sm btn-outline-secondary"><MagnifyIcon /></button></div>
                        </div>
                    </div>
                </div>
            </template>
        </ControlDoc>
        <MovimientosOTVue v-if="showMovimientosOT" @close="closePopup" @goAhead="MovimientosOt()" :size="15">
            <template v-slot:title>Movimientos de OTs</template>
            <template v-slot:body>
                <div class="row p-3" style="overflow-x:hidden">
                    <div class="col-12">
                        <fieldset class="border mb-2 ps-1 pe-1 pb-1 pt-0">
                            <legend class="p-0">Rango de Fechas</legend>
                            <div class="row">
                                <div class="col-6 text-center pe-0">
                                    <label >Inicial</label>
                                    <input type="date" class="form-control form-control-sm " v-model="filtrosReporte.fechaIni" />		
                                </div>
                                <div class="col-6 text-center ps-0">
                                    <label >Final</label>
                                    <input type="date" class="form-control form-control-sm " v-model="filtrosReporte.fechaFin" />		
                                </div>
                            </div>
                        </fieldset>
                        <lookUpCtrl :objeto="filtrosReporte" :label="'Unidad'" :cols="[2,3,7]" :archivo="'Vehiculos'" :campo="'numero'" :descripcion="string" :model="'unidad'" v-model="filtrosReporte.unidad" />
                        <lookUpCtrl :objeto="filtrosReporte" :label="'Operador'" :cols="[2,3,7]" :archivo="'Operadores'" :campo="'numero'" :descripcion="'nombre'" :model="'operador'" v-model="filtrosReporte.operador" />
                        <lookUpCtrl :objeto="filtrosReporte" :label="'Cliente'" :cols="[2,3,7]" :archivo="'Clientes'" :campo="'clienteid'" :descripcion="'nombre'" :model="'cliente'" v-model="filtrosReporte.cliente" />
                    </div>
                </div>
                <div class="row p-3" style="overflow-x:hidden">
                    <div class="col-6 ">
                        <div class="form-check text-center mt-2">
                            <input class="form-check-input"  v-model="filtrosReporte.exportar"  type="checkbox" id="string">
                            <label class="form-check-label" for="string">Exportar</label>
                        </div>
                    </div>
                    <div class="col-6 text-end">
                        <div class="btn-group-toggle" >
                            <label class="btn btn-xs btn-outline-info btn-pin" :class="[filtrosReporte.pin ? 'btn-pin-active' : '']">
                                <input type="checkbox" v-model="filtrosReporte.pin"> <PinIcon v-if="filtrosReporte.pin"  /> <PinOffIcon v-else  />
                            </label>
                        </div>
                    </div>
                </div>
            </template>
        </MovimientosOTVue>
        <PrintBoleta v-show="isPrintPreviewVisible" :orientacion="orientacionRPT" :component="'RPT_'+reporte" :filtros="filtrosReporte" :registro="selectedRegistro" @close="closePrint"></PrintBoleta>
    </div>
    </section>
</template>

<script>

import FileDocumentOutlineIcon from 'vue-material-design-icons/FileDocumentOutline.vue';
import PinOffIcon from 'vue-material-design-icons/PinOff.vue';
import PinIcon from 'vue-material-design-icons/Pin.vue';
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronDoubleRightIcon from 'vue-material-design-icons/ChevronDoubleRight.vue';
import ChevronDoubleLeftIcon from 'vue-material-design-icons/ChevronDoubleLeft.vue';
import MagnifyIcon from 'vue-material-design-icons/Magnify.vue';
import PlusIcon from 'vue-material-design-icons/Plus.vue';
import PrinterIcon from 'vue-material-design-icons/Printer.vue';
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'
import handleBrowse from '@/mixins/handleBrowse'
import InformativoF5 from '@/components/PopupWindow.vue'
import DeletePopUp from '@/components/YesNoPopup.vue';
import ConfirmaVacio from '@/components/PopupWindow.vue'
import handlePopUp from '@/mixins/handlePopUp'
import lookUpCtrl from '@/components/LookUpCtrl.vue'
import Cancela from '@/components/YesNoPopup.vue';
import InfoPopUp from '@/components/InfoPopUp.vue';
import ControlDoc from '@/components/PopupWindow.vue'
import MovimientosOTVue from '@/components/PopupWindow.vue'
import PrintBoleta from '@/components/PrintComponent.vue';

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';

export default {
    name:'WIN_Traficos',
    props:['mode','perpage'],
    mixins:[handleGlobals,handleBrowse,handlePopUp],
    components:{SideBar,TopBar,lookUpCtrl,DeletePopUp,MovimientosOTVue,PinIcon,PinOffIcon,Cancela,InfoPopUp,PlusIcon,PrintBoleta,ControlDoc,PrinterIcon,MagnifyIcon,ChevronLeftIcon,ConfirmaVacio,ChevronDoubleRightIcon,ChevronDoubleLeftIcon,InformativoF5,ChevronRightIcon, LoadingContent,FileDocumentOutlineIcon},
    data(){
        return{
            traficos: [],
            filtroxestatus: "Abiertos",
            filtroMisGrupos: false,
            filtroSoloMisTráficos:false,
            showInformativo: false,
            selectedTrafico: {},
            filtroSucursales:false,
            showLigarRem:false,
            remision:0,
            visible_filtroMisGrupos: false,
            gps: false,
            parametros:[],
            filtrosReporte:[],
            menuFiltro:[],
            reporte:'',
            showMovimientosOT:false,
            cancelText:'',
            cancelTitle:'',
            showCancela:false,
            showConfirmaVacio:false,
            showControlDoc:false,
            selectedConfirmaVacio:[],
            isPrintPreviewVisible:false
        }
    },
    computed:{
        keymapLocal(){
            return {
                'f5': this.toggleInformativo
            }
        },
    },
    created(){
        if(this.OpenMode == false){
            this.getFiltros()
        } 
    },
    methods:{
        async getFiltros(){
                await fetch(this.$api+'/rdx', {    
                    method: "POST",
                    headers: {
                        'Content-Type' : 'application/json',
                        'Authorization' : this.$store.state.iasctratkn
                    },
                    body: JSON.stringify({accion: "filters"})
                }).then((response)=>{
                    if(response.ok){
                        return response.json()
                    }
                }).then((data)=>{
                    let reg = data['TRA']
                    if(reg){
                        this.filtroSucursales = reg['sucursal'].dato 
                        this.filtroSoloMisTráficos = reg['usuario'].dato 
                        this.filtroxestatus = reg['estatus'].dato 
                        this.paginaActual  = reg['paginaActual'].dato
                        if(this.paginaActual == '') this.paginaActual = 0
                    }else{
                        this.paginaActual  = 1
                        this.filtroSucursales = 0
                        this.filtroSoloMisTráficos = 0
                        this.filtroxestatus = 'Todos'
                    }
                }).catch((err)=>{
                this.falseToken(err)
            });
        },
        closePopup(){
            this.showInformativo = false
            this.showConfirmaVacio = false
            this.showControlDoc = false
        },
        closePrint(){
            if(!this.filtrosReporte.pin){
                this.showMovimientosOT = false
            }
            this.isPrintPreviewVisible = false
        },
        closeCancela(){
            this.showCancela = false
        },
        setDetalles(){
            console.log('si')
        },
        toggleInformativo(){
            this.selectedTrafico = this.selectedRegistro
            this.showInformativo = !this.showInformativo
        },
        consultar(){
            this.isLoading = true

            if(this.newSelectedFilter){
                    this.paginacion = ""
                    this.paginaActual = 0
                }

            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "browse", 
                                        archivo: "Trafico", 
                                        activarPaginas:true,
                                        orden: "folio",
                                        filtro: {   sucursal:{  dato: 1, inactivo: (this.filtroSucursales == 0) }, 
                                                    usuario: {  dato: this.user.sub.toUpperCase(), inactivo: (this.filtroSoloMisTráficos == 0) }, 
                                                    estatus: {  dato: this.filtroxestatus, inactivo: this.filtroxestatus == 'Todos' },
                                                    paginaActual: {dato: 'NA'}},
                                        pagina: this.paginaActual, 
                                        paginacion: this.paginacion,
                                        registrosxpagina: this.rows })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.traficos       = data['registros']
                this.totalRegistros = data['totalRegistros']
                this.totalPaginas   = data['totalPaginas']
                this.paginaActual   = data['paginaActual']
                this.gps            = data['nGPS']
                this.parametros     = data['parametros']   
                this.menuFiltro     = data['menu_filtro']   
                this.savePage('trafico',this.paginaActual)
            }).finally(()=>{
                this.isLoading = false;
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        getColor(trafico){
            if(trafico.finlinea == 1){
                if(trafico.estatus == "P"){
                    return '#FFFF00'
                }    
                else if(trafico.estatus == "A"){
                    return '#53F4E3'
                }    
                else if(trafico.estatus == "C"){
                    return '#FF0000'
                }    
                else if(trafico.estatus == "T"){
                    return '#008000'
                }   
                else{
                    return '#000000'
                } 
            }
        },
      
        modificar(){
            this.$router.push({ name: 'CTrafico', params: {trafico: this.selectedRegistro.folio}});
        },
        cancelar(){
            if(this.selectedRegistro.estatus == "C"){
                this.showNotification = true
                this.notificationTitle= 'Error'
                this.notificationText = 'Este tráfico ya estaba cancelado, no se puede cancelar nuevamente'
                return
            }
            if(this.selectedRegistro.factura > 0){
                this.showNotification = true
                this.notificationTitle= 'Error'
                this.notificationText = 'El tráfico ya esta facturado.'
                return
            }

            this.showCancela = true
            this.cancelTitle = 'Cancelar Tráfico'
            this.cancelText= "Cancelación del Tráfico No. "+this.selectedRegistro.folio+" ¿ Desea cancelar con cargo=si, sin cargo=no, o no cancelar=cancelar ?"
        },
        sincargo(){
            this.selectedRegistro.estatus = "C"  //Estatus de Cancelado
            this.selectedRegistro.concargo = "N"
        },
        concargo(){
            this.selectedRegistro.estatus = "C"  //Estatus de Cancelado
            this.selectedRegistro.concargo = "S"
        },
        liberar(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "metodo", 
                                        archivo: "Trafico", 
                                        metodo: 'liberar',
                                        params:{trafico:this.selectedRegistro}})
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['msg'] != ''){
                  
                    this.showNotification = true
                    this.notificationTitle= 'Error'
                    this.notificationText = data['msg'] 
                    console.log(this.showNotification )
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        MovimientosOt(){
            this.reporte = 'MovimientosOT'
            this.orientacionRPT = 'h'
            this.isPrintPreviewVisible=true;
        },
        ligarRem(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({  accion: "metodo", 
                                        archivo: "Trafico", 
                                        metodo: 'ligarRem',
                                        params:{trafico:this.selectedRegistro}})
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['msg'] != ''){
                    this.showNotification = true
                    this.notificationTitle= 'Error'
                    this.notificationText = data['msg'] 
                }else{
                    this.openLigaremtra = true 
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        exit_remision(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({  accion: "metodo", 
                                        archivo: "Trafico", 
                                        metodo: 'exit_remision',
                                        params:{remision:this.remision, folio: this.selectedRegistro.folio}})
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['msg'] != ''){
                    this.showNotification = true
                    this.notificationTitle= 'Error'
                    this.notificationText = data['msg'] 
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        afterLigarRem(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({  accion: "metodo", 
                                        archivo: "Trafico", 
                                        metodo: 'afterligarRem',
                                        params:{remision:this.remision, folio: this.selectedRegistro.folio}})
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['msg'] != ''){
                    this.showNotification = true
                    this.notificationTitle= 'Error'
                    this.notificationText = data['msg'] 
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        generaVacio(){
            this.showConfirmaVacio = true
        },
        anticipos(){
           // let sValoresIniciales = "CTPL_OPERADOR.LUP_CAMPO" + TAB + TRA_OPERADOR + CR + "CTPL_Unidad.LUP_CAMPO" + TAB + TRA_UNIDAD + CR + "COMBO_Docto" + TAB + "TRA" + CR + "ATI_REMISION" + TAB + TRA_FOLIO + CR + "ATI_SUCREMISION" + TAB + TRA_SUCURSAL
           // Open(WIN_Form_Anticipos,"Creation",sValoresIniciales)
        },
        valesDiesel(){
          //  let sValoresIniciales = "CTPL_OPERADOR.LUP_CAMPO" + TAB + TRA_OPERADOR + CR + "CTPL_UNIDAD.LUP_CAMPO" + TAB + TRA_UNIDAD + CR + "COMBO_Docto" + TAB + "T" + CR + "DIE_REMISION" + TAB + TRA_FOLIO + CR + "DIE_SUCREMISION" + TAB + TRA_SUCURSAL
          //  Open(WIN_Form_Diesel,"Creation",sValoresIniciales)
        },
        ControlDoc(){
            if(this.selectedRegistro.unidad==0){
                this.showNotification = true
                this.notificationTitle = 'Advertencia'
                this.notificationText = "Necesitas Tener Registrada la Unidad"
                return
            }
            this.showControlDoc = true
        //    Open(WIN_ControlDocumentosTra,Table.TRA_SUCURSAL,Table.TRA_FOLIO,Table.TRA_OPERADOR,Table.TRA_UNIDAD)
        },
        importar(){
            //clCEdi is clEdi
            //clCEdi.GetFileFTP()
            console.log('x')
        }
    }
}
</script>

