<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Tipos de Contenedores</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row w-65">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-10">
                                            <form>
                                                <div class="row ">
                                                    <label class="col-2 text-nowrap">Número</label>
                                                    <div class="col-5"> 
                                                        <input ref="numero" type="number" maxlength="3" class="form-control form-control-sm"   v-model="contenedor.numero" />													
                                                    </div>
                                                </div>	
                                                <div class="row ">
                                                    <label class="col-2 text-nowrap">Descripción</label>
                                                    <div class="col-10">
                                                        <input ref="descripcion" maxlength="50" type="text" class="form-control form-control-sm" v-model="contenedor.descripcion" />													
                                                    </div>
                                                </div>	
                                            </form>
                                        </div>
                                        <div class="col-2">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li><button @click="setContenedor()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li><router-link to="/tpocontenedores" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import handleForm from '@/mixins/handleForm';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    components:{SideBar, TopBar,LoadingContent },
    mixins:[handleForm,handleGlobals],
    data(){
        return{
            id: this.$route.params.contenedor,
            modo: (this.$route.params.contenedor > 0 ? 'Modificando' : 'Creacion'), 
            contenedor: [],
            titulo: 'Agregando Tipo de Contenedor'
        }
    },
    mounted(){
        this.llenaForma();
    },
    methods:{
        
        async llenaForma(){
            await this.getContenedor();
            this.titulo = this.$route.params.contenedor > 0 ? 'Cambiando Tipo de Contenedor' :  'Agregando Tipo de Contenedor'
        },
        getContenedor(){
            let req = []
            this.isLoading = true
             fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "inicializar", modo:this.modo, archivo: "TpoContenedor", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json()
                }   
            }).then((data)=>{
                this.contenedor = data['res']
                req = data['req']
            }).finally(()=>{
                this.setRequired(req)
                this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setContenedor(){
            this.isLoading = true
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: 'validar', archivo: "TpoContenedor", modo:this.modo, record: this.contenedor }) 
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['res'] == 'error'){
                    this.showNotification = true
                    this.notificationTitle =  'Error'
                    this.notificationText = data['msg']
                    this.notificationRef = 'numero'
                }else{
                    this.isLoading = false
                    this.$router.push('/tpocontenedores')
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        afterClose(){
            this.$refs[this.notificationRef].focus()
        }
    }
}
</script>