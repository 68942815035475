<template>
   <section>
        <SideBar v-if="!OpenMode" />
        <TopBar v-if="!OpenMode" />
        <div :class="[!OpenMode ? 'content' : '' ]">   
        <div class="main-content-header">
            <h1>Anticipos de Clientes</h1>
        </div>
        <LoadingContent v-if="isLoading" />
        <div class="row">
            <div class="col-lg-12 col-md-6">
                <div class="card mb-30" :style="{height:[contentHeight+'px']}">
                    <div class="row " :class="[!OpenMode ? 'p-4' : '']">
                        <div class="content-header col-12"> 
                            <div class="row">
                                <div class="col-md-6 col-12 text-start ps-4"></div>
                                <div class="col-md-6 col-12"><p class="p-1 pe-4 mb-0 text-end">Página {{ paginaActual }} de {{ totalPaginas }} </p></div>
                                
                                <div class="col-md-2 offset-md-10 col-12 ps-2 pe-5 text-end pb-0 pt-0">
                                    <ul class="list-unstyled mb-0 d-flex justify-content-between pager-btns float-right">
                                        <li><button @click="setPaginacion('first')" type="button" class="pager pager-first btn btn-sm btn-outline-secondary"><ChevronDoubleLeftIcon /></button></li>
                                        <li><button @click="setPaginacion('prev')"  type="button" class="pager pager-prev  btn btn-sm btn-outline-secondary"><ChevronLeftIcon /></button></li>
                                        <li><button @click="setPaginacion('next')"  type="button" class="pager pager-next  btn btn-sm btn-outline-secondary"><ChevronRightIcon /></button></li>
                                        <li><button @click="setPaginacion('last')"  type="button" class="pager pager-last  btn btn-sm btn-outline-secondary"><ChevronDoubleRightIcon /></button></li> 
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-10 col-12">
                            <div class="table-responsive ps-2">
                                <b-table-simple class="browse" hover small caption-top responsive >
                                    <b-thead head-variant="light" class="table-header">
                                        <b-tr>
                                            <b-th class="text-nowrap">Cliente</b-th>
                                            <b-th class="text-nowrap">Nombre</b-th>
                                            <b-th class="text-nowrap">Num</b-th>
                                            <b-th class="text-nowrap">Suc</b-th>
                                            <b-th class="text-nowrap">Valor</b-th>
                                            <b-th class="text-nowrap"></b-th>
                                            <b-th class="text-nowrap">Mon</b-th>
                                            <b-th class="text-nowrap">T.Cambio</b-th>
                                            <b-th class="text-nowrap">Fecha</b-th>
                                            <b-th class="text-nowrap">Valor Asignado</b-th>
                                            <b-th class="text-nowrap">Saldo</b-th>
                                            <b-th class="text-nowrap">Tipo</b-th>
                                            <b-th class="text-nowrap">Folio</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="(anticipo,indx) in anticipos" :key="indx" 
                                                @click="selectedRegistro = anticipo;selectedRegistro.indx = indx" 
                                                @dblclick=" (!OpenMode ? modificar() : exportRegistry()) " :class="{'selected-row': (selectedRegistro.numid == anticipo.numid) }">
                                            <b-td class="text-nowrap text-center">{{ anticipo.cliente | leadingZeros(5)}}</b-td>
                                            <b-td class="text-nowrap">{{ anticipo.cliente_nombre }}</b-td>
                                            <b-td class="text-nowrap text-center">{{ anticipo.consecutivo }}</b-td>
                                            <b-td class="text-nowrap text-center">{{ anticipo.sucursal  | leadingZeros(3)}}</b-td>
                                            <b-td class="text-nowrap text-end">{{ parseFloat(anticipo.valor) | toCurrency  }}</b-td>
                                            <b-td class="moneda">
                                                <img  v-if="anticipo.moneda == 'NAL' " style="max-width:30px;width:30px;height:15px" :src="basePath+'assets/images/mx.svg'" />
                                                <img  v-else-if="anticipo.moneda == 'DLS' " style="max-width:30px;width:30px;height:15px" :src="basePath+'assets/images/us.svg'" />
                                                <img  v-else :src="basePath+'assets/images/eu.svg'" style="max-width:30px;width:30px;height:15px"  />
                                            </b-td>
                                            <b-td class="text-nowrap text-center">{{ anticipo.moneda }}</b-td>
                                            <b-td class="text-nowrap text-end">{{ anticipo.tipocambio  | tipoCambio}}</b-td>
                                            <b-td class="text-nowrap text-end">{{ formato(anticipo.fecha,"MMM-DD-YYYY")  }}</b-td>
                                            <b-td class="text-nowrap text-end">{{ parseFloat(anticipo.valorasignado) | toCurrency  }}</b-td>
                                            <b-td class="text-nowrap text-end">{{ parseFloat(anticipo.saldo) | toCurrency }}</b-td>
                                            <b-td class="text-nowrap text-center">{{ anticipo.tipo }}</b-td>
                                            <b-td class="text-nowrap text-center">{{ anticipo.folio | leadingZeros(8)}}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </div>
                        </div> 
                        <div class="col-2 ps-2 pe-5 btn-manager" >
                            <ul class="d-block list-unstyled action-block">
                                <li v-if="!OpenMode"><router-link to="/anticipos/agregar" class="btn btn-xs btn-success btn-block">Agregar</router-link></li>
                                <li v-if="!OpenMode"><button @click="modificar()" :disabled="disableBtn" type="button" class="btn btn-xs btn-primary btn-block">Cambiar</button></li>
                                <li v-if="!OpenMode"><button @click="ask()" :disabled="disableBtn" class="btn btn-xs btn-danger btn-block">Borrar</button></li>
                                <li v-if="!OpenMode"><button @click="imprimir()" class="btn btn-xs btn-secondary btn-block">Imprimir</button></li>
                                <li v-if="OpenMode"><button @click="exportRegistry()" class="btn btn-xs btn-success btn-block">Elegir</button></li>
                            </ul>
                            <div style="width: 100px;text-align: left;float: right;">
                                <div class="form-check">
                                    <input class="form-check-input" v-model="filtroSaldos" @change="consultar()"  value="T" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
                                    <label class="form-check-label" for="flexRadioDefault2">
                                        Todos
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" v-model="filtroSaldos" @change="consultar()" value="S" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        con Saldo
                                    </label>
                                </div>
                            </div>
                            <ul class="d-block list-unstyled action-block pt-3">
                                <li><button @click="cancelar()"  class="btn btn-xs btn-outline-secondary btn-block">
                                    <DeleteOutlineIcon class="d-md-none" /><span class="d-none d-md-block">Cancelar</span></button></li>
                            </ul>
                        </div>
                        <div class="content-footer col-12 text-start"></div>
                    </div>
                </div>
            </div>
        </div>
        <DeleteReg  v-show="showDelete" @close="closeDelete" @aceptar="anticipos = borrar('faAnticipos',anticipos)">
            <template v-slot:header>
                <h6 class="text-uppercase">{{notificationTitle}}</h6>
            </template>
            <template v-slot:body>
                {{notificationText}}
            </template>
        </DeleteReg>
    </div>
    </section>
</template>

<script>
import DeleteReg from '@/components/YesNoPopup.vue';
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronDoubleRightIcon from 'vue-material-design-icons/ChevronDoubleRight.vue';
import ChevronDoubleLeftIcon from 'vue-material-design-icons/ChevronDoubleLeft.vue';
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'
import handleBrowse from '@/mixins/handleBrowse'
import handlePopUp from '@/mixins/handlePopUp'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';

export default {
    name:'WIN_faAnticipos',
    props:['mode','perpage'],
    mixins:[handleGlobals,handleBrowse,handlePopUp],
    components:{SideBar,TopBar,ChevronLeftIcon,ChevronDoubleRightIcon,ChevronDoubleLeftIcon,ChevronRightIcon, DeleteReg,LoadingContent},
    data(){
        return{
            anticipos: [],
            filtroSaldos: 'T'
        }
    },
    created(){
        if(this.OpenMode == false){
            this.getFiltros()
        } 
    },
    methods:{
        async getFiltros(){
                await fetch(this.$api+'/rdx', {    
                    method: "POST",
                    headers: {
                        'Content-Type' : 'application/json',
                        'Authorization' : this.$store.state.iasctratkn
                    },
                    body: JSON.stringify({accion: "filters"})
                }).then((response)=>{
                    if(response.ok){
                        return response.json()
                    }
                }).then((data)=>{
                    let reg = data['ANT']
                    if(reg){
                        this.paginaActual = reg['paginaActual'].dato
                        this.filtroSaldos = reg['saldo'].dato
                        if(this.paginaActual == '') this.paginaActual = 0
                    }else{
                        this.paginaActual = 1
                        this.filtroSaldos = 'T'
                    }
                }).catch((err)=>{
                this.falseToken(err)
                });
            },
        consultar(){
            this.isLoading = true
            
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "browse", 
                                        archivo: "faAnticipos", 
                                        activarPaginas:true,
                                        orden: "cliente,consecutivo",
                                        filtro: {   saldo: {dato: 1 , inactivo: this.filtroSaldos == 'T', operador: '>'},
                                                    paginaActual: {dato: 'NA'}},
                                        pagina: this.paginaActual, 
                                        paginacion: this.paginacion ,
                                        registrosxpagina: this.rows })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.anticipos      = data['registros'];
                this.totalRegistros = data['totalRegistros'];
                this.totalPaginas   = data['totalPaginas'];
                this.paginaActual   = data['paginaActual'];
            }).finally(()=>{
                this.isLoading = false;
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        cancelar(){
            if(this.selectedRegistro.valor == 0){
                this.showNotification = true
                this.notificationTitle = 'Advertencia'
                this.notificationText = "Este Anticipo ya fue Cancelado"
                return
            }

            if(this.selectedRegistro.saldo < this.selectedRegistro.valor || this.selectedRegistro.valorasignado > 0 ){
                this.showNotification = true
                this.notificationTitle = 'Advertencia'
                this.notificationText = "No se puede cancelar un Anticipo cuando ya tiene saldo asignado a Facturas."
                return 
            }

            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "metodp", 
                                        archivo: "FaAnticipos", 
                                        metodo:'cancelar',
                                        params:{cliente: this.selectedRegistro.cliente, consecutivo: this.selectedRegistro.consecutivo}
                }).then((response)=>{
                    if(response.ok){
                        return response.json();
                    }   
                }).then((data)=>{
                    if(data){
                        this.selectedRegistro.valor = 0
                        this.selectedRegistro.saldo = 0
                    }
                }).catch((err)=>{
                    this.falseToken(err)
                })
            })
        },
        modificar(){
            this.$router.push({ name: 'CfaAnticipos', params: {anticipo: this.selectedRegistro.ant_cliente}});
        },
    }
}
</script>

