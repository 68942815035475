<template>
   <section>
        <SideBar v-if="!OpenMode" />
        <TopBar v-if="!OpenMode" />
        <div :class="[!OpenMode ? 'content' : '' ]">   
        <div class="main-content-header">
            <h1>Maestro de Clientes</h1>
        </div>
        <LoadingContent v-if="isLoading" />
        <div class="row">
            <div class="col-lg-12 col-md-6">
                <div class="card mb-30" :style="{height:[contentHeight+'px']}">
                    <div class="row " :class="[!OpenMode ? 'p-4' : '']">
                        <div class="content-header col-12"> 
                            <div class="row">
                                <div class="col-md-6 col-12 text-start ps-4"></div>
                                <div class="col-md-6 col-12"><p class="p-1 pe-4 mb-0 text-end">Página {{ paginaActual }} de {{ totalPaginas }} </p></div>
                                
                                <div class="col-md-2 offset-md-10 col-12 ps-2 pe-5 text-end pb-0 pt-0">
                                    <ul class="list-unstyled mb-0 d-flex justify-content-between pager-btns float-right">
                                        <li><button @click="setPaginacion('first')" type="button" class="pager pager-first btn btn-sm btn-outline-secondary"><ChevronDoubleLeftIcon /></button></li>
                                        <li><button @click="setPaginacion('prev')"  type="button" class="pager pager-prev  btn btn-sm btn-outline-secondary"><ChevronLeftIcon /></button></li>
                                        <li><button @click="setPaginacion('next')"  type="button" class="pager pager-next  btn btn-sm btn-outline-secondary"><ChevronRightIcon /></button></li>
                                        <li><button @click="setPaginacion('last')"  type="button" class="pager pager-last  btn btn-sm btn-outline-secondary"><ChevronDoubleRightIcon /></button></li> 
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-10 col-12">
                            <div class="table-responsive ps-2">
                                <b-table-simple class="browse" hover small caption-top responsive >
                                    <b-thead head-variant="light" class="table-header">
                                        <b-tr>
                                            <b-th class="text-nowrap">D</b-th>
                                            <b-th class="text-nowrap">Suc</b-th>
                                            <b-th class="text-nowrap">Cliente</b-th>
                                            <b-th class="text-nowrap">Tipo</b-th>
                                            <b-th class="text-nowrap">Nombre</b-th>
                                            <b-th class="text-nowrap">Dirección</b-th>
                                            <b-th class="text-nowrap">Ciudad</b-th>
                                            <b-th class="text-nowrap">C.P.</b-th>
                                            <b-th class="text-nowrap">R.F.C.</b-th>
                                            <b-th class="text-nowrap">Teléfono</b-th>
                                            <b-th class="text-nowrap">Fax</b-th>
                                            <b-th class="text-nowrap">Contacto</b-th>
                                            <b-th class="text-nowrap">Correo</b-th>
                                            <b-th class="text-nowrap">Fecha Alta</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="(cliente,indx) in clientes" :key="cliente.clienteid" @click="selectedRegistro = cliente;selectedRegistro.indx = indx" @dblclick=" (!OpenMode ? modificar() : exportRegistry()) " :class="{'son-text-danger': (cliente.vencido > 0), 'selected-row': (selectedRegistro.clienteid == cliente.clienteid) }">
                                            <b-td class="text-nowrap"><FileDocumentOutlineIcon v-if="cliente.doctos > 0" /></b-td>
                                            <b-td class="text-nowrap">{{ cliente.sucursal| leadingZeros(3)}}</b-td>
                                            <b-td class="text-center text-nowrap" >{{ cliente.clienteid | leadingZeros(5)}}</b-td>
                                            <b-td class="text-nowrap">{{ cliente.tipo }}</b-td>
                                            <b-td class="text-nowrap">{{ cliente.nombre }}</b-td>
                                            <b-td class="text-nowrap">{{ cliente.direccion }}</b-td>
                                            <b-td class="text-nowrap">{{ cliente.ciudad}}</b-td>
                                            <b-td class="text-nowrap">{{ cliente.cpostal}}</b-td>
                                            <b-td class="text-nowrap">{{ cliente.rfc}}</b-td>
                                            <b-td class="text-nowrap">{{ cliente.telefono }}</b-td>
                                            <b-td class="text-nowrap">{{ cliente.fax}}</b-td>
                                            <b-td class="text-nowrap">{{ cliente.contacto }}</b-td>
                                            <b-td class="text-nowrap">{{ cliente.correo}}</b-td>
                                            <b-td class="text-center text-nowrap">{{ formato(cliente.fechaalta,'MMM-DD-YYYY') }}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </div>
                        </div> 
                        <div class="col-2 ps-2 pe-5 btn-manager" >
                            <ul class="d-block list-unstyled action-block">
                                <li v-if="!OpenMode"><router-link to="/clientes/agregar" class="btn btn-xs btn-success btn-block">Agregar</router-link></li>
                                <li v-if="!OpenMode"><button @click="modificar()" :disabled="disableBtn" type="button" class="btn btn-xs btn-primary btn-block">Cambiar</button></li>
                                <li v-if="!OpenMode"><button @click="ask()" :disabled="disableBtn" class="btn btn-xs btn-danger btn-block">Borrar</button></li>
                                <li v-if="!OpenMode"><button @click="imprimir()" class="btn btn-xs btn-secondary btn-block">Imprimir</button></li>
                                <li v-if="OpenMode"><button @click="exportRegistry()" class="btn btn-xs btn-success btn-block">Elegir</button></li>
                            </ul>
                           
                            <div style="width: 100px;text-align: left;float: right;">
                                <div class="form-check">
                                    <input class="form-check-input" v-model="filtroEstado" @change="consultar()" value="A" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        Solo Activos
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" v-model="filtroEstado" @change="consultar()"  value="T" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
                                    <label class="form-check-label" for="flexRadioDefault2">
                                        Todos
                                    </label>
                                </div>
                            </div>
                        </div>
                        
                        <div class="content-footer col-12 text-start"></div>
                    </div>
                </div>
            </div>
        </div>
        <DeleteReg  v-show="showDelete" @close="closeDelete" @aceptar="clientes = borrar('Clientes',clientes)">
            <template v-slot:header>
                <h6 class="text-uppercase">{{notificationTitle}}</h6>
            </template>
            <template v-slot:body>
                {{notificationText}}
            </template>
        </DeleteReg>
    </div>
    </section>
</template>

<script>
import DeleteReg from '@/components/YesNoPopup.vue';
import FileDocumentOutlineIcon from 'vue-material-design-icons/FileDocumentOutline.vue';
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronDoubleRightIcon from 'vue-material-design-icons/ChevronDoubleRight.vue';
import ChevronDoubleLeftIcon from 'vue-material-design-icons/ChevronDoubleLeft.vue';
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'
import handleBrowse from '@/mixins/handleBrowse'
import handlePopUp from '@/mixins/handlePopUp'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';

export default {
    name:'WIN_Clientes',
    props:['mode','perpage'],
    mixins:[handleGlobals,handleBrowse,handlePopUp],
    components:{SideBar,TopBar,ChevronLeftIcon,ChevronDoubleRightIcon,ChevronDoubleLeftIcon,ChevronRightIcon, DeleteReg,LoadingContent,FileDocumentOutlineIcon},
    data(){
        return{
            clientes: [],
            filtroEstado: "T"
        }
    },
    created(){
        if(this.OpenMode == false){
            this.getFiltros()
        } 
    },
    methods:{
        async getFiltros(){
                await fetch(this.$api+'/rdx', {    
                    method: "POST",
                    headers: {
                        'Content-Type' : 'application/json',
                        'Authorization' : this.$store.state.iasctratkn
                    },
                    body: JSON.stringify({accion: "filters"})
                }).then((response)=>{
                    if(response.ok){
                        return response.json()
                    }
                }).then((data)=>{
                    let reg = data['CTE']
                    if(reg){
                        this.filtroEstado       = reg['estatus'].dato
                        this.paginaActual       = reg['paginaActual'].dato
                        if(this.paginaActual == '') this.paginaActual = 0
                    }else{
                        this.filtroEstado       = "T"
                        this.paginaActual       = 1
                    }
                }).catch((err)=>{
                this.falseToken(err)
                });
            },
        consultar(){
            this.isLoading = true
            
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "browse", 
                                        archivo: "Clientes", 
                                        activarPaginas:true,
                                        orden: "clienteid",
                                        filtro: {   obsoleto: {  dato: 1, inactivo: (this.filtroEstado == 'T') }, 
                                                    paginaActual: {dato: 'NA'}},
                                        pagina: this.paginaActual, 
                                        paginacion: this.paginacion ,
                                        registrosxpagina: this.rows })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.clientes       = data['registros'];
                this.totalRegistros = data['totalRegistros'];
                this.totalPaginas   = data['totalPaginas'];
                this.paginaActual   = data['paginaActual'];
            }).finally(()=>{
                this.isLoading = false;
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        imprimir(){

        },
        modificar(){
            this.$router.push({ name: 'CClientes', params: {cliente: this.selectedRegistro.clienteid}});
        },
    }
}
</script>

