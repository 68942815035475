<template>
    <div class=" row">
        <label :class="['col-md-'+(cols ? cols[0] : '2'),'col-'+(device ? device[0] : '2')]" class="text-nowrap">{{label}}</label>
        <div  :class="['col-md-'+(cols ? cols[1] : '2'),'col-'+(device ? device[1] : '2')]" class="pe-0 d-flex">
            <input type="text" 
                    class="form-control form-control-sm"    
                    @keyup="showPreview($event)"
                    @keydown.tab="showPreview($event)"
                    @keydown.esc.prevent="$event.currentTarget.blur(); show = false"
                    ref="lookupCtrl"
                    :disabled="disabled" 
                    :readonly="readonly" 
                    :value = "value"
                    :id="reference"
                    @input="handleInput($event)"
             />
            <div class="row lookupSearch" v-if="show" ref="preview">
                <b-table  
                        :items="filteredFile"
                        ref="selectableTable" 
                        selectable 
                        @row-selected="onRowSelected" 
                        :select-mode="'single'">
                    <template #row-details="row">
                        <b-row class="mb-2">
                            <b-col sm="2" class="text-sm-right"><b>{{row.item.campo}}</b></b-col>
                            <b-col>{{ row.item.descripcion }}</b-col>
                        </b-row>
                    </template>
                </b-table>
            </div>
        </div>
        <div :class="['col-md-'+(cols ? cols[2] : '8'),'col-'+(device ? device[2] : '2')]" class="ps-0 pe-3 d-flex">
            <button type="button" class="btn btn-sm btn-outline-secondary selector"  :disabled="disabled" @click="getData(); isLookUpVisible = true" ><ChevronDownIcon /></button>
            <input  v-if="!hideDescripcion" type="text" readonly class="form-control form-control-sm" :value="text" />
        </div>	
        <LookUp v-show="isLookUpVisible" :component="'WIN_'+archivo" @close="closePopup" @receiveSelected="getLookup"></LookUp>
    </div>
</template>


<script>

  import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue';
  import LookUp from './LookUp.vue';

  export default {
    name: 'LookUpCtrl', 
    props: ['archivo','disabled','reference','readonly','hideDescripcion','campo','descripcion','label','objeto','cols','value','device','model','onexit'],
    components:{ ChevronDownIcon,LookUp},
    emits: ['onexit'], 
    data(){
        return{
            file: this.$store.state.lookupFile,
            show: false,
            selected: [],
            isLookUpVisible:false,
            newValue: this.$props.value,
            camposDescripcion:{},
            campoBusqueda: this.descripcion,
            resultadoBusqueda: ''
        }
    },
    mounted(){
      //  this.getData()
        if(this.descripcion != ''){
            this.camposDescripcion = this.descripcion.split(',')
            if(this.camposDescripcion.length > 1){
                this.campoBusqueda = 'descripcion'
            }
        }

        this.getUnique()
    },
    computed:{
        filteredFile(){
            let ftr = this.file.filter((e)=>{
                let f = e[this.campo]
                return  String(f).includes(this.value)
            }).map((e)=>{
                let f = {}
                f.campo = e[this.campo]
                f.descripcion = e[this.campoBusqueda]
                return f
            })
            return ftr
        },
        text:{
            get(){
                if(this.value){
                    if(this.file.length > 0){
                        let arr = this.file.filter((e)=> {
                            return e[this.campo] == this.value
                        })
                        if(arr.length > 0) return arr[0][this.campoBusqueda]
                    }else{
                        return this.resultadoBusqueda
                    }
                    
                }
                return ''
            },
            set(val){
                return val 
            }  
        },
        content:{
            set(val){
                this.newValue = val
            },
            get(){
                return this.newValue
            }
        }
    },
    methods: {
        onRowSelected(items) {
            this.selected = items
            this.content = this.selected[0].campo
            this.text = this.selected[0].descripcion
            this.$set(this.objeto, this.model, this.selected[0].campo)
          
            this.show = false
            this.file = []
        },
        handleInput(event){
           this.text = event.target.value == '' ? '' : this.text
           this.$emit('input',event.target.value)
        },
        showPreview(event){
            if(this.file.length == 0){
                this.getData()
            }
            this.show = (event.target.value != '' && this.filteredFile.length > 0 ? true : false)
        },
        closePopup(){
            this.isLookUpVisible = false
        },
        getLookup(e){
            this.$refs.lookupCtrl.value = e[this.campo]
            this.content = e[this.campo]
            
            //console.log(this.model+' '+e[this.campo])
            this.$set(this.objeto, this.model, e[this.campo])
            
            this.text = e[this.campoBusqueda]
            this.$emit('onexit')
            this.isLookUpVisible = false
            this.file = []
        },
        async getData(){
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "browse", 
                                        archivo: this.archivo, 
                                        activarPaginas: false,
                                        filtro:{},
                                        orden: this.campo, 
                                        pagina:"todo", 
                                        paginacion: "",
                                        registrosxpagina: 0 })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.$store.state.lookupFile = data['registros'];
                this.file = this.$store.state.lookupFile
            })
        },
        async getUnique(){
            if(this.camposDescripcion.length > 0 || this.value < 1){
                if(this.camposDescripcion.length != undefined){
                    await fetch(this.$api+'/rdx', {
                        method: "POST",
                        headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                        body: JSON.stringify({  accion: "lookup", 
                                                archivo: this.archivo,
                                                campo: this.campo, 
                                                valor: this.value,
                                                busqueda: this.camposDescripcion,
                                                operador: ""})
                    }).then((response)=>{
                        if(response.ok){
                            return response.json();
                        }   
                    }).then((data)=>{
                        this.resultadoBusqueda = data
                    })
                }
            }
        }
    }
}
</script>