<template>
    <transition name="popup-fade">
        <div class="popup-backdrop">
            <div class="popup popup-xl" role="dialog" aria-labelledby="PopupTitulo" aria-describedby="PopupDescripcion">
                <header class="popup-header">
                    <button type="button" class="btn-close" @click="close" aria-label="Cerrar">🗙</button>
                </header>
        
                <section class="popup-body" id="PopupDescripcion">
                    <div class="container-fluid" >
                        <iframe
                            :src="`https://verificacfdi.facturaelectronica.sat.gob.mx/`"
                            frameborder="0" 
                            id="iframeId"
                            style="width:990px;height:1000px;overflow: hidden;"
                            @load="getData()">
                        </iframe>
                    </div>
                </section>
            </div>
        </div>
    </transition>
</template>

<script>
export default{
    data(){
        return{}
    },
    
    methods:{
        close() {
            this.$emit('close');
        },
        getData(){
            var recipient_window = window.open("https://verificacfdi.facturaelectronica.sat.gob.mx/")
        //    recipient_window.postMessage("{'ctl00_MainContent_TxtUUID': 'dn0m1n8tor', 'secret_ID': 'sRrsvRIMHSDBHeqAnhfdfnFB668LDR9Y'}", "*");

            let iframe = recipient_window.querySelector('#iframeId').contentWindow
            iframe.document.body.querySelector('#ctl00_MainContent_TxtRfcEmisor').value = "blue"
            
        }
    }
}
</script>
