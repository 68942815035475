<template>
    <transition name="popup-fade">
      <div class="popup-backdrop">
          <div class="popup w-25"  role="dialog" aria-labelledby="PopupTitulo" aria-describedby="PopupDescripcion" >
              <header class="popup-header">Tipo de Cambio
                  <button type="button" class="btn-close" @click="close" aria-label="Cerrar">🗙</button>
              </header>

                <section class="popup-body" id="PopupDescripcion">
                    <div class="row">
                        <label class="col-4">Fecha</label>
                        <div class="col-4">
                            <input type="date" v-model="edt.fecha" disabled class="form-control form-control-sm" />
                        </div>
                    </div>
                   
                    <div class="row">
                        <div class="col-12 ">
                            <div class="row">
                                <label class="col-4">Tipo de Cambio</label>
                                <div class="col-5">
                                    <input type="number" v-model="edt.tipocambio" class="form-control form-control-sm" />
                                </div>
                                <div class="col-3">
                                    <button class="btn btn-outline-secondary" @click="sat()">SAT</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

              <footer class="popup-footer">
                  <div class="row">
                      <div class="col-12 text-end justify-content-middle">
                          <button type="button" class="btn btn-sm btn-outline-danger" aria-label="Ok" @click="close">Salir</button>
                          <button type="button" class="btn btn-sm btn-outline-success" aria-label="Ok" @click="GuardarTipoCambio()">Generar</button>
                      </div>
                  </div>
              </footer>
              <TCambioSAT v-if="tcambioSat" @close="tcambioSat = false">
                <template v-slot:title>Tipos de Cambio (SAT)</template>
                    <template v-slot:body>
                        <div class="row">
                            <div class="col-10">
                                <b-table-simple hover small caption-top responsive>
                                    <b-thead head-variant="light"  class="table-header">
                                        <b-tr class="table-header">
                                            <b-th>Dia</b-th>
                                            <b-th>TCambio</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody v-if="tcambio.length > 0">
                                        <b-tr v-for="(tc,x) in tcambio" :key="x" :class="{'selected-row': (selectedTC.indx == x) }" @click="selectedTC=merc;selectedTC.indx = x" @dblclick="tcambioSat = false">
                                            <b-td class="text-center">{{ tc.pda }}</b-td>
                                            <b-td class="text-center">{{ tc.clave }}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                    <b-tbody v-else>
                                        <b-tr>
                                            <b-td class="text-center" :variant="'secondary'" colspan="16"><p class="p-4">NO HAY DATOS</p></b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </div>
                            <div class="col-2">
                                <button @click="tcambioSat = false" class="btn btn-success btn-sm">Elegir</button>
                            </div>
                        </div>
                    </template>
                </TCambioSAT>
          </div>
      </div>
    </transition>
</template>

<script>
    import handleGlobals from '@/mixins/handleGlobals'
    import TCambioSAT from '@/components/PopupWindow.vue';
    export default {
    name: 'WIN_GeneraFactura',
    params:['datos'],
    mixins:[handleGlobals],
    components:{TCambioSAT},
    emits:['setTCambio'],
    data(){
        return{
            tcambioSat:false,
            tcambio:[],
            selectedTC: {},
            edt: {}
        }
    },
    created(){
        this.edt.fecha = new Date()
        this.edt.fecha = this.formato(this.edt.fecha,"YYYY-MM-DD")
        
    },
    methods: {
        close() {
            this.$emit('close');
        },
        sat(){
            this.tcambioSat = true
            if(!this.selectedTC){
                this.showNotification = true;
                this.notificationTitle= "Error";
                this.notificationTexto = "Página del S.A.T. parece no estar activa.";
                return
            }
            this.edt.tipocambio = this.selectedTC
        },
        GuardarTipoCambio(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json" ,
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "metodo", 
                                        metodo: 'GuardarTipoCambio' , 
                                        archivo: "Global", 
                                        params: {   fecha:  this.edt.fecha,
                                                    tipocambio: this.edt.tipocambio }})
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.$emit('setTCambio',data)
            });
        },
       
    },
};
</script>