<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Ordenes de Embarques</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row w-65">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-9 col-12">
                                            <form>
                                                <div class="row ">
                                                    <div class="col-6">
                                                        <div class=" row">
                                                            <label class="col-md-2 col-4 text-nowrap">Folio</label>
                                                            <div class="col-md-10 col-8">
                                                                <input type="number" ref="folio" class="form-control form-control-sm "  v-model="embarque.folio" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 text-end">
                                                        <div class=" row">
                                                            <label class="col-md-2 col-4 text-nowrap">Sucursal</label>
                                                            <div class="col-md-5 col-8">
                                                                <input type="number" ref="sucursal" class="form-control form-control-sm " readonly  v-model="embarque.sucursal" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <lookUpCtrl v-if="embarque.folio" :objeto="embarque" :label="'Remitente'" :cols="[1,2,9]" :archivo="'Clientes'"  :campo="'clienteid'" :descripcion="'nombre'" :model="'remitente'" v-model="embarque.remitente" />
                                                <lookUpCtrl v-if="embarque.folio" :objeto="embarque" :label="'Ruta No.'" :cols="[1,2,9]" :archivo="'Rutas'"  :campo="'rutaid'" :descripcion="'descripcion'" :model="'ruta'" v-model="embarque.ruta" />
                                                
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-md-2 col-4 text-nowrap">Cantidad</label>
                                                            <div class="col-md-10 col-8">
                                                                <input type="number" ref="cantidad" class="form-control form-control-sm " v-model="embarque.cantidad" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-md-2 col-4 text-nowrap">Toneladas</label>
                                                            <div class="col-md-5 col-8">
                                                                <input type="number" step="any" min="0" ref="toneladas" class="form-control form-control-sm "  v-model="embarque.toneladas" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-md-2 col-4 text-nowrap">Viajes</label>
                                                            <div class="col-md-5 col-8">
                                                                <input type="text" ref="viajes" maxlength="12" class="form-control form-control-sm" v-model="embarque.viajes" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <lookUpCtrl v-if="embarque.folio" :objeto="embarque" :label="'Operador No.'" :cols="[1,2,9]" :archivo="'Operadores'"  :campo="'numero'" :descripcion="'nombres'" :model="'operador'" v-model="embarque.operador" />
                                                
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-md-2 col-4 text-nowrap">Licencia</label>
                                                            <div class="col-md-5 col-8">
                                                                <input type="text" ref="licencia" maxlength="20" class="form-control form-control-sm "  v-model="embarque.licencia" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <lookUpCtrl v-if="embarque.folio" :objeto="embarque" :label="'Vehiculos'" :cols="[1,2,9]" :archivo="'Vehiculos'"  :campo="'numero'" :descripcion="'ano'" :model="'vehiculo'" v-model="embarque.vehiculo" />
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-md-2 col-4 text-nowrap">Placa</label>
                                                            <div class="col-md-5 col-8">
                                                                <input type="text" ref="placa" maxlength="10" class="form-control form-control-sm "  v-model="embarque.placa" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <lookUpCtrl v-if="embarque.folio" :objeto="embarque" :label="'Tipo Remolque'" :cols="[1,2,9]" :archivo="'TpoUnidad'"  :campo="'clave'" :descripcion="'descripcion'" :model="'tiporemolque'" v-model="embarque.tiporemolque" />
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-md-2 col-4 text-nowrap">Solicitó</label>
                                                            <div class="col-md-5 col-8">
                                                                <input type="text" ref="solicito" maxlength="50" class="form-control form-control-sm "  v-model="embarque.solicito" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-md-2 col-4 text-nowrap">Observaciones</label>
                                                            <div class="col-md-5 col-8">
                                                                <textarea ref="observaciones" maxlength="50" style="min-heigth:150px" class="form-control form-control-sm "  v-model="embarque.observaciones" ></textarea>		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="col-md-3 col-12">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li class="mobile-float"><button @click="setEmbarques()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li><router-link to="/embarques" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <InfoPopUpVue  v-show="showNotification" @close="closeInfo">
                        <template v-slot:header>
                            <h6 class="text-uppercase">{{notificationTitle}}</h6>
                        </template>
                        <template v-slot:body>
                            {{notificationText}}
                        </template>
                    </InfoPopUpVue>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import InfoPopUpVue from '@/components/InfoPopUp.vue';
import handleGlobals from '@/mixins/handleGlobals'
import handlePopUp from '@/mixins/handlePopUp'
import handleForm from '@/mixins/handleForm';
import LoadingContent from '@/components/LoadingSpinner.vue'
import lookUpCtrl from '@/components/LookUpCtrl.vue';

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    mixins:[handleGlobals,handlePopUp,handleForm],
    components:{SideBar,TopBar,InfoPopUpVue,LoadingContent,lookUpCtrl},
    data(){
        return{
            id: this.$route.params.folio,
            modo: this.$route.params.folio > 0 ? 'Modificando' : 'Creacion', 
            embarque: {},
            titulo: 'Agregando una Orden de Embarque',  
        }
    },
    mounted(){
        if(this.modo == 'Modificando'){
            this.getEmbarques()
        }
    },
    methods:{
        async getEmbarques(){
            let req = []
            this.isLoading = true
           await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "inicializar",modo:this.modo,  archivo: "OrdEmbarque", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.embarque = data['res'];
                req = data['req']
            }).finally(()=>{
               this.setRequired(req)
               this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setEmbarques(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: 'validar', archivo: "OrdEmbarque", modo:this.modo, record: this.embarque }) 
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['res'] == 'error'){
                    this.showNotification = true
                    this.notificationTitle =  'Error'
                    this.notificationText = data['msg']
                    this.notificationRef = 'folio'
                }else{
                    this.$router.push('/embarques')
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        afterClose(){
            this.$refs[this.notificationRef].focus()
        },
      
       
    }
}
</script>