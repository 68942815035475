<template>
  <transition name="popup-fade">
    <div class="popup-backdrop">
      <div class="popup " role="dialog" aria-labelledby="PopupTitulo" aria-describedby="PopupDescripcion" :class="[size != '' ? 'w-'+size : '']">
        <section class="popup-body p-2" id="PopupDescripcion">
          <slot name="body"></slot>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ProgressPopup',
  props:['size'],
  methods: {
    close() {
      this.$emit('close');
    },
    action() {
      this.$emit('goAhead');
    },
  },  
}
</script>