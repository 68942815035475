<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Historia de Tipos de Cambio</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row w-40">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-9 col-12">
                                            <form>
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-md-3 col-4 text-nowrap">Fecha</label>
                                                            <div class="col-md-9 col-8 ">
                                                                <input type="date" ref="fecha" :disabled="disabled_fecha" class="form-control form-control-sm "  v-model="fecha" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-md-3 col-4 text-nowrap">Tipo de Cambio</label>
                                                            <div class="col-md-7 col-6">
                                                                <input type="number" min="0" step='0.01' class="form-control form-control-sm "  ref="tcambio" v-model="tcambio.tcambio " />
                                                            </div>
                                                            
                                                            <div class="col-md-2 col-2">
                                                                <button type="button" @click="getSAT()" class="btn btn-outline-secondary btn-sm">SAT</button>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </form>
                                        </div>
                                        <div class="col-md-3 col-12">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li class="mobile-float"><button @click="setHistoria()" class="btn btn-sm btn-success btn-block">Guardar</button></li>
                                                <li class="mobile-float" v-if="showSalir"><router-link to="/historiatcambio" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PopupWindowVue v-if="openTCSAT" @close="closePopup" @goAhead="SetTCSAT">
                        <template v-slot:title>
                            <h6>Tipos de Cambio (SAT)</h6>
                        </template>
                        <template v-slot:body>
                            <div class="table-responsive ps-2">
                                <h4 class="text-uppercase">{{formato(fechahoy,"MMMM YYYY") }} </h4>
                                <b-table-simple class="browse" hover small caption-top responsive >
                                    <b-thead head-variant="light" class="table-header">
                                        <b-tr>
                                            <b-th class="text-nowrap">Día</b-th>
                                            <b-th class="text-nowrap">TCambio</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="(sat,x) in sattable" :key="x" 
                                        @click="selectedSatTCambio = sat;selectedSatTCambio.indx = x" 
                                        @dblclick="SetTCSAT()" 
                                        :class="{'selected-row': (selectedSatTCambio.dia == sat.dia)}">
                                            <b-td class="text-nowrap text-start ">{{ formato(sat.dia ,"dddd / DD") }}</b-td>
                                            <b-td class="text-nowrap text-end">{{ sat.tcambio }}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </div>
                        </template>
                    </PopupWindowVue>
                    <InfoPopUpVue  v-show="showNotification" @close="closeInfo">
                        <template v-slot:header>
                            <h6 class="text-uppercase">{{notificationTitle}}</h6>
                        </template>
                        <template v-slot:body>
                            {{notificationText}}
                        </template>
                    </InfoPopUpVue>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import InfoPopUpVue from '@/components/InfoPopUp.vue';
import handleGlobals from '@/mixins/handleGlobals'
import handlePopUp from '@/mixins/handlePopUp'
import handleForm from '@/mixins/handleForm'
import LoadingContent from '@/components/LoadingSpinner.vue'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
import PopupWindowVue from '@/components/PopupWindow.vue';
export default {
    mixins:[handleGlobals,handlePopUp,handleForm],
    components:{SideBar,TopBar,InfoPopUpVue,LoadingContent,PopupWindowVue},
    data(){
        return{
            id: this.$route.params.tcambio,
            modo: (this.$route.params.tcambio != '' && this.$route.params.tcambio != undefined ? 'Modificando' : 'Creacion'), 
            tcambio: [],
            titulo: 'Agregando un T.Cambio',  
            disabled_fecha:false,
            showSalir:false,
            openTCSAT:false,
            sattable:[],
            selectedSatTCambio:0,
            fechahoy: new Date()
        }
    },
    mounted(){
        this.getHistoria();
        if(this.modo == 'Modificando'){
            this.showSalir = true
            this.disabled_fecha = true
        }
    },
    computed:{
        fecha:{
            get(){
                return this.formato(this.tcambio.fecha,"YYYY-MM-DD")
            },
            set(val){
                return val 
            }  
        },
    },
    methods:{
        async getHistoria(){
            this.isLoading = true
            let req =[]
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "inicializar",modo:this.modo,  archivo: "TCHistoria", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.tcambio = data['res'];
                req = data['req']
            }).finally(()=>{
               this.setRequired(req)
               this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setHistoria(){
            let dSemana = new Date()
            dSemana.setDate(dSemana.getDate() + 7)
            if(this.tcambio.fecha > dSemana){
                this.showNotification = true
                this.notificationTitle =  'Error'
                this.notificationText = "No puedes capturar una fecha futura."
                this.notificationRef = 'fecha'
                return
            }
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: 'validar', archivo: "TCHistoria", modo:this.modo, id: this.id, record: this.tcambio }) 
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['res'] == 'error'){
                    this.showNotification = true
                    this.notificationTitle =  'Error'
                    this.notificationText = data['msg']
                    this.notificationRef = 'clave'
                }else{
                    if(data['msg'] != ''){
                        this.showNotification = true
                        this.notificationTitle = 'Error'
                        this.notificationText = data['msg']
                        return
                    }else{
                        this.$router.push('/historiatcambio')
                    }
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        closePopup(){
            this.openTCSAT = false
        },
        getSAT(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "metodo",archivo: "TCHistoria", metodo:"TipoCambio_DOF" , 
                                        params:{ }})
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.sattable = data.sort((a, b) => new Date(b.dia) - new Date(a.dia))
                
                if(this.sattable.length <= 0){
                    this.notificationTitle = 'Aviso'
                    this.notificationText = 'El sitio del S.A.T. parece no estar activo.'
                    this.showNotification = true
                }else{
                    this.openTCSAT = true
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        afterClose(){
            this.$refs[this.notificationRef].focus()
        },
        SetTCSAT(){
            this.closePopup()
            this.tcambio.tcambio = this.selectedSatTCambio.tcambio
        }
     
    }
}
</script>