<template>
    <div>
        <input type="text" class="form-control form-control-sm text-end" :disabled="disabled" :readonly="readonly" v-model="displayValue" @blur="isInputActive = false" @focus="isInputActive = true"/>
    </div>
</template>

<script>
export default {
    name:'my-currency-input',
    props: ["value","readonly","disabled"],
    data() {
        return {
            isInputActive: false
        }
    },
    computed: {
        displayValue: {
            get: function() {
                if (this.isInputActive) {
                    // Cursor is inside the input field. unformat display value for user
                    return (this.value != undefined ? this.value.toString() : '')
                } else {
                    // User is not modifying now. Format display value for user interface
                  //return this.value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                    
                    return (this.value != undefined ? Number(this.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") : 0)
                }
            },
            set: function(modifiedValue) {
                // Recalculate value after ignoring "$" and "," in user input
                let newValue = parseFloat(modifiedValue.replace(/[^\d]/g, ""))
                // Ensure that it is not NaN
               
                if (isNaN(newValue)) {
                    newValue = 0
                }
                // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
                // $emit the event so that parent component gets it
                this.$emit('input', newValue)
            }
        }
    }
}
</script>
