<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Maestro de distancias</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row w-65">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-10">
                                            <form>
                                                <div class="row ">
                                                    <label class="col-2 text-nowrap">Num ID</label>
                                                    <div class="col-3">
                                                        <input ref="numid" type="number" class="form-control form-control-sm" required disabled v-model="distancia.numid" />													
                                                    </div>
                                                </div>	
                                        
                                                <LookUpCtrl v-if="distancia.numid" :label="'Origen'" :cols="[2,3,7]" :archivo="'Ciudades'" :objeto="distancia"  :campo="'clave'" :descripcion="'ciudad'" :model="'origen'" v-model="distancia.origen" />
                                        
                                                <LookUpCtrl v-if="distancia.numid" :label="'Destino'" :cols="[2,3,7]" :archivo="'Ciudades'" :objeto="distancia" :campo="'clave'" :descripcion="'ciudad'" :model="'destino'" v-model="distancia.destino" />
                                                  
                                               
                                                <div class="row ">
                                                    <label class="col-2 text-nowrap">Ruta</label>
                                                    <div class="col-10">
                                                        <input ref="ruta" type="text" maxlength="30" class="form-control form-control-sm" v-model="distancia.ruta" />													
                                                    </div>
                                                </div>	
                                    
                                                <div class="row ">
                                                    <label class="col-2 text-nowrap">Distancia</label>
                                                    <div class="col-7">
                                                        <input ref="distancia" type="number" class="form-control form-control-sm" v-model="distancia.distancia" />													
                                                    </div>
                                                </div>	
                                                   
                                                <div class="row ">
                                                    <label class="col-2 text-nowrap">Tiempo</label>
                                                    <div class="col-5"> 
                                                        <input ref="tiempo" type="text" maxlength="10" class="form-control form-control-sm"  v-model="distancia.tiempo" />													
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                        <div class="col-2">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li><button @click="setDistancia()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li><router-link to="/distancias" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import handleForm from '@/mixins/handleForm';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'
import LookUpCtrl from '@/components/LookUpCtrl.vue';

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    components:{SideBar, TopBar,LoadingContent,LookUpCtrl },
    mixins:[handleForm,handleGlobals],
    data(){
        return{
            id: this.$route.params.distancia,
            modo: (this.$route.params.distancia > 0 ? 'Modificando' : 'Creacion'), 
            distancia: {},
            titulo: 'Agregando una distancia',
        }
    },
    mounted(){
        this.llenaForma();
    },
    methods:{
        async llenaForma(){
            await this.getDistancia();
            this.titulo = this.$route.params.distancia > 0 ? 'Cambiando una distancia' :  'Agregando una distancia'
        },
        getDistancia(){
            let req = []
            this.isLoading = true
             fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "inicializar", modo:this.modo, archivo: "Distancias", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json()
                }   
            }).then((data)=>{
                this.distancia = data['res']
                req = data['req']
            }).finally(()=>{
                this.setRequired(req)
                this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setDistancia(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: 'validar', archivo: "Distancias", modo:this.modo, record: this.distancia }) 
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['res'] == 'error'){
                    this.showNotification = true
                    this.notificationTitle =  'Error'
                    this.notificationText = data['msg']
                    this.notificationRef = 'ruta'
                }else{
                    this.$router.push('/distancias')
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        afterClose(){
           
            this.$refs[this.notificationRef].focus()
        }
    }
}
</script>