<template>
    <section>
        <SideBar v-if="!OpenMode" />
        <TopBar v-if="!OpenMode" />
        <div :class="[!OpenMode ? 'content' : '' ]">   
        <div class="main-content-header">
            <h1>Maestro de Vehiculos</h1>
        </div>
        <LoadingContent v-if="isLoading" />
        <div class="row">
            <div class="col-lg-12 col-md-6">
                <div class="card mb-30" :style="{height:[contentHeight+'px']}">
                    <div class="row " :class="[!OpenMode ? 'p-4' : '']">
                        <div class="content-header  col-12"> 
                            <div class="row">
                                <div class="col-5 text-start ps-4">

                                </div>
                                <div class="col-5 text-start ps-4">
                                    <div class="row">
                                        <label  class="col-5 text-nowrap text-end">Mostrar</label>
                                        <div class="col-7">
                                            <select v-model="filtroEstado" @change="consultar()" class="form-control form-control-sm">
                                                <option value="D">Disponible</option>
                                                <option value="T">Trafico</option>
                                                <option value="M">Mantenimiento</option>
                                                <option value="F">Fuera de Servicio</option>
                                                <option value="X">Todos</option>
                                                <option value="A">Activos</option>
                                                <option value="I">Inactivos</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2"><p class="p-1 pe-4 mb-0 text-end">Página {{ paginaActual }} de {{ totalPaginas }} </p></div>
                                <div class="col-2 offset-10 ps-2 pe-5 text-end pb-0 pt-0">
                                    <ul class="list-unstyled mb-0 d-flex justify-content-between pager-btns float-right">
                                        <li><button @click="setPaginacion('first')" type="button" class="pager pager-first btn btn-sm btn-outline-secondary"><ChevronDoubleLeftIcon /></button></li>
                                        <li><button @click="setPaginacion('prev')"  type="button" class="pager pager-prev  btn btn-sm btn-outline-secondary"><ChevronLeftIcon /></button></li>
                                        <li><button @click="setPaginacion('next')"  type="button" class="pager pager-next  btn btn-sm btn-outline-secondary"><ChevronRightIcon /></button></li>
                                        <li><button @click="setPaginacion('last')"  type="button" class="pager pager-last  btn btn-sm btn-outline-secondary"><ChevronDoubleRightIcon /></button></li> 
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-10 col-12">
                            <div class="table-responsive ps-2">
                                <b-table-simple class="browse" hover small caption-top responsive >
                                    <b-thead head-variant="light"  class="table-header">
                                        <b-tr>
                                            <b-th class="text-nowrap">D</b-th>
                                            <b-th class="text-nowrap">Suc</b-th>
                                            <b-th class="text-nowrap">Número</b-th>
                                            <b-th class="text-nowrap">Número Económico</b-th>
                                            <b-th class="text-nowrap">Marcador</b-th>
                                            <b-th class="text-nowrap">Rendimiento Std.</b-th>
                                            <b-th class="text-nowrap">Kms/Lto<br>Hrs/Lto</b-th>
                                            <b-th class="text-nowrap">Año</b-th>
                                            <b-th class="text-nowrap">Marca</b-th>
                                            <b-th class="text-nowrap">Modelo</b-th>
                                            <b-th class="text-nowrap">Color</b-th>
                                            <b-th class="text-nowrap">Placa</b-th>
                                            <b-th class="text-nowrap">GeoZona ID</b-th>
                                            <b-th class="text-nowrap">GeoZona Nombre</b-th>
                                            <b-th class="text-nowrap">Operador</b-th>
                                            <b-th class="text-nowrap">Nombre</b-th>
                                            <b-th class="text-nowrap">Serie</b-th>
                                            <b-th class="text-nowrap">Estatus</b-th>
                                            <b-th class="text-nowrap">Activo o Inactivo</b-th>
                                            <b-th class="text-nowrap">Póliza de Seguro</b-th>
                                            <b-th class="text-nowrap">Inciso Póliza</b-th>
                                            <b-th class="text-nowrap">Tipo Unidad</b-th>
                                            <b-th class="text-nowrap">Modalidad</b-th>
                                            <b-th class="text-nowrap">Uso</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="(veh,indx) in vehiculos" 
                                            :key="veh.numero" 
                                            @click="selectedRegistro = veh;selectedRegistro.indx = indx" 
                                            @dblclick=" (!OpenMode ? modificar() : exportRegistry()) " 
                                            :class="{ 'selected-row': (selectedRegistro.numero == veh.numero),
                                                      'son-text-danger': (veh.activoinactivo_desc == 'Inactivo') }">
                                            <b-td class="text-nowrap"><FileDocumentOutlineIcon v-if="veh.doctos > 0" /></b-td>
                                            <b-td class="text-center">{{ veh.sucursal | leadingZeros(3) }}</b-td>
                                            <b-td class="text-nowrap">{{ veh.numero | leadingZeros(7) }}</b-td>
                                            <b-td class="text-nowrap">{{ veh.economico }}</b-td>
                                            <b-td class="text-nowrap">{{ veh.marcador | toCurrency }}</b-td>
                                            <b-td class="text-nowrap">{{ veh.rendimientostd | toCurrency }}</b-td>
                                            <b-td class="text-nowrap">{{ veh.factor }}</b-td>
                                            <b-td class="text-nowrap">{{ veh.ano }}</b-td>
                                            <b-td class="text-nowrap">{{ veh.marca }}</b-td>
                                            <b-td class="text-nowrap">{{ veh.modelo }}</b-td>
                                            <b-td class="text-nowrap">{{ veh.color }}</b-td>
                                            <b-td class="text-nowrap">{{ veh.placa }}</b-td>
                                            <b-td class="text-nowrap">{{ veh.dev_zoneid }}</b-td>
                                            <b-td class="text-nowrap">{{ veh.geo_nombre }}</b-td>
                                            <b-td class="text-nowrap">{{ veh.operador  | leadingZeros(5) }}</b-td>
                                            <b-td class="text-nowrap">{{ veh.ope_nombre}}</b-td>
                                            <b-td class="text-nowrap">{{ veh.serie }}</b-td>
                                            <b-td class="text-nowrap">{{ veh.estatus }}</b-td>
                                            <b-td class="text-nowrap">{{ veh.activoinactivo_desc }}</b-td>
                                            <b-td class="text-nowrap">{{ veh.polizaseguro }}</b-td>
                                            <b-td class="text-nowrap">{{ veh.incisopoliza }}</b-td>
                                            <b-td class="text-nowrap">{{ veh.tipounidad }}</b-td>
                                            <b-td class="text-nowrap">{{ veh.modo }}</b-td>
                                            <b-td class="text-nowrap">{{ veh.uso }}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </div>
                        </div> 
                        <div class="col-2 ps-2 pe-5" >
                            <ul class="d-block list-unstyled action-block">
                                <li v-if="!OpenMode"><router-link to="/vehiculos/agregar" class="btn btn-xs btn-success btn-block">Agregar</router-link></li>
                                <li v-if="!OpenMode"><button @click="modificar()" :disabled="disableBtn" type="button" class="btn btn-xs btn-primary btn-block">Cambiar</button></li>
                                <li v-if="!OpenMode"><button @click="ask()" :disabled="disableBtn" class="btn btn-xs btn-danger btn-block">Borrar</button></li>
                                <li v-if="!OpenMode"><button @click="imprimir()" :disabled="true" class="btn btn-xs btn-secondary btn-block">Imprimir</button></li>
                                <li v-if="OpenMode"><button  @click="exportRegistry()" class="btn btn-xs btn-success btn-block">Elegir</button></li>
                            </ul>

                            <div class="ms-auto" style="width: 100px;text-align: left;">
                                <div class="form-check">
                                    <input class="form-check-input" v-model="filtroTipo" @change="consultar()" value="VEH" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        Vehículos
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" v-model="filtroTipo" @change="consultar()" value="REM" type="radio" name="flexRadioDefault" id="flexRadioDefault3">
                                    <label class="form-check-label" for="flexRadioDefault3">
                                        Remolques
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" v-model="filtroTipo" @change="consultar()"  value="T" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
                                    <label class="form-check-label" for="flexRadioDefault2">
                                        Todos
                                    </label>
                                </div>
                            </div>
                            
                            <ul class="d-block list-unstyled action-block pt-5">
                                <li class="dropdown">
                                    <button :disabled="disableBtn" class="btn btn-xs btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><MapSearchOutlinetIcon /></button>
                                    <div class="dropdown-menu">
                                        <span @click="showUltimasPosiciones = true" class="dropdown-item" href="#">Ultimas posiciones</span>
                                        <span @click="showVehiculosCercanos = true" class="dropdown-item" href="#">Vehículos cercanos</span>
                                    </div>
                                </li>
                                <li><button class="btn btn-xs btn-outline-secondary btn-block text-nowrap">Marcadores</button></li>
                            </ul>
                        </div>
                        <div class="content-footer col-12 text-start"></div>
                    </div>
                </div>
            </div>
        </div>
      
        <UltimasPosicionesWnd v-if="showUltimasPosiciones" :size="50" :economico="selectedRegistro.economico" :numero="selectedRegistro.numero" :component="'WIN_Posiciones'" @close="showUltimasPosiciones = false"></UltimasPosicionesWnd>
        <VehiculosCercanosWnd v-if="showVehiculosCercanos" :size="80" :params="[{ economico: selectedRegistro.economico, numero: selectedRegistro.numero}]" :component="'WIN_VehiculosCercanos'" @close="showVehiculosCercanos = false"></VehiculosCercanosWnd>

        <DeleteReg  v-show="showDelete" @close="closeDelete" @aceptar="vehiculos = borrar('Vehiculos',vehiculos)">
            <template v-slot:header>
                <h6 class="text-uppercase">{{notificationTitle}}</h6>
            </template>
            <template v-slot:body>
                {{notificationText}}
            </template>
        </DeleteReg>
        </div>
    </section>
</template>

<script>
import UltimasPosicionesWnd from '@/components/ModalComponent.vue';
import VehiculosCercanosWnd from '@/components/ModalComponent.vue';
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronDoubleRightIcon from 'vue-material-design-icons/ChevronDoubleRight.vue';
import ChevronDoubleLeftIcon from 'vue-material-design-icons/ChevronDoubleLeft.vue';
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';
import MapSearchOutlinetIcon from 'vue-material-design-icons/MapSearchOutline.vue';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'
import handleBrowse from '@/mixins/handleBrowse'
import handlePopUp from '@/mixins/handlePopUp'
import DeleteReg from '@/components/YesNoPopup.vue';

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    name:'WIN_Vehiculos',
    props:['mode','perpage'],
    mixins:[handleGlobals,handleBrowse,handlePopUp],
    components:{SideBar,TopBar,UltimasPosicionesWnd,VehiculosCercanosWnd,DeleteReg,ChevronLeftIcon,ChevronDoubleRightIcon,MapSearchOutlinetIcon,ChevronDoubleLeftIcon,ChevronRightIcon, LoadingContent},
    data(){
        return{
            vehiculos: [],
            filtroEstado: 'X',
            filtroTipo:'VEH',
            showUltimasPosiciones: false,
            showVehiculosCercanos:false
        }
    },
    created(){
        if(this.OpenMode == false){
            this.getFiltros()
        } 
    },
    methods:{
        async getFiltros(){
                await fetch(this.$api+'/rdx', {    
                    method: "POST",
                    headers: {
                        'Content-Type' : 'application/json',
                        'Authorization' : this.$store.state.iasctratkn
                    },
                    body: JSON.stringify({accion: "filters"})
                }).then((response)=>{
                    if(response.ok){
                        return response.json()
                    }
                }).then((data)=>{
                    let reg = data['VEH']
                    if(reg){
                        this.filtroEstado   = reg['estatus'].dato
                        this.filtroTipo     = reg['tipo'].dato
                        this.activoinactivo = reg['activoinactivo'].dato
                        this.paginaActual   = reg['paginaActual'].dato
                        if(this.paginaActual == '') this.paginaActual = 0
                    }else{
                        this.filtroEstado = 'X'
                        this.filtroTipo = 'VEH'
                        this.activoinactivo = ''
                        this.paginaActual  = 1
                    }
                }).catch((err)=>{
                    this.falseToken(err)
                });
        },
        consultar(){
            this.isLoading = true
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "browse", 
                                        archivo: "Vehiculos", 
                                        activarPaginas:true,
                                        orden: "numero",
                                        filtro: {   estatus: {dato: this.filtroEstado, inactivo: this.filtroEstado == 'X' || this.filtroEstado == 'A' || this.filtroEstado == 'I'},
                                                    tipo: {dato: this.filtroTipo, inactivo: this.filtroTipo == 'T'},
                                                    activoinactivo: {dato: this.filtroEstado, inactivo: ((this.filtroEstado != 'A' && this.filtroEstado != 'I') || this.filtroEstado == 'X') },
                                                    paginaActual: {  dato: 'NA'}
                                                },
                                        pagina: this.paginaActual, 
                                        paginacion: this.paginacion,
                                        registrosxpagina: this.rows })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.vehiculos      = data['registros'];
                this.totalRegistros = data['totalRegistros'];
                this.totalPaginas   = data['totalPaginas'];
                this.paginaActual   = data['paginaActual'];
                this.savePage('vehiculos',this.paginaActual)

            }).finally(()=>{
                this.isLoading = false;
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        imprimir(){

        },
        modificar(){
            this.$router.push({ name: 'CVehiculos', params: {vehiculo: this.selectedRegistro.numero}});
        }
    }
}
</script>

