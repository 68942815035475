<template>
    <section>
        <SideBar v-if="!OpenMode" />
        <TopBar v-if="!OpenMode" />
        <div :class="[!OpenMode ? 'content' : '' ]">   
            <div class="main-content-header">
                <h1>Historia de Tipos de Cambio</h1>
            </div>
            <LoadingContent v-if="isLoading" />
            <div class="row">
                <div class="col-lg-12 col-md-6">
                    <div class="card mb-30" :style="{height:[contentHeight+'px']}">
                        <div class="row " :class="[!OpenMode ? 'p-4' : '']">
                            <div class="content-header col-12"> 
                                <div class="row">
                                    <div class="col-md-6 col-12 text-start ps-4"></div>
                                    <div class="col-md-6 col-12"><p class="p-1 pe-4 mb-0 text-end">Página {{ paginaActual }} de {{ totalPaginas }} </p></div>
                                    
                                    <div class="col-2 offset-10 ps-2 pe-5 text-end pb-0 pt-0">
                                        <ul class="list-unstyled mb-0 d-flex justify-content-between pager-btns float-right">
                                            <li><button @click="setPaginacion('first')" type="button" class="pager pager-first btn btn-sm btn-outline-secondary"><ChevronDoubleLeftIcon /></button></li>
                                            <li><button @click="setPaginacion('prev')"  type="button" class="pager pager-prev  btn btn-sm btn-outline-secondary"><ChevronLeftIcon /></button></li>
                                            <li><button @click="setPaginacion('next')"  type="button" class="pager pager-next  btn btn-sm btn-outline-secondary"><ChevronRightIcon /></button></li>
                                            <li><button @click="setPaginacion('last')"  type="button" class="pager pager-last  btn btn-sm btn-outline-secondary"><ChevronDoubleRightIcon /></button></li> 
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-10 col-12">
                                <div class="table-responsive ps-2">
                                    <b-table-simple class="browse" hover small caption-top responsive >
                                        <b-thead head-variant="light"  class="table-header">
                                            <b-tr>
                                                <b-th>Fecha</b-th>
                                                <b-th>Día</b-th>
                                                <b-th>TC Pesos/Usd</b-th>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr v-for="(tc,x) in historia" 
                                                :key="x" 
                                                @click="selectedRegistro = tc; selectedRegistro.indx = x" 
                                                @dblclick=" (!OpenMode ? modificar() : exportRegistry()) " 
                                                :class="{ 'selected-row': (selectedRegistro.fecha == tc.fecha) }">
                                                <b-td class="text-end">{{formato( tc.fecha,"DD/MMM/YYYY")}}</b-td>
                                                <b-td class="text-start">{{ tc.dia }}</b-td>
                                                <b-td class="text-end">{{ tc.tcambio }}</b-td>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </div>
                            </div>
                            <div class="col-2 ps-2 pe-5 btn-manager" >
                                <ul class="d-block list-unstyled action-block">
                                    <li v-if="!OpenMode"><router-link to="/historiatcambio/agregar" class="btn btn-xs btn-success btn-block">Agregar</router-link></li>
                                    <li v-if="!OpenMode"><router-link to="/historiatcambio/modificar" custom >
                                            <button :disabled="disableBtn" type="button" class="btn btn-xs btn-primary btn-block">Cambiar</button>
                                        </router-link>
                                    </li>
                                    <li v-if="!OpenMode"><button @click="ask()" :disabled="disableBtn" class="btn btn-xs btn-danger btn-block">Borrar</button></li>
                                    <li v-if="!OpenMode"><button @click="imprimir()" :disabled="true" class="btn btn-xs btn-secondary btn-block">Imprimir</button></li>
                                    <li v-if="OpenMode"><button @click="exportRegistry()" class="btn btn-xs btn-success btn-block">Elegir</button></li>
                                </ul>
                            </div>
                            
                            <div class="content-footer col-12 text-start"></div>
                        </div>
                    </div>
                </div>
            </div>
          
            <DeleteReg  v-show="showDelete" @close="closeDelete" @aceptar="historia = borrar('TCHistoria',historia)">
                <template v-slot:header>
                    <h6 class="text-uppercase">{{notificationTitle}}</h6>
                </template>
                <template v-slot:body>
                    {{notificationText}}
                </template>
            </DeleteReg>
        </div>
    </section>
</template>

<script>
import handleBrowse from '@/mixins/handleBrowse'
import handleGlobals from '@/mixins/handleGlobals'
import handlePopUp from '@/mixins/handlePopUp'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
import DeleteReg from '@/components/YesNoPopup.vue';
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronDoubleRightIcon from 'vue-material-design-icons/ChevronDoubleRight.vue';
import ChevronDoubleLeftIcon from 'vue-material-design-icons/ChevronDoubleLeft.vue';
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';
import LoadingContent from '@/components/LoadingSpinner.vue'
export default {
    name:'WIN_TCHistoria',
    props:['mode','perpage'],
    mixins:[handleGlobals,handleBrowse,handlePopUp],
    components:{SideBar,TopBar,ChevronLeftIcon,DeleteReg,ChevronDoubleRightIcon,ChevronDoubleLeftIcon,ChevronRightIcon, LoadingContent},
    data(){
        return{
            historia: []
        }
    },
    created(){
        if(this.OpenMode == false){
            this.getFiltros()
        } 
    },
   
    methods:{
        async getFiltros(){
                await fetch(this.$api+'/rdx', {    
                    method: "POST",
                    headers: {
                        'Content-Type' : 'application/json',
                        'Authorization' : this.$store.state.iasctratkn
                    },
                    body: JSON.stringify({accion: "filters"})
                }).then((response)=>{
                    if(response.ok){
                        return response.json()
                    }
                }).then((data)=>{
                    let reg = data['TCH']
                    if(reg){
                        this.paginaActual = reg['paginaActual'].dato
                        if(this.paginaActual == '') this.paginaActual = 0
                    }else{
                        this.paginaActual = 1
                    }
                }).catch((err)=>{
                    this.falseToken(err)
                })
            },
        consultar(){
            this.isLoading = true
            
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "browse", 
                                        archivo: "TCHistoria", 
                                        activarPaginas:true,
                                        orden: "fecha",
                                        filtro: {paginaActual: {dato: 'NA'}},
                                        pagina: this.paginaActual, 
                                        paginacion: this.paginacion,
                                        registrosxpagina: this.rows })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.historia       = data['registros'];
                this.totalRegistros = data['totalRegistros'];
                this.totalPaginas   = data['totalPaginas'];
                this.paginaActual   = data['paginaActual'];
            }).finally(()=>{
                this.isLoading = false;
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        modificar(){
            this.$router.push({ name: 'CTCHistoria', params: {tcambio: this.selectedRegistro.fecha}});
        },
    }
}
</script>

