<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Maestro</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row w-65">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-10">
                                            <form>
                                                <div class="row ">
                                                    <label class="col-2 text-nowrap">Grupo</label>
                                                    <div class="col-3">
                                                        <input ref="grupo" type="number" class="form-control form-control-sm" required disabled v-model="grupo.grupo" />													
                                                    </div>
                                                </div>	
                                                <div class="row ">
                                                    <label class="col-2 text-nowrap">Nombre</label>
                                                    <div class="col-5"> 
                                                        <input ref="descripcion" type="text" maxlength="20" class="form-control form-control-sm" required v-model="grupo.descripcion" />													
                                                    </div>
                                                </div>	
                                                <LookUpCtrl v-if="grupo.grupo" :label="'Empleado'" :cols="[4,7,1]" :archivo="'Empleados'" :objeto="grupo"  :campo="'empleado'" :descripcion="'nombre'" :model="'empleado'" v-model="grupo.empleado" />
                                                <div class="row ">
                                                    <div class="col-12 form-group " style="padding-bottom:6rem">
                                                        <b-table-simple>
                                                            <b-thead>
                                                                <b-tr class="table-header">
                                                                    <b-th>Número</b-th>
                                                                    <b-th>Num. Económico</b-th>
                                                                    <b-th>Placa</b-th>
                                                                </b-tr>
                                                            </b-thead>
                                                            <b-tbody v-if="vehiculos.length > 0">
                                                                <b-tr v-for="(veh,x) in vehiculos" :key="x" >
                                                                    <b-td class="text-center">{{ veh.numero }}</b-td>
                                                                    <b-td class="text-center">{{ veh.economico }}</b-td>
                                                                    <b-td class="text-center">{{ veh.placa }}</b-td>
                                                                </b-tr>
                                                            </b-tbody>
                                                            <b-tbody v-else>
                                                                <b-tr>
                                                                    <b-td class="text-center" :variant="'secondary'" colspan="3">
                                                                        <p class="p-4">NO HAY DATOS</p></b-td>
                                                                </b-tr>
                                                            </b-tbody>
                                                        </b-table-simple>
                                                    </div>
                                                    <div class="col-2">
                                                        <button @click="add()" type="button" class="btn btn-xs btn-outline-success d-block">+</button>
                                                        <button @click="substract()" type="button" class="btn btn-xs btn-outline-danger d-block">-</button>
                                                    </div>
                                                </div>	
                                            </form>
                                        </div>
                                        <div class="col-2">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li><button @click="setGrupo()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li><router-link to="/gpovehiculos" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import handleForm from '@/mixins/handleForm';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'
import LookUpCtrl from '@/components/LookUpCtrl.vue';

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    components:{SideBar, TopBar,LoadingContent ,LookUpCtrl},
    mixins:[handleForm,handleGlobals],
    data(){
        return{
            id: this.$route.params.grupo,
            modo: (this.$route.params.grupo > 0 ? 'Modificando' : 'Creacion'), 
            grupo:{},
            vehiculos: [],
            titulo: 'Agregando una Agrupación de Vehículos',
        }
    },
    mounted(){
        this.llenaForma();
    },
    methods:{
        async llenaForma(){
            await this.getGrupo();
            this.titulo = this.$route.params.grupo > 0 ? 'Cambiando una Agrupación de Vehículos' :  'Agregando una Agrupación de Vehículos'
            if(this.modo == 'Modificando') this.getVehiculos()
        },
        getGrupo(){
            let req = []
            this.isLoading = true
             fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "inicializar", modo:this.modo, archivo: "TrGruposVehiculos", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json()
                }   
            }).then((data)=>{
                this.grupo = data['res']
                req = data['req']
            }).finally(()=>{
                this.setRequired(req)
                this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setGrupo(){
            this.isLoading = true
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: 'validar', archivo: "TrGruposVehiculos", modo:this.modo, record: this.grupo }) 
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['res'] == 'error'){
                    this.showNotification = true
                    this.notificationTitle =  'Error'
                    this.notificationText = data['msg']
                    this.notificationRef = 'grupo'
                }else{
                    this.isLoading = false
                    this.$router.push('/gpovehiculos')
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        getVehiculos(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "metodo", metodo:'CargarVehiculos', archivo: "TrGruposVehiculos", params:{grupo:this.grupo.grupo} })
            }).then((response)=>{
                if(response.ok){
                    return response.json()
                }   
            }).then((data)=>{
                this.vehiculos = data['res']
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        afterClose(){
           
            this.$refs[this.notificationRef].focus()
        },
        add(){

        },
        substract(){
            this.vehiculos.find(e=>e.vehiculo.numero == this.selectedVeh.numero).splice()
        }
    }
}
</script>