<template>
    <transition name="popup-fade">
      <div class="popup-backdrop">
        <div class="popup popup-xl search-box" role="dialog" aria-labelledby="Busqueda" aria-describedby="PopupBusqueda" >
          <section class="popup-body p-0" id="PopupBusqueda">
            <div class="card p-3">
                <div class="row">
                    <div class="col-12">
                        <form @submit.prevent="action" >
                            <div class="form-group row">                                
                                <label class="col-3 text-nowrap ">Filtrar campo :</label>
                                <div class="col-4">
                                    <select class="form-control form-control-sm" v-model="campoBusqueda">
                                        <option v-for="(campo, key) in campos" :key="key" :value="campo">{{campo}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-3 pe-0 mr-0">
                                    <select v-model="filtroActivo" class="form-control form-control-sm">
                                        <option :value="'='">Igual a</option>
                                        <option :value="'>'">Mayor que</option>
                                        <option :value="'>='">Mayor igual que</option>
                                        <option :value="'<'">Menor que</option>
                                        <option :value="'<='">Menor igual que</option>
                                        <option :value="'<=>'">Entre</option>
                                        <option :value="'a'">Año</option>
                                        <option :value="'a'">Año Mes</option>
                                        <option :value="'a'">Mes</option>
                                        <option :value="'a'">Día</option>
                                    </select>
                                </div>
                                <div class="col-9">
                                    <input type="text" class="form-control form-control-sm" v-model="textoABuscar">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
          </section>
        </div>
      </div>
    </transition>
  </template>
<script>
export default {
    name:'BusquedaComponent',
    props:['campos'],
    data(){
        return{
            filtroActivo:'=',
            textoABuscar: '',
            campoBusqueda:''
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        action() {
            this.$emit('applyFilter',this.filtroActivo,this.filtroActivo,this.textoABuscar)
        },
    },  
}
</script>