<template>
     <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Archivo de Destinos</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row  w-65">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-10 col-12">
                                            <form>
                                                <div class="row">
                                                    <div class="col-md-4 col-12">
                                                        <div class=" row">
                                                            <label class="col-3 text-nowrap">NumID</label>
                                                            <div class="col-9 ">
                                                                <input type="number" ref="numid" class="form-control  form-control-sm" readonly v-model="destino.numid" />													
                                                            </div>
                                                        </div>
                                                    </div>	
                                                    <div class="col-md-4 col-12">
                                                        <div class=" row">
                                                            <label class="col-3 text-nowrap">RFC</label>
                                                            <div class="col-9 ">
                                                                <input type="text" ref="rfc" class="form-control t form-control-sm"  v-model="destino.rfc" />													
                                                            </div>
                                                        </div>
                                                    </div>	
                                                    <div class="col-md-4 col-12">
                                                        <div class=" row">
                                                            <label class="col-3 text-nowrap">ID Reg.</label>
                                                            <div class="col-9 ">
                                                                <input type="text" ref="idreg" class="form-control  form-control-sm" readonly v-model="destino.idreg" />													
                                                            </div>
                                                        </div>
                                                    </div>	
                                                </div>	
                
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-md-1 col-3 text-nowrap">Nombre</label>
                                                            <div class="col-md-11 col-9 ">
                                                                <input type="text" class="form-control form-control-sm " ref="nombre" v-model="destino.nombre" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-md-1 col-3 text-nowrap">Dirección</label>
                                                            <div class="col-md-11 col-9 ">
                                                                <input type="text" ref="direccion" class="form-control form-control-sm "  v-model="destino.direccion" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-md-6 col-12">
                                                        <div class=" row">
                                                            <label class="col-md-2 col-3 text-nowrap">Colonia</label>
                                                            <div class="col-md-10 col-9">
                                                                <input type="text" ref="colonia" class="form-control form-control-sm "  v-model="destino.colonia" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-12"> 
                                                        <LookUpCtrl v-if="destino.numid" :label="'SAT'" :reference="'cvecolonia'" :objeto="destino" :cols="[3,7,2]"  :device="[3,7,2]" :archivo="'satColonias'" :hideDescripcion="true" :campo="'cvecolonia'" :descripcion="''" :model="'cvecolonia'" v-model="destino.cvecolonia" />
                                                    </div>
                                                </div>

                                                <div class="row ">
                                                    <div class="col-12">
                                                        <LookUpCtrl v-if="destino.numid" :reference="'numciudad'" :label="'Ciudad'" :objeto="destino" :cols="[1,2,9]" :device="[3,3,6]" :archivo="'Ciudades'" :campo="'clave'" :descripcion="'ciudad'" :model="'numciudad'" v-model="destino.numciudad" />
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-md-1 col-3 text-nowrap">Ciudad</label>
                                                            <div class="col-md-11 col-9">
                                                                <input type="text" ref="ciudad" class="form-control form-control-sm "  v-model="destino.ciudad" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-md-6 col-12">
                                                        <LookUpCtrl v-if="destino.numid" :label="'SAT Municipio'" :cols="[4,7,1]" :device="[3,7,2]" :archivo="'satMunicipios'" :objeto="destino" :hideDescripcion="true" :campo="'cvemunicipio'" :descripcion="'municipio'" :model="'cvemunicipio'" :reference="'cvemunicipio'" v-model="destino.cvemunicipio" />
                                                    </div>
                                                    <div class="col-md-6 col-12">
                                                        <LookUpCtrl v-if="destino.numid" :label="'SAT Localidad'" :cols="[4,7,1]" :device="[3,7,2]" :archivo="'satLocalidades'" :objeto="destino" :hideDescripcion="true" :campo="'cveloc'" :descripcion="'descripcion'" :model="'cvelocalidad'" :reference="'cvelocalidad'" v-model="destino.cvelocalidad" />
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-md-6 col-12">
                                                        <div class=" row">
                                                            <label class="col-md-2 col-3 text-nowrap">Estado</label>
                                                            <div class="col-md-10 col-9">
                                                                <input type="text" ref="estado" class="form-control form-control-sm "  v-model="destino.estado" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-12">
                                                        <div class=" row">
                                                            <label class="col-3 text-nowrap">N.Ext.</label>
                                                            <div class="col-9 ">
                                                                <input type="text" ref="numext" class="form-control form-control-sm "  v-model="destino.numext" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-md-4 col-12">
                                                        <div class=" row">
                                                            <label class="col-3 text-nowrap">CPostal</label>
                                                            <div class="col-9 ">
                                                                <input type="text" ref="cpostal" class="form-control form-control-sm "  v-model="destino.cpostal" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-md-6 col-12">
                                                        <div class=" row">
                                                            <label class="col-md-2 col-3 text-nowrap">Cve Pais</label>
                                                            <div class="col-md-10 col-9 ">
                                                                <input type="text" ref="cvepais" class="form-control form-control-sm "  v-model="destino.cvepais" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-12">
                                                        <div class=" row">
                                                            <label class="col-3 text-nowrap">NumRegIdTrib</label>
                                                            <div class="col-9 ">
                                                                <input type="text" ref="regidtrib" class="form-control form-control-sm "  v-model="destino.regidtrib" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            

                                                <div class="row ">
                                                    <div class="col-md-6 col-12">
                                                        <div class=" row">
                                                            <label class="col-md-2 col-3 text-nowrap">Contacto</label>
                                                            <div class="col-md-10 col-9 ">
                                                                <input type="text" ref="contacto" class="form-control form-control-sm "  v-model="destino.contacto" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-12">
                                                        <div class="form-check">
                                                            <input class="form-check-input" ref="patio"  v-model="destino.patio"  type="checkbox" id="patio">
                                                            <label class="form-check-label" for="patio">Patio</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-md-6 col-12">
                                                        <div class=" row">
                                                            <label class="col-md-2 col-3 text-nowrap">Teléfono</label>
                                                            <div class="col-md-10 col-9 ">
                                                                <input type="text" ref="telefono" class="form-control form-control-sm "  v-model="destino.telefono" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <div class="row">
                                                <div class="col-12">
                                                    <LookUpCtrl v-if="destino.numid" :objeto="destino" :label="'GPS Zona'" :cols="[1,2,9]" :device="[3,3,6]" :archivo="'gpsGeoZones'" :campo="'id'" :descripcion="'nombre'" :model="'gpszona'" :reference="'gpszona'"  v-model="destino.gpszona" />
                                                </div>  
                                                </div>

                                                <div class="row ">
                                                    <div class="col-md-8 col-12">
                                                        <div class=" row">
                                                            <label class="col-md-2 col-3 text-nowrap">Lat.</label>
                                                            <div class="col-md-10 col-9 ps-0 ">
                                                                <input type="text" ref="lat" :disabled="disabled_lat" class="form-control form-control-sm "  v-model="destino.lat" />		
                                                            </div>
                                                        </div>
                                                        <div class=" row">
                                                            <label class="col-md-2 col-3 text-nowrap">Lng.</label>
                                                            <div class="col-md-10 col-9 ps-0 ">
                                                                <input type="text" ref="lng" :disabled="disabled_lng"  class="form-control form-control-sm "  v-model="destino.lng" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-12">
                                                        <button type="button" disabled @click="ubicarMapa()" class="btn btn-outline-secondary p-4">Ubicar en mapa</button>
                                                    </div>
                                                </div>

                                                <div class="row ">
                                                    <div class="col-md-6 col-12">
                                                        <div class=" row">
                                                            <label class="col-2 text-nowrap">Radio</label>
                                                            <div class="col-6 ">
                                                                <input type="text" ref="radio" class="form-control form-control-sm "  v-model="destino.radio" />		
                                                            </div>
                                                            <label  class="col-2">mts.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-12">
                                                        <div class="row">
                                                            <label class="col-3 text-nowrap text-end">SAT Estado</label>
                                                            <div class="col-9">
                                                                <select ref="cveestado" class="form-control form-control-sm" v-model="destino.cveestado">
                                                                    <option value=""></option>
                                                                    <option v-for="(estado, key) in satestados" :key="key" :value="key">{{estado}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                        <div class="col-md-2 col-12">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li class="mobile-float"><button @click="setDestino()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li><router-link to="/trdestinos" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <InfoPopUpVue  v-show="showNotification" @close="closeInfo">
                    <template v-slot:header>
                        <h6 class="text-uppercase">{{notificationTitle}}</h6>
                    </template>
                    <template v-slot:body>
                        {{notificationText}}
                    </template>
                </InfoPopUpVue>

                <UbicarMapaVue v-if="showUbicarDestino">
                    <template v-slot:header>
                        <h6 class="text-uppercase">Ubicar Posición</h6>
                    </template>
                    <template v-slot:body>
                        <div class="row">
                            <div class="col-12">
                                
                            </div>
                        </div>
                    </template>
                </UbicarMapaVue>
            </div>
        </div>
    </section>
</template>

<script>
import UbicarMapaVue from '@/components/PopupWindow.vue';
import LookUpCtrl from '@/components/LookUpCtrl.vue';
import InfoPopUpVue from '@/components/InfoPopUp.vue';
import handlePopUp from '@/mixins/handlePopUp';
import handleForm from '@/mixins/handleForm';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    components:{SideBar,TopBar, LookUpCtrl ,UbicarMapaVue,InfoPopUpVue,LoadingContent},
    mixins:[handlePopUp,handleForm,handleGlobals],
    data(){
        return{
            id: this.$route.params.destino,
            modo: (this.$route.params.destino > 0 ? 'Modificando' : 'Creacion'), 
            destino: {},
            satestados: [],
            titulo: 'Agregando un Destino',
            showUbicarDestino: false,
            disabled_lat:true,
            disabled_lng:true,
            disabled_cliente:false,
            bDuplicate:false
        }
    },
  
    mounted(){
        this.llenaForma();
    },
    methods:{
        async llenaForma(){
            this.Get_SAT_ClavesEstados()
            await this.getDestino()
            if(this.$route.params.destino > 0){
                this.titulo = 'Cambiando un Destino'

                //this.disabled_cliente = true
                //if(CTPL_CDDESTINO.LUP_DESCRIPCION == this.destino.ciudad){
                //    this.disabled_ciudad = true
                //    this.disabled_estado = true
                //}
                this.$refs.nombre.focus()
            }

            if(this.bDuplicate){
                this.modo = "Creacion"
            }
        },
        async getDestino(){
            this.isLoading = true

            let req = []
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn},
                body: JSON.stringify({ accion: "inicializar",modo:this.modo, archivo: "TrDestinos", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.destino = data['res'];
                req = data['req']
                this.destino.patio = this.destino.patio == 'S'
            }).finally(()=>{
               this.setRequired(req)
               this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        ubicarMapa(){
            if(this.destino.direccion == "" ){
                this.showNotification = true
                this.notificationTitle = 'Error'
                this.notificationText = "Capture la Dirección."
                this.notificationRef = 'direccion'
            }
            if(this.destino.ciudad == "" ){
                this.showNotification = true
                this.notificationTitle = 'Error'
                this.notificationText = "Capture la Ciudad."
                this.notificationRef = 'ciudad'
            }
            if(this.destino.estado == "" ){
                this.showNotification = true
                this.notificationTitle = 'Error'
                this.notificationText = "Capture la Estado."
                this.notificationRef = 'estado'
            }

           //let sLat = this.destino.lat
           //let sLng = this.destino.lng
           //let sNombre  = this.destino.nombre
            let sCD  = ''
            let sDireccion = ""

            if(this.destino.direccion != "" ){
                sDireccion = this.destino.direccion
            } 

            if(this.destino.colonia != "" ){
                sDireccion += " "+ this.destino.colonia
            } 

            if(this.destino.ciudad != "" ){
                sCD = this.destino.ciudad
                let arr = sCD.split(',')
                if(arr[0].length < 5 ){
                    sDireccion += ", "+ arr[0]
                }else{
                    sDireccion += ", "+ this.destino.ciudad
                }
            }
            if(this.destino.estado != ""){
                sDireccion += ", "+this.destino.estado
            }
            sDireccion = sDireccion.replace("#","")

            console.losg(sDireccion)
            //open modal map
            this.showPopUp
            /*
            nRadio is int = DEST_RADIO

            this.$router.push('/ubicardestino')
            IF Open(sVentana,sNombre,sDireccion,sLat,sLng,"",nRadio) = True THEN
                DEST_LAT = sLat
                DEST_LNG = sLng
            END
            */
        },
        setDestino(){
            this.destino.patio = this.destino.patio ? 'S' : 'N'

            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: 'validar', archivo: "TrDestinos", modo:this.modo, record: this.destino })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['msg'] != '' && data['closeWindow'] == false){
                    this.notificationTitle = 'Error'
                    this.notificationText = data['msg']
                    this.showNotification = true
                }else{
                    if(data['msg'] != '' && data['closeWindow'] == true){
                        this.notificationTitle = 'Aviso'
                        this.notificationText = data['msg']
                        this.showNotification = true
                    }
                    this.$router.push('/trdestinos')
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        afterClose(){
            this.$refs[this.notificationRef].focus()
        },
        async Get_SAT_ClavesEstados(){
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "metodo", 
                                        archivo: "Global", 
                                        metodo: "Get_SAT_ClavesEstados",
                                        params: {}
                                    })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.satestados = data;
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
    }
}
</script>