<template>
   <section>
        <SideBar v-if="!OpenMode" />
        <TopBar v-if="!OpenMode" />
        <div :class="[!OpenMode ? 'content' : '' ]">   
        <div class="main-content-header">
            <h1>Ligar Remisiones a Factura</h1>
        </div>
        <LoadingContent v-if="isLoading" />
        <div class="row">
            <div class="col-lg-12 col-md-6">
                <div class="card mb-30" :style="{height:[contentHeight+'px']}">
                    <div class="row " :class="[!OpenMode ? 'p-4' : '']">
                        <div class="content-header col-12"> 
                            <LookUpCtrl :objeto="ligas" :label="'Sucursal'" :cols="[1,2,9]" :archivo="'InSucursales'" :campo="'sucursal'" :descripcion="'nombre'" :model="'sucursal'" v-model="sucursal" />
                               
                            <div class="row">
                                <div class="col-md-4 col-12 ps-2 pe-5 text-end pb-0 pt-0">
                                    <div class=" row">
                                        <label class="col-2 text-nowrap text-end">Factura</label>
                                        <div class="col-7 d-flex">
                                            <input type="number" class="form-control form-control-sm" @blur="exitFactura()" v-model="factura" />		
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12 ps-2 pe-5 text-end pb-0 pt-0">
                                    <div class=" row">
                                        <label class="col-5 text-nowrap text-end">Importe</label>
                                        <div class="col-7 d-flex">
                                            <input type="number" class="form-control form-control-sm" readonly v-model="importe" />		
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-10 col-12">
                            <div class="table-responsive ps-2">
                                <b-table-simple class="browse" hover small caption-top responsive >
                                    <b-thead head-variant="light" class="table-header">
                                        <b-tr>
                                            <b-th class="text-nowrap">
                                                <div class="form-check">
                                                    <input class="form-check-input"  v-model="selectAll"  type="checkbox">
                                                </div>
                                            </b-th>
                                            <b-th class="text-nowrap">Suc</b-th>
                                            <b-th class="text-nowrap">Folio</b-th>
                                            <b-th class="text-nowrap">Cliente</b-th>
                                            <b-th class="text-nowrap">Nombre</b-th>
                                            <b-th class="text-nowrap">Importe</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody v-if="remisiones.length > 0">
                                        <b-tr v-for="(rem,indx) in remisiones" :key="indx" @click="selectedRegistro = rem;selectedRegistro.indx = indx"  :class="{'selected-row': (selectedRegistro.folio == rem.folio) }">
                                            <b-td class="text-nowrap">
                                                <div class="form-check">
                                                    <input class="form-check-input" @change="calculo()" v-model="rem.tag"  type="checkbox" >
                                                </div>
                                            </b-td>
                                            <b-td class="text-nowrap text-center">{{ rem.sucursal| leadingZeros(3)}}</b-td>
                                            <b-td class="text-nowrap text-center">{{ rem.folio | leadingZeros(7) }}</b-td>
                                            <b-td class="text-end text-nowrap" >{{ rem.cliente | leadingZeros(5)}}</b-td>
                                            <b-td class="text-nowrap">{{ rem.cliente_nombre }}</b-td>
                                            <b-td class="text-nowrap text-end">{{ parseFloat(rem.total) | toCurrency }}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                    <b-tbody v-else>
                                        <b-tr  >
                                            <b-td class="text-center" :variant="'secondary'" colspan="6"><p class="p-4">NO HAY DATOS</p></b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </div>
                        </div> 
                        <div class="col-2 ps-2 pe-5 btn-manager" >
                            <ul class="d-block list-unstyled action-block">
                                <li><button :disabled="disabled_btn" class="btn btn-xs btn-success btn-block">Ligar</button></li>
                            </ul>
                        </div>
                        <div class="content-footer col-12 text-start"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
</template>

<script>
import LookUpCtrl from '@/components/LookUpCtrl.vue';

import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'
import handleBrowse from '@/mixins/handleBrowse'
import handlePopUp from '@/mixins/handlePopUp'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';

export default {
    name:'WIN_LigarRemFac',
    props:['mode','perpage'],
    mixins:[handleGlobals,handleBrowse,handlePopUp],
    components:{SideBar,TopBar,LoadingContent,LookUpCtrl},
    data(){
        return{
            selectAll:false,
            sucursal:0,
            factura:0,
            importe:0,
            seleccion:[],
            remisiones:[],
            disabled_btn:true,
            
        }
    },
    created(){
      
    },
    methods:{
        exitFactura(){
            if(this.factura > 0) {
                this.limpiar() 
            }else{
                return
            }
            if(this.remisiones.length > 0 ){
                this.showNotification = true
                this.notificationTitle =  'Aviso'
                this.notificationText = 'Esa Factura ya está ligada a las Remisiones mostradas'
				return
			}
            this.isLoading = true
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "metodo", 
                                        archivo: "Remision", 
                                        metodo:'exitfacturaLigaRemFac',
                                        params: { sucursal: this.sucursal ,factura: this.factura},
                                     })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.remisiones = data.remisiones
                this.importe = data.importe 
                this.disabled_btn = data.disabled_btn_ok
                this.isLoading = false;
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        calculo(){
            this.isLoading = true
            this.importe = this.remisiones.forEach((e)=>{
                if(e.select == 1 ) return e.importe
            })
            this.isLoading = false
        },
        limpiar(){
            this.isLoading = true
            this.importe = 0
            this.seleccion = []
            this.remisiones = []
            this.isLoading = false
        }
    }
}
</script>

