<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Maestro de Operadores</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row w-75">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-10 col-12">
                                            <form>
                                                <nav>
                                                    <div class="nav nav-underline" id="nav-tab" role="tablist">
                                                        <li class="nav-item"><a class="nav-link active" @click="tab='operador'"  id="nav-operador-tab" data-bs-toggle="tab" href="#nav-operador" role="tab" aria-controls="nav-operador" aria-selected="true">Información del Operador</a></li>
                                                        <li class="nav-item"><a class="nav-link" @click="tab='telefonos'"  id="nav-telefonos-tab" data-bs-toggle="tab" href="#nav-telefonos" role="tab" aria-controls="nav-telefonos" aria-selected="false">Teléfonos</a></li>
                                                        <li class="nav-item"><a class="nav-link" @click="tab='credenciales'" ref="credenciales" id="nav-credenciales-tab" data-bs-toggle="tab" href="#nav-credenciales" role="tab" aria-controls="nav-credenciales" aria-selected="false">Credenciales</a></li>
                                                        <li class="nav-item"><a class="nav-link" @click="tab='nominas'"  id="nav-nominas-tab" data-bs-toggle="tab" href="#nav-nominas" role="tab" aria-controls="nav-nominas" aria-selected="false">Nóminas</a></li>
                                                        <li class="nav-item"><a class="nav-link" @click="tab='notas'"  id="nav-notas-tab" data-bs-toggle="tab" href="#nav-notas" role="tab" aria-controls="nav-notas" aria-selected="false">Notas</a></li>
                                                        <li class="nav-item"><a class="nav-link" @click="tab='doctos'"  id="nav-doctos-tab" data-bs-toggle="tab" href="#nav-doctos" role="tab" aria-controls="nav-doctos" aria-selected="false">Doctos</a></li>
                                                    </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="container p-1 tab-pane fade show active" id="nav-operador" role="tabpanel" aria-labelledby="nav-operador-tab">
                                                        <div class="row ">
                                                            <div class="col-12">
                                                                <div class=" row">
                                                                    <label for="numero" class="col-md-2 col-2 text-nowrap">Número</label>
                                                                    <div class="col-md-2 col-3 d-flex">
                                                                        <input type="number" id="numero" ref="numero" maxlength="6" class="form-control text-end form-control-sm" :disabled="disabled_numero"  v-model="operador.numero" />													
                                                                    </div>
                                                                    <div class="col-md-8 col-7 d-flex">
                                                                        <input type="text" id="operadorNombre" ref="operadorNombre" class="form-control text-start form-control-sm" disabled readonly v-model="operadorNombre" />													
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>	
                                                            <div class="col-12">
                                                                <LookUpCtrl v-if="modo == 'Creacion' || operador.numero" :objeto="operador" :label="'Sucursal'" :cols="[2,1,9]" :archivo="'InSucursales'" :campo="'sucursal'" :descripcion="'nombre'" :model="'sucursal'" v-model="operador.sucursal" />
                                                            </div>	
                                                        </div>	
                    
                                                        <div class="row ">
                                                            <div class="col-md-6 col-12">
                                                                <div class=" row">
                                                                    <label for="apellidopat" class="col-4 text-nowrap">Apellido Paterno</label>
                                                                    <div class="col-8 d-flex">
                                                                        <input type="text" id="apellidopat" ref="apellidopat" class="form-control form-control-sm" maxlength="15" v-model="operador.apellidopat" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        
                                                            <div class="col-md-6 col-12">
                                                                <div class=" row">
                                                                    <label for="apellidomat" class="col-3 text-nowrap">Apellido Materno</label>
                                                                    <div class="col-9">
                                                                        <input type="text" id="apellidomat" ref="apellidomat" class="form-control form-control-sm" maxlength="15" v-model="operador.apellidomat" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-6 col-12">
                                                                <div class=" row">
                                                                    <label for="nombres" class="col-4 text-nowrap">Nombres</label>
                                                                    <div class="col-8 d-flex">
                                                                        <input type="text" id="nombres" ref="nombres" class="form-control form-control-sm" maxlength="20" v-model="operador.nombres" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-12">
                                                                <div class=" row">
                                                                    <label for="fechanaci" class="col-3 text-nowrap">Fecha Nacimiento</label>
                                                                    <div class="col-5 d-flex">
                                                                        <input type="date" id="fechanaci" ref="fechanaci" class="form-control form-control-sm" v-model="operador.fechanaci" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row ">
                                                            <div class="col-12">
                                                                <div class=" row">
                                                                    <label for="dir1" class="col-2 text-nowrap">Dirección</label>
                                                                    <div class="col-10 d-flex">
                                                                        <input type="text" id="dir1" ref="dir1" class="form-control form-control-sm" maxlength="30" v-model="operador.dir1" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row ">
                                                            <div class="col-12">
                                                                <div class=" row">
                                                                    <label for="dir2" class="col-2 text-nowrap">Dirección 2</label>
                                                                    <div class="col-10 d-flex">
                                                                        <input type="text" id="dir2" ref="dir2" class="form-control form-control-sm" maxlength="30" v-model="operador.dir2" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="row ">
                                                            <div class="col-md-6 col-12">
                                                                <div class="row ">
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label for="ciudad" class="col-4 text-nowrap ">Ciudad</label>
                                                                            <div class="col-8">
                                                                                <input type="text" id="ciudad" ref="ciudad" class="form-control form-control-sm "  maxlength="20" v-model="operador.ciudad" />		
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label for="estado" class="col-4 text-nowrap">Estado</label>
                                                                            <div class="col-8 d-flex">
                                                                                <input type="text" id="estado" ref="estado" class="form-control form-control-sm "  maxlength="10" v-model="operador.estado" />		
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label for="codigopost" class="col-4 text-nowrap">Código Postal</label>
                                                                            <div class="col-8 d-flex">
                                                                                <input type="text" id="codigopost" ref="codigopost" class="form-control form-control-sm "  maxlength="7" v-model="operador.codigopost" />		
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="row">
                                                                            <label for="estatus" class="col-4 text-nowrap ">Estatus</label>
                                                                            <div class="col-8">
                                                                                <select id="estatus" ref="estatus" class="form-control form-control-sm" v-model="operador.estatus">
                                                                                    <option value="A">Activo</option>
                                                                                    <option value="I">Inactivo</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="row">
                                                                            <label for="regidtrib" class="col-4 text-nowrap ">RegIdTrib</label>
                                                                            <div class="col-8 ">
                                                                                <input type="text" id="regidtrib" ref="regidtrib" class="form-control form-control-sm "  maxlength="20" v-model="operador.regidtrib" />		
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-12">
                                                                <div class="row ">
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label for="fechaingreso" class="col-3 text-nowrap">Fecha Ingreso</label>
                                                                            <div class="col-5 ">
                                                                                <input type="date" id="fechaingreso" ref="fechaingreso" class="form-control form-control-sm "  v-model="ctrl_fechaingreso" />		
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label for="fechabaja" class="col-3 text-nowrap">Fecha de Baja</label>
                                                                            <div class="col-5">
                                                                                <input type="date" id="fechabaja" ref="fechabaja" class="form-control form-control-sm "  v-model="operador.fechabaja" />		
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label for="saldolts" class="col-3 text-nowrap">Saldo Litros</label>
                                                                            <div class="col-9 ">
                                                                                <input type="number" min="0" step="any" id="saldolts" ref="saldolts" class="form-control form-control-sm " disabled  v-model="operador.saldolts" />		
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="row">
                                                                            <label for="cvepais" class="col-3 text-nowrap ">Cve. Pais</label>
                                                                            <div class="col-9">
                                                                                <select id="cvepais" ref="cvepais" class="form-control form-control-sm" v-model="operador.cvepais">
                                                                                    <option value=""></option>
                                                                                    <option v-for="(pais, key) in satpaises" :key="key" :value="key">{{pais}}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="container p-1 tab-pane fade" id="nav-telefonos" role="tabpanel" aria-labelledby="nav-telefonos-tab">
                                                        <div class="row">
                                                            <div class=" col-md-6 col-12">
                                                                <div class=" row">
                                                                    <label for="tel1" class="col-4 text-nowrap">Teléfono 1</label>
                                                                    <div class="col-8 d-flex">
                                                                        <input type="text" id="tel1" ref="tel1" maxlength="15" class="form-control form-control-sm"  v-model="operador.tel1" />													
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label for="tel2" class="col-4 text-nowrap">Teléfono 2</label>
                                                                    <div class="col-8 d-flex">
                                                                        <input type="text" id="tel2" ref="tel2" maxlength="15" class="form-control form-control-sm"  v-model="operador.tel2" />													
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label for="tel3" class="col-4 text-nowrap">Teléfono 3</label>
                                                                    <div class="col-8 d-flex">
                                                                        <input type="text" id="tel3" ref="tel3" maxlength="15" class="form-control form-control-sm"  v-model="operador.tel3" />													
                                                                    </div>
                                                                </div>
                                                            </div>	
                                                            <div class="col-md-6 col-12">
                                                                <div class=" w-100 foto-licencia base-image-input text-center pb-2">
                                                                    <label for="inputFile" class="cursor-pointer" >
                                                                        <div class="m-auto d-flex align-items-center" style="height:200px">
                                                                            <img class="rounded img-fluid " :src="fotografia" alt="" style="max-height:200px" />
                                                                        </div>
                                                                    </label>
                                                                    <input class="d-none" id="inputFile" ref="inputFile" type="file" @change="uploadImage($event)" />
                                                                </div>
                                                                <button type="button" @click="$refs.inputFile.click()" class="btn btn-outline-secondary btn-block" style="width:200px">Seleccionar Foto / Licencia</button>
                                                            </div>
                                                        </div>	
                                                                                                    
                                                    </div>
                                                    <div class="container p-1 tab-pane fade" id="nav-credenciales" role="tabpanel" aria-labelledby="nav-credenciales-tab">
                                                        <div class="row">
                                                            <div class="col-md-6 col-12">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label for="licencia" class="col-md-4 col-5 text-nowrap">Licencia</label>
                                                                            <div class="col-md-8 col-7 ">
                                                                                <input type="text" id="licencia" ref="licencia" maxlength="20"  class="form-control text-end form-control-sm" v-model="operador.licencia" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label for="sigresello" class="col-md-4 col-5 text-nowrap">Siguiente Resello</label>
                                                                            <div class="col-md-5 col-7 ">
                                                                                <input type="date" id="sigresello" ref="sigresello" class="form-control text-end form-control-sm" v-model="operador.sigresello" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-12 ">
                                                                <div class=" row">
                                                                    <div class="col-12 ">
                                                                        <div class=" row">
                                                                            <label for="fechaexpi" class="col-5 text-nowrap">Fecha Expiración</label>
                                                                            <div class="col-5">
                                                                                <input type="date" id="fechaexpi" ref="fechaexpi" class="form-control text-end form-control-sm" v-model="operador.fechaexpi" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label for="fechaextender" class="col-5 text-nowrap">Extender Licencia Hasta</label>
                                                                            <div class="col-5">
                                                                                <input type="date" id="fechaextender" ref="fechaextender" class="form-control text-end form-control-sm" v-model="operador.fechaextender" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>	
                                                            </div>
                                                        </div>

                                                        <div class="row pt-4">
                                                            <div class="col-12">
                                                                <b-table bordered class="editable-table">
                                                                    <b-thead>
                                                                        <b-tr class="table-header">
                                                                            <b-th>No</b-th>
                                                                            <b-th>Nombre de Credencial</b-th>
                                                                            <b-th>Referencia / Número</b-th>
                                                                            <b-th>Fecha de Vencimiento</b-th>
                                                                        </b-tr>
                                                                    </b-thead>
                                                                    <b-tbody v-if="credenciales.length > 0">
                                                                        <b-tr v-for="credencial in credenciales" :key="credencial.id">
                                                                            <b-td class="text-center">{{credencial.no}}</b-td>
                                                                            <b-td class="text-center">{{credencial.nombre}}</b-td>
                                                                            <b-td class="text-center">{{credencial.referencia}}</b-td>
                                                                            <b-td class="text-center">{{ formato(credencial.vencimiento,"DD-MMM-YYYY") }}</b-td>
                                                                        </b-tr>
                                                                        <b-tr v-for="i in 5" :key="i" >
                                                                            <b-td class="text-center"></b-td>
                                                                            <b-td class="text-center"></b-td>
                                                                            <b-td class="text-center"></b-td>
                                                                            <b-td class="text-center"></b-td>
                                                                        </b-tr>
                                                                       
                                                                    </b-tbody>
                                                                    <b-tbody v-else>
                                                                        <b-tr v-for="i in 5" :key="i" >
                                                                            <b-td class="text-center"></b-td>
                                                                            <b-td class="text-center"></b-td>
                                                                            <b-td class="text-center"></b-td>
                                                                            <b-td class="text-center"></b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div  class="container p-1 tab-pane fade" id="nav-nominas" role="tabpanel" aria-labelledby="nav-nominas-tab">
                                                        <div class="row">
                                                            <div class="col-md-6 col-12">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label for="rfc" class="col-3 text-nowrap">R.F.C</label>
                                                                            <div class="col-9">
                                                                                <input type="text" id="rfc" ref="rfc" maxlength="13"  class="form-control  form-control-sm" v-model="operador.rfc" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label for="curp" class="col-3 text-nowrap">C.U.R.P.</label>
                                                                            <div class="col-9">
                                                                                <input type="text" id="curp" ref="curp" maxlength="18"  class="form-control form-control-sm" v-model="operador.curp" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label for="imss" class="col-3 text-nowrap">I.M.S.S.</label>
                                                                            <div class="col-9">
                                                                                <input id="imss" ref="imss" type="text" maxlength="11"  class="form-control  form-control-sm" v-model="operador.imss" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label for="infonavit" class="col-3 text-nowrap">Infonavit</label>
                                                                            <div class="col-9 ">
                                                                                <select id="infonavit" ref="infonavit" class="form-control form-control-sm" @change="GeneraInfonavit()" v-model="operador.infonavit">
                                                                                    <option value="N">Ninguno</option>
                                                                                    <option value="P">Porcentaje</option>
                                                                                    <option value="C">Cuota Fija</option>
                                                                                </select>												
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                    <div class="col-12">
                                                                        <div class="row" v-if="visible_infoparam">
                                                                            <label for="infoparam" class="col-3 text-nowrap ">Infonavit Param</label>
                                                                            <div class="col-5 d-flex">
                                                                                <input type="number" step="any" min="0" id="infoparam" ref="infoparam"  class="form-control form-control-sm" v-model="operador.infoparam" />													
                                                                            </div>
                                                                            <label for="">{{labelInfonavit}}</label>
                                                                        </div>
                                                                    </div>	
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label for="salario" class="col-3 text-nowrap">Salario</label>
                                                                            <div class="col-5">
                                                                                <input type="number" step="any" min="0" id="salario" ref="salario" class="form-control text-end form-control-sm" v-model="operador.salario" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="row">
                                                                            <label for="cuentabancaria" class="col-3 text-nowrap">Cuenta Bancaria</label>
                                                                            <div class="col-9">
                                                                                <input type="text" maxlength="40" id="cuentabancaria" ref="cuentabancaria" class="form-control form-control-sm" v-model="operador.cuentabancaria" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                    <div class="col-12">
                                                                        <div class="row" v-if="visible_clabebanco">
                                                                            <label for="clabebanco" class="col-3 text-nowrap">CLABE Bancaria</label>
                                                                            <div class="col-9">
                                                                                <input type="text" maxlength="40" id="clabebanco" ref="clabebanco" class="form-control form-control-sm" v-model="operador.clabebanco" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="row">
                                                                            <label for="basedatos" class="col-3 text-nowrap">B.D. (Ext)</label>
                                                                            <div class="col-9">
                                                                                <input type="text" maxlength="10" id="basedatos" ref="basedatos" class="form-control text-end form-control-sm" v-model="operador.basedatos" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-12">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="row">
                                                                            <label for="tipooperador" class="col-3 text-nowrap ">Tipo Oper.</label>
                                                                            <div class="col-9">
                                                                                <select class="form-control form-control-sm" id="tipooperador" v-model="operador.abc">
                                                                                    <option value="A">A</option>
                                                                                    <option value="B">B</option>
                                                                                    <option value="C">C</option>
                                                                                    <option value="D">D</option>
                                                                                    <option value="E">E</option>
                                                                                    <option value="F">F</option>
                                                                                    <option value="G">G</option>
                                                                                    <option value="H">H</option>                                                                 
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                    <div class="col-12">
                                                                        <div class="row">
                                                                            <label for="tipocalcsdo" class="col-3 text-nowrap ">Tipo Cálculo</label>
                                                                            <div class="col-9">
                                                                                <select id="tipocalcsdo" ref="tipocalcsdo" class="form-control form-control-sm" @blur="GeneraTipoCalcSdo()" v-model="operador.tipocalcsdo">
                                                                                    <option value="C1">C1</option>
                                                                                    <option value="C2">C2</option>
                                                                                    <option value="C3">C3</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                    <div class="col-12">
                                                                        <div class="row">
                                                                            <label for="tiposueldo" class="col-3 text-nowrap ">Tipo Sueldo</label>
                                                                            <div class="col-9">
                                                                                <select id="tiposueldo" ref="tiposueldo" class="form-control form-control-sm" v-model="operador.tiposueldo">
                                                                                    <option v-for="sdo in tiposueldos" :key="sdo.k" :value="sdo.nombre">{{sdo.nombre}}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-md-6 col-12">
                                                                        <div class="row">
                                                                            <label for="rebajaimss" class="col-3 text-nowrap">IMSS</label>
                                                                            <div class="col-9">
                                                                                <input type="number" step="any" min="0" id="rebajaimss" ref="rebajaimss" class="form-control text-end form-control-sm" v-model="operador.rebajaimss" />													
                                                                            </div>
                                                                        </div>
                                                                   
                                                                        <div class=" row">
                                                                            <label for="rebajaispt" class="col-3 text-nowrap">ISPT</label>
                                                                            <div class="col-9">
                                                                                <input type="number" step="any" min="0" id="rebajaispt" ref="rebajaispt" class="form-control text-end form-control-sm" v-model="operador.rebajaispt" />													
                                                                            </div>
                                                                        </div>
                                                                   
                                                                        <div class=" row">
                                                                            <label for="rebajainfo" class="col-3 text-nowrap">INFO</label>
                                                                            <div class="col-9">
                                                                                <input type="number" step="any" min="0" id="rebajainfo" ref="rebajainfo" class="form-control text-end form-control-sm" v-model="operador.rebajainfo" />													
                                                                            </div>
                                                                        </div>
                                                                    
                                                                        <div class=" row">
                                                                            <label for="rebajaaho" class="col-3 text-nowrap">AHOR</label>
                                                                            <div class="col-9">
                                                                                <input type="number" step="any" min="0" id="rebajaaho" ref="rebajaaho" class="form-control text-end form-control-sm" v-model="operador.rebajaaho" />													
                                                                            </div>
                                                                        </div>
                                                                   
                                                                        <div class=" row">
                                                                            <label for="pension" class="col-3 text-nowrap">PENSIÓN</label>
                                                                            <div class="col-9">
                                                                                <input type="number" step="any" min="0" id="pension" ref="pension" class="form-control text-end form-control-sm" v-model="operador.pension" />													
                                                                            </div>
                                                                        </div>
                                                                    
                                                                        <div class=" row">
                                                                            <label for="fonacot" class="col-5 text-nowrap">FONACOT</label>
                                                                            <div class="col-7">
                                                                                <input type="number" step="any" min="0" id="fonacot" ref="fonacot" class="form-control text-end form-control-sm" v-model="operador.fonacot" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>		
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div  class="container p-1 tab-pane fade" id="nav-notas" role="tabpanel" aria-labelledby="nav-notas-tab">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class=" row">
                                                                    <label for="notas" class="col-1 text-nowrap">Notas</label>
                                                                    <div class="col-11 d-flex">
                                                                        <textarea id="notas" ref="notas" maxlength="999" class="form-control form-control-sm" style="height:20vh !important" rows="6" v-model="operador.notas"></textarea>													
                                                                    </div>
                                                                </div>
                                                            </div>	
                                                            <div class="col-12">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <p style="font-style: italic">Acceso a servicios externos</p>
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label for="correo" class="col-1 text-nowrap">Correo</label>
                                                                    <div class="col-11 d-flex">
                                                                        <input id="correo" ref="correo" maxlength="50" type="text" class="form-control form-control-sm" v-model="operador.correo" />													
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label for="password" class="col-md-1 col-5 text-nowrap">Contraseña</label>
                                                                    <div class="col-md-7 col-12 d-flex">
                                                                        <input id="password" ref="password" maxlength="25" type="text" class="form-control form-control-sm" v-model="operador.password" />													
                                                                    </div>
                                                                </div>
                                                            </div>	
                                                        </div>
                                                    </div>
                                                    <div class="container p-1 tab-pane fade" id="nav-doctos" role="tabpanel" aria-labelledby="nav-doctos-tab">
                                                        <div class="row ">
                                                            <div class="col-12 form-group " style="padding-bottom:6rem">
                                                                <b-table-simple>
                                                                    <b-thead>
                                                                        <b-tr class="table-header">
                                                                            <b-th></b-th>
                                                                            <b-th>Documento</b-th>
                                                                            <b-th>Agregado</b-th>
                                                                        </b-tr>
                                                                    </b-thead>
                                                                    <b-tbody>
                                                                        <b-tr v-for="docto in doctos" :key="docto.EDOQ_REFERENCIA" >
                                                                            <b-td class="text-center">{{ docto.EDOQ_REFERENCIA }}</b-td>
                                                                            <b-td class="text-center">{{ docto.EDOQ_REFERENCIA }}</b-td>
                                                                            <b-td class="text-center">{{ docto.EDOQ_REFERENCIA }}</b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="col-2">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li><button @click="setOperador()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li><router-link to="/operadores" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <InfoPopUpVue  v-show="showNotification" @close="closeInfo">
                <template v-slot:header>
                    <h6 class="text-uppercase">{{notificationTitle}}</h6>
                </template>
                <template v-slot:body>
                    {{notificationText}}
                </template>
            </InfoPopUpVue>
        </div>
    </section>
</template>

<script>
import LookUpCtrl from '@/components/LookUpCtrl.vue';
import handleGlobals from '@/mixins/handleGlobals';
import handlePopUp from '@/mixins/handlePopUp'
import handleForm from '@/mixins/handleForm';
import InfoPopUpVue from '@/components/InfoPopUp.vue'
import LoadingContent from '@/components/LoadingSpinner.vue'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    components:{SideBar,TopBar, LookUpCtrl ,InfoPopUpVue,LoadingContent},
    mixins:[handleGlobals,handleForm,handlePopUp],
    data(){
        return{
            id: this.$route.params.operador,
            modo: (this.$route.params.operador > 0 ? 'Modificando' : 'Creacion'), 
            operador: [],
            satpaises: [],
            credenciales: [],
            complementos: [],
            doctos: [],
            titulo: 'Agregando un Operador',
            tiposueldos:[],
            gr_sueldotipos:false,
            visible_clabebanco:false,
            disabled_numero:false,
            visible_infoparam:false,
            labelInfonavit:"",
            fotografia: '',
            fechaextender:''
        }
    },
    computed:{
        operadorNombre(){
            return (this.operador.nombres ? this.operador.nombres : '') + " " + (this.operador.apellidopat ? this.operador.apellidopat : '') + " " + (this.operador.apellidomat ? this.operador.apellidomat : '')
        },
        cbox_sinimptocero:{
            get(){
                return this.operador.sinimptocero == 'S' ? true : false
            },
            set(val){
                return val 
            }  
        },
        cbox_obsoleto:{
            get(){
                return this.operador.obsoleto == 'S' ? true : false
            },
            set(val){
                return val
            }  
        }
    },
    mounted(){
        this.llenaForma();
    },
    methods:{
        async llenaForma(){
            this.Get_SAT_ClavesPaises()
            await this.getOperador()
            if(this.$route.params.operador > 0){
                this.titulo = 'Cambiando un Operador';
              
                this.disabled_numero = true
                if( this.operador.fotografia == "" && this.operador.foto != ""){
                    //IMG_foto = gsDatos +"\Fotos\"+ NoSpace(OPE_FOTO)
                }
                
            }else{
                this.disabled_numero = false
            }
        },
        GeneraTipoCalcSdo(){
            this.tiposueldos = []
            let arr = [];
            if(this.operador.tipocalcsdo == "C1" ){
                arr.k = "ALL"
                arr.nombre = "TODOS"
                this.tiposueldos.push(arr)
            }else if(this.operador.tipocalcsdo == "C2" ){
                this.tiposueldos.push({k:"SEN",nombre:"SENCILLO"},{k:"FUL",nombre:"FULL"})
            }else{
                this.tiposueldos.push(  {k:"SEN",nombre:"SENCILLO"},
                                        {k:"FUL",nombre:"FULL"},
                                        {k:"LOC",nombre:"LOCAL"},
                                        {k:"PAT",nombre:"PATIO"},
                                        {k:"MIN",nombre:"MINAS"},
                                        {k:"ATE",nombre:"ARRASTRE"},
                                        {k:"CAP",nombre:"CAPACITACION"})
            }
        },
        GeneraInfonavit(){
            if(this.operador.infonavit == "N" ){
                this.visible_infoparam = false
            }
            if(this.operador.infonavit == "P" ){
                this.visible_infoparam = true
                this.labelInfonavit = " %"	
            }
            if(this.operador.infonavit == "C" ){
                this.visible_infoparam = true
                this.labelInfonavit = " Veces SMDF"	
            }
        },
        async getOperador(){
            let req = []
            this.isLoading = true
           await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "inicializar", modo:this.modo, archivo: "Operadores", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                console.log(data)
                this.operador = data['res'];
                this.gr_sueldotipos = data['gr_sueldotipos']
                this.visible_clabebanco = data['visible_clabebanco']
                if(this.gr_sueldotipos == true){ //FNG
                    this.GeneraInfonavit()
                    this.leerCredenciales()
                }
                req = data['req']
            }).finally(()=>{
              this.setRequired(req)
              this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setOperador(){
            this.isLoading = true
            this.operador.nombre        = this.operadorNombre
         // this.operador.sinimptocero  = this.cbox_sinimptocero
         // this.operador.obsoleto      = this.cbox_obsoleto
     
            let expiYear = new Date(this.operador.fechaexpi).getFullYear()
            let extenderYear = new Date(this.fechaextender).getFullYear()
            
            if(expiYear > 0 && extenderYear > 0 ){
                if(this.fechaextender < this.operador.fechaexpi){
                    this.showNotification = true
                    this.notificationTitle = "Error"
                    this.notificationText = "La Fecha de Extensión de Licencia no puede ser menor de la fecha de expiración."
                    this.$refs.credenciales.click()
                }
                var Difference_In_Time = new Date(this.fechaextender).getTime() - new Date(this.operador.fechaexpi).getTime();
                var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      
                this.operador.diasextraexpi = Difference_In_Days
            }

            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn},
                body: JSON.stringify({ accion: 'validar', archivo: 'Operadores', modo:this.modo, record: this.operador}) 
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }
            }).then(()=>{
                this.isLoading = false
                this.$router.push('/operadores')
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
       
        leerCredenciales(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: 'metodo', archivo: "Operadores", metodo:'leerCredenciales', params:{ operador: this.operador.numero } })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.credenciales = data
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        Get_SAT_ClavesPaises(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: 'metodo', archivo: "Global", metodo:'Get_SAT_ClavesPaises', params:{  } })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.satpaises = data
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        uploadImage ($event) {
            var image 
            if($event.target.files.length >= 1){
                image = $event.target.files[0]
            
                if(image.size <= 3000000){
                    if(image.type == 'image/jpeg' || image.type == 'image/png' || image.type == 'image/webp' || image.type == 'image/jpg'){
                        this.fotografia = URL.createObjectURL(image);
                        this.operador.fotografia = image;
                    }else{
                        this.$notify({
                            group: 'iasc',
                            title: 'Error',
                            text: 'El tipo de archivo no se permite',
                            type: 'danger'
                        });
                    }
                }else{
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: 'El archivo debe pesar menos de 1Mb',
                        type: 'danger'
                    });
                }
          
            }
        }
    }
}
</script>