<template>
    <section>
        <SideBar v-if="!OpenMode" />
        <TopBar v-if="!OpenMode" />
        <div :class="[!OpenMode ? 'content' : '' ]">   
        <div class="main-content-header">
            <h1>Maestro de Uso CFDI</h1>
        </div>
        <LoadingContent v-if="isLoading" />
        <div class="row">
            <div class="col-lg-12 col-md-6">
                <div class="card mb-30" :style="{height:[contentHeight+'px']}">
                    <div class="row " :class="[!OpenMode ? 'p-4' : '']">
                        <div class="content-header  col-12"> 
                            <div class="row">
                                <div class="col-6 text-start p-2 ps-4"></div>
                                <div class="col-6"><p class="p-1 pe-4 text-end">Página {{ paginaActual }} de {{ totalPaginas }} </p></div>
                                <div class="col-md-4 col-12">
                                    <a class="link" href="http://omawww.sat.gob.mx/tramitesyservicios/Paginas/documentos/catCFDI_V_40_05012022.xls" target="_blank">Buscar Catalogos SAT <OpenInNewIcon /></a>
                                </div>
                                <div class="col-2 offset-6 ps-2 pe-5 text-end">
                                    <ul class="list-unstyled mb-0 d-flex justify-content-between pager-btns float-right">
                                        <li><button @click="setPaginacion('first')" type="button" class="pager pager-first btn btn-sm btn-outline-secondary"><ChevronDoubleLeftIcon /></button></li>
                                        <li><button @click="setPaginacion('prev')"  type="button" class="pager pager-prev  btn btn-sm btn-outline-secondary"><ChevronLeftIcon /></button></li>
                                        <li><button @click="setPaginacion('next')"  type="button" class="pager pager-next  btn btn-sm btn-outline-secondary"><ChevronRightIcon /></button></li>
                                        <li><button @click="setPaginacion('last')"  type="button" class="pager pager-last  btn btn-sm btn-outline-secondary"><ChevronDoubleRightIcon /></button></li> 
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-10 col-12">
                            <div class="table-responsive">
                                <b-table-simple class="browse" hover small caption-top responsive >
                                    <b-thead head-variant="light"  class="table-header">
                                        <b-tr>
                                            <b-th>Clave</b-th>
                                            <b-th>Descripción</b-th>
                                            <b-th>Física</b-th>
                                            <b-th>Moral</b-th>
                                            <b-th>RegFisReceptor</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="sat in sats" :key="sat.clave" 
                                        @click="selectedRegistro = sat" 
                                        @dblclick="exportRegistry()" 
                                        :class="{'selected-row': (selectedRegistro.clave == sat.clave)}">
                                            <b-td class="text-start">{{ sat.clave}}</b-td>
                                            <b-td class="text-start text-nowrap">{{ sat.descripcion }}</b-td>
                                            <b-td class="text-center">{{ sat.fisica}}</b-td>
                                            <b-td class="text-center">{{ sat.moral}}</b-td>
                                            <b-td class="text-nowrap">{{ sat.regfisreceptor}}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </div>

                        </div> 
                        <div class="col-2 ps-2 pe-5">
                            <ul class="d-block list-unstyled action-block">
                                <li class="mobile-float"><button @click="$refs.fileUpload.click()" class="btn btn-sm btn-success btn-block">Importar</button></li>
                                <input @change="LeerArchivo" type="file" name="file" class="d-none" multiple="false" ref="fileUpload" accept=".csv,.xls,.xlsx" />
                                <li v-if="OpenMode"><button @click="exportRegistry()" class="btn btn-xs btn-success btn-block">Elegir</button></li>
                            </ul>
                        </div>
                        <div class="content-footer col-12"></div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <YesNoPopupVue  v-show="showImport" @close="closeImport" @aceptar="Importar()">
            <template v-slot:header>
                <h6 class="text-uppercase">{{importTitle}}</h6>
            </template>
            <template v-slot:body>
                {{importText}}
            </template>
        </YesNoPopupVue>
        <ProgressPopUp  v-show="showProgress" @close="closeInfo" :size="25">
            <template v-slot:body>
                <div  class="text-center p-5">
                    <h1> Copiando registros... </h1>
                    <div  class="p-5"><LoadingContent /></div>
                    <h1> Este proceso puede tardar unos minutos. No cierre esta pestaña.</h1>
                </div>
               
            </template>
        </ProgressPopUp>
    </section>
</template>

<script>
import axios from 'axios'
import handleBrowse from '@/mixins/handleBrowse'
import handleGlobals from '@/mixins/handleGlobals'
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronDoubleRightIcon from 'vue-material-design-icons/ChevronDoubleRight.vue';
import ChevronDoubleLeftIcon from 'vue-material-design-icons/ChevronDoubleLeft.vue';
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';
import OpenInNewIcon from 'vue-material-design-icons/OpenInNew.vue';
import LoadingContent from '@/components/LoadingSpinner.vue'
import YesNoPopupVue from '@/components/YesNoPopup.vue'
import ProgressPopUp from '@/components/ProgressPopUp.vue'
import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';

export default {
    name: 'WIN_satUsoCFDI',
    props:['mode','perpage'],
    mixins: [handleGlobals,handleBrowse],
    components:{SideBar,TopBar,YesNoPopupVue,ProgressPopUp,ChevronLeftIcon,ChevronDoubleRightIcon,ChevronDoubleLeftIcon,ChevronRightIcon, LoadingContent,OpenInNewIcon},
    data(){
        return{
            sats: [],
            showImport: false,
            importText:false,
            importTitle:false,
            files:[],
            showProgress:false,
            progressText:'',
            closeInfo:false
        }
    },
    created(){
        if(this.OpenMode == false){
            this.getFiltros()
        } 
    },
    methods:{
        async getFiltros(){
                await fetch(this.$api+'/rdx', {    
                    method: "POST",
                    headers: {
                        'Content-Type' : 'application/json',
                        'Authorization' : this.$store.state.iasctratkn
                    },
                    body: JSON.stringify({accion: "filters"})
                }).then((response)=>{
                    if(response.ok){
                        return response.json()
                    }
                }).then((data)=>{
                    let reg = data['SUCF']
                    if(reg){
                        this.paginaActual  = reg['paginaActual'].dato
                        if(this.paginaActual == '') this.paginaActual = 0
                    }else{
                       
                        this.paginaActual  = 1
                    }
                }).catch((err)=>{
                this.falseToken(err)
            });
        },
        LeerArchivo(e) {
            this.files = e.target.files || e.dataTransfer.files;
            if (!this.files.length)
                return;

            this.showImport = true
            this.importTitle = "Importante"
            this.importText = "Si importas se borrarán los registros actuales. ¿Deseas continuar?"
        },
        closeImport(){
            this.showImport = false
        },
        Importar(){
            this.closeImport()
            this.showProgress = true
           
            let fd = new FormData()
            fd.append('file', this.files[0])
            fd.append('accion','metodo')
            fd.append('archivo', 'satUsoCFDI')
            fd.append('metodo','importar')
            axios.post(this.$api+'/rdx',fd, {
                headers: { "Content-Type": "multipart/form-data",
                            'Authorization' : this.$store.state.iasctratkn },  
            }).then(()=>{
                this.consultar()
            }).finally(()=>{
                this.showProgress = false
                this.closeInfo = true
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        consultar(){
            this.isLoading = true
           
            if(this.newSelectedFilter){
                this.paginacion = ""
                this.paginaActual = 0
            }
            
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "browse", 
                                        archivo: "satUsoCFDI", 
                                        activarPaginas: true,
                                        orden: "clave", 
                                        filtro: {paginaActual: {dato: 'NA'}},
                                        pagina: this.paginaActual, 
                                        paginacion: this.paginacion,
                                        registrosxpagina:this.rows  })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.sats           = data['registros'];
                this.totalRegistros = data['totalRegistros'];
                this.totalPaginas   = data['totalPaginas'];
                this.paginaActual   = data['paginaActual'];
            }).finally(()=>{
                this.isLoading = false;
            }).catch((err)=>{
                this.falseToken(err)
            });
        }
    }
}
</script>
