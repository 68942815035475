<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Archivo de Fianzas e Intercambio</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row w-65">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-10">
                                            <form>
                                                <div class="row ">
                                                    <div class="col-6 ">
                                                        <div class="row">
                                                            <label class="col-4 text-nowrap">Sucursal</label>
                                                            <div class="col-3">
                                                                <input ref="sucursal" type="number" class="form-control form-control-sm" disabled maxlength="3" v-model="fianza.sucursal" />
                                                            </div>
                                                        </div>
                                                    </div>	
                                                    <div class="col-6 ">
                                                        <div class="row">
                                                            <label class="col-4 text-nowrap">Usuario en Cierre</label>
                                                            <div class="col-5">
                                                                <input ref="usuario" type="text" class="form-control form-control-sm" maxlength="12" disabled v-model="fianza.usuario" />													
                                                            </div>
                                                        </div>
                                                    </div>	
                                                </div>	
                                                <div class="row ">
                                                    <label class="col-2 text-nowrap">Folio</label>
                                                    <div class="col-3">
                                                        <input ref="folio" type="number" class="form-control form-control-sm" maxlength="15" required v-model="fianza.folio" />													
                                                    </div>
                                                </div>	
                                                <lookUpCtrl :objeto="folio" :label="'Linea Americana'" :cols="[2,2,8]" :archivo="'Lineas'" :campo="'linea'" :descripcion="'nombre'" :model="'lineaamericana'" v-model="fianza.lineaamericana" />

                                                <fieldset>
                                                    <legend>Caja</legend>
                                                    <div class="row ">
                                                        <div class="col-4 ">
                                                            <lookUpCtrl :objeto="folio" :label="'No.'" :cols="[3,5,2]" :archivo="'Vehiculos'" :campo="'economico'" :descripcion="'serie,marca,ano'" :hideDescripcion="true" :model="'nocaja'" v-model="fianza.nocaja" />
                                                        </div>	
                                                        <div class="col-4 ">
                                                            <div class="row">
                                                                <label class="col-3 text-nowrap">Marca</label>
                                                                <div class="col-7">
                                                                    <input ref="marca" type="text" class="form-control form-control-sm" maxlength="15"  v-model="fianza.marca" />													
                                                                </div>
                                                            </div>
                                                        </div>	
                                                        <div class="col-4 ">
                                                            <div class="row">
                                                                <label class="col-3 text-nowrap">Año</label>
                                                                <div class="col-7">
                                                                    <input ref="ano" type="number" class="form-control form-control-sm" maxlength="4"  v-model="fianza.ano" />													
                                                                </div>
                                                            </div>
                                                        </div>	
                                                        <div class="col-4 ">
                                                            <div class="row">
                                                                <label class="col-3 text-nowrap">Serie</label>
                                                                <div class="col-7">
                                                                    <input ref="serie" type="text" class="form-control form-control-sm" maxlength="30" v-model="fianza.serie" />													
                                                                </div>
                                                            </div>
                                                        </div>	
                                                        <div class="col-4 ">
                                                            <div class="row">
                                                                <label class="col-3 text-nowrap text-right">Tipo</label>
                                                                <div class="col-9">
                                                                    <select class="form-control form-control-sm" v-model="fianza.tipo">
                                                                        <option value="Plataforma">Plataforma</option>
                                                                        <option value="Chasis">Chasis</option>
                                                                        <option value="Contenedor">Contenedor</option>
                                                                        <option value="Semi-Low Boy">Semi-Low Boy</option>
                                                                        <option value="Low Boy">Low Boy</option>
                                                                        <option value="Piggy Back">Piggy Back</option>
                                                                        <option value="Otros">Otros</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>	
                                                    </div>	
                                                </fieldset>

                                                <div class="row">
                                                    <div class="col-4">
                                                        <div class="row">
                                                            <label class="col-5 text-nowrap">Fecha Entrada</label>
                                                            <div class="col-7">
                                                                <input ref="entrada" type="date" class="form-control form-control-sm" v-model="fianza.entrada" />
                                                            </div>
                                                        </div>
                                                    </div>	
                                                    <div class="col-4">
                                                        <div class="row">
                                                            <label class="col-5 text-nowrap">Fecha Salida</label>
                                                            <div class="col-7">
                                                                <input ref="salida" type="date" class="form-control form-control-sm" v-model="fianza.salida" />													
                                                            </div>
                                                        </div>
                                                    </div>	
                                                    <div class="col-4">
                                                        <div class="row">
                                                            <label class="col-5 text-nowrap">Vencimiento</label>
                                                            <div class="col-7">
                                                                <input ref="vencimiento" type="date" class="form-control form-control-sm" v-model="fianza.vencimiento" />													
                                                            </div>
                                                        </div>
                                                    </div>	
                                                </div>	
                                                <div class="row ">
                                                    <div class="col-6">
                                                        <LookUpCtrl :label="'Unidad Entrada'" :cols="[4,6,1]" :archivo="'Vehiculos'" :objeto="fianza" :hideDescripcion="true" :campo="'numero'" :descripcion="'numero'" :model="'tractorentrada'" v-model="fianza.tractorentrada" />
                                                    </div>
                                                    <div class="col-6">
                                                        <LookUpCtrl :label="'Unidad Salida'" :cols="[4,6,1]" :archivo="'Vehiculos'" :objeto="fianza" :hideDescripcion="true" :campo="'numero'" :descripcion="'numero'" :model="'tractorsalida'" v-model="fianza.tractorsalida" />
                                                    </div>
                                                </div>
                                                <LookUpCtrl :label="'Cliente Entrada'" :cols="[2,2,8]" :archivo="'Clientes'" :objeto="fianza" :campo="'clienteid'" :descripcion="'nombre'" :model="'clienteentrada'" v-model="fianza.clienteentrada" />
                                                <LookUpCtrl :label="'Cliente Salida'" :cols="[2,2,8]" :archivo="'Clientes'" :objeto="fianza" :campo="'clienteid'" :descripcion="'nombre'" :model="'clientesalida'" v-model="fianza.clientesalida" />
                                                <div class="row ">
                                                    <div class="col-6">
                                                        <div class="form-check">
                                                            <input class="form-check-input"  v-model="fianza.vacioentrada" type="checkbox" id="vacioentrada">
                                                            <label class="form-check-label" for="vacioentrada">Vacio Entrada</label>
                                                        </div>                                                    
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-check">
                                                            <input class="form-check-input"  v-model="fianza.vaciosalida" type="checkbox" id="vaciosalida">
                                                            <label class="form-check-label" for="vaciosalida">Vacio Salida</label>
                                                        </div> 
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-6">
                                                        <div class="row">
                                                            <label class="col-4 text-nowrap">Inter Entrada</label>
                                                            <div class="col-8">
                                                                <input type="text" class="form-control form-control-sm" maxlength="20" v-model="fianza.interentrada" />													
                                                            </div>
                                                        </div>                                                
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="row">
                                                            <label class="col-4 text-nowrap">Inter Salida</label>
                                                            <div class="col-8">
                                                                <input type="text" class="form-control form-control-sm" maxlength="20" v-model="fianza.intersalida" />													
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <label class="col-2 text-nowrap">Observaciones</label>
                                                    <div class="col-10">
                                                        <textarea class="form-control form-control-sm" maxlength="255" rows="5" v-model="fianza.observaciones"></textarea>												
                                                    </div>
                                                </div>     
                                            </form>
                                        </div>
                                        <div class="col-2">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li><button @click="setFianza()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li><router-link to="/fianzas" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import LookUpCtrl from '@/components/LookUpCtrl.vue';
import handleForm from '@/mixins/handleForm';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    components:{SideBar,TopBar, LookUpCtrl,LoadingContent },
    mixins:[handleForm,handleGlobals],
    data(){
        return{
            id: this.$route.params.fianza,
            modo: (this.$route.params.fianza > 0 ? 'Modificando' : 'Creacion'), 
            fianza: {},
            titulo: 'Agregando una Fianza',
        }
    },
    mounted(){
        this.llenaForma();
    },
    methods:{
        async llenaForma(){
            await this.getFianza();
            this.titulo = this.$route.params.fianza > 0 ? 'Cambiando una Fianza' :  'Agregando una Fianza'
        },
        getFianza(){
            let req = []
            this.isLoading = true
             fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "inicializar", modo:this.modo, archivo: "Fianzas", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json()
                }   
            }).then((data)=>{
                this.fianza = data['res']
                req = data['req']
            }).finally(()=>{
                this.setRequired(req)
                this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setFianza(){
            this.isLoading = true
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: 'validar', archivo: "Fianzas", modo:this.modo, record: this.fianza }) 
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['res'] == 'error'){
                    this.showNotification = true
                    this.notificationTitle =  'Error'
                    this.notificationText = data['msg']
                    this.notificationRef = 'folio'
                }else{
                    this.isLoading = false
                    this.$router.push('/fianzas')
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        afterClose(){
            this.$refs[this.notificationRef].focus()
        }
    }
}
</script>