<template>
    <section>
        <SideBar v-if="!OpenMode" />
        <TopBar v-if="!OpenMode" />
        <div :class="[!OpenMode ? 'content' : '' ]">   
        <div class="main-content-header">
            <h1>Fichas de Depósito</h1>
        </div>
        <LoadingContent v-if="isLoading" />
        <div class="row">
            <div class="col-lg-12 col-md-6">
                <div class="card mb-30" :style="{height:[contentHeight+'px']}">
                    <div class="row " :class="[!OpenMode ? 'p-4' : '']">
                        <div class="content-header  col-12"> 
                            <div class="row">
                                <div class="col-6 text-start ps-4"></div>
                                <div class="col-6"><p class="p-1 pe-4 mb-0 text-end">Página {{ paginaActual }} de {{ totalPaginas }} </p></div>
                                <div class="col-2 offset-10 ps-2 pe-5 text-end pb-0 pt-0">
                                    <ul class="list-unstyled mb-0 d-flex justify-content-between pager-btns float-right">
                                        <li><button @click="setPaginacion('first')" type="button" class="pager pager-first btn btn-sm btn-outline-secondary"><ChevronDoubleLeftIcon /></button></li>
                                        <li><button @click="setPaginacion('prev')"  type="button" class="pager pager-prev  btn btn-sm btn-outline-secondary"><ChevronLeftIcon /></button></li>
                                        <li><button @click="setPaginacion('next')"  type="button" class="pager pager-next  btn btn-sm btn-outline-secondary"><ChevronRightIcon /></button></li>
                                        <li><button @click="setPaginacion('last')"  type="button" class="pager pager-last  btn btn-sm btn-outline-secondary"><ChevronDoubleRightIcon /></button></li> 
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-10 col-12">
                            <div class="table-responsive ps-2">
                                <b-table-simple class="browse" hover small caption-top responsive >
                                    <b-thead head-variant="light"  class="table-header">
                                        <b-tr>
                                            <b-th>D</b-th>
                                            <b-th>NumID</b-th>
                                            <b-th>Suc</b-th>
                                            <b-th>Banco</b-th>
                                            <b-th>TPol</b-th>
                                            <b-th>Ref</b-th>
                                            <b-th>Fecha</b-th>
                                            <b-th>Valor</b-th>
                                            <b-th>Mon</b-th>
                                            <b-th>T.Cambio</b-th>
                                            <b-th>Usuario</b-th>
                                            <b-th>PCancela</b-th>
                                            <b-th>F.Cancela</b-th>
                                            <b-th>U.Cancela</b-th>
                                            <b-th>Cancelada</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="deposito in depositos" 
                                            :key="deposito.numid" 
                                            @click="selectedRegistro = deposito" 
                                            @dblclick=" (!OpenMode ? modificar() : exportRegistry()) " 
                                            :class="{ 'selected-row': (selectedRegistro.numid == deposito.numid) }">
                                            <b-td class="text-secondary m-auto pe-1"><FileDocumentOutlineIcon v-if="deposito.doctos > 0 " /></b-td>
                                            <b-td class="text-center">{{ deposito.numid | leadingZeros(3)}}</b-td>
                                            <b-td class="text-nowrap">{{ deposito.descripcion }}</b-td>
                                            <b-td class="text-nowrap">{{ deposito.clientes }}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </div>
                        </div> 
                        <div class="col-2 ps-2 pe-5" >
                            <ul class="d-block list-unstyled action-block">
                                <li v-if="!OpenMode"><router-link to="/fagrupos/agregar" class="btn btn-xs btn-success btn-block">Agregar</router-link></li>
                                <li v-if="!OpenMode"><router-link to="/fagrupos/modificar" custom >
                                        <button :disabled="disableBtn" type="button" class="btn btn-xs btn-primary btn-block">Cambiar</button>
                                    </router-link>
                                </li>
                                <li v-if="!OpenMode"><button @click="borrar()" :disabled="disableBtn" class="btn btn-xs btn-danger btn-block">Borrar</button></li>
                                <li v-if="!OpenMode"><button @click="imprimir()" :disabled="true" class="btn btn-xs btn-secondary btn-block">Imprimir</button></li>
                                <li v-if="OpenMode"><button @click="exportRegistry()" class="btn btn-xs btn-success btn-block">Elegir</button></li>
                            </ul>

                        </div>
                        
                        <div class="content-footer col-12 text-start"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
</template>

<script>
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronDoubleRightIcon from 'vue-material-design-icons/ChevronDoubleRight.vue';
import ChevronDoubleLeftIcon from 'vue-material-design-icons/ChevronDoubleLeft.vue';
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'
import handleBrowse from '@/mixins/handleBrowse'
import FileDocumentOutlineIcon from 'vue-material-design-icons/FileDocumentOutline.vue';
import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    name:'WIN_FichasHead',
    props:['mode','perpage'],
    mixins:[handleGlobals,handleBrowse],
    components:{SideBar,TopBar,FileDocumentOutlineIcon,ChevronLeftIcon,ChevronDoubleRightIcon,ChevronDoubleLeftIcon,ChevronRightIcon, LoadingContent},
    data(){
        return{
            depositos: []
        }
    },
    created(){
        if(this.OpenMode == false){
            this.getFiltros()
        } 
    },
    methods:{
        async getFiltros(){
                await fetch(this.$api+'/rdx', {    
                    method: "POST",
                    headers: {
                        'Content-Type' : 'application/json',
                        'Authorization' : this.$store.state.iasctratkn
                    },
                    body: JSON.stringify({accion: "filters"})
                }).then((response)=>{
                    if(response.ok){
                        return response.json()
                    }
                }).then((data)=>{
                    let reg = data['FDE']
                    if(reg){
                        this.paginaActual       = reg['paginaActual'].dato
                        if(this.paginaActual == '') this.paginaActual = 0
                    }else{
                        this.paginaActual       = 1
                    }
                }).catch((err)=>{
                this.falseToken(err)
            });
            },
        consultar(){
            this.isLoading = true
            
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "browse", 
                                        archivo: "FichasHead", 
                                        activarPaginas:true,
                                        orden: "numid",
                                        filtro: {paginaActual: {dato: 'NA'}},
                                        pagina: this.paginaActual, 
                                        paginacion: this.paginacion,
                                        registrosxpagina: this.rows })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.depositos      = data['registros'];
                this.totalRegistros = data['totalRegistros'];
                this.totalPaginas   = data['totalPaginas'];
                this.paginaActual   = data['paginaActual'];
            }).finally(()=>{
                this.isLoading = false;
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        
        borrar(){

        },
        imprimir(){

        },
        modificar(){
            this.$router.push({ name: 'CFichasHead', params: {grupo: this.selectedRegistro.grupo}});
        },
    }
}
</script>

