<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Maestro de Ciudades</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row w-65">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-10">
                                            <form>
                                                <div class="row ">
                                                    <label class="col-2 text-nowrap">Clave</label>
                                                    <div class="col-3 d-flex">
                                                        <input ref="clave" type="number" class="form-control form-control-sm" required v-model="ciudad.clave" />													
                                                    </div>
                                                </div>	
                                                <div class="row ">
                                                    <label class="col-2 text-nowrap">Ciudad y Edo</label>
                                                    <div class="col-10 d-flex"> 
                                                        <input ref="ciudad" type="text" maxlength="50" class="form-control form-control-sm" required v-model="ciudad.ciudad" />													
                                                    </div>
                                                </div>	
                                                <div class="row ">
                                                    <label class="col-2 text-nowrap">Estado</label>
                                                    <div class="col-6 d-flex">
                                                        <input ref="estado" type="text" class="form-control form-control-sm" v-model="ciudad.estado" />													
                                                    </div>
                                                </div>	
                                                <div class="row ">
                                                    <div class="col-6">
                                                        <div class=" row">
                                                            <label class="col-4 text-nowrap">Estado (Abrev)</label>
                                                            <div class="col-8 d-flex">
                                                                <input ref="estado_abrev" type="text" class="form-control form-control-sm"  v-model="ciudad.estado_abrev" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="row">
                                                            <label class="col-4 text-nowrap text-end">SAT Estado</label>
                                                            <div class="col-8">
                                                                <select ref="cveestado" class="form-control form-control-sm" v-model="ciudad.cveestado">
                                                                    <option value=""></option>
                                                                    <option v-for="(edo, key) in satestados" :key="key" :value="key">{{edo}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-6">
                                                        <LookUpCtrl v-if="ciudad.clave" :label="'SAT Municipio'" :cols="[4,7,1]" :archivo="'satMunicipios'" :objeto="ciudad" :hideDescripcion="true" :campo="'cvemunicipio'" :descripcion="'municipio'" :model="'cvemunicipio'" v-model="ciudad.cvemunicipio" />
                                                    </div>
                                                    <div class="col-6">
                                                        <LookUpCtrl v-if="ciudad.clave" :label="'SAT Localidad'" :cols="[4,7,1]" :archivo="'satLocalidades'" :objeto="ciudad" :hideDescripcion="true" :campo="'cveloc'" :descripcion="'descripcion'" :model="'cvelocalidad'" v-model="ciudad.cvelocalidad" />
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <label class="col-2 text-nowrap">Altitud</label>
                                                    <div class="col-3 d-flex">
                                                        <input ref="altitud" type="number" class="form-control form-control-sm"   v-model="ciudad.altitud" />													
                                                    </div>
                                                    <label class="col-1 text-nowrap">Mts</label>
                                                </div>	
                                                <div class="row ">
                                                    <label class="col-2 text-nowrap">Latitud</label>
                                                    <div class="col-6 d-flex">
                                                        <input ref="latitud" type="text" class="form-control form-control-sm"   v-model="ciudad.latitud" />													
                                                    </div>
                                                </div>	
                                                <div class="row ">
                                                    <label class="col-2 text-nowrap">Longitud</label>
                                                    <div class="col-6 d-flex">
                                                        <input ref="longitud" type="text" class="form-control form-control-sm"  v-model="ciudad.longitud" />													
                                                    </div>
                                                </div>	
                                            </form>
                                        </div>
                                        <div class="col-2">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li><button @click="setCiudad()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li><router-link to="/ciudades" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import LookUpCtrl from '@/components/LookUpCtrl.vue';
import handleForm from '@/mixins/handleForm';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    components:{SideBar, TopBar,LookUpCtrl,LoadingContent },
    mixins:[handleForm,handleGlobals],
    data(){
        return{
            id: this.$route.params.ciudad,
            modo: (this.$route.params.ciudad > 0 ? 'Modificando' : 'Creacion'), 
            ciudad: [],
            satestados: [],
            titulo: 'Agregando una Ciudad',
        }
    },
    mounted(){
        this.llenaForma();
    },
    methods:{
        async llenaForma(){
            this.Get_SAT_ClavesEstados()
            await this.getCiudad();
            this.titulo = this.$route.params.ciudad > 0 ? 'Cambiando una Ciudad' :  'Agregando una Ciudad'
        },
        async Get_SAT_ClavesEstados(){
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "metodo", 
                                        archivo: "Global", 
                                        metodo: "Get_SAT_ClavesEstados",
                                        params: {}
                                    })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.satestados = data;
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        getCiudad(){
            let req = []
            this.isLoading = true
             fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "inicializar", modo:this.modo, archivo: "Ciudades", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json()
                }   
            }).then((data)=>{
                this.ciudad = data['res']
                req = data['req']
            }).finally(()=>{
                this.setRequired(req)
                this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setCiudad(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: 'validar', archivo: "Ciudades", modo:this.modo, record: this.ciudad }) 
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['res'] == 'error'){
                    this.showNotification = true
                    this.notificationTitle =  'Error'
                    this.notificationText = data['msg']
                    this.notificationRef = 'clave'
                }else{
                    this.$router.push('/ciudades')
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        afterClose(){
           
            this.$refs[this.notificationRef].focus()
        }
    }
}
</script>