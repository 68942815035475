<template>
    <section>
        <SideBar v-if="!OpenMode" />
        <TopBar v-if="!OpenMode" />
        <div :class="[!OpenMode ? 'content' : '' ]">   
            <div class="main-content-header">
                <h1>Ordenes de Embarque</h1>
            </div>
            <LoadingContent v-if="isLoading" />
            <div class="row">
                <div class="col-lg-12 col-md-6">
                    <div class="card" :style="{height:[contentHeight+'px']}">
                        <div class="row " :class="[!OpenMode ? 'p-4' : '']">
                            <div class="content-header col-12"> 
                                <div class="row">
                                    <div class="col-md-4 col-12 text-start "></div>
                                    <div class="col-3 d-none d-md-flex text-start"></div>
                                    <div class="col-md-3 col-12 text-start"></div>
                                    <div class="col-md-2 col-12"><p class="p-1 pe-4 mb-0 text-end">Página {{ paginaActual }} de {{ totalPaginas }} </p></div>
                                    <div class=" col-12 col-md-2 offset-md-10 ps-2 pe-5 text-end pb-0 pt-0">
                                        <ul class="list-unstyled mb-0 d-flex justify-content-between pager-btns float-right">
                                            <li><button @click="setPaginacion('first')" type="button" class="pager pager-first btn btn-sm btn-outline-secondary"><ChevronDoubleLeftIcon /></button></li>
                                            <li><button @click="setPaginacion('prev')"  type="button" class="pager pager-prev  btn btn-sm btn-outline-secondary"><ChevronLeftIcon /></button></li>
                                            <li><button @click="setPaginacion('next')"  type="button" class="pager pager-next  btn btn-sm btn-outline-secondary"><ChevronRightIcon /></button></li>
                                            <li><button @click="setPaginacion('last')"  type="button" class="pager pager-last  btn btn-sm btn-outline-secondary"><ChevronDoubleRightIcon /></button></li> 
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-10 col-12 pt-2">
                                <div class="table-responsive ps-2">
                                    <b-table-simple class="browse" hover small caption-top responsive >
                                        <b-thead head-variant="light" class="table-header">
                                            <b-tr>
                                                <b-th class="text-nowrap">Suc</b-th>
                                                <b-th class="text-nowrap">Folio</b-th>
                                                <b-th class="text-nowrap">Fecha</b-th>
                                                <b-th class="text-nowrap">Remitente</b-th>
                                                <b-th class="text-nowrap">Ruta</b-th>
                                                <b-th class="text-nowrap">Cajas</b-th>
                                                <b-th class="text-nowrap">Peso</b-th>
                                                <b-th class="text-nowrap">Viajas</b-th>
                                                <b-th class="text-nowrap">Operador</b-th>
                                                <b-th class="text-nowrap">Nombre</b-th>
                                                <b-th class="text-nowrap">Licencia</b-th>
                                                <b-th class="text-nowrap">Tractor</b-th>
                                                <b-th class="text-nowrap">Placa</b-th>
                                                <b-th class="text-nowrap">Remisión</b-th>
                                                <b-th class="text-nowrap">Tipo Remolque</b-th>
                                                <b-th class="text-nowrap">Solicitó</b-th>
                                                <b-th class="text-nowrap">Observaciones</b-th>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr   v-for="(embarque,indx) in embarques" 
                                                    :key="indx" 
                                                    @click="selectedRegistro=embarque;selectedRegistro.indx = indx" 
                                                    @dblclick=" (!OpenMode ? modificar() : exportRegistry()) " 
                                                    :class="{'selected-row': (selectedRegistro.folio == embarque.folio) }"> 

                                                <b-td class="text-nowrap">{{ embarque.sucursal| leadingZeros(3)}}</b-td>
                                                <b-td class="text-center text-nowrap" >{{ embarque.folio | leadingZeros(5)}}</b-td>
                                                <b-td class="text-nowrap">{{ formato(embarque.fecha,'MMM-DD-YYYY')}}</b-td>
                                                <b-td class="text-center text-nowrap" >{{ embarque.remitente | leadingZeros(5)}}</b-td>
                                                <b-td class="text-center text-nowrap" >{{ embarque.ruta | leadingZeros(5)}}</b-td>
                                                <b-td class="text-nowrap">{{ embarque.cajas }}</b-td>
                                                <b-td class="text-nowrap">{{ embarque.peso }}</b-td>
                                                <b-td class="text-nowrap">{{ embarque.viajes }}</b-td>
                                                <b-td class="text-center text-nowrap" >{{ embarque.operador | leadingZeros(4)}}</b-td>
                                                <b-td class="text-nowrap">{{ embarque.oper_nombre}}</b-td>
                                                <b-td class="text-nowrap">{{ embarque.licencia}}</b-td>
                                                <b-td class="text-center text-nowrap" >{{ embarque.tractor | leadingZeros(5)}}</b-td>
                                                <b-td class="text-nowrap">{{ embarque.placa}}</b-td>
                                                <b-td class="text-center text-nowrap" >{{ embarque.remision | leadingZeros(7)}}</b-td>
                                                <b-td class="text-nowrap">{{ embarque.tiroremolque}}</b-td>
                                                <b-td class="text-nowrap">{{ embarque.solicito}}</b-td>
                                                <b-td class="text-nowrap">{{ embarque.observaciones }}</b-td>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </div>
                            </div> 
                            <div class="col-2 ps-2 pe-5 btn-manager" >
                                <ul class="d-block list-unstyled action-block">
                                    <li v-if="!OpenMode"><router-link to="/embarques/agregar" class="btn btn-xs btn-success btn-block">Agregar</router-link></li>
                                    <li v-if="!OpenMode"><button @click="modificar()" :disabled="disableBtn" type="button" class="btn btn-xs btn-primary btn-block">Cambiar</button></li>
                                    <li v-if="!OpenMode"><button @click="ask()" :disabled="disableBtn" class="btn btn-xs btn-danger btn-block">Borrar</button></li>
                                    <li v-if="!OpenMode"><button @click="imprimir()" class="btn btn-xs btn-secondary btn-block">Imprimir</button></li>
                                    <li v-if="OpenMode"><button @click="exportRegistry()" class="btn btn-xs btn-success btn-block">Elegir</button></li>
                                </ul>

                                <div style="width: 100px;text-align: left;float: right;">
                                    <div class="form-check">
                                        <input class="form-check-input" v-model="filtroEstado" @change="consultar()" value="P" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked>
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            Pendientes
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" v-model="filtroEstado" @change="consultar()"  value="T" type="radio" name="flexRadioDefault" id="flexRadioDefault2" >
                                        <label class="form-check-label" for="flexRadioDefault2">
                                            Todos
                                        </label>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="content-footer col-12 text-start"></div>
                        </div>
                    </div>
                </div>
            </div>
            <BusquedaVue v-show="busquedaVisible" v-hotkey="keymap" :campos="camposFiltro" @applyFilter="aplicaFiltro()"></BusquedaVue>
            <PrintComponentVue v-if="print" @close="closePopup">
                <template  v-slot:title >Reporte de Operadores</template>
            </PrintComponentVue>

            <DeleteReg  v-show="showDelete" @close="closeDelete" @aceptar="embarques = borrar('OrdEmbarque',embarques)">
                <template v-slot:header>
                    <h6 class="text-uppercase">{{notificationTitle}}</h6>
                </template>
                <template v-slot:body>
                    {{notificationText}}
                </template>
            </DeleteReg>

            <InfoPopUp  v-show="showNotification" @close="closeInfo" >
                <template v-slot:header>
                    <h6 class="text-uppercase">{{notificationTitle}}</h6>
                </template>
                <template v-slot:body>
                    {{notificationText}}
                </template>
            </InfoPopUp>
            
        </div>
    </section>
</template>

<script>
import InfoPopUp from '@/components/InfoPopUp.vue';
import DeleteReg from '@/components/YesNoPopup.vue';
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronDoubleRightIcon from 'vue-material-design-icons/ChevronDoubleRight.vue';
import ChevronDoubleLeftIcon from 'vue-material-design-icons/ChevronDoubleLeft.vue';
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'
import handleBrowse from '@/mixins/handleBrowse'
import handlePopUp from '@/mixins/handlePopUp'
import PrintComponentVue from '@/components/PrintComponent.vue'; 
import BusquedaVue from '@/components/Busqueda.vue'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
import axios from 'axios'

export default {
    name:'WIN_OrdEmbarque',
    props:['mode','perpage'],
    mixins:[handleGlobals,handleBrowse,handlePopUp],
    components:{SideBar,TopBar,ChevronLeftIcon,InfoPopUp,DeleteReg,BusquedaVue,ChevronDoubleRightIcon,ChevronDoubleLeftIcon,ChevronRightIcon, LoadingContent,PrintComponentVue},
    data(){
        return{
            embarques: [],
            filtroEstado: "P",
        }
    },
    created(){
        if(this.OpenMode == false){
            this.getFiltros()
        } 
    },
    mounted(){
        this.consultar()
    },
    methods:{
        async getFiltros(){
                await fetch(this.$api+'/rdx', {    
                    method: "POST",
                    headers: {
                        'Content-Type' : 'application/json',
                        'Authorization' : this.$store.state.iasctratkn
                    },
                    body: JSON.stringify({accion: "filters"})
                }).then((response)=>{
                    if(response.ok){
                        return response.json()
                    }
                }).then((data)=>{
                    let reg = data['ORD']
                    if(reg){
                        this.filtroEstado       = reg['remision'].dato
                        this.paginaActual       = reg['paginaActual'].dato
                        if(this.paginaActual == '') this.paginaActual = 0
                    }else{
                        this.filtroEstado       = 0
                        this.paginaActual       = 1
                    }
                }).catch((err)=>{
                this.falseToken(err)
            });
        },
        consultar(){
            this.isLoading = true
            axios.post(this.$api+'/rdx',{  accion: "browse", 
                                    archivo: "OrdEmbarque", 
                                    activarPaginas: true,
                                    orden: "folio",
                                    filtro: {
                                        remision: { dato: 0, inactivo: (this.filtroEstado == 'T' ? true : false) },
                                        paginaActual: { dato: 'NA'}
                                    }, 
                                    pagina: this.paginaActual, 
                                    paginacion: this.paginacion,
                                    registrosxpagina: this.rows },{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.iasctratkn
                }
            }).then((res)=>{
                if(res.msg){
                    this.msg_err = res.msg
                }else{
                    this.embarques      = res.data['registros'];
                    this.totalRegistros = res.data['totalRegistros'];
                    this.totalPaginas   = res.data['totalPaginas'];
                    this.paginaActual   = res.data['paginaActual']
                }
            }).finally(()=>{
                this.isLoading = false;
            }).catch((err)=>{
                this.falseToken(err)
            });
            
        },
      
        modificar(){
            this.$router.push({ name: 'COrdEmbarque', params: {embarque: this.selectedRegistro.folio}});
        },
    }
}
</script>

