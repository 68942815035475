<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Control de Tráfico</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-10">
                                            <form>
                                                <div class="row ">
                                                    <div class="col-12 col-md-2 offset-md-10">
                                                        <div class=" row">
                                                            <label class="col-4 text-nowrap">Sucursal</label>
                                                            <div class="col-8 d-flex">
                                                                <input type="number" ref="sucursal" class="form-control text-end form-control-sm" readonly v-model="trafico.sucursal" />													
                                                            </div>
                                                        </div>
                                                    </div>	
                                                </div>	
                                                <nav>
                                                    <div class="nav nav-underline" id="nav-tab" role="tablist">
                                                        <li class="nav-item"><a class="nav-link active" id="nav-general-tab" @click="tab='general'"  data-bs-toggle="tab" href="#nav-general" role="tab" aria-controls="nav-general" aria-selected="true">General</a></li>
                                                        <li class="nav-item"><a class="nav-link" @click="showBtns = false" id="nav-comentarios-tab" data-bs-toggle="tab" href="#nav-comentarios" role="tab" aria-controls="nav-comentarios" aria-selected="false">Comentarios</a></li>
                                                        <li class="nav-item"><a class="nav-link" @click="showBtns = false" id="nav-gps-tab" data-bs-toggle="tab" href="#nav-gps" role="tab" aria-controls="nav-gps" aria-selected="false">GPS</a></li>
                                                        <li class="nav-item"><a class="nav-link" @click="showBtns = false" id="nav-doctos-tab" data-bs-toggle="tab" href="#nav-doctos" role="tab" aria-controls="nav-doctos" aria-selected="false">Doctos</a></li>
                                                        
                                                    </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="container p-1 tab-pane fade show active" id="nav-general" role="tabpanel" aria-labelledby="nav-general-tab">
                                                        <div class="row ">
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Folio</label>
                                                                    <div class="col-6 d-flex">
                                                                        <input type="number" ref="folio" class="form-control text-end form-control-sm" readonly v-model="trafico.folio" />													
                                                                    </div>
                                                                </div>
                                                            </div>	
                                                            <div class="col-8">
                                                                <LookUpCtrl :label="'División de'" :reference="'division'" :cols="[2,3,7]" :archivo="'Clientes'" :campo="'clienteid'" :descripcion="'nombre'" :model="'division'" v-model="trafico.division" />
                                                            </div>	
                                                        </div>	
                    
                                                        <div class="row ">
                                                            <div class="col-6">
                                                                <lookUpCtrl v-if="trafico.folio" :objeto="trafico" :label="'Cliente'" :cols="[2,2,8]" :archivo="'Clientes'" :campo="'clienteid'" :descripcion="'nombre'" :model="'cliente'" :reference="'cliente'" v-model="trafico.cliente" />
                                                            </div>
                                                            <div class="col-6">
                                                                <lookUpCtrl v-if="trafico.folio" :objeto="trafico" :label="'Unidad'" :cols="[2,2,8]" :archivo="'Vehiculos'" :campo="'numero'" :descripcion="'descripcion'" :model="'unidad'" :reference="'unidad'" v-model="trafico.unidad" />
                                                            </div>
                                                        </div>

                                                        <div class="row ">
                                                            <div class="col-6">
                                                                <lookUpCtrl v-if="trafico.folio" :objeto="trafico" :label="'División'" :cols="[2,2,8]" :archivo="'Clientes'" :campo="'clienteid'" :descripcion="'nombre'" :model="'division'" :reference="'division'" v-model="trafico.division" />
                                                            </div>
                                                            <div class="col-6">
                                                                <lookUpCtrl v-if="trafico.folio" :objeto="trafico" :label="'Remolque'" :cols="[2,2,8]" :archivo="'Vehiculos'" :campo="'economico'" :descripcion="'descripcion'" :model="'remolque'" :reference="'remolque'" v-model="trafico.remolque" />
                                                            </div>
                                                        </div>
                                                        <div class="row ">
                                                            <div class="col-6">
                                                                <lookUpCtrl v-if="trafico.folio" :objeto="trafico" :label="'Tpo Unidad'" :cols="[2,2,8]" :archivo="'TpoUnidad'" :campo="'clave'" :descripcion="'descripcion'" :model="'tpounidad'" :reference="'tpounidad'"  v-model="trafico.tpounidad" />
                                                            </div>
                                                            <div class="col-6">
                                                                <lookUpCtrl v-if="trafico.folio" :objeto="trafico" :label="'Operador 2'" :cols="[2,2,8]" :archivo="'Operadores'" :campo="'numero'" :descripcion="'nombre'" :model="'operador2'"  :reference="'operador2'" v-model="trafico.operador2" />
                                                            </div>
                                                        </div>
                                                        <div class="row ">
                                                            <div class="col-6">
                                                                <lookUpCtrl v-if="trafico.folio" :objeto="trafico" :label="'Ruta'" :cols="[2,2,8]" :archivo="'TrRutas'" :campo="'clave'" :descripcion="'descripcion'" :model="'ruta'" :reference="'ruta'"  v-model="trafico.ruta" />
                                                            </div>
                                                            <div class="col-3">
                                                            {{ trafico.tipoviaje }}
                                                            </div>
                                                            <div class="col-3">
                                                                <div class=" row">
                                                                    <label class="col-5 text-nowrap">Remolque Externo</label>
                                                                    <div class="col-7 d-flex">
                                                                        <input type="text" class="form-control form-control-sm " ref="remolqueext" v-model="trafico.remolqueext" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    
                                                        <div class="row">
                                                            <div class="col-2 offset-1">
                                                                <div class=" row">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" ref="subcontratado" v-model="trafico.subcontratado" type="checkbox" id="subcontratado">
                                                                    <label class="form-check-label" for="subcontratado">Sub Contratado</label>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Proveedor</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="text" class="form-control form-control-sm " ref="proveedor" v-model="trafico.proveedor " />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                {{trafico.kms}}
                                                            </div>
                                                            <div class="col-3">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Pedimento</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="text" class="form-control form-control-sm " ref="pedimento" v-model="trafico.pedimento " />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row ">
                                                            <div class="col-5 offset-1">
                                                                <div class=" row">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" ref="concargo" v-model="trafico.concargo "  type="checkbox" id="concargo">
                                                                    <label class="form-check-label" for="concargo">Con Cargo</label>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Carrier</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="text" class="form-control form-control-sm " ref="carrier" v-model="trafico.carrier" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Peso</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="text" class="form-control form-control-sm " ref="peso" v-model="trafico.peso" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row ">
                                                            <div class="col-2 offset-1">
                                                                <div class=" row">
                                                                <div class="form-check">
                                                                    <input class="form-check-input"  v-model="trafico.quitaovacio " ref="quitaovacio" type="checkbox" id="quitaovacio">
                                                                    <label class="form-check-label" for="quitaovacio">Quinta o Vacio</label>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Tráfico Ref</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="number" class="form-control form-control-sm " ref="rtrafico" v-model="trafico.rtrafico" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Carga</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="text" class="form-control form-control-sm " ref="carga" v-model="trafico.carga" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Carga</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="text" class="form-control form-control-sm " ref="carga" v-model="trafico.carga" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row ">
                                                            <div class="col-2 offset-1">
                                                                <div class=" row">
                                                                <div class="form-check">
                                                                    <input class="form-check-input"  v-model="trafico.mandaravisos" ref="mandaravisos" type="checkbox" id="mandaravisos">
                                                                    <label class="form-check-label" for="mandaravisos">Mandar Avisos</label>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-5">
                                                            
                                                            </div>
                                                            <div class="col-3 offset-1">
                                                                <lookUpCtrl v-if="trafico.folio" :objeto="trafico" :label="'Fianza'" :cols="[4,6,2]" :archivo="'Fianzas'" :campo="'folio'" :hideDescripcion="true" :descripcion="'nocaja'" :model="'fianza'" :reference="'fianza'" v-model="trafico.fianza" />
                                                            </div>
                                                        </div>

                                                        <div class="row ">
                                                            <div class="col-9">
                                                                <div class=" row">
                                                                    <label class="col-3 offset-2 text-center text-nowrap">Nombre</label>
                                                                    <label class="col-3 text-center text-nowrap">Fecha</label>
                                                                    <label class="col-2 text-center text-nowrap">Hora</label>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-2 text-nowrap">Programado para</label>
                                                                    <div class="col-3 d-flex">
                                                                        <input type="text" ref="solnombre" class="form-control form-control-sm "  v-model="trafico.solnombre" />		
                                                                    </div>
                                                                    <div class="col-3 d-flex">
                                                                        <input type="date" ref="solfecha" class="form-control form-control-sm "  v-model="trafico.solfecha" />		
                                                                    </div>
                                                                    <div class="col-2 d-flex">
                                                                        <input type="time" ref="solhora" class="form-control form-control-sm "  v-model="trafico.solhora" />		
                                                                    </div>
                                                                </div>

                                                                <div class=" row">
                                                                    <label class="col-2 text-nowrap">Salida Aut. por</label>
                                                                    <div class="col-3 d-flex">
                                                                        <input type="text" ref="salidanombre" class="form-control form-control-sm "  v-model="trafico.salidanombre" />		
                                                                    </div>
                                                                    <div class="col-3 d-flex">
                                                                        <input type="date" ref="salidafecha" class="form-control form-control-sm "  v-model="trafico.salidafecha" />		
                                                                    </div>
                                                                    <div class="col-2 d-flex">
                                                                        <input type="time" ref="salidahora" class="form-control form-control-sm "  v-model="trafico.salidahora" />		
                                                                    </div>
                                                                    <div class="col-1 d-flex">
                                                                        <label class="text-center">Kms</label>	
                                                                    </div>
                                                                </div>

                                                                <div class=" row">
                                                                    <label class="col-2 text-nowrap">Despachado por</label>
                                                                    <div class="col-3 d-flex">
                                                                        <input type="text" ref="desnombre" class="form-control form-control-sm "  v-model="trafico.desnombre" />		
                                                                    </div>
                                                                    <div class="col-3 d-flex">
                                                                        <input type="date" ref="desfecha" class="form-control form-control-sm "  v-model="trafico.desfecha" />		
                                                                    </div>
                                                                    <div class="col-2 d-flex">
                                                                        <input type="time" ref="deshora" class="form-control form-control-sm "  v-model="trafico.deshora" />		
                                                                    </div>
                                                                    <div class="col-2 d-flex">
                                                                        <input type="number" ref="kmssalida" class="form-control form-control-sm "  v-model="trafico.kmssalida" /> <label class="text-nowrap">Salida</label>	
                                                                    </div>
                                                                </div>

                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap text-end">Terminación Hora Meta</label>
                                                                    <div class="col-2 d-flex">
                                                                        <input type="time" ref="retornohorameta" class="form-control form-control-sm "  v-model="trafico.retornohorameta" />		
                                                                    </div>
                                                                    <div class="col-3 d-flex">
                                                                        <input type="date" ref="retornofecha" class="form-control form-control-sm "  v-model="trafico.retornofecha" />		
                                                                    </div>
                                                                    <div class="col-2 d-flex">
                                                                        <input type="time" ref="retornohora" class="form-control form-control-sm "  v-model="trafico.retornohora" />		
                                                                    </div>
                                                                    <div class="col-2 d-flex">
                                                                        <input type="number" ref="kmsretorno" class="form-control form-control-sm "  v-model="trafico.kmsretorno" /> <label class="text-nowrap">Retorno</label>	
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-2 text-nowrap text-end">Bitácora Uni</label>
                                                                    <div class="col-3 d-flex">
                                                                        <input type="time" ref="bitacorauni" class="form-control form-control-sm "  v-model="trafico.bitacorauni" />		
                                                                    </div>

                                                                    <label class="col-2 text-nowrap text-end">Bitácora Ope</label>
                                                                    <div class="col-3 d-flex">
                                                                        <input type="date" ref="bitacoraope" class="form-control form-control-sm "  v-model="trafico.bitacoraope" />		
                                                                    </div>
                                                                    <div class="col-2 offset-2 d-flex">
                                                                        <input type="time" ref="recorridos" class="form-control form-control-sm "  readonly  v-model="trafico.recorridos" />		<label class="text-nowrap">Recorridos</label>	
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Factura Imp.</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="text" ref="facturaimp" class="form-control form-control-sm "  v-model="trafico.facturaimp " />		
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Sellos</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="text" ref="sellos" class="form-control form-control-sm "  v-model="trafico.sellos " />		
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Viaje</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="text" ref="viaje" class="form-control form-control-sm "  v-model="trafico.viaje " />		
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <label class="col-3 text-nowrap text-end">Tipo</label>
                                                                    <div class="col-9">
                                                                        <select ref="tipo" class="form-control form-control-sm" v-model="trafico.tipo">
                                                                            <option value="6Y">Tractor</option>
                                                                            <option value="D4">Driver</option>
                                                                            <option value="EQ">Trailer</option>
                                                                            <option value="SN">Seal Number</option>
                                                                            <option value="AG">Agent's Shipment Number</option>
                                                                            <option value="91">Total Cost</option>
                                                                            <option value="CUR">Cost currency</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <label class="col-3 text-nowrap text-end">Tipo</label>
                                                                    <div class="col-9">
                                                                        <select v-if="trafico.numparada == 1" ref="tipo" class="form-control form-control-sm" v-model="trafico.tipo">
                                                                            <option value="AB">1-Pick-up Appointment</option>
                                                                            <option value="X3">2-Arrived at pickup location</option>
                                                                            <option value="CP">3-Loaded profile-status</option>
                                                                            <option value="AF">4-Departed  pickup location</option>
                                                                        </select>
                                                                        <select v-else class="form-control form-control-sm" ref="tipo" v-model="trafico.tipo">
                                                                            <option value="AA">5-Delivery Appointment</option>
                                                                            <option value="P1">6-Departed Terminal Location</option>
                                                                            <option value="X1">7-Arrived at delivery location</option>
                                                                            <option value="CD">8-Departed delivery location</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row ">
                                                            <div class="col-12 " >
                                                                <b-table-simple hover small caption-top responsive >
                                                                    <b-thead head-variant="light" class="table-header">
                                                                        <b-tr class="table-header">
                                                                            <b-th>Pda</b-th>
                                                                            <b-th>Clave</b-th>
                                                                            <b-th>Ruta</b-th>
                                                                            <b-th>Origen</b-th>
                                                                            <b-th>Destino</b-th>
                                                                            <b-th>Unidad</b-th>
                                                                            <b-th>Oper.</b-th>
                                                                            <b-th>Uso</b-th>
                                                                            <b-th>Cga</b-th>
                                                                            <b-th>Arribo Fecha</b-th>
                                                                            <b-th>Arribo Hora</b-th>
                                                                            <b-th>Arribo Meta</b-th>
                                                                            <b-th>Despacho Nombre</b-th>
                                                                            <b-th>Despacho Fecha</b-th>
                                                                            <b-th>Despacho Hora</b-th>
                                                                            <b-th>Terminación Nombre</b-th>
                                                                            <b-th>Terminación Fecha</b-th>
                                                                            <b-th>Terminación Hora</b-th>
                                                                            <b-th>Puente Mexicano</b-th>
                                                                            <b-th>Puente Americano</b-th>
                                                                            <b-th>Aduana Mex. Fecha</b-th>
                                                                            <b-th>Aduana Mex. Hora</b-th>
                                                                            <b-th>Aduana Amer. Fecha</b-th>
                                                                            <b-th>Aduana Amer. Hora</b-th>
                                                                            <b-th>Estatus</b-th>
                                                                            <b-th>Suc</b-th>
                                                                            <b-th>Liquidación</b-th>
                                                                        </b-tr>
                                                                    </b-thead>
                                                                    <b-tbody>
                                                                        <b-tr v-for="(pda,g) in partidas" :key="g" @click="selectedPartida = pda" >
                                                                            <b-td class="text-center">{{ pda.partida }}</b-td>
                                                                            <b-td class="text-center">{{ pda.servicio }}</b-td>
                                                                            <b-td class="text-center">{{ pda.ruta }}</b-td>
                                                                            <b-td class="text-center">{{ pda.origen_desc }}</b-td>
                                                                            <b-td class="text-center">{{ pda.destino_desc }}</b-td>
                                                                            <b-td class="text-center">{{ pda.unidad }}</b-td>
                                                                            <b-td class="text-center">{{ pda.operador }}</b-td>
                                                                            <b-td class="text-center">{{ pda.utilizacion }}</b-td>
                                                                            <b-td class="text-center">{{ pda.cargado }}</b-td>
                                                                            <b-td class="text-center">{{ pda.ctearribofecha }}</b-td>
                                                                            <b-td class="text-center">{{ pda.ctearribohora }}</b-td>
                                                                            <b-td class="text-center">{{ pda.ctearribohorameta }}</b-td>
                                                                            <b-td class="text-center">{{ pda.ctedespnombre }}</b-td>
                                                                            <b-td class="text-center">{{ pda.ctedespfecha }}</b-td>
                                                                            <b-td class="text-center">{{ pda.ctedesphora }}</b-td>
                                                                            <b-td class="text-center">{{ pda.ctetermnombre }}</b-td>
                                                                            <b-td class="text-center">{{ pda.ctetermfecha }}</b-td>
                                                                            <b-td class="text-center">{{ pda.ctetermhora }}</b-td>
                                                                            <b-td class="text-center">{{ pda.ptemexicano }}</b-td>
                                                                            <b-td class="text-center">{{ pda.pteamericano }}</b-td>
                                                                            <b-td class="text-center">{{ pda.modmexfecha }}</b-td>
                                                                            <b-td class="text-center">{{ pda.modmexhora }}</b-td>
                                                                            <b-td class="text-center">{{ pda.modamefecha }}</b-td>
                                                                            <b-td class="text-center">{{ pda.modamehora }}</b-td>
                                                                            <b-td class="text-center">{{ pda.estatus }}</b-td>
                                                                            <b-td class="text-center">{{ pda.sucliquidacion }}</b-td>
                                                                            <b-td class="text-center">{{ pda.liquidacion }}</b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </div>
                                                        </div>	
                                                        <div class="row">
                                                            <div class="col-12 ">
                                                                <ul class="list-unstyled d-flex">
                                                                    <li class="pe-1"><button :disabled="Disabled_GR_Grupo2" type="button" @click="openPartidas('add')" class="btn btn-sm btn-secondary  w-100" >Agregar</button></li>
                                                                    <li class="pe-1"><button ref="BTN_Cambiar" :disabled="btnsServicios" type="button" @click="openPartidas('edit')" class="btn btn-sm btn-secondary w-100" >Cambiar</button></li>
                                                                    <li class="pe-1"><button :disabled="btnsServicios" type="button" @click="askDeletePartida()" class="btn btn-sm btn-secondary w-100">Borrar</button></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="container p-1 tab-pane fade" id="nav-comentarios" role="tabpanel" aria-labelledby="nav-comentarios-tab">
                                                        <div class="row">
                                                            <div class="col-12 form-group">
                                                                <label class="text-nowrap">Comentarios del Operador</label>
                                                                <textarea class="form-control form-control-sm" style="min-height:20vh" rows="" name="" ref="comentariosoper" v-model="trafico.comentariosoper "></textarea>													
                                                            </div>
                                                        </div>	
                                                        <div class="row">
                                                            <div class="col-12 form-group">
                                                                <label class="text-nowrap">Comentarios Generales</label>
                                                                <textarea class="form-control form-control-sm" style="min-height:20vh" rows="" name="" ref="comentarios" v-model="trafico.comentarios "></textarea>													
                                                            </div>
                                                        </div>	
                                                    </div>
                                                    <div class="container p-1 tab-pane fade" id="nav-gps" role="tabpanel" aria-labelledby="nav-gps-tab">
                                                        <div class="row ">
                                                            <div class="col-12 form-group " >
                                                                <b-table-simple hover small caption-top responsive>
                                                                    <b-thead>
                                                                        <b-tr class="table-header">
                                                                            <b-th></b-th>
                                                                            <b-th>Fecha</b-th>
                                                                            <b-th>Hora</b-th>
                                                                            <b-th>Latitud</b-th>
                                                                            <b-th>Longitud</b-th>
                                                                            <b-th>Tag</b-th>
                                                                        </b-tr>
                                                                    </b-thead>
                                                                    <b-tbody v-if="trafico.gps.length > 0">
                                                                        <b-tr v-for="(gps,g) in trafico.gps" :key="g" >
                                                                            <b-td class="text-center">{{ gps.GPS_LINEA }}</b-td>
                                                                            <b-td class="text-center">{{ gps.GPS_FECHA }}</b-td>
                                                                            <b-td class="text-center">{{ gps.GPS_HORA }}</b-td>
                                                                            <b-td class="text-center">{{ gps.GPS_POSITION_LAT }}</b-td>
                                                                            <b-td class="text-center">{{ gps.GPS_POSITION_LNG }}</b-td>
                                                                            <b-td class="text-center">{{ gps.GPS_ADDRESS }}</b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                    <b-tbody v-else>
                                                                        <b-tr  >
                                                                            <b-td class="text-center" :variant="'secondary'" colspan="6"><p class="p-4">NO HAY DATOS</p></b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </div>
                                                            <div class="col-12 form-group ">
                                                                <button class="btn btn-sm btn-outline-secondary" :disabled="trafico.gps.length <= 0">Mostrar Trayecto</button>
                                                            </div>
                                                        </div>	
                                                    </div>
                                                    <div  class="container p-1 tab-pane fade" id="nav-doctos" role="tabpanel" aria-labelledby="nav-doctos-tab">
                                                        <div class="row ">
                                                            <div class="col-12 form-group " style="padding-bottom:6rem">
                                                                <b-table-simple>
                                                                    <b-thead>
                                                                        <b-tr class="table-header">
                                                                            <b-th></b-th>
                                                                            <b-th>Documento</b-th>
                                                                            <b-th>Agregado</b-th>
                                                                        </b-tr>
                                                                    </b-thead>
                                                                    <b-tbody>
                                                                        <b-tr v-for="docto in doctos" :key="docto.EDOQ_REFERENCIA" >
                                                                            <b-td class="text-center">{{ docto.EDOQ_REFERENCIA }}</b-td>
                                                                            <b-td class="text-center">{{ docto.EDOQ_REFERENCIA }}</b-td>
                                                                            <b-td class="text-center">{{ docto.EDOQ_REFERENCIA }}</b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="col-2">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li><button @click="setTrafico()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li><router-link to="/traficos" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>

                                            <ul class="d-block p-1 list-unstyled controls-form" v-if="showBtns">
                                                <li><button @click="generaCP()" class="btn btn-sm btn-secondary btn-block">Genera CP</button></li>
                                                <li><button @click="ImprimirBol()" class="btn btn-sm btn-secondary btn-block">Imprimir Bol.</button></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <YesNoPopupVue v-show="showYesNo" @close="showYesNo = false" @aceptar="deletePartida()">
                    <template v-slot:header>
                        <h6 class="text-uppercase">{{notificationTitle}}</h6>
                    </template>
                    <template v-slot:body>
                        {{notificationText}}
                    </template>
                </YesNoPopupVue>
                <WIN_GeneraFactura v-if="openGeneraFactura" :datos="generaFactura" @close="close" />
                <Partidas v-if="showPartidas" @close="showPartidas = false" @goAhead="SetPartida" :size="40">
                    <template v-slot:title>{{tituloModo}} partida</template>
                    <template v-slot:body>
                        <div class="row">
                            <div class="col-2"><p>RUTA ORIGINAL</p></div>
                            <div class="col-3">
                                <div class="row">
                                    <label class="col-2 text-nowrap ">Folio</label>
                                    <div class="col-7">
                                        <input type="number" class="form-control form-control-sm " readonly v-model="selectedPartida.folio" />		
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="row">
                                    <label class="col-4 text-nowrap ">Partida</label>
                                    <div class="col-8">
                                        <input type="number" class="form-control form-control-sm " readonly v-model="selectedPartida.partida" />		
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="row">
                                    <label class="col-3 text-nowrap ">USO</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control form-control-sm " maxlength="1" readonly v-model="selectedPartida.utilizacion" />		
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="row">
                                    <label class="col-4 text-nowrap ">Sucursal</label>
                                    <div class="col-8">
                                        <input type="number" class="form-control form-control-sm "  readonly v-model="selectedPartida.sucursal" />		
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-10">
                                <fieldset>
                                    <div class="row">
                                        <div class="col-9">
                                            <LookUpCtrl :objeto="selectedPartida"  :label="'Servicio'" :cols="[2,2,8]" :archivo="'faServicios'"  :campo="'clave'" :descripcion="'descripcion'" :model="'selectedPartida'"  v-model="selectedPartida.servicio" />
                                        </div>
                                        <div class="col-3">
                                            <div class="row">
                                                <label class="col-4 text-nowrap ">Cantidad</label>
                                                <div class="col-8">
                                                    <input type="number" class="form-control form-control-sm "  readonly v-model="selectedPartida.cantidad" />		
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-9">
                                            <LookUpCtrl :objeto="selectedPartida"  :label="'Tpo Unidad'" :cols="[2,2,8]" :archivo="'TpoUnidad'"  :campo="'clave'" :descripcion="'descripcion'" :model="'selectedPartida'"  v-model="selectedPartida.tpounidad" />
                                        </div>
                                        <div class="col-3">
                                            <div class="row">
                                                <label class="col-4 text-nowrap ">Precio</label>
                                                <div class="col-8">
                                                    <input type="number" class="form-control form-control-sm "  readonly v-model="selectedPartida.precio" />		
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-9">
                                            <LookUpCtrl :objeto="selectedPartida"  :label="'Ruta'" :cols="[2,2,8]" :archivo="'trRutas'"  :campo="'clave'" :descripcion="'descripcion'" :model="'selectedPartida'" v-model="selectedPartida.ruta" />
                                        </div>
                                        <div class="col-3">
                                            <div class="row">
                                                <label class="col-4 text-nowrap ">Comisión</label>
                                                <div class="col-8">
                                                    <input type="number" class="form-control form-control-sm "  readonly v-model="selectedPartida.comision" />		
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-9">
                                            <LookUpCtrl :objeto="selectedPartida"  :label="'Origen'" :cols="[2,2,8]" :archivo="'TrDestinos'"  :campo="'numid'" :descripcion="'nombre'" :model="'selectedPartida'"  v-model="selectedPartida.origen" />
                                        </div>
                                        <div class="col-3">
                                            <div class="row">
                                                <div class="col-10">
                                                    <input type="number" class="form-control form-control-sm "  readonly v-model="selectedPartida.stc_origen" />		
                                                </div>
                                                <div class="col-2">
                                                    <button></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-9">
                                            <LookUpCtrl :objeto="selectedPartida"  :label="'Destino'" :cols="[2,2,8]" :archivo="'TrDestinos'"  :campo="'numid'" :descripcion="'nombre'" :model="'selectedPartida'"  v-model="selectedPartida.destino" />
                                        </div>
                                        <div class="col-3">
                                            <div class="row">
                                                <div class="col-12">
                                                    <input type="number" class="form-control form-control-sm "  readonly v-model="selectedPartida.stc_destino" />		
                                                </div>
                                                <div class="col-2">
                                                    <button></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-9">
                                            <LookUpCtrl :objeto="selectedPartida"  :label="'Origen'" :cols="[2,2,8]" :archivo="'TrDestinos'"  :campo="'numid'" :descripcion="'nombre'" :model="'selectedPartida'"  v-model="selectedPartida.origen" />
                                            <LookUpCtrl :objeto="selectedPartida"  :label="'Destino'" :cols="[2,2,8]" :archivo="'TrDestinos'"  :campo="'numid'" :descripcion="'nombre'" :model="'selectedPartida'"  v-model="selectedPartida.destino" />
                                        </div>
                                        <div class="col-3">
                                            <div class="form-check">
                                                <input class="form-check-input"  v-model="selectedPartida.autopistas "  type="checkbox" id="autopistas">
                                                <label class="form-check-label" for="autopistas">Autopistas</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input"  v-model="selectedPartida.ptemexicano "  type="checkbox" id="ptemexicano">
                                                <label class="form-check-label" for="ptemexicano">Pte Mexicano</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input"  v-model="selectedPartida.pteamericano "  type="checkbox" id="pteamericano">
                                                <label class="form-check-label" for="pteamericano">Pte Americano</label>
                                            </div>
                                        </div>
                                </fieldset>
                            </div>
                            <div class="col-2">
                                <div class="form-check">
                                    <input class="form-check-input"  v-model="selectedPartida.adicional1" type="checkbox" id="adicional1">
                                    <label class="form-check-label" for="adicional1">Adicional 1</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input"  v-model="selectedPartida.adicional2" type="checkbox" id="adicional2">
                                    <label class="form-check-label" for="adicional2">Adicional 2</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input"  v-model="selectedPartida.adicional3" type="checkbox" id="adicional3">
                                    <label class="form-check-label" for="adicional3">Adicional 3</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input"  v-model="selectedPartida.adicional4" type="checkbox" id="adicional4">
                                    <label class="form-check-label" for="adicional4">Adicional 4</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input"  v-model="selectedPartida.adicional5" type="checkbox" id="adicional5">
                                    <label class="form-check-label" for="adicional5">Adicional 5</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-9">
                                <LookUpCtrl :objeto="selectedPartida"  :label="'Remolque'" :cols="[2,2,8]" :archivo="'faServicios'"  :campo="'clave'" :descripcion="'descripcion'" :model="'servicio'" @onexit="ActivaRutaYDestino()" v-model="selectedPartida.servicio" />
                            </div>
                            <div class="col-3">
                                <div class="form-check">
                                    <input class="form-check-input"  v-model="selectedPartida.cargado "  type="checkbox" id="cargado">
                                    <label class="form-check-label" for="cargado">Cargado</label>
                                </div>
                            </div>
                        </div>
                     
                        <div class="row">
                            <label class="col-2 text-nowrap ">Remolque Externo</label>
                            <div class="col-4">
                                <input type="text" class="form-control form-control-sm " v-model="selectedPartida.unidad" />		
                            </div>
                        </div>
                         
                        <div class="row">
                            <div class="col-6">
                                <fieldset>
                                    <legend>Cliente</legend>
                                    <div class="row">
                                        <div class="col-2 offset-3 bg-secondary text-light text-center">Nombre</div>
                                        <div class="col-4 bg-secondary text-light text-center">Fecha</div>
                                        <div class="col-3 bg-secondary text-light text-center">Hora</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="row">
                                                <label class="col-3 text-nowrap text-end bold">Arribo</label>
                                                <label class="col-4 ps-1 pe-0 text-nowrap ">Hora Meta</label>
                                                <div class="col-5 p-0">
                                                    <input type="time" class="form-control form-control-sm" v-model="selectedPartida.cte_arribo_horameta" />		
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="row">
                                                <div class="col-12  p-0">
                                                    <input type="date" class="form-control form-control-sm " v-model="selectedPartida.cte_arribo_fecha" />		
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="row">
                                                <div class="col-12 p-0">
                                                    <input type="time" class="form-control form-control-sm " v-model="selectedPartida.cte_arribo_hora" />		
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="row">
                                                <label class="col-4 offset-3 p-0 text-nowrap ">Despachado</label>
                                                <div class="col-5 p-0">
                                                    <input type="text" class="form-control form-control-sm " v-model="selectedPartida.cte_des_nombre" />		
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="row">
                                                <div class="col-12 p-0">
                                                    <input type="date" class="form-control form-control-sm " v-model="selectedPartida.cte_des_fecha" />		
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="row">
                                                <div class="col-12 p-0">
                                                    <input type="time" class="form-control form-control-sm " v-model="selectedPartida.cte_des_hora" />		
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="row">
                                                <label class="col-4 offset-3 p-0  text-nowrap ">Terminación</label>
                                                <div class="col-5 p-0">
                                                    <input type="text" class="form-control form-control-sm " v-model="selectedPartida.cte_ter_nombre" />		
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="row">
                                                <div class="col-12 p-0">
                                                    <input type="date" class="form-control form-control-sm " v-model="selectedPartida.cte_ter_fecha" />		
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="row">
                                                <div class="col-12 p-0">
                                                    <input type="time" class="form-control form-control-sm " v-model="selectedPartida.cte_ter_hora" />		
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-9">
                                        <fieldset>
                                            <legend>Aduana Mexicana</legend>
                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="row">
                                                        <label class="col-4 text-nowrap ">Entrada</label>
                                                        <div class="col-8">
                                                            <input type="date" class="form-control form-control-sm " v-model="selectedPartida.modmex_fecha_ent" />		
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <input type="time" class="form-control form-control-sm " v-model="selectedPartida.modmex_hora_ent" />		
                                                </div>
                                                <div class="col-6">
                                                    <div class="row">
                                                        <label class="col-4 text-nowrap ">Salida</label>
                                                        <div class="col-8">
                                                            <input type="date" class="form-control form-control-sm " v-model="selectedPartida.modmex_fecha_sal" />		
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <input type="time" class="form-control form-control-sm " v-model="selectedPartida.modmex_hora_sal" />	
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-check">
                                            <input class="form-check-input"  v-model="selectedPartida.semafororojo" type="checkbox" id="semafororojo">
                                            <label class="form-check-label" for="semafororojo">Semáforo Rojo</label>
                                        </div>
                                    </div>    
                                    <div class="col-9">   
                                        <fieldset>
                                            <legend>Aduana Americana</legend>
                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="row">
                                                        <label class="col-4 text-nowrap ">Entrada</label>
                                                        <div class="col-8">
                                                            <input type="date" class="form-control form-control-sm " v-model="selectedPartida.modame_fecha_ent" />		
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <input type="time" class="form-control form-control-sm " v-model="selectedPartida.modame_hora_ent" />		
                                                </div>
                                                <div class="col-6">
                                                    <div class="row">
                                                        <label class="col-4 text-nowrap ">Salida</label>
                                                        <div class="col-8">
                                                            <input type="date" class="form-control form-control-sm " v-model="selectedPartida.modame_fecha_sal" />		
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <input type="time" class="form-control form-control-sm " v-model="selectedPartida.modame_hora_sal" />	
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div class="col-3">
                                    <div class="form-check">
                                        <input class="form-check-input"  v-model="selectedPartida.rayosx" type="checkbox" id="rayosx">
                                        <label class="form-check-label" for="rayosx">Rayos X</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input"  v-model="selectedPartida.dot" type="checkbox" id="dot">
                                        <label class="form-check-label" for="dot">DOT</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input"  v-model="selectedPartida.dps" type="checkbox" id="dps">
                                        <label class="form-check-label" for="dps">DPS</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="row">
                                    <label class="col-4 text-nowrap text-right">Pte Mexicano</label>
                                    <div class="col-4">
                                        <select class="form-control form-control-sm" v-model="element">
                                            <option value=""></option>
                                            <option value="Efectivo">Efectivo</option>
                                            <option value="IAVE">IAVE</option>
                                        </select>
                                    </div>
                                    <div class="col-4">
                                        <input type="number" class="form-control text-end form-control-sm" v-model="selectedPartida.pmexicano_desc" />		
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <label class="col-4 text-nowrap text-right">Pte Americano</label>
                                    <div class="col-4">
                                        <select class="form-control form-control-sm" v-model="element">
                                            <option value=""></option>
                                            <option value="Efectivo">Efectivo</option>
                                            <option value="Ticket">Ticket</option>
                                            <option value="Engomado">Engomado</option>
                                        </select>
                                    </div>
                                    <div class="col-4">
                                        <input type="number" class="form-control text-end form-control-sm"  v-model="selectedPartida.pamericano_desc" />		
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </Partidas>
            </div>
        </div>
    </section>
</template>

<script>
import LookUpCtrl from '@/components/LookUpCtrl.vue';
import WIN_GeneraFactura from '@/components/WIN_GeneraFactura.vue';
import handleForm from '@/mixins/handleForm';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'
import Partidas from '@/components/PopupWindow.vue';
import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
import YesNoPopupVue from '@/components/YesNoPopup.vue';
export default {
    components:{SideBar,TopBar, LookUpCtrl,LoadingContent ,WIN_GeneraFactura,YesNoPopupVue,Partidas},
    mixins:[handleForm,handleGlobals],
    data(){
        return{
            id: this.$route.params.trafico,
            modo: (this.$route.params.trafico > 0 ? 'Modificando' : 'Creacion'), 
            trafico: [],
            condiciones: [],
            metodospago: [],
            showBtns:true,
            showYesNo:false,
            complementos: [],
            bancos: [],
            doctos: [],
            titulo: 'Agregando un Tráfico',
            State_Tipo: false,
            State_CLIENTEID:false,
            Visible_REGIDTRIB:false,
            partidas:[],
            selectedPartida:[],
            generaFactura:[],
            openGeneraFactura:false,
            showPartidas:false
        }
    },
    computed:{
        cbox_sinimptocero:{
            get(){
                return this.trafico.sinimptocero == 'S' ? true : false
            },
            set(val){
                return val 
            }  
        },
        cbox_obsoleto:{
            get(){
                return this.trafico.obsoleto == 'S' ? true : false
            },
            set(val){
                return val
            }  
        },
    },
    mounted(){
        this.llenaForma();
    },
    methods:{
        async llenaForma(){
            await this.getTrafico();
            if(this.$route.params.trafico > 0){
                await this.llenaPartidas();
                this.titulo = 'Cambiando un Tráfico';
            }else{
                this.trafico.sucursal = this.$store.state.gnSucursal
                this.trafico.tasaiva  = this.$store.state.gnIva
                this.trafico.pfm  = "M"
                this.trafico.tipo = "C"	
                this.State_CLIENTEID = true	
                this.trafico.pais = "MEXICO"
                this.trafico.condicion = 1
                this.trafico.diaspago = ""
            }
        },
        async getTrafico(){
            this.isLoading = true
            let req = []
           await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "inicializar", modo: this.modo , archivo: "Trafico", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.trafico = data['res'];
                req = data['req']
            }).finally(()=>{
                this.setRequired(req)
                this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        async llenaPartidas(){
           await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "metodo", archivo: "Trafico", metodo:'getPartidas',params: {id:this.id} })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.partidas = data;
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        generaCP(){
            if(this.selectedPartida != ''){
                this.notificationText = "La Carta Porte debe ser por el visor"
                this.notificationTitle = "Error"
                this.showNotification = true
                return
            }

            if(this.selectedPartida.remision > 0){
                this.notificationText = "La Carta Porte para este servicio ya se generó: "+this.$options.filters.leadingZeros(this.selectedPartida.remision,7)
                this.notificationTitle = "Error"
                this.showNotification = true
                return
            }
            this.RemisionarTMQ()
        },
        close(){
            this.openGeneraFactura = false 
        },
        ImprimirBol(){
            if(this.trafico.desfecha == ""){
                this.notificationText = "No hay fecha de despacho en Encabezado"
                this.notificationTitle = "Error"
                this.showNotification = true
                return
            }
           // iPreview(i100)
           // iPrintReport(RPT_BoletaExp_TMQ,(TRA_SUCURSAL),(TRA_FOLIO),(COL_PARTIDA))
        },
        RemisionarTMQ(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "metodo", metodo: 'RemisionarTMQ' , archivo: "Trafico", params: {folio: this.trafico.folio,partida:this.selectedPartida.partida }})
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.generaFactura.tipo = data['tipo']
                this.generaFactura.cfd = data['cfd']
                this.generaFactura.convertir = data['convertir']
                this.generaFactura.tc = data['tc']
                this.generaFactura.cservicios = data['cservicios']
                this.generaFactura.trafico = this.trafico
                this.generaFactura.partida = this.selectedPartida
                this.openGeneraFactura = true
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setTrafico(){
            if(this.modo == "Modificando"){
                if(this.trafico.tipo  == 'C'){
                    if(this.trafico.direccion == ''){
                        this.trafico.direccion.focus()
                    } 
                    if(this.trafico.ciudad == ''){
                        this.trafico.ciudad.focus()
                    } 
                    if(this.trafico.estado == ''){
                        this.trafico.estado.focus()
                    } 
                    if(this.trafico.pais == ''){
                        this.trafico.pais.focus()
                    } 
                    if(this.trafico.rfc == ''){
                        this.trafico.rfc.focus()
                    } 
                    if(this.trafico.cpostal == ''){
                        this.trafico.cpostal.focus()
                    } 
                    if(this.trafico.correo == ''){
                        this.trafico.correo.focus()
                    } 
                }
                console.log(this.trafico)
            }else{
                fetch(this.$api+'/rdx', {
                    method: "POST",
                    headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                    body: JSON.stringify({ accion: "agregar", archivo: "Trafico", record: this.trafico })
                }).then((response)=>{
                    if(response.ok){
                        return response.json();
                    }   
                }).then((data)=>{
                    if(data['o-']){
                        this.showNotification = true
                        this.notificationTitle = 'Advertencia'
                        this.notificationText = "El Registro ha cambiando desde que abrio la ventana. ¿ Que desea hacer ?"
                    }
                    this.$router.push('/traficos')
                }).catch((err)=>{
                    this.falseToken(err)
                });
            }
        },
        askDeletePartida(){
            if(this.partidas.length < 1) {
                this.showNotification = true
                this.notificationTitle = 'Error'
                this.notificationText = "No hay ninguna partida para Borrar"
                return
            }

            if(!this.selectedPartida){
                this.showNotification = true
                this.notificationTitle = 'Error'
                this.notificationText = "No hay ninguna partida seleccionada"
                return
            }

            if(this.selectedPartida.remision > 0 ){
                this.showNotification = true
                this.notificationTitle = 'Error'
                this.notificationText = "No es posible borrar esta partida pq ya tiene una remision asignada"
                return
            }

            if(this.selectedPartida.generado == true){
                if(this.selectedPartida.servicio == this.trafico.servicio ){
                    this.showNotification = true
                    this.notificationTitle = 'Error'
                    this.notificationText = "No puedes borrar una partida generada por la ruta original."
                    return
                }
            }

            this.showYesNo = true
            this.notificationTitle = 'Advertencia'
            this.notificationText = "¿Estás seguro que deseas eliminar esa partida ?"
        },
        openPartidas(modo){
            if(this.trafico.cliente == 0){
                this.notificationText = "Necesita capturar un cliente antes de asignar partidas"
                this.notificationTitle = "Error"
                this.showNotification = true
                return
            }
            let gnCliente = 0
            if(this.trafico.division > 0) gnCliente = this.trafico.division
            console.log(gnCliente)
            if(modo != 'add') this.selectedPartida = {}
            this.showPartidas = true
        },
        SetPartida(){

        },
        deletePartida(){
            this.partidas.splice(this.selectedPartida.indx,1)

            if(this.selectedPartida.indx < this.partidas.length){
                this.selectedPartida[this.selectedPartida.indx].origen = this.selectedPartida[this.selectedPartida.indx-1].destino
                this.selectedPartida[this.selectedPartida.indx].origen_desc = this.selectedPartida[this.selectedPartida.indx-1].destino_desc
            }

            this.partidas.forEach((e,indx)=>{
                e.partida = indx
            })
        },
        ActivaRutaYDestino(){
          //  HReadSeekFirst(FaServicios,SVC_CLAVE,CTPL_SERVICIO.LUP_CAMPO,hLimitParsing)
          //  IF FaServicios.SVC_FLETE = "S" THEN
          //      CTPL_RUTA..Visible = True
          //      CTPL_ORIGEN..State = Active
          //      CTPL_DESTINO..State = Active
          //  ELSE
          //      CTPL_RUTA..Visible = False
          //      CTPL_ORIGEN..State = Grayed
          //      CTPL_DESTINO..State = Grayed	
          //  END

        }
    }
}
</script>