<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Archivo de Facturas</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-10 position-relative" >
                                            <form>
                                                <nav>
                                                    <div class="nav nav-underline" id="nav-tab" role="tablist">
                                                        <li class="nav-item"><a class="nav-link active" id="nav-general-tab" @click="tab='general'"  data-bs-toggle="tab" href="#nav-general" role="tab" aria-controls="nav-general" aria-selected="true">General</a></li>
                                                        <li class="nav-item"><a class="nav-link" @click="tab = 'servicios'" id="nav-servicios-tab" data-bs-toggle="tab" href="#nav-servicios" role="tab" aria-controls="nav-servicios" aria-selected="false">Servicios</a></li>
                                                        <li class="nav-item"><a class="nav-link" @click="tab = 'notas'" id="nav-notas-tab" data-bs-toggle="tab" href="#nav-notas" role="tab" aria-controls="nav-notas" aria-selected="false">Notas</a></li>
                                                        <li class="nav-item"><a class="nav-link" @click="tab = 'cartaporte'" id="nav-cartaporte-tab" data-bs-toggle="tab" href="#nav-cartaporte" role="tab" aria-controls="nav-cartaporte" aria-selected="false">Carta Porte</a></li>
                                                        <li class="nav-item"><a v-if="transacciones_visible" class="nav-link" @click="tab = 'transacciones'" id="nav-transacciones-tab" data-bs-toggle="tab" href="#nav-transacciones" role="tab" aria-controls="nav-transacciones" aria-selected="false">Transacciones</a></li>
                                                        <li class="nav-item"><a class="nav-link" @click="tab = 'doctos'" id="nav-doctos-tab" data-bs-toggle="tab" href="#nav-doctos" role="tab" aria-controls="nav-doctos" aria-selected="false">Doctos</a></li>
                                                    </div>
                                                </nav>
                                                <div class="row position-absolute w-30" style="top:0;right: 0;">
                                                    <div class="col-12 col-md-6">
                                                        <label class="col-3 text-nowrap ">Suc. {{ factura.sucursal | leadingZeros(3) }}</label>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <div class=" row d-none">
                                                            <div class="form-check" >
                                                                <input class="form-check-input"  v-model="factura.cservicios" :disabled="Disabled_GR_Grupo2" type="checkbox" id="cservicios">
                                                                <label class="form-check-label" for="cservicios">
                                                                    Catálogo de Servicios
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class=" row">
                                                            <div class="col-3">
                                                                <div class=" row">
                                                                    <div class="form-check form-check-small" v-if="tab == 'general'" >
                                                                        <input class="form-check-input" :disabled="Disabled_GR_Grupo2" v-model="factura.nofiscal"  type="checkbox" id="nofiscal">
                                                                        <label class="form-check-label" for="nofiscal">
                                                                            NF
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>	
                                                </div>	
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="container p-1 tab-pane fade show active" id="nav-general" role="tabpanel" aria-labelledby="nav-general-tab">
                                                        <div class="row ">
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap ">Factura</label>
                                                                    <div class="col-3 pe-0 ">
                                                                        <input type="number" ref="serie" class="form-control form-control-sm" :disabled="(factura.serie_disabled ? factura.serie_disabled :  Disabled_GR_Grupo2)" v-model="factura.serie" />
                                                                    </div>
                                                                    <div class="col-4 pe-0 ">
                                                                        <input type="number" ref="folio" class="form-control form-control-sm" :disabled="Disabled_GR_Grupo2" v-model="factura.folio" @blur="factura.factura = (factura.serie * 100000) + factura.folio" />
                                                                    </div>
                                                                    <div class="col-2 pe-0">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input" ref="cfd" :disabled="Disabled_CFD" @change="getFoliosDigitales()" v-model="factura.cfd" type="checkbox" id="cfd">
                                                                            <label class="form-check-label" for="cfd">CFD</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
    
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap text-end">Fecha</label>
                                                                    <div class="col-8">
                                                                        <input type="date" ref="fecha" class="form-control form-control-sm" :disabled="Disabled_GR_Grupo2" v-model="ctrl_fecha " />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap text-end">Cond. Pago</label>
                                                                    <div class="col-8 ">
                                                                        <select class="form-control form-control-sm" :disabled="Disabled_GR_Grupo2" ref="condiciones" v-model="factura.condicionespago">
                                                                            <option v-for="cond in condiciones" :key="cond.condno" :value="cond.condno">{{cond.descripcion}}</option>
                                                                        </select>
                                                                    </div>	
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="row">
                                                            <div class="col-6">
                                                                <LookUpCtrl v-if="factura.factura && factura.usadestinos == 'S'" :disabled="Disabled_GR_Grupo2" :objeto="factura" :label="'Remitente'" :cols="[2,2,8]" :archivo="'TrDestinos'"  :campo="'numid'" :descripcion="'rfc'"         :model="'remitente'"  v-model="factura.remitente" @onexit="exitRemitente()" />
                                                                <LookUpCtrl v-else-if="factura.factura && factura.usadestinos != 'S'" :disabled="Disabled_GR_Grupo2" :objeto="factura"  :label="'Remitente'" :cols="[2,2,8]" :archivo="'Clientes'"  :campo="'clienteid'" :descripcion="'rfc'" :model="'remitente'"  v-model="factura.remitente" @onexit="exitRemitente()" />
                                                            </div>

                                                            <div class="col-6">
                                                                <LookUpCtrl v-if="factura.factura && factura.usadestinos == 'S'" :disabled="Disabled_GR_Grupo2" :objeto="factura"  :label="'Destinatario'" :cols="[2,2,8]" :archivo="'TrDestinos'"  :campo="'numid'" :descripcion="'rfc'"         :model="'destinatario'" @onexit="exitDestinatario()" v-model="factura.destinatario" />
                                                                <LookUpCtrl v-else-if="factura.factura && factura.usadestinos != 'S'" :disabled="Disabled_GR_Grupo2" :objeto="factura"  :label="'Destinatario'" :cols="[2,2,8]" :archivo="'Clientes'"  :campo="'clienteid'" :descripcion="'rfc'"  :model="'destinatario'" @onexit="exitDestinatario()" v-model="factura.destinatario" />
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-6">
                                                                <div class=" row">
                                                                    <div class="col-2 text-end">
                                                                        <button type="button" :disabled="Disabled_GR_Grupo2" @click="CambiosR('R')" class="btn btn-sm selector btn-outline-secondary ">...</button>
                                                                    </div>
                                                                    <div class="col-10  ">
                                                                        <input ref="origennombre" type="text" class="form-control  form-control-sm" :disabled="Disabled_GR_Grupo2" readonly v-model="factura.origennombre " />
                                                                        <input ref="origendireccion" type="text" class="form-control  form-control-sm" :disabled="Disabled_GR_Grupo2" readonly v-model="factura.origendireccion " />
                                                                    </div>	
                                                                </div>
                                                            </div>
                                                            <div class="col-6">
                                                                <div class=" row">
                                                                    <div class="col-2  text-end">
                                                                        <button type="button" :disabled="Disabled_GR_Grupo2" @click="CambiosR('D')" class="btn btn-sm selector btn-outline-secondary ">...</button>
                                                                    </div>
                                                                    <div class="col-10  ">
                                                                        <input ref="destinonombre" type="text" class="form-control  form-control-sm" :disabled="Disabled_GR_Grupo2" readonly v-model="factura.destinonombre " />
                                                                        <input ref="destinodireccion" type="text" class="form-control  form-control-sm" :disabled="Disabled_GR_Grupo2" readonly v-model="factura.destinodireccion " />
                                                                    </div>	
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="row ">
                                                            <div class="col-md-6 col-12">
                                                                <div class=" row">
                                                                    <label class="col-2 text-nowrap">Origen</label>
                                                                    <div class="col-10 d-flex">
                                                                        <input ref="origen" type="text" class="form-control form-control-sm " :disabled="Disabled_GR_Grupo2" v-model="factura.origen" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-12">
                                                                <div class=" row">
                                                                    <label class="col-2 text-nowrap">Destino</label>
                                                                    <div class="col-10 d-flex">
                                                                        <input ref="destino" type="text" class="form-control form-control-sm " :disabled="Disabled_GR_Grupo2" v-model="factura.destino" />		
                                                                    </div>
                                                                    <button type="button" @click="GetDatosTCA()" v-if="BTN_Trancasa_visible" class="btn btn-sm selector btn-outline-secondary trancasa">...</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row ">
                                                            <div class="col-md-6 col-12">
                                                                <div class=" row">
                                                                    <label class="col-2 text-nowrap">Recoger en</label>
                                                                    <div class="col-10 d-flex">
                                                                        <input ref="recogeren" type="text" class="form-control form-control-sm " :disabled="Disabled_GR_Grupo2" v-model="factura.recogeren" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-12">
                                                                <div class=" row">
                                                                    <label class="col-2 text-nowrap">Entregar en</label>
                                                                    <div class="col-10 d-flex">
                                                                        <input ref="entregaren" type="text" class="form-control form-control-sm " :disabled="Disabled_GR_Grupo2" v-model="factura.entregaren" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="row ">
                                                            <div class="col-12 ">
                                                                <div class=" row">
                                                                    <div class="col-6">
                                                                        <div class=" row">
                                                                            <label class="col-2 text-nowrap">Pedimento</label>
                                                                            <div class="col-10">
                                                                                <input ref="pedimento" type="text" class="form-control form-control-sm " :disabled="Disabled_GR_Grupo2" v-model="factura.pedimento" />		
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <LookUpCtrl :label="'Cliente'" :disabled="Disabled_GR_Grupo2" v-if="factura.factura" :objeto="factura"  :cols="[2,8,2]" :archivo="'Clientes'"  :campo="'clienteid'" :descripcion="'nombre'" :model="'cliente'"  v-model="factura.cliente" />
                                                                    </div>
                                                                    <div v-show="mode == 'Creacion'" @dragover.prevent @drop.prevent class="border">
                                                                        <input type="file" class="d-none" id="uploadxml" @change="requestUploadFile" />
                                                                        <div @drop="dragFile" style="background-color:green;margin-bottom:10px;padding:10px;"  @click="importarXML()" class="btn-outline-secondary btn comprobante">
                                                                            IMPORTAR<br>XML
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row ">
                                                            <div class="col-12 col-md-6">
                                                                <LookUpCtrl :label="'Operador'" v-if="factura.factura" :objeto="factura"  :cols="[2,2,8]" :archivo="'Operadores'" :disabled="Operador_status || Cancelada" :campo="'numero'" :descripcion="'nombre'" :model="'operador'" v-model="factura.operador " />
                                                            </div>
                                                            <div class="col-12 col-md-6">
                                                                <div class=" row">
                                                                    <div class="col-6">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap">Viaje</label>
                                                                            <div class="col-8 d-flex">
                                                                                <input ref="viaje" type="text" class="form-control form-control-sm " :disabled="Disabled_GR_Grupo2" v-model="factura.viaje" />		
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row ">
                                                            <div class="col-12 col-md-6">
                                                                <LookUpCtrl :label="'Unidad'" v-if="factura.factura" :objeto="factura"  :cols="[2,2,8]" :archivo="'Vehiculos'" :disabled="tractor_D" :campo="'numero'" :descripcion="'placa,marca,modelo,ano'" :model="'tractor'" v-model="factura.tractor " />
                                                            </div>
                                                            <div class="col-12 col-md-6">
                                                                <div class=" row">
                                                                    <div class="col-6">
                                                                        <LookUpCtrl :label="'Moneda'" :reference="'moneda'" :disabled="Disabled_GR_Grupo2" v-if="factura.factura" :objeto="factura" :cols="[4,3,5]" :archivo="'Monedas'" :campo="'clave'" :descripcion="'descripcion'" :model="'moneda'"  v-model="factura.moneda" @onexit="exitMoneda()" />
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class=" row">
                                                                            <label class="col-5 text-nowrap text-end">T.Cambio</label>
                                                                            <div class="col-7 d-flex">
                                                                                <input ref="tipocambio" type="text" :disabled="Disabled_GR_Grupo2" readonly class="form-control form-control-sm " v-model="factura.tipocambio" />		
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row ">
                                                            <div class="col-3">
                                                                <LookUpCtrl :label="'Remolque'" :disabled="Disabled_GR_Grupo2" v-if="factura.factura" :objeto="factura"  :cols="[4,6,2]" :archivo="'Vehiculos'" :hideDescripcion="true" :campo="'economico'" :descripcion="''" :model="'remolque1'"  v-model="factura.remolque1" />
                                                            </div>
                                                            <div class="col-3">
                                                                <LookUpCtrl :label="'Dolly'" :disabled="Disabled_GR_Grupo2" v-if="factura.factura" :objeto="factura"  :cols="[4,6,2]" :archivo="'Vehiculos'" :hideDescripcion="true" :campo="'economico'" :descripcion="''" :model="'dolly'"  v-model="factura.dolly" />
                                                            </div>
                                                            <div class="col-3">
                                                                <LookUpCtrl :label="'Remolque 2'" :disabled="Disabled_GR_Grupo2" v-if="factura.factura" :objeto="factura" :cols="[4,6,2]" :archivo="'Vehiculos'" :hideDescripcion="true" :campo="'economico'" :descripcion="''" :model="'remolque2'"  v-model="factura.remolque2" />
                                                            </div>
                                                            <div class="col-3">
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap text-end">Kms</label>
                                                                    <div class="col-8  d-flex">
                                                                        <input ref="kms" type="text" class="form-control  form-control-sm" :disabled="Disabled_GR_Grupo2" v-model="factura.kms " />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class=" row">
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap ">Fracción</label>
                                                                    <div class="col-9 pe-0 d-flex">
                                                                        <input ref="fraccion" type="text" class="form-control  form-control-sm" :disabled="Disabled_GR_Grupo2" v-model="factura.fraccion " />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap text-end">Cuota x Tonelada</label>
                                                                    <div class="col-8 pe-0 d-flex">
                                                                        <CurrencyInput ref="cuotaxtonelada" :disabled="Disabled_GR_Grupo2" v-model="factura.cuotaxtonelada " @blur="GeneraServicioFlete()" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap text-end">Valor Declarado</label>
                                                                    <div class="col-8 d-flex">
                                                                        <CurrencyInput ref="valordeclarado" :disabled="Disabled_GR_Grupo2" @blur="GetTotal()" v-model="factura.valordeclarado " />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class=" row">
                                                            <div class="col-4"></div>
                                                            <div class="col-8">
                                                                <div class="row">
                                                                    <div class="col-6"  v-if="GR_Bonificacion">
                                                                        <div class=" row">
                                                                            <label class="col-5 text-end">Retención Cuota Sindical</label>
                                                                            <div class="col-7 pe-0 d-flex">
                                                                                <CurrencyInput ref="bonifxcuotap" :disabled="Disabled_GR_Grupo2" v-model="factura.bonifxcuota_porc " @blur="BonificaxCuota()" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-3"  v-if="GR_Bonificacion">
                                                                        <div class=" row">
                                                                            <div class="col-12 pe-0 d-flex">
                                                                                <CurrencyInput ref="bonifxcuota" :disabled="Disabled_GR_Grupo2" v-model="factura.bonifxcuota " />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-3" :class="[GR_Bonificacion ? '' : 'offset-9']">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap text-end">%IVA</label>
                                                                            <div class="col-7 pe-0 d-flex">
                                                                                <CurrencyInput ref="piva" :disabled="Disabled_GR_Grupo2" v-model="factura.piva " @blur="GetTotal()" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class=" row">
                                                            <div class="col-8 ">
                                                                <div class=" row ">
                                                                    <div class="col-md-6 col-12">
                                                                        <div class=" row" v-show="false">
                                                                            <div class="row">
                                                                                <label class="col-3 text-nowrap text-right">Relación</label>
                                                                                <div class="col-9">
                                                                                    <select class="form-control form-control-sm" v-model="factura.relacion">
                                                                                        <option value=""></option>
                                                                                        <option v-for="(string, key) in array" :key="key" :value="element">{{element}}</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 ">
                                                                        <h4 class="text-danger" v-if="Cancelada">FACTURA CANCELADA</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap ">Subtotal</label>
                                                                    <div class="col-8">
                                                                        <CurrencyInput readonly v-model="factura.subtotal" />
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap ">Descuento</label>
                                                                    <div class="col-8">
                                                                        <CurrencyInput readonly v-model="factura.descuento" />
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap ">IVA</label>
                                                                    <div class="col-8">
                                                                        <CurrencyInput readonly v-model="factura.iva" />
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <div class="form-check" style="position:absolute; margin-left:-7px">
                                                                        <input class="form-check-input"  v-model="factura.retener"  :disabled="Disabled_GR_Grupo2"  @change="GetTotal()" type="checkbox" id="ret">
                                                                    </div>
                                                                    <label class="col-4 text-nowrap ">RET</label>
                                                                    <div class="col-8" style="position:relative">
                                                                        <CurrencyInput v-if="!otrasretenciones_mostrar"  readonly v-model="factura.retencion" />
                                                                        <CurrencyInput v-if="otrasretenciones_mostrar" readonly v-model="factura.retencion" />
                                                                        <div class="form-check" v-if="Show_RET5MILLAR" style="position: absolute; right: -78px;top: 3px;">
                                                                            <input class="form-check-input" :disabled="Disabled_GR_Grupo2" v-model="factura.ret5millar" type="checkbox" id="ret5millar">
                                                                            <label class="form-check-label" for="ret5millar">
                                                                                5 al Millar
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap">Total</label>
                                                                    <div class="col-8">
                                                                        <CurrencyInput readonly v-model="factura.total" />
                                                                        <div class="form-check" v-if="Show_retnom" style="position: absolute; right: -78px;top: 3px;">
                                                                            <input class="form-check-input" :disabled="Disabled_GR_Grupo2" @change="retnom_procedure()" v-model="factura.retnom" type="checkbox" id="retnom">
                                                                            <label class="form-check-label" for="retnom">
                                                                                Ret.Nom.
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="container p-1 tab-pane fade" id="nav-servicios" role="tabpanel" aria-labelledby="nav-servicios-tab">
                                                        <div class="row ">
                                                            <div class="col-12 form-group " style="padding-bottom:6rem">
                                                                <b-table-simple hover small caption-top responsive>
                                                                    <b-thead  head-variant="light"  class="table-header">
                                                                        <b-tr class="table-header">
                                                                            <b-th>Servicio</b-th>
                                                                            <b-th>Cantidad</b-th>
                                                                            <b-th>Remisiones</b-th>
                                                                            <b-th>Unidad</b-th>
                                                                            <b-th>CCosto</b-th>
                                                                            <b-th>Peso</b-th>
                                                                            <b-th>Descripción</b-th>
                                                                            <b-th>Unitario</b-th>
                                                                            <b-th>Importe</b-th>
                                                                            <b-th>Descuento</b-th>
                                                                            <b-th>% IVA</b-th>
                                                                            <b-th>Exe</b-th>
                                                                            <b-th>Impuesto</b-th>
                                                                            <b-th>Retención</b-th>
                                                                            <b-th>Total</b-th>
                                                                            <b-th>Neto</b-th>
                                                                        </b-tr>
                                                                    </b-thead>
                                                               
                                                                    <b-tbody v-if="servicios.length > 0">
                                                                        <b-tr v-for="(servicio,x) in servicios"  
                                                                                :key="x" 
                                                                                :class="{'selected-row': (selectedServicio.indx == x) }" 
                                                                                @click="selectedServicio=servicio;selectedServicio.indx = x ;actionSel = false" 
                                                                                @dblclick="opcionesServicio()" >
                                                                            <b-td class="text-center">{{ servicio.servicio }}</b-td>
                                                                            <b-td class="text-end">{{ servicio.cantidad | toCurrency}}</b-td>
                                                                            <b-td class="text-end">{{ servicio.remisiones }}</b-td>
                                                                            <b-td class="text-start">{{ servicio.unidad }}</b-td>
                                                                            <b-td class="text-center">{{ servicio.ccosto }}</b-td>
                                                                            <b-td class="text-end">{{ servicio.peso | toCurrency | important }}</b-td>
                                                                            <b-td class="text-start">{{ servicio.descripcion }}</b-td>
                                                                            <b-td class="text-end">{{ servicio.unitario | toCurrency | important }}</b-td>
                                                                            <b-td class="text-end">{{ servicio.importe | toCurrency | important }}</b-td>
                                                                            <b-td class="text-end">{{ servicio.descuento | toCurrency | important }}</b-td>
                                                                            <b-td class="text-end">{{ servicio.iva | toCurrency }}</b-td>
                                                                            <b-td class="text-end">{{ servicio.exe }}</b-td>
                                                                            <b-td class="text-end">{{ servicio.impuesto  | toCurrency }}</b-td>
                                                                            <b-td class="text-end">{{ servicio.ret | toCurrency }}</b-td>
                                                                            <b-td class="text-end">{{ servicio.total | toCurrency }}</b-td>
                                                                            <b-td class="text-end">{{ servicio.neto  | toCurrency }}</b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                    <b-tbody v-else>
                                                                        <b-tr>
                                                                            <b-td class="text-center" :variant="'secondary'" colspan="16"><p class="p-4">NO HAY DATOS</p></b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                    <b-tfoot v-if="servicios.length > 0">
                                                                        <b-tr>
                                                                            <b-td class="text-lrft">Total </b-td>
                                                                            <b-td class="text-end" colspan="5">{{ servicioTotalPeso | toCurrency | important }}</b-td>
                                                                            <b-td class="text-end" colspan="3">{{ servicioTotalImporte | toCurrency | important }}</b-td>
                                                                            <b-td class="text-end">{{ servicioTotalDescuento | toCurrency | important }}</b-td>
                                                                            <b-td class="text-end" colspan=3>{{ servicioTotalImpuesto  | toCurrency  | important  }}</b-td>
                                                                            <b-td class="text-end">{{ c   | toCurrency | important  }}</b-td>
                                                                            <b-td class="text-end">{{ servicioTotaltotal | toCurrency | important }}</b-td>
                                                                            <b-td class="text-end">{{ servicioTotalNeto  | toCurrency  | important  }}</b-td>
                                                                        </b-tr>
                                                                    </b-tfoot>
                                                                </b-table-simple>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-9">
                                                                <ul class="list-unstyled d-flex">
                                                                    <li class="pe-1"><button :disabled="Disabled_GR_Grupo2" type="button" @click="openPartidas('add')" class="btn btn-sm btn-secondary  w-100" >Agregar</button></li>
                                                                    <li class="pe-1"><button ref="BTN_Cambiar" :disabled="btnsServicios" type="button" @click="openPartidas('edit')" class="btn btn-sm btn-secondary w-100" >Cambiar</button></li>
                                                                    <li class="pe-1"><button :disabled="btnsServicios" type="button" @click="deletePartida()" class="btn btn-sm btn-secondary w-100">Borrar</button></li>
                                                                </ul>
                                                            </div>
                                                            <div class="col-3">
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <button type="button" :disabled="Disabled_GR_Grupo2" class="btn btn-sm btn-secondary" @click="AgregarAnticipo()" >Agregar Anticipo</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="container p-1 tab-pane fade" id="nav-notas" role="tabpanel" aria-labelledby="nav-notas-tab">
                                                        <div class="row ">
                                                            <div class="col-12 form-group">
                                                                <label class="text-nowrap">Contenido</label>
                                                                <textarea class="form-control form-control-sm" style="min-height:20vh" rows="" name="" :disabled="Disabled_GR_Grupo2" v-model="factura.observaciones "></textarea>													
                                                            </div>	
                                                        </div>	
                                                        <div class="row ">
                                                            <div class="col-12 form-group">
                                                                <label class="text-nowrap">Observaciones</label>
                                                                <textarea class="form-control form-control-sm" style="min-height:10vh" rows="" name="" :disabled="Disabled_GR_Grupo2" v-model="factura.notas "></textarea>													
                                                            </div>	
                                                        </div>	
                                                        <div class="row ">
                                                            <div class="col-12 form-group">
                                                                <label class="text-nowrap">Conceptos</label>
                                                                <textarea class="form-control form-control-sm" style="min-height:5vh" rows="" name="" v-model="factura.conceptos "></textarea>													
                                                            </div>	
                                                        </div>	
                                                    
                                                    </div>
                                                    <div  class="container p-1 tab-pane fade" id="nav-cartaporte" role="tabpanel" aria-labelledby="nav-cartaporte-tab">
                                                        <div class="row">
                                                            <div class="col-md-8 col-12">
                                                                <div class=" row">
                                                                    <label class="col-2 text-nowrap ">Conf. Veh.</label>
                                                                    <div class="col-10 ps-0">
                                                                        <select class="form-control form-control-sm" :disabled="Disabled_GR_Grupo2" v-model="factura.confveh">
                                                                            <option value=""></option>
                                                                            <option value="VL">VL - Vehículo ligero de carga (2 llantas en el eje delantero y 2 llantas en el eje trasero)</option>
                                                                            <option value="C2">C2 - Camión Unitario (2 llantas en el eje delantero y 4 llantas en el eje trasero)</option>
                                                                            <option value="C3">C3 - Camión Unitario (2 llantas en el eje delantero y 6 o 8 llantas en los dos ejes traseros)</option>
                                                                            <option value="C2R2">C2R2 - Camión-Remolque (6 llantas en el camión y 8 llantas en remolque)</option>
                                                                            <option value="C3R2">C3R2 - Camión-Remolque (10 llantas en el camión y 8 llantas en remolque)</option>
                                                                            <option value="C2R3">C2R3 - Camión-Remolque (6 llantas en el camión y 12 llantas en remolque)</option>
                                                                            <option value="C3R3">C3R3 - Camión-Remolque (10 llantas en el camión y 12 llantas en remolque)</option>
                                                                            <option value="T2S1">T2S1 - Tractocamión Articulado (6 llantas en el tractocamión, 4 llantas en el semirremolque)</option>
                                                                            <option value="T2S2">T2S2 - Tractocamión Articulado (6 llantas en el tractocamión, 8 llantas en el semirremolque)</option>
                                                                            <option value="T2S3">T2S3 - Tractocamión Articulado (6 llantas en el tractocamión, 12 llantas en el semirremolque)</option>
                                                                            <option value="T3S1">T3S1 - Tractocamión Articulado (10 llantas en el tractocamión, 4 llantas en el semirremolque)</option>
                                                                            <option value="T3S2">T3S2 - Tractocamión Articulado (10 llantas en el tractocamión, 8 llantas en el semirremolque)</option>
                                                                            <option value="T3S3">T3S3 - Tractocamión Articulado (10 llantas en el tractocamión, 12 llantas en el semirremolque)</option>
                                                                            <option value="T2S1R2">T2S1R2 - Tractocamión Semirremolque-Remolque (6 llantas en el tractocamión, 4 llantas en el semirremolque y 8 llantas en el remolque)</option>
                                                                            <option value="T2S2R2">T2S2R2 - Tractocamión Semirremolque-Remolque (6 llantas en el tractocamión, 8 llantas en el semirremolque y 8 llantas en el remolque)</option>
                                                                            <option value="T2S1R3">T2S1R3 - Tractocamión Semirremolque-Remolque (6 llantas en el tractocamión, 4 llantas en el semirremolque y 12 llantas en el remolque)</option>
                                                                            <option value="T3S1R2">T3S1R2 - Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 4 llantas en el semirremolque y 8 llantas en el remolque)</option>
                                                                            <option value="T3S1R3">T3S1R3 - Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 4 llantas en el semirremolque y 12 llantas en el remolque)</option>
                                                                            <option value="T3S2R2">T3S2R2 - Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 8 llantas en el semirremolque y 8 llantas en el remolque)</option>
                                                                            <option value="T3S2R3">T3S2R3 - Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 8 llantas en el semirremolque y 12 llantas en el remolque)</option>
                                                                            <option value="T3S2R4">T3S2R4 - Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 8 llantas en el semirremolque y 16 llantas en el remolque)</option>
                                                                            <option value="T2S2S2">T2S2S2 - Tractocamión Semirremolque-Semirremolque (6 llantas en el tractocamión, 8 llantas en el semirremolque delantero y 8 llantas en el semirremolque trasero)</option>
                                                                            <option value="T3S2S2">T3S2S2 - Tractocamión Semirremolque-Semirremolque (10 llantas en el tractocamión, 8 llantas en el semirremolque delantero y 8 llantas en el semirremolque trasero)</option>
                                                                            <option value="T3S3S2">T3S3S2 - Tractocamión Semirremolque-Semirremolque (10 llantas en el tractocamión, 12 llantas en el semirremolque delantero y 8 llantas en el semirremolque trasero)</option>
                                                                            <option value="OTROEVGP">OTROEVGP - Especializado de carga Voluminosa y/o Gran Peso</option>
                                                                            <option value="OTROSG">OTROSG - Servicio de Grúas</option>
                                                                            <option value="GPLUTA">GPLUTA - Grúa de Pluma Tipo A</option>
                                                                            <option value="GPLUTB">GPLUTB - Grúa de Pluma Tipo B</option>
                                                                            <option value="GPLUTC">GPLUTC - Grúa de Pluma Tipo C</option>
                                                                            <option value="GPLUTD">GPLUTD - Grúa de Pluma Tipo D</option>
                                                                            <option value="GPLATA">GPLATA - Grúa de Plataforma Tipo A</option>
                                                                            <option value="GPLATB">GPLATB - Grúa de Plataforma Tipo B</option>
                                                                            <option value="GPLATC">GPLATC - Grúa de Plataforma Tipo C</option>
                                                                            <option value="GPLATD">GPLATD - Grúa de Plataforma Tipo D</option>
                                                                        </select>
                                                                    </div>	
                                                                </div>	
                                                            </div>
                                                            <div class="col-md-4 col-12">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" :disabled="Disabled_GR_Grupo2"  v-model="factura.ccp" @change="setCCP()" type="checkbox" id="ccp">
                                                                    <label class="form-check-label" for="ccp">
                                                                        Incluir Complemento
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-4 col-12">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Salida</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="date" :disabled="Disabled_GR_Grupo2" class="form-control form-control-sm "  v-model="factura.salida_fecha" />		
                                                                        <input type="time" :disabled="Disabled_GR_Grupo2" class="form-control form-control-sm ps-1 pe-1"  v-model="factura.salida_hora" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 col-12">
                                                                <div class=" row">
                                                                    <label class="col-5 text-nowrap text-end">ID Remitente</label>
                                                                    <div class="col-7 d-flex">
                                                                        <input type="text" class="form-control form-control-sm" :disabled="Disabled_GR_Grupo2" @blur="setID('R')" v-model="factura.idorigen" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-5 col-12">
                                                                <LookUpCtrl :label="'Unidad Peso'" :disabled="Disabled_GR_Grupo2" v-if="factura.factura" :objeto="factura"  :cols="[2,3,7]" :archivo="'satUnidades'"  :campo="'clave'" :descripcion="'nombre'" :model="'unidadpeso'" v-model="factura.unidadpeso" />
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-4 col-12">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Llegada Progr.</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="date" :disabled="Disabled_GR_Grupo2" class="form-control form-control-sm "  v-model="factura.llegada_fecha" />		
                                                                        <input type="time" :disabled="Disabled_GR_Grupo2" class="form-control form-control-sm ps-1 pe-1"  v-model="factura.llegada_hora" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 col-12">
                                                                <div class=" row">
                                                                    <label class="col-5 text-nowrap text-end">ID Destinatario</label>
                                                                    <div class="col-7 d-flex">
                                                                        <input type="text" class="form-control form-control-sm " @blur="setID('D')" :disabled="Disabled_GR_Grupo2" v-model="factura.iddestino" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-5 col-12">
                                                                <LookUpCtrl :label="'Operador 2'" :disabled="Disabled_GR_Grupo2" v-if="factura.factura" :objeto="factura" :cols="[2,3,7]" :archivo="'Operadores'" :campo="'numero'" :descripcion="'nombre'" :model="'operador2'" v-model="factura.operador2" />
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-4 col-12">
                                                                <div class=" row">
                                                                    <label class="col-7 text-nowrap">Distancia Recorrida</label>
                                                                    <div class="col-5 d-flex">
                                                                        <CurrencyInput :disabled="Disabled_GR_Grupo2" v-model="factura.distrecorrida" />		
                                                                        <label class="col-2 ps-1 text-nowrap">KMS</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 col-12 d-none d-md-flex"></div>
                                                            <div class="col-md-4 col-12">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap text-end">Pais OD</label>
                                                                    <div class="col-9 ">
                                                                        <select class="form-control form-control-sm" :disabled="Disabled_GR_Grupo2" v-model="factura.paisod">
                                                                            <option value=""></option>
                                                                            <option v-for="(clave, key) in satPaises" :key="key" :value="key">{{clave}}</option>
                                                                        </select>
                                                                    </div>	
                                                                </div>
                                                            </div>
                                                            <div class="col-6" v-if="factura.logisticainvrd">
                                                                <div class="form-check">
                                                                    <input class="form-check-input"  v-model="factura.logisticainvrd" type="checkbox" id="logisticainvrd">
                                                                    <label class="form-check-label" for="logisticainvrd">
                                                                        Logística Inversa, recolección o devolución
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="col-6" v-if="factura.logisticainvrd">
                                                                <lookUpCtrl :objeto="factura" :label="'Reg.Aduana'" :cols="[1,2,9]" :archivo="'satRegimenAduanero'" :campo="'clave'" :descripcion="'descripcion'" :modal="'regimenaduanero'" v-model="factura.regimenaduanero" />
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <nav ref="navCP">
                                                                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                                        <a class="nav-item nav-link active" id="nav-mercancias-tab" data-bs-toggle="tab" href="#nav-mercancias" role="tab" aria-controls="nav-mercancias" aria-selected="true">Mercancías</a>
                                                                        <a class="nav-item nav-link" id="nav-destinos-tab" data-bs-toggle="tab" href="#nav-destinos" role="tab" aria-controls="nav-destinos" aria-selected="false">Destinos</a>
                                                                        <a v-if="showWS" class="nav-item nav-link" id="nav-ws-tab" data-bs-toggle="tab" href="#nav-ws" role="tab" aria-controls="nav-ws" aria-selected="false">WS</a>
                                                                    </div>
                                                                </nav>
                                                                <div class="tab-content" id="nav-cartaporte">
                                                                    <div class="container p-1 tab-pane fade show active" id="nav-mercancias" role="tabpanel" aria-labelledby="nav-mercancias-tab">
                                                                        <div class="row ">
                                                                            <div class="col-12 form-group" style="padding-bottom:6rem">
                                                                                <b-table-simple hover small caption-top responsive>
                                                                                    <b-thead head-variant="light"  class="table-header">
                                                                                        <b-tr class="table-header">
                                                                                            <b-th>Pda</b-th>
                                                                                            <b-th>Clave</b-th>
                                                                                            <b-th>Descripción</b-th>
                                                                                            <b-th>Cantidad</b-th>
                                                                                            <b-th>Cve. Unidad</b-th>
                                                                                            <b-th>Unidad</b-th>
                                                                                            <b-th>Dimensiones</b-th>
                                                                                            <b-th>MP</b-th>
                                                                                            <b-th>Cve. MP</b-th>
                                                                                            <b-th>Embalaje</b-th>
                                                                                            <b-th>Desc. Embalaje</b-th>
                                                                                            <b-th>Peso Kg</b-th>
                                                                                            <b-th>Valor</b-th>
                                                                                            <b-th>Fracc. Arancelaria</b-th>
                                                                                            <b-th>UUID</b-th>
                                                                                            <b-th>Moneda</b-th>
                                                                                        </b-tr>
                                                                                    </b-thead>
                                                                                    <b-tbody v-if="mercancias.length > 0">
                                                                                        <b-tr v-for="(merc,x) in mercancias" :key="x" :class="{'selected-row': (selectedMercancia.indx == x) }" @click="selectedMercancia=merc;selectedMercancia.indx = x" @dblclick="openMercancias('edit')">
                                                                                            <b-td class="text-center">{{ merc.pda }}</b-td>
                                                                                            <b-td class="text-center">{{ merc.clave }}</b-td>
                                                                                            <b-td class="text-center">{{ merc.descripcion }}</b-td>
                                                                                            <b-td class="text-center">{{ merc.cantidad }}</b-td>
                                                                                            <b-td class="text-center">{{ merc.cveunidad }}</b-td>
                                                                                            <b-td class="text-center">{{ merc.unidad }}</b-td>
                                                                                            <b-td class="text-center">{{ merc.dimensiones }}</b-td>
                                                                                            <b-td class="text-center"> <input type="checkbox" :checked="merc.mp=='S'" disabled :value="merc.mp" /></b-td>
                                                                                            <b-td class="text-center">{{ merc.cvemp }}</b-td>
                                                                                            <b-td class="text-center">{{ merc.embalaje }}</b-td>
                                                                                            <b-td class="text-center">{{ merc.descembalaje }}</b-td>
                                                                                            <b-td class="text-center">{{ merc.pesokg }}</b-td>
                                                                                            <b-td class="text-center">{{ merc.valor }}</b-td>
                                                                                            <b-td class="text-center">{{ merc.fraccarancelaria }}</b-td>
                                                                                            <b-td class="text-center">{{ merc.uuid }}</b-td>
                                                                                            <b-td class="text-center">{{ merc.moneda }}</b-td>
                                                                                        </b-tr>
                                                                                    </b-tbody>
                                                                                    <b-tbody v-else>
                                                                                        <b-tr>
                                                                                            <b-td class="text-center" :variant="'secondary'" colspan="16"><p class="p-4">NO HAY DATOS</p></b-td>
                                                                                        </b-tr>
                                                                                    </b-tbody>
                                                                                </b-table-simple>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <ul class="list-unstyled d-flex">
                                                                                    <li class="pe-1"><button type="button" :disabled="Disabled_GR_Grupo2" @click="openMercancias('add')" class="btn btn-sm btn-secondary  w-100" >Agregar</button></li>
                                                                                    <li class="pe-1"><button type="button" :disabled="Disabled_GR_Grupo2" @click="openMercancias('edit')" class="btn btn-sm btn-secondary w-100" >Cambiar</button></li>
                                                                                    <li class="pe-1"><button type="button" :disabled="Disabled_GR_Grupo2" @click="deleteMercancias()" class="btn btn-sm btn-secondary w-100">Borrar</button></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="container p-1 tab-pane fade show " id="nav-destinos" role="tabpanel" aria-labelledby="nav-destinos-tab">
                                                                        <div class="row ">
                                                                            <div class="col-12 form-group " style="padding-bottom:6rem">
                                                                                <b-table-simple>
                                                                                    <b-thead>
                                                                                        <b-tr class="table-header">
                                                                                            <b-th>Destino</b-th>
                                                                                            <b-th>ID</b-th>
                                                                                            <b-th>Nombre</b-th>
                                                                                            <b-th>Es Origen</b-th>
                                                                                            <b-th>Fecha</b-th>
                                                                                            <b-th>Hora</b-th>
                                                                                            <b-th>Distancia Recorrida</b-th>
                                                                                        </b-tr>
                                                                                    </b-thead>
                                                                                    <b-tbody v-if="destinos.length > 0">
                                                                                        <b-tr v-for="(dest,x) in destinos" :key="x" :class="{'selected-row': (selectedDestino.indx == x) }" @click="selectedDestino=dest;selectedDestino.indx = x" >
                                                                                            <b-td class="text-center">{{ dest.numid }}</b-td>
                                                                                            <b-td class="text-start"><input type="text" @blur="setDestinoID(dest.id)" v-model="dest.id" /></b-td>
                                                                                            <b-td class="text-start">{{ dest.nombre }}</b-td>
                                                                                            <b-td class="text-center"><input type="checkbox" :checked="dest.esorigen=='S'"  v-model="dest.esorigen"></b-td>
                                                                                            <b-td class="text-end"><input type="date" v-model="dest.fecha" /></b-td>
                                                                                            <b-td class="text-center"><input type="time" v-model="dest.hora" /> </b-td>
                                                                                            <b-td class="text-end"><CurrencyInput v-model="dest.distanciarecorrida" /></b-td>
                                                                                        </b-tr>
                                                                                    </b-tbody>
                                                                                    <b-tbody v-else>
                                                                                        <b-tr  >
                                                                                            <b-td class="text-center" :variant="'secondary'" colspan="7"><p class="p-4">NO HAY DATOS</p></b-td>
                                                                                        </b-tr>
                                                                                    </b-tbody>
                                                                                </b-table-simple>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <ul class="list-unstyled d-flex">
                                                                                    <li class="pe-1"><button type="button" @click="openTrDestinos = true" :disabled="Disabled_GR_Grupo2" class="btn btn-sm btn-outline-success w-100">+</button></li>
                                                                                    <li class="pe-1"><button type="button" @click="deleteDestinos()" :disabled="Disabled_GR_Grupo2" class="btn btn-sm btn-outline-danger w-100" id="modifyPartida">-</button></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div v-if="showWS"  class="container p-1 tab-pane fade show " id="nav-ws" role="tabpanel" aria-labelledby="nav-ws-tab">
                                                                        <div class="row ">
                                                                            <div class="col-12 form-group " style="padding-bottom:6rem">
                                                                                <b-table-simple>
                                                                                    <b-thead>
                                                                                        <b-tr class="table-header">
                                                                                            <b-th>Remision</b-th>
                                                                                            <b-th>Propiedad</b-th>
                                                                                            <b-th>Valor</b-th>
                                                                                        </b-tr>
                                                                                    </b-thead>
                                                                                    <b-tbody v-if="wss.length > 0">
                                                                                        <b-tr v-for="ws in wss" :key="ws.Id" >
                                                                                            <b-td class="text-center">{{ ws.remision }}</b-td>
                                                                                            <b-td class="text-center">{{ ws.propiedad }}</b-td>
                                                                                            <b-td class="text-center">{{ ws.valor }}</b-td>
                                                                                        </b-tr>
                                                                                    </b-tbody>
                                                                                    <b-tbody v-else>
                                                                                        <b-tr  >
                                                                                            <b-td class="text-center" :variant="'secondary'" colspan="3"><p class="p-4">NO HAY DATOS</p></b-td>
                                                                                        </b-tr>
                                                                                    </b-tbody>
                                                                                </b-table-simple>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <ul class="list-unstyled d-flex">
                                                                                    <li class="pe-1"><button type="button" id="openPartidas" class="btn btn-sm btn-secondary  w-100" >Conexión</button></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row ">
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <div class="col-6">
                                                                                <div class="form-check" >
                                                                                    <input class="form-check-input" :disabled="Disabled_GR_Grupo2" v-model="factura.consolidada "  type="checkbox" id="consolidada">
                                                                                    <label class="form-check-label" for="consolidada">
                                                                                        Consolidado
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-6 text-end">
                                                                                <a class="" href="http://omawww.sat.gob.mx/tramitesyservicios/Paginas/complemento_carta_porte.htm" target="_blank">Ir a complemento carta porte SAT <OpenInNewIcon /></a>
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>	
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div v-if="transacciones_visible" class="container p-1 tab-pane fade" id="nav-transacciones" role="tabpanel" aria-labelledby="nav-transacciones-tab">
                                                        <div class="row ">
                                                            <div class="col-12 form-group" style="padding-bottom:6rem">
                                                                <b-table-simple hover small caption-top responsive class="no-action-table">
                                                                    <b-thead head-variant="light"  class="table-header">
                                                                        <b-tr class="table-header">
                                                                            <b-th>Cliente</b-th>
                                                                            <b-th>Factura</b-th>
                                                                            <b-th>Docto</b-th>
                                                                            <b-th>Recibo</b-th>
                                                                            <b-th>Fecha</b-th>
                                                                            <b-th>Tipo</b-th>
                                                                            <b-th>Descripción</b-th>
                                                                            <b-th>Valor</b-th>
                                                                            <b-th>SaldoFac</b-th>
                                                                        </b-tr>
                                                                    </b-thead>
                                                                    <b-tbody v-if="edocta.length > 0">
                                                                        <b-tr v-for="(tra,x) in edocta" :key="x" :class="{'selected-row': (selectedTransaccion.indx == x) }" @click="selectedTransaccion=tra;selectedTransaccion.indx = x" >
                                                                            <b-td class="text-end">{{ tra.cliente | leadingZeros(5)  }}</b-td>
                                                                            <b-td class="text-end">{{ tra.factura }}</b-td>
                                                                            <b-td class="text-start">{{ tra.docto }}</b-td>
                                                                            <b-td class="text-end">{{ tra.recibo | leadingZeros(7) | important }}</b-td>
                                                                            <b-td class="text-end">{{ formato(tra.fecha,'DD/MM/YYYY') }}</b-td>
                                                                            <b-td class="text-center">{{ tra.tipo  | leadingZeros(3) }}</b-td>
                                                                            <b-td class="text-start">{{ tra.descripcion }}</b-td>
                                                                            <b-td class="text-end">{{ tra.valor | toCurrency | important }}</b-td>
                                                                            <b-td class="text-end">{{ tra.saldofac | toCurrency | important }}</b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                    <b-tbody v-else>
                                                                        <b-tr  >
                                                                            <b-td class="text-center" :variant="'secondary'" colspan="9"><p class="p-4">NO HAY TRANSACCIONES</p></b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-4 col-12" v-if="showRemisiones">
                                                                <b-table-simple>
                                                                    <b-thead>
                                                                        <b-tr class="table-header">
                                                                            <b-th>Remisión</b-th>
                                                                            <b-th>Total</b-th>
                                                                        </b-tr>
                                                                    </b-thead>
                                                                    <b-tbody v-if="remisiones.length > 0">
                                                                        <b-tr v-for="(remision,x) in remisiones" :key="x" >
                                                                            <b-td class="text-end">{{ remision.folio  | leadingZeros(7) }}</b-td>
                                                                            <b-td class="text-end">{{ remision.total | toCurrency | important }}</b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                    <b-tbody v-else>
                                                                        <b-tr  >
                                                                            <b-td class="text-center" :variant="'secondary'" colspan="2"><p class="p-4">NO HAY REMISIONES</p></b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </div>
                                                            <div class="col-md-2 d-none d-md-flex"></div>
                                                            <div class="col-md-6 col-12">
                                                                <div class="row ">
                                                                    <div class="col-8">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap">Total de Cargos</label>
                                                                            <div class="col-8 d-flex">
                                                                                <CurrencyInput readonly v-model="tran_cargos" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                    <div class="col-4">
                                                                        <div class=" row">
                                                                            <label class="col-3 text-nowrap text-end">Saldo</label>
                                                                        </div>
                                                                    </div>	
                                                                </div>	

                                                                <div class="row ">
                                                                    <div class="col-8">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap">Total de Abonos</label>
                                                                            <div class="col-8 d-flex">
                                                                                <CurrencyInput readonly  v-model="tran_abonos" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                    <div class="col-4">
                                                                        <div class=" row">
                                                                            <div class="col-12">
                                                                                <CurrencyInput readonly v-model="tran_saldo" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>	

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div  class="container p-1 tab-pane fade" id="nav-doctos" role="tabpanel" aria-labelledby="nav-doctos-tab">
                                                        <div class="row ">
                                                            <div class="col-12 form-group " style="padding-bottom:6rem">
                                                                <b-table-simple>
                                                                    <b-thead>
                                                                        <b-tr class="table-header">
                                                                            <b-th></b-th>
                                                                            <b-th>Documento</b-th>
                                                                            <b-th>Agregado</b-th>
                                                                        </b-tr>
                                                                    </b-thead>
                                                                    <b-tbody>
                                                                        <b-tr v-for="docto in doctos" :key="docto.EDOQ_REFERENCIA" >
                                                                            <b-td class="text-center">{{ docto.EDOQ_REFERENCIA }}</b-td>
                                                                            <b-td class="text-center">{{ docto.EDOQ_REFERENCIA }}</b-td>
                                                                            <b-td class="text-center">{{ docto.EDOQ_REFERENCIA }}</b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="col-2">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li><button type="button" @click="setFactura()" :disabled="Disabled_GR_Grupo2" class="btn btn-sm btn-success btn-block ">Aceptar</button></li>
                                                <li><router-link to="/facturas" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                                <li v-if="tab == 'general'" ><button type="button" @click="actualizar()" :disabled="Disabled_GR_Grupo2"  class="btn btn-sm btn-warning btn-block">Actualizar</button></li>
                                            </ul>
                                            
                                            <ul v-if="tab == 'cartaporte'" class="d-block p-1 list-unstyled controls-form" style="margin-top: 7rem;">
                                                <li><button type="button" class="btn btn-outline-secondary btn-block" v-if="factura.BTN_ImportarCP_visible" :disabled="Disabled_GR_Grupo2">Importar</button></li>
                                                <li><button type="button" class="btn btn-outline-secondary btn-block"  :disabled="Disabled_GR_Grupo2">XML Cliente</button></li>
                                                <li><button type="button" class="btn btn-outline-secondary btn-block" v-if="factura.btn_importarMercancia_visible"  :disabled="Disabled_GR_Grupo2">Importa Mercancia</button></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <CambiaDatos v-if="showCambiaDatos" @close="closePopup" @goAhead="RealizaCambio">
                    <template v-slot:title>{{cambiosTitle}}</template>|
                    <template v-slot:body>
                        <div class="row">
                            <label class="col-2 text-nowrap ">Nombre</label>
                            <div class="col-10">
                                <input type="text" class="form-control form-control-sm " v-model="cambiaDatos.nombre" />		
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-2 text-nowrap ">Dirección</label>
                            <div class="col-10">
                                <textarea class="form-control form-control-sm " rows="2" v-model="cambiaDatos.direccion" ></textarea>		
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-2 text-nowrap ">Colonia</label>
                            <div class="col-7">
                                <input type="text" class="form-control form-control-sm " v-model="cambiaDatos.fax" />		
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-2 text-nowrap ">RFC</label>
                            <div class="col-5">
                                <input type="text" class="form-control form-control-sm " v-model="cambiaDatos.rfc" />		
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-2 text-nowrap ">Teléfono</label>
                            <div class="col-7">
                                <input type="text" class="form-control form-control-sm " v-model="cambiaDatos.telefono" />		
                            </div>
                        </div>
                    </template>
                </CambiaDatos>
                <Partidas v-if="showPartidas" @close="closePopup" @goAhead="SetGetServicio">
                    <template v-slot:title>{{tituloModo}} una partida</template>|
                    <template v-slot:body>
                        <LookUpCtrl :objeto="selectedServicio"  :label="'Servicio'" :cols="[2,2,8]" :archivo="'faServicios'"  :campo="'clave'" :descripcion="'descripcion'" :model="'servicio'" @onexit="servicioSeleccionado()" v-model="selectedServicio.servicio" />
                        <div class="row">
                            <label class="col-2 text-nowrap">Descripción</label>
                            <div class="col-10">
                                <textarea class="form-control form-control-sm " rows="5" v-model="selectedServicio.descripcion" ></textarea>		
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-2 text-nowrap ">Unidad</label>
                            <div class="col-4">
                                <input type="text" class="form-control form-control-sm " v-model="selectedServicio.unidad" />		
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-2 text-nowrap ">Peso</label>
                            <div class="col-4">
                                <input type="number" class="form-control text-end form-control-sm " v-model="selectedServicio.peso" />		
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-2 text-nowrap ">Cantidad</label>
                            <div class="col-4">
                                <input type="number" class="form-control text-end form-control-sm " @blur="CalculoServicios()"  v-model="selectedServicio.cantidad" />		
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-2 text-nowrap ">Unitario</label>
                            <div class="col-4">
                                <CurrencyInput v-model="selectedServicio.unitario" @blur="CalculoServicios()" />		
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-2 text-nowrap ">Importe</label>
                            <div class="col-4">
                                <CurrencyInput v-model="selectedServicio.importe_antes_descuento" />		
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-6">
                                <div class="row">
                                    <label class="col-4 text-nowrap">Descuento</label>
                                    <div class="col-8">
                                        <CurrencyInput v-model="selectedServicio.descuento" @blur="selectedServicio.descuento = (Number(selectedServicio.descuento) > Number(selectedServicio.importe) ? selectedServicio.importe : selectedServicio.descuento ) ; CalculoServicios() " />		
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-8 offset-md-2">
                                        <CurrencyInput v-model="selectedServicio.importe" />		
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="row">
                                    <label class="col-4 text-nowrap">IVA</label>
                                    <div class="col-8">
                                        <CurrencyInput v-model="selectedServicio.iva" :disabled="serviciosIvaDisabled" @blur="CalculoServicios()" />		
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <label class="col-2 text-nowrap">Impuesto</label>
                                    <div class="col-5 d-flex">
                                        <CurrencyInput v-model="selectedServicio.impuesto" />		
                                    </div>
                                    <div class="form-check col-5">
                                        <input class="form-check-input" v-model="selectedServicio.exento" type="checkbox" id="exento" @blur="exitExento()">
                                        <label class="form-check-label" for="exento">Exento</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="row">
                                    <label class="col-4 text-nowrap">Retención</label>
                                    <div class="col-8">
                                        <CurrencyInput v-model="selectedServicio.retencion" />		
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-check">
                                    <input class="form-check-input"  v-model="selectedServicio.cpn_retencion" @change="CalculoServicios()" type="checkbox" id="ret">
                                    <label class="form-check-label" for="ret">Retención</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="row">
                                    <label class="col-4 text-nowrap">Neto</label>
                                    <div class="col-8">
                                        <CurrencyInput v-model="selectedServicio.neto" />		
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <label class="col-3 text-nowrap ">Objeto Imp.</label>
                                    <div class="col-7">
                                        <select class="form-control form-control-sm" v-model="selectedServicio.objeto">
                                            <option value="01">01 - No objeto de impuesto</option>
                                            <option value="02" :selected="selectedServicio.objeto == ''">02 - Sí objeto de impuesto</option>
                                            <option value="03">03 - Sí objeto del impuesto y no obligado al desglose</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </Partidas>
                <Mercancias v-if="showMercancias" @close="closePopup" @goAhead="SetGetMercancias">
                    <template v-slot:title>{{tituloModo}} una mercancía</template>|
                    <template v-slot:body>
                        <nav>
                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                <a class="nav-item nav-link active" id="nav-gen-merc"  data-bs-toggle="tab" href="#nav-gen-merc" role="tab" aria-controls="nav-gen-merc" aria-selected="true">General</a>
                                <a class="nav-item nav-link" id="nav-cantidad-tab" data-bs-toggle="tab" href="#nav-cantidad-merc" role="tab" aria-controls="nav-cantidad-merc" aria-selected="false">Cantidad Transporta</a>
                                <a class="nav-item nav-link" id="nav-pedimento-tab" data-bs-toggle="tab" href="#nav-pedimento-merc" role="tab" aria-controls="nav-pedimento-merc" aria-selected="false">Pedimento</a>
                                <a class="nav-item nav-link" id="nav-guias-tab" data-bs-toggle="tab" href="#nav-guias-merc" role="tab" aria-controls="nav-guias-merc" aria-selected="false">Guías</a>
                            </div>
                        </nav>
                        <div class="tab-content" id="nav-tabContent">
                            <div class="container p-1 tab-pane fade show active" id="nav-gen-merc" role="tabpanel" aria-labelledby="nav-gen-merc">
                                <lookUpCtrl v-if="selectedMercancia.id" :objeto="selectedMercancia" :label="'Cve SAT'" :cols="[2,2,8]" :archivo="'satProdServ'" :campo="'clave'" :descripcion="'descripcion'" v-model="selectedMercancia.cvesat" />
                                <div class="row">
                                    <label class="col-2 text-nowrap ">Descripción</label>
                                    <div class="col-10">
                                        <input type="text" class="form-control form-control-sm " v-model="selectedMercancia.descripcion" />		
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="col-2 text-nowrap ">Peso Kg.</label>
                                    <div class="col-10">
                                        <input type="number" class="form-control form-control-sm " v-model="selectedMercancia.pesokg" />		
                                    </div>
                                </div>
                                
                                <lookUpCtrl v-if="selectedMercancia.id" :objeto="selectedMercancia" :label="'Cve Unidad'" :cols="[2,2,8]" :archivo="'satUnidades'" :campo="'clave'" :model="'cveunidad'" :descripcion="'nombre'" v-model="selectedMercancia.cveunidad" />
                                <div class="row">
                                    <div class="col-6">
                                        <div class="row">
                                            <label class="col-4 text-nowrap ">Unidad</label>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm " v-model="selectedMercancia.unidad" />		
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row">
                                            <label class="col-3 text-nowrap ">Dimensiones</label>
                                            <div class="col-9">
                                                <input type="text" class="form-control form-control-sm " placeholder="ej. 30/40/30cm" v-model="selectedMercancia.dimensiones" />		
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="col-2 text-nowrap ">Cantidad</label>
                                    <div class="col-10">
                                        <CurrencyInput v-model="selectedMercancia.cantidad" />		
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="row">
                                            <label class="col-4 text-nowrap ">Valor</label>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm " v-model="selectedMercancia.valor" />		
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row">
                                            <label class="col-3 text-nowrap ">Moneda</label>
                                            <div class="col-7">
                                                <input type="text" class="form-control form-control-sm "  v-model="selectedMercancia.moneda" />		
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4 offset-2">
                                        <div class="form-check ">
                                            <input class="form-check-input"  v-model="selectedMercancia.peligroso "  type="checkbox" id="mp">
                                            <label class="form-check-label" for="mp">Material Peligroso</label>
                                        </div>
                                    </div>
                                </div>
                                <lookUpCtrl v-if="selectedMercancia.id" :objeto="selectedMercancia" :label="'Cve Unidad'" :cols="[2,2,8]" :archivo="'satMatPeligroso'" :campo="'clave'" :descripcion="'descripcion'" v-model="selectedMercancia.mp" />
                                <lookUpCtrl v-if="selectedMercancia.id" :objeto="selectedMercancia" :label="'Cve Unidad'" :cols="[2,2,8]" :archivo="'satEmbalajes'" :campo="'clave'" :descripcion="'descripcion'" v-model="selectedMercancia.embalaje" />
                                <div class="row">
                                    <label class="col-2 text-nowrap ">Desc. Embalaje</label>
                                    <div class="col-10">
                                        <input type="text" class="form-control form-control-sm " readonly v-model="selectedMercancia.descembalaje" />		
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="col-2 text-nowrap ">Fracc. Arancelaria</label>
                                    <div class="col-10">
                                        <input type="text" class="form-control form-control-sm "  v-model="selectedMercancia.fraccarancel" />		
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="col-2 text-nowrap ">UUID</label>
                                    <div class="col-10">
                                        <input type="text" class="form-control form-control-sm "  v-model="selectedMercancia.uuid" />		
                                    </div>
                                </div>
                            </div>   
                            <div class="container p-1 tab-pane fade show " id="nav-cantidad-merc" role="tabpanel" aria-labelledby="nav-cantidad-merc">
                                <div class="row">
                                    <label class="col-2 text-nowrap">Origen</label>
                                    <div class="col-8">
                                        <select class="form-control form-control-sm" v-model="selectedMercancia.origen">
                                            <option value=""></option>
                                            
                                        </select>
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="col-2 text-nowrap">Destino</label>
                                    <div class="col-8">
                                        <select class="form-control form-control-sm" v-model="selectedMercancia.destino">
                                            <option value=""></option>
                                            
                                        </select>
                                    </div>
                                    
                                </div>
                                <div class="row">
                                    <label class="col-2 text-nowrap ">Cantidad</label>
                                    <div class="col-5">
                                        <input type="number" class="form-control form-control-sm " v-model="selectedMercancia.cantidad" />		
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <button class="btn btn-xs btn-outline-success">+</button>
                                        <button class="btn btn-xs btn-outline-danger">-</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-10">
                                        <b-table-simple>
                                            <b-thead>
                                                <b-tr class="table-header">
                                                    <b-th>Origen</b-th>
                                                    <b-th>Destino</b-th>
                                                    <b-th>Cantidad</b-th>
                                                </b-tr>
                                            </b-thead>
                                            <b-tbody v-if="cantidades.length > 0">
                                                <b-tr v-for="(cantidad,x) in cantidades" :key="x" >
                                                    <b-td>{{cantidad.origen}}</b-td>
                                                    <b-td>{{cantidad.destino}}</b-td>
                                                    <b-td>{{cantidad.cantidad}}</b-td>
                                                </b-tr>
                                            </b-tbody>
                                            <b-tbody v-else>
                                                <b-tr  >
                                                    <b-td class="text-center" :variant="'secondary'" colspan="3"><p class="p-4">NO HAY DATOS</p></b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </div>
                                    <div class="col-2">
                                        <button class="btn btn-xs btn-outline-success d-block">up</button>
                                        <button class="btn btn-xs btn-outline-danger d-block">down</button>
                                    </div>
                                </div>
                            </div>   
                            <div class="container p-1 tab-pane fade show " id="nav-pedimento-merc" role="tabpanel" aria-labelledby="nav-pedimento-merc">
                                <div class="row">
                                    <div class="col-12">
                                        <b-table-simple>
                                            <b-thead>
                                                <b-tr class="table-header">
                                                    <b-th>Validación</b-th>
                                                    <b-th>Aduana</b-th>
                                                    <b-th>Patente</b-th>
                                                    <b-th>Curso</b-th>
                                                    <b-th>Consecutivo</b-th>
                                                </b-tr>
                                            </b-thead>
                                            <b-tbody v-if="pedimentos.length > 0">
                                                <b-tr v-for="(pedimento,x) in pedimentos" :key="x" >
                                                    <b-td>{{pedimento.validacion}}</b-td>
                                                    <b-td>{{pedimento.aduana}}</b-td>
                                                    <b-td>{{pedimento.patente}}</b-td>
                                                    <b-td>{{pedimento.curso}}</b-td>
                                                    <b-td>{{pedimento.consecutivo}}</b-td>
                                                </b-tr>
                                            </b-tbody>
                                            <b-tbody v-else>
                                                <b-tr  >
                                                    <b-td class="text-center" :variant="'secondary'" colspan="5"><p class="p-4">NO HAY DATOS</p></b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <button class="btn btn-xs btn-outline-success">+</button>
                                        <button class="btn btn-xs btn-outline-danger">-</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-3"><p>Validación : </p></div>
                                            <div class="col-9"><p>últimos 2 dígitos del año de validación</p></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-3"><p>Aduana : </p></div>
                                            <div class="col-9"><p>2 dígitos de la aduana de despacho</p></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-3"><p>Patente : </p></div>
                                            <div class="col-9"><p>4 dígitos del número de la patente</p></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-3"><p>Curso : </p></div>
                                            <div class="col-9"><p>1 dígito que corresponde al último dígito del año en curso, salvo que se trate de un pedimento consolidado iniciado en el año inmediato anterior o del pedimento original de una rectificación</p></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-3"><p>Consecutivo : </p></div>
                                            <div class="col-9"><p>6 dígitos de la numeración progresiva por aduana</p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                            <div class="container p-1 tab-pane fade show " id="nav-guias-merc" role="tabpanel" aria-labelledby="nav-guias-merc">
                                <div class="row">
                                    <label class="col-2 text-nowrap ">Num. Guía</label>
                                    <div class="col-10">
                                        <input type="text" class="form-control form-control-sm " v-model="selectedMercancia.guianum" />		
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="col-2 text-nowrap ">Descripción</label>
                                    <div class="col-10">
                                        <input type="text" class="form-control form-control-sm " v-model="selectedMercancia.descripcion" />		
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="col-2 text-nowrap ">Peso</label>
                                    <div class="col-5">
                                        <input type="number" class="form-control text-end form-control-sm " v-model="selectedMercancia.peso" />		
                                    </div>
                                    <label class="col-2 text-nowrap ">Kg.</label>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <b-table-simple>
                                            <b-thead>
                                                <b-tr class="table-header">
                                                    <b-th>Num. Guía</b-th>
                                                    <b-th>Descripción</b-th>
                                                    <b-th>Peso</b-th>
                                                </b-tr>
                                            </b-thead>
                                            <b-tbody v-if="guias.length > 0">
                                                <b-tr v-for="(guia,x) in guias" :key="x" >
                                                <b-td class="text-center">{{guia.numero}}</b-td>
                                                <b-td class="text-center">{{guia.descripcion}}</b-td>
                                                <b-td class="text-center">{{guia.peso}}</b-td>
                                                </b-tr>
                                            </b-tbody>
                                            <b-tbody v-else>
                                                <b-tr  >
                                                    <b-td class="text-center" :variant="'secondary'" colspan="3"><p class="p-4">NO HAY DATOS</p></b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                </div>
                            </div>
                        </div>   
                    </div>     
                </template>
                </Mercancias>
                <InfoPopUpVue  v-show="showNotification" @close="closeInfo">
                    <template v-slot:header>
                        <h6 class="text-uppercase">{{notificationTitle}}</h6>
                    </template>
                    <template v-slot:body>
                        {{notificationText}}
                    </template>
                </InfoPopUpVue>

                <DatosDieRut v-if="showDatosDieRut" @close="closePopup" @goAhead="SetDatosdeDiesel">
                    <template v-slot:title>Datos de Diesel</template>
                    <template v-slot:body>
                        <fieldset class="border" >
                            <legend class="col-2 text-nowrap ">Proveedor</legend>
                            <div class="row ps-3 pb-3">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" @change="changeDieselProveedor()" v-model="selectedDiesel.proveedor" type="radio" name="RADIO_DIESELPROVE" id="pfm1" value="A">
                                    <label class="form-check-label" for="pfm1">Nosotros</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" @change="changeDieselProveedor()" v-model="selectedDiesel.proveedor" type="radio" name="RADIO_DIESELPROVE" id="pfm2" value="O">
                                    <label class="form-check-label" for="pfm2">Otros</label>
                                </div>
                            </div>
                        </fieldset>
                        
                        <lookUpCtrl v-if="selectedDieselVisibleProveedor" :objeto="selectedDiesel" :label="'Proveedor'" :cols="[2,2,8]" :archivo="'CpProveedores'" :campo="'prove'" :descripcion="'nombre'" v-model="selectedDiesel.prove" />
                        
                        <div class="row" v-if="selectedDieselVisibleNombre">
                            <label class="col-2 text-nowrap ">Nombre</label>
                            <div class="col-10">
                                <input type="text" class="form-control form-control-sm " v-model="selectedDiesel.nombre" />		
                            </div>
                        </div>
                        
                        <div class="row">
                            <label class="col-2 text-nowrap ">Folio</label>
                            <div class="col-4">
                                <input type="number" class="form-control text-end form-control-sm " v-model="selectedDiesel.folio" />		
                            </div>
                        </div>
                    </template>
                </DatosDieRut>

                <DatosAntRut v-if="showDatosAntRut" @close="closePopup" @goAhead="SetDatosAntRut">
                    <template v-slot:title>Datos del Anticipo</template>
                    <template v-slot:body>
                        <div class="row">
                            <div class="col-8">
                                <lookUpCtrl v-if="selectedDieselVisibleProveedor" :disabled="selectedAntBanco_disabled" :objeto="selectedAnt" :label="'Banco'" :cols="[2,2,8]" :archivo="'glBancos'" :campo="'clave'" :descripcion="'banco'" v-model="selectedAnt.banco" />
                            </div>
                            <div class="col-4">
                                <div class="row" >
                                    <label class="col-2 text-nowrap ">Ref.</label>
                                    <div class="col-10">
                                        <input type="text" :disabled="selectedAntRef_disabled"  class="form-control form-control-sm " v-model="selectedAnt.chequeno" />		
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-2 text-nowrap ">Tipo</label>
                            <div class="col-10 ps-3 pb-3">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" @change="changeDieselProveedor()" :disabled="selectedAntTipo_disabled" v-model="selectedDiesel.proveedor" type="radio" name="RADIO_DIESELTipo" id="dieseltipo1" value="E">
                                    <label class="form-check-label" for="dieseltipo1">Efectivo</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" @change="changeDieselProveedor()" :disabled="selectedAntTipo_disabled" v-model="selectedDiesel.proveedor" type="radio" name="RADIO_DIESELTipo" id="dieseltipo2" value="T">
                                    <label class="form-check-label" for="dieseltipo2">Traspaso</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" @change="changeDieselProveedor()" :disabled="selectedAntTipo_disabled" v-model="selectedDiesel.proveedor" type="radio" name="RADIO_DIESELTipo" id="dieseltipo3" value="C">
                                    <label class="form-check-label" for="dieseltipo3">Cheque</label>
                                </div>
                            </div>
                        </div>
                        
                    </template>
                </DatosAntRut>

                <PreguntaToneladas v-if="showPreguntaToneladas" @close="closeToneladas" @goAhead="SetPreguntaToneladas">
                    <template v-slot:title>Toneladas</template>
                    <template v-slot:body>
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <label class="col-3 text-nowrap text-right">Concepto de Servicio</label>
                                    <div class="col-9">
                                        <select class="form-control form-control-sm" v-model="preguntatoneladas.concepto">
                                            <option v-for="(string, key) in array" :key="key" :value="element">{{element}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="row" >
                                    <label class="col-2 text-nowrap ">Peso a Facturar (TON)</label>
                                    <div class="col-10">
                                        <input type="number" step="any" min="0" class="form-control form-control-sm " v-model="preguntatoneladas.peso" />		
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </template>
                </PreguntaToneladas>
                <TipoCambio v-if="openTipoCambio" @setTCambio="receiveTC" @close="openTipoCambio = false"></TipoCambio>
                <TrDestinos v-show="openTrDestinos" :component="'WIN_TrDestinos'" @close="closePopup" @receiveSelected="getTrDestinos" />
                </div>
        </div>
    </section>
</template>

<script>
import handleForm from '@/mixins/handleForm'
import handlePopUp from '@/mixins/handlePopUp'
import handleGlobals from '@/mixins/handleGlobals'
import OpenInNewIcon from 'vue-material-design-icons/OpenInNew.vue';
import CambiaDatos from '@/components/PopupWindow.vue';
import Partidas from '@/components/PopupWindow.vue';
import Mercancias from '@/components/PopupWindow.vue';
import DatosAntRut from '@/components/PopupWindow.vue';
import DatosDieRut from '@/components/PopupWindow.vue';
import PreguntaToneladas from '@/components/PopupWindow.vue';
import LookUpCtrl from '@/components/LookUpCtrl.vue';
import InfoPopUpVue from '@/components/InfoPopUp.vue'
import CurrencyInput from '@/components/MyCurrencyComponent.vue';
import TrDestinos from '@/components/LookUp.vue';
import LoadingContent from '@/components/LoadingSpinner.vue'
import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
import TipoCambio from '@/components/WIN_TipoCambio_Ficha.vue'
export default {
    mixins:[handleGlobals,handlePopUp,handleForm],
    components:{SideBar,TopBar, TipoCambio,PreguntaToneladas,CurrencyInput,LoadingContent ,TrDestinos,OpenInNewIcon,DatosDieRut,DatosAntRut,CambiaDatos,LookUpCtrl,Partidas,Mercancias,InfoPopUpVue},
    data(){
        return{
            id: this.$route.params.factura,
            modo: (this.$route.params.factura > 0 ? 'Modificando' : 'Creacion'), 
            tab:'general',
            factura: [],
            condiciones: [],
            metodospago: [],
            showPreguntaToneladas:false,
            preguntatoneladas:{},
            guias:[],
            openTrDestinos:false,
            cantidades:[],
            servicios:[],
            openTipoCambio:false,
            selectedDestino:{},
            serviciosIvaDisabled:false,
            retenciones:[],
            otrasretenciones_mostrar:false,
            showDatosDieRut:false,
            selectedDiesel:{
                proveedor: 'A',
                folio: 0,
                nombre: '',
                prove:0
            },
            selectedServicio:{
                servicio: 0,
                descripcion: '',
                unidad:'',
                peso:0,
                cantidad:0,
                unitario:0,
                importe_antes_descuento:0,
                descuento:0,
                importe:0,
                iva:0,
                impuesto:0,
                exento:false,
                retencion:0,
                cpn_retencion:false,
                neto:0,
                objeto:'02'
            },
            selectedTransaccion:{},
            selectedMercancia:{},
            mercancias:[],
            destinos:[],
            accesorios: [],
            wss: [],
            remisiones: [],
            xmlFile:[],
            transacciones: [],
            showRemisiones:false,
            showMercancias:false,
            doctos: [],
            BTN_COMPROBANTE_visible:false,
            BTN_Trancasa_visible:false,
            BTN_XMLCliente_visible:false,
            showCambiaDatos:false,
            selectedDieselVisibleProveedor: true,
            selectedDieselVisibleNombre: true,
            Disabled_GR_Folio:false,
            ModoRD: '',
            titulo: 'Agregando una Factura',
            cambiosTitle: '',
            FaFoliosE:[],
            cantidadtransporta:[],
            guiasIdentificacion:[],
            showWS:false,
            showLookup:false,
            tituloModo:'Agregando',
            Show_RET5MILLAR:false,
            Operador_status: false,
            Tractor_status: false,
            State_Tipo: false,
            LookupFile: '',
            State_CLIENTEID:false,
            Disabled_FACTURA:false,
            selectedAnt: [],
            Disabled_GR_Grupo2:false,
            Disabled_CFD:false,
            Cancelada:false,
            showPartidas:false,
            showDatosAntRut:false,
            selectedAntBanco_disabled: false,
            selectedAntRef_disabled: false,
            selectedAntTipo_disabled: false,
            GR_Bonificacion:false,
            transacciones_visible:false,
            satPaises:[],
            edocta:[],
            pedimentos:[],
            actionSel: true,
            State_CTE_CLIENTEID:false,
            GR_CCP_Grayed: false,
            Visible_REGIDTRIB:false,
            tran_abonos: 0,
            tran_cargos: 0,
            gnRetencion: 0,
            tran_saldo : 0,
            gnDecimales:0,
            cambiaDatos: {
                    nombre: '', 	
                    direccion: '',
                    rfc: '', 
                    telefono: '',
                    fax:''	}	,
        }
    },
    computed:{
        ctrl_fecha:{
            get(){
                return this.formato(this.factura.fecha,"YYYY-MM-DD")
            },
            set(val){
                return val 
            }  
        },
        ctrl_llegada_fecha:{
            get(){
                return this.formato(this.factura.llegada_fecha,"YYYY-MM-DD")
            },
            set(val){
                return val 
            }  
        },
        ctrl_salida_fecha:{
            get(){
                return this.formato(this.factura.salida_fecha,"YYYY-MM-DD")
            },
            set(val){
                return val 
            }  
        },
        servicioTotalPeso(){
            return this.servicios.reduce((acc, item) => Number(acc) + Number((item.peso ? item.peso : 0)), 0)
        },
        servicioTotalImporte (){
            return this.servicios.reduce((acc, item) => Number(acc) + Number((item.importe ? item.importe : 0)), 0)
        },
        servicioTotalDescuento(){
            return this.servicios.reduce((acc, item) => Number(acc) + Number((item.descuento ? item.descuento : 0)), 0)
        },
        servicioTotalImpuesto (){
            return this.servicios.reduce((acc, item) => Number(acc) + Number((item.impuesto ? item.impuesto : 0)), 0)
        },
        servicioTotalRetencion(){
            return this.servicios.reduce((acc, item) => Number(acc) + Number((item.ret ? item.ret : 0)), 0)
        },
        servicioTotaltotal(){
            return this.servicios.reduce((acc, item) => Number(acc) + Number((item.total ? item.total : 0)), 0)
        },
        servicioTotalNeto(){
            return this.servicios.reduce((acc, item) => Number(acc) + Number((item.neto ? item.neto : 0)), 0)
        },
        tractor_D(){
            return (this.modo == "Modificando" ? this.Disabled_GR_Grupo2 : (this.Cancelada || this.Tractor_status))
        },
        btnsServicios(){
            return (this.modo == "Modificando" ? this.Disabled_GR_Grupo2 : this.actionSel)
        }
    },
    created(){
        this.isLoading = true
        this.fetchCondicionesPago()
        this.Get_SAT_ClavesPaises()
    },
    mounted(){
        this.llenaForma();
    },
    methods:{
        closePopup(){
            this.showCambiaDatos = false
            this.showPartidas = false
            this.showMercancias = false
        },
        setID(Tipo){
            if(Tipo == 'R'){
                if(this.factura.idorigen > 0 ){
                    this.factura.idorigen = 'OR'+(this.$options.filters.leadingZeros( this.factura.idorigen,6))
                }
            }else{
                if(this.factura.iddestino > 0 ){
                    this.factura.iddestino = 'DE'+(this.$options.filters.leadingZeros( this.factura.iddestino,6))
                }
            }
        },
        setDestinoID(valor){
            if(valor > 0){
                this.selectedDestino.id = (this.selectedDestino.esorigen == true ? 'OR' : 'DE')+(this.$options.filters.leadingZeros( valor,6))
            }
        },
        async fetchCondicionesPago(){
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "browse", 
                                        archivo: "Condiciones", 
                                        activarPaginas: false,
                                        filtro: {paginaActual: {dato: 'NA'}},
                                        orden:"condno", 
                                        pagina:"todo", 
                                        paginacion: "",
                                        registrosxpagina: 0 })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.condiciones = data['registros'];
            });
        },
        setCCP(){
            if(this.factura.ccp == true){
                this.GR_CCP_Grayed = false
                this.factura.cpver = "2.0"
            }else{
                this.cpver = ""
                this.GR_CCP_Grayed = true
            }
        },
        GetDatosTCA(){
            //todo:
        },
        async llenaForma(){
            this.FaFoliosE = await this.getDigital()
            
            if(this.$route.name == 'DFacturas'){
                this.modo = 'Duplicar'
            }

            await this.getFactura();

            if(this.$route.params.factura > 0){
                this.titulo = 'Cambiando una Factura'
                
                this.factura_disabled = this.factura.factura_disabled
                this.Disabled_CFD = this.factura.Disabled_CFD
                this.Show_RET5MILLAR = this.factura.Show_RET5MILLAR
                this.Operador_status = this.factura.Operador_status
                this.Tractor_status = this.factura.Tractor_status
                this.Disabled_GR_Grupo2 = this.factura.Disabled_GR_Grupo2
                this.Disabled_GR_Folio = this.factura.Disabled_GR_Folio
                this.tcreeval_visible = this.factura.tcreeval_visible
                this.serie_disabled = this.factura.serie_disabled
                this.Cancelada = this.factura.cancelada_visible
                this.moneda_disabled = this.factura.moneda_disabled
                this.incobrable_visible = this.factura.incobrable_visible
              
                this.CargaPdas()
                this.CargarMercanciasyDestinos()
                
                if(this.factura.cpver != ""){
                    this.setCCP()
                }

                if(this.modo == "Duplicar"){
                    this.Facturas = await this.Reset(this.Facturas)
                  
                    //Revisa si el folio esta fuera del rango de los folios activos
                   
                    if(this.factura.folio > this.FaFoliosE.gnFolioMax){
                        this.showNotification = true
                        this.notificationTitle = "Aviso"
                        this.notificationText = "El Folio es mayor al Rango Permitido. Revise el Maestro de Folios Digitales."
                    }
                    
                    this.DuplicarNoFiscal()
                    
                    this.Tipo = 'D'
                    this.titulo = "Agregando una Factura"
                }	
            
                this.CargarCFDIRelacionados()

                this.GetEdoCta()

                if(this.remisiones){
                    this.showRemisiones = true
                }
            }else{ //modify
                this.GR_CCP_Grayed = true
             //   this.BTN_COMPROBANTE_visible = data['BTN_COMPROBANTE_visible']
            }

            if(this.GR_Bonificacion){
                if(this.factura.bonifxcuota > 0 && this.factura.bonifxcuota_porc == 0){
                    this.BonificaxCuota()
                }
            }

            if(this.$ext == 'fng'){
                this.showWS = true
                this.CargarInfoWS()
            }
            this.factura.tipocambio = this.$options.filters.tipoCambio(this.factura.tipocambio)
        },
        async getFactura(){
            let req = []
            
            await fetch(this.$api+'/rdx', {
                    method: "POST",
                    headers: { "Content-Type": "application/json",
                                'Authorization' : this.$store.state.iasctratkn },
                    body: JSON.stringify({ accion: "inicializar", modo:this.modo, archivo: "Facturas", id: this.id })
                }).then((response)=>{
                    if(response.ok){
                        return response.json();
                    }   
                }).then((data)=>{
                    this.factura = data['res'];
                    this.Facturas = data['res']['FacturasFile']
                    this.FaFoliosE= data['res']['foliosDigitales']
                    this.transacciones_visible = data['res']['transacciones_visible']
                    this.BTN_Trancasa_visible = data['res']['BTN_Trancasa_visible']
                    this.BTN_XMLCliente_visible = data['res']['BTN_XMLCliente_visible']

                    this.factura.sinimptocero = this.factura.sinimptocero == 'S' 
                    this.factura.obsoleto = this.factura.obsoleto == 'S' 
                    this.factura.cservicios = this.factura.cservicios == 'S' 
                    this.factura.nofiscal = this.factura.nofiscal == 'S' 
                    this.factura.ccp = this.factura.ccp == 'S' 
                    this.factura.consolidada = this.factura.consolidada == 'S' 
                    this.factura.retnom = this.factura.retnom == 'S' 
                    this.factura.ret5millar = this.factura.ret5millar == 'S' 
                    this.factura.retener = this.factura.retener == 'S' 
                    this.gnRetencion = this.factura.gnRetencion
                    let params = {
                        SEGURO: data['res']['glseguro'],
                        retencion: data['res']['glretencion'],
                    }
                    this.$store.dispatch('saveParams',params)

                    req = data['req']
                }).finally(()=>{
                    this.setRequired(req)
                    this.isLoading = false
                }).catch((err)=>{
                this.falseToken(err)
            });
        },
        async setFactura(){
            this.factura.servicios = this.servicios
            this.factura.mercancias = this.mercancias
            this.factura.destinos = this.destinos
            this.factura.ws = this.ws
            
            this.factura.mercancias.cantidadtransporta = this.cantidadtransporta
            this.factura.mercancias.guiasIdentificacion = this.guiasIdentificacion
            this.factura.mercancias.pedimentos = this.pedimentos

            this.factura.sinimptocero = this.factura.sinimptocero ? 'S' : 'N'
            this.factura.obsoleto  = this.factura.obsoleto ? 'S' : 'N'
            this.factura.cservicios = this.factura.cservicios ? 'S' : 'N'
            this.factura.nofiscal  = this.factura.nofiscal ? 'S' : 'N'
            this.factura.cfd = this.factura.cfd ? 'S' : 'N'
            this.factura.ccp = this.factura.ccp ? 'S' : 'N'
            this.factura.consolidada = this.factura.consolidada ? 'S' : 'N'
            this.factura.ret5millar = this.factura.ret5millar ? 'S' : 'N'

            this.factura.fecha = this.ctrl_fecha
            this.factura.llegada_fecha = this.ctrl_llegada_fecha
            this.factura.salida_fecha = this.ctrl_salida_fecha

            if(this.$store.state.PARAMS.activacc){ 
                if(this.factura.tractor == 0 && this.modo == "Creacion"){
                    this.showNotification = true
                    this.notificationTitle = 'Error'
                    this.notificationText = 'Verifique la unidad'
                    return
                }
            }
            
            if(this.factura.condicionespago == 0){
                this.showNotification = true
                this.notificationTitle = 'Verificar Información'
                this.notificationText = 'No tiene seleccionado ninguna condicion de pago'
                this.notificationRef = 'condiciones'
                return
            }

            if(this.factura.total <= 0){
                this.showNotification = true
                this.notificationTitle = 'Error'
                this.notificationText = 'Los valores en la factura provocan un total negativo o cero.'
                return
            }

            let FechaFac = this.formato(this.factura.fecha,"YYYY")
            if(FechaFac != this.$store.state.gnAno){
                this.showNotification = true
                this.notificationTitle = 'Error'
                this.notificationText = 'No puede grabar una factura de otro año diferente al actual.'
                return
            }
             /*
            if(this.factura.quienpaga == "R" && (this.factura.remitente  == undefined || this.factura.remitente <= 0)){
                this.showNotification = true
                this.notificationTitle = 'Error'
                this.notificationText = 'El Cliente esta seleccionado como el Remitente pero el Remitente no tiene registro en Clientes.'
                return
            }
            if(this.factura.quienpaga == "D" && (this.factura.destinatario  == undefined || this.factura.destinatario  <= 0)){
                this.showNotification = true
                this.notificationTitle = 'Error'
                this.notificationText = 'El Cliente esta seleccionado como el Destinatario pero el Destinatario no tiene registro en Clientes.'
                return
            }
           
            if(this.factura.quienpaga == "O" && (this.factura.cliente  == undefined || this.factura.cliente == 0 )){
                this.showNotification = true
                this.notificationTitle = 'Error'
                this.notificationText = 'El Cliente esta seleccionado como Otro pero no tiene registro en Clientes.'
                return
            }*/

            if(this.factura.iva == 0 && this.factura.piva > 0 ){
                this.showNotification = true
                this.notificationTitle = 'Error'
                this.notificationText = 'La Factura tiene una tasa del IVA de '+this.factura.piva+' pero el importe del IVA es 0.'
                return
            }

            if(this.factura.cservicios == 'S'){
                let res = this.servicios.filter((e)=>{
                                return e.iva == this.factura.piva
                            })
                
                if(res.length <= 0 ){
                    this.showNotification = true
                    this.notificationTitle = 'Error'
                    this.notificationText = 'No se encontró nungún servicio con el porcentaje de IVA de la factura.'
                    return
                }
            }

            if(this.factura.ccp == 'S' && this.$store.state.PARAMS.gbDesdeTrafico == false ){
                if(this.factura.cservicios == 'N'){
                    this.showNotification = true
                    this.notificationTitle = 'Error'
                    this.notificationText = 'Con complemento de carta porte se tiene que usar catálogos de servicios.'
                    return
                }
                if(this.factura.digital == 'N'){
                    this.showNotification = true
                    this.notificationTitle = 'Error'
                    this.notificationText = 'El Complemento de carta porte solo se puede utilizar para comprobantes digitales.'
                    return
                }
                if(this.factura.confveh == "" ){
                    this.showNotification = true
                    this.notificationTitle = 'Error'
                    this.notificationText = 'Captura la configuración vehicular.'
                    return
                }
                
                if(this.factura.unidadpeso == "" ){
                    this.showNotification = true
                    this.notificationTitle = 'Error'
                    this.notificationText = 'Falta la unidad de peso en carta porte.'
                    return
                }
                
                this.destinos.forEach((e)=>{
                    if(e.id == "" ){
                        this.showNotification = true
                        this.notificationTitle = 'Error'
                        this.notificationText = 'Todos los destinos tienen que tener un valor en la columna ID.'
                        return
                    }
                    if(this.$options.filters.isValidDate(e.fecha) == false){
                        this.showNotification = true
                        this.notificationTitle = 'Error'
                        this.notificationText = 'Fecha en la columna de la tabla de destinos es incorrecta.'
                        return
                    }
                    if(this.$options.filters.isValidDate(e.hora) == false){
                        this.showNotification = true
                        this.notificationTitle = 'Error'
                        this.notificationText = 'Hora en la columna de la tabla de destinos es incorrecta.'
                        return
                    }
                    if(e.kms <= 0 ){ 
                        this.showNotification = true
                        this.notificationTitle = 'Error'
                        this.notificationText = 'La distancia recorrida de la tabla de destinos tiene que tener un valor.'
                        return
                    }
                })
            
                if(this.destinos.length > 0 && (this.factura.idorigen == "" || this.factura.iddestino == "")){
                    this.showNotification = true
                    this.notificationTitle = 'Error'
                    this.notificationText = 'Capturar los ID del remitente y/o destinatario'
                    return
                }

                if((this.$ext == "her" || this.$ext == "tra" || this.$ext =="thr") && this.modo == 'Creacion'){
                    await fetch(this.$api+'/rdx', {
                            method: "POST",
                            headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                            body: JSON.stringify({ accion: 'metodo', archivo: "Facturas", metodo: 'ValidaViaje' , params: {factura: this.factura.viaje} })
                        }).then((response)=>{
                            if(response.ok){
                                return response.json();
                            }   
                        }).then((data)=>{
                            if(data > 0){
                                this.showNotification = true
                                this.notificationTitle = "Error"
                                this.notificationText = "El viaje "+this.factura.viaje+" ya se encuentra en otra factura."
                                return
                            }    
                        })
                }
            }

            if(this.modo != "Modificando"){
                if(this.factura.uuid != "" && this.factura.uuid != undefined){
                    this.showNotification = true
                    this.notificationTitle = "Error"
                    this.notificationText ="La Factura no puede tener folio fiscal. Favor de comunicarse a la oficinas de fussion para soporte."
                    return
                }
            }

            if((this.factura.retener == 'N' && this.servicioTotalRetencion > 0) || (this.factura.retencion == 0 && this.servicioTotalRetencion > 0) ){
                    this.showNotification = true
                    this.notificationTitle = "Error"
                    this.notificationText ="El total de retención de la factura no coincide con las retenciones de los servicios."
                    return   
            }


            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: 'validar', archivo: "Facturas", modo:this.modo, record: this.factura }) 
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then(()=>{
                console.log(this.factura)
            })
        },
        changeDieselProveedor(){
            if(this.selectedDiesel.proveedor == "O"){
                this.selectedDiesel.folio = 0
                this.selectedDieselVisibleProveedor = true
                this.selectedDieselVisibleNombre = true
            }else{
                this.selectedDiesel.prove = 0
                this.selectedDieselVisibleProveedor = false
                this.selectedDieselVisibleNombre = false	
                this.selectedDiesel.folio = this.GetFolioDiesel()
            }
        },
        async GetFolioDiesel(){
            let folio = await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion:'metodo', 
                                        archivo: "Facturas", 
                                        metodo: 'GetFolioDiesel',
                                        params: {proveedor: this.selectedDiesel.proveedor} })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                return data
            }).catch((err)=>{
                this.falseToken(err)
            });
            return folio
        },
        async SetDatosAntRut(){
            this.factura.antRut = this.selectedAnt
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: 'metodo', 
                                        archivo: "Facturas", 
                                        metodo: 'closedDatosAntRut',
                                        params: {   ref: this.selectedAnt.ref, 
                                                    banco: this.selectedAnt.banco, 
                                                    tipo: this.selectedAnt.tipo, 
                                                    fecha: this.factura.fecha} })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['msg'] == ''){
                    this.selectedAnt.ref = data['ref'];
                    this.selectedAnt.banco = data['banco'];
                    this.selectedAnt.tipo = data['tipo'];
                    this.factura.antRut = this.selectedAnt;
                }else{
                    this.showNotification = true;
                    this.notificationTitle= "error";
                    this.notificationTexto = data['msg'];
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        SetDatosdeDiesel(){
            this.factura.diesel = this.selectedDiesel
            //todo
            this.factura.dieselRts = this.selectedDiesel
        },
        CargarInfoWS(){
            //TODO:
        },
        async getDigital(){
            let r = await fetch(this.$api+'/rdx',{
                        method: "POST",
                        headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                        body: JSON.stringify({
                                    accion: "metodo", 
                                    archivo: "Facturas", 
                                    metodo: "getDigitales",
                                    params: {}
                        })
                }).then((response)=>{
                    if(response.ok){
                        return response.json()
                    }   
                }).then((data)=>{
                    return data
                }).catch((err)=>{
                    this.falseToken(err)
                });
                return r
        },
        async CargaPdas(){
            await fetch(this.$api+'/rdx',{
                        method: "POST",
                        headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                        body: JSON.stringify({
                                    accion: "metodo", 
                                    archivo: "Facturas", 
                                    metodo: "getPartidas",
                                    params: { factura: this.factura.factura ,
                                              sucursal: this.factura.sucursal}
                        })
                }).then((response)=>{
                    if(response.ok){
                        return response.json()
                    }   
                }).then((data)=>{
                    this.servicios = data
                }).catch((err)=>{
                this.falseToken(err)
            });
            
        },
        async CargarMercanciasyDestinos(){
           
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "metodo", archivo: "Facturas", 
                                        metodo: 'CargarMercanciasyDestinos', 
                                        params: {sucursal: this.factura.sucursal, factura: this.factura.factura} })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.mercancias = data['mercancias']['partidas'];
                this.destinos = data['destinos'];
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        async Get_SAT_ClavesPaises(){
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "metodo", archivo: "Global", metodo: 'Get_SAT_ClavesPaises', params: {} })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.satPaises = data;
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        async GetEdoCta(){
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "metodo", 
                                        archivo: "Facturas", 
                                        metodo: 'getEdoCta', 
                                        params: {factura: this.factura,facturaFile: this.factura.Facturas } })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.remisiones = data['remisiones']	
                this.edocta = data['edocta']	
                this.tran_abonos = data['abonos']	
                this.tran_cargos = data['cargos']	
                this.tran_saldo  = data['saldo']	
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        async GetFolio(){
            await fetch(this.$api+'/rdx', {
                        method: "POST",
                        headers: { "Content-Type": "application/json",
                        'Authorization' : this.$store.state.iasctratkn },
                        body: JSON.stringify({  accion: "metodo", archivo: "Facturas", metodo: 'PreGetFolio', 
                                                params: {sucursal: this.factura.sucursal, 
                                                            docto: 'FAC', 
                                                            serie: this.factura.serie, 
                                                            folio:this.factura.folio } })
                    }).then((response)=>{
                        if(response.ok){
                            return response.json();
                        }   
                    }).then((data)=>{
                        this.factura.folio = data	
                    }).catch((err)=>{
                this.falseToken(err)
            });
            this.factura.factura = (this.factura.serie * 100000) + this.factura.folio
        },
        CambiosR(Modo){
            this.showCambiaDatos = true
            this.ModoRD = Modo
           
            if(Modo == "R"){
                this.cambiosTitle = "Cambios en Remitente (Factura)"
                this.cambiaDatos.nombre 	= this.factura.origennombre
                this.cambiaDatos.direccion	= this.factura.origendireccion
                this.cambiaDatos.rfc 		= this.factura.origenrfc
                this.cambiaDatos.telefono 	= this.factura.origentelefono
                this.cambiaDatos.fax		= this.factura.origenfax
            }else{
                this.cambiosTitle = "Cambios en Destinatario (Factura)"
                this.cambiaDatos.nombre 	= this.factura.destinonombre
                this.cambiaDatos.direccion	= this.factura.destinodireccion
                this.cambiaDatos.rfc 		= this.factura.destinorfc
                this.cambiaDatos.telefono 	= this.factura.destinotelefono
                this.cambiaDatos.fax		= this.factura.destinofax
            }
        },
        RealizaCambio(){
            if(this.ModoRD == 'R'){
                this.factura.origennombre       = this.cambiaDatos.nombre
                this.factura.origendireccion    = this.cambiaDatos.direccion
                this.factura.origenfax          = this.cambiaDatos.fax
                this.factura.origenrfc          = this.cambiaDatos.rfc
                this.factura.origentelefono     = this.cambiaDatos.telefono
            }else{
                this.factura.destinonombre      = this.cambiaDatos.nombre
                this.factura.destinodireccion   = this.cambiaDatos.direccion
                this.factura.destinorfc         = this.cambiaDatos.rfc
                this.factura.destinotelefono    = this.cambiaDatos.telefono
                this.factura.destinofax         = this.cambiaDatos.fax
            }
            this.closePopup()
        },
        actualizar(){
            if(this.factura.pesototal == 0 || this.factura.cuotaxtonelada == 0){
            //	ShowMessage("Error",gsSistema,"Toneladas o la Tarifa son cero, Favor de verificar.")
            //	Return
            }else{
                this.factura.flete = Math.round(this.factura.pesototal * this.factura.cuotaxtonelada)
                this.GetTotal()
            }
        },
        afterClose(){
            this.$refs[this.notificationRef].focus()
        },
        SetGetServicio(){
            if(this.tituloModo == 'Agregando'){
                this.servicios.push(this.selectedServicio)
            }
            this.GetTotal()
            this.closePopup()
        },
        SetGetMercancias(){
            this.mercancias.push(this.selectedMercancia)
            this.closePopup()
        },
        openPartidas(modo){
            if(modo == 'add'){
                this.tituloModo = "Agregando"
                this.selectedServicio = {}
            }else{
                this.tituloModo = "Cambiando"
            }
            this.showPartidas = true
        },
        deletePartida(){
            this.servicios.splice(this.selectedServicio.indx,1)
            this.GetTotal()
        },
        AgregarAnticipo(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "metodo", archivo: "Facturas", metodo:"AgregarAnticipo",params: {} })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }
            }).then((data)=>{
                this.openPartidas('add')
                this.selectedServicio = data
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        openMercancias(modo){
            if(!this.Disabled_GR_Grupo2){
                if(modo == 'add'){
                    this.selectedMercancia = {}
                }
                this.showMercancias = true

                /*
                n is clClientesCartaPorte.STMercanciaCP
                n.Partida = garrMercancias..Count + 1
                n.arrDestinos = garrDestinosCP
                WHILE Open(WIN_Form_MercanciaCP,"Creation",n) = True
                    garrMercancias.Add(n)
                    TableDisplay(TABLE_Mercancias)
                    TableSelectPlus(TABLE_Mercancias,TABLE_Mercancias..Count)
                    
                    VariableReset(n)
                    n.Partida = garrMercancias..Count + 1
                END
                */
            }
        },
        getFoliosDigitales(){
            this.isLoading = true
             fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "metodo", archivo: "Facturas", metodo: 'cambiaAFoliosDigitales', 
                                        params: { serie: this.factura.serie, cfd: this.factura.cfd } })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data.msg){
                    this.showNotification = true;
                    this.notificationTitle= "error";
                    this.notificationTexto = data.msg;
                    this.factura.cfd = false
                }else{
                    console.log(data)
                    this.factura.serie = data.serie
                    this.Disabled_CFD = data.cfd
                }
                if(data.cfd){
                    this.factura.nofiscal = data.nofiscal
                }
            }).catch((err)=>{
                this.falseToken(err)
            })
            this.updateParams()
            this.GetFolio()
            this.isLoading = false
        },
       
        DuplicarNoFiscal(){
            /*
            this.factura.nofiscal = false
            ExecuteProcess(CBOX_FAC_NOFISCAL,trtChange)

            CTPL_MONEDA.LUP_CAMPO 	= WIN_FacturarInvoice.ITEM_MONEDA 
            FAC_TIPOCAMBIO		= WIN_FacturarInvoice.ITEM_TIPOCAMBIO

            n is int
            TableDeleteAll(TABLE)
            //revisar las servicios seleccionados
            FOR EACH ROW OF WIN_FacturarInvoice.TABLE
                IF WIN_FacturarInvoice.TABLE.TBL_CHECK = False THEN CONTINUE
                
                n = TableAddLine(TABLE)
                TABLE[n].TBL_SERVICIO 		= WIN_FacturarInvoice.TABLE.TBL_SERVICIO 		
                TABLE[n].TBL_CANTIDAD 		= WIN_FacturarInvoice.TABLE.TBL_CANTIDAD 		
                TABLE[n].TBL_REMISIONES 	= WIN_FacturarInvoice.TABLE.TBL_REMISIONES 	
                TABLE[n].TBL_UNIDAD 		= WIN_FacturarInvoice.TABLE.TBL_UNIDAD 		
                TABLE[n].TBL_DESCRIPCION 	= WIN_FacturarInvoice.TABLE.TBL_DESCRIPCION
                TABLE[n].TBL_UNITARIO 		= WIN_FacturarInvoice.TABLE.TBL_UNITARIO 		
                TABLE[n].TBL_IMPORTE 		= WIN_FacturarInvoice.TABLE.TBL_IMPORTE 		
                TABLE[n].TBL_PESO 			= WIN_FacturarInvoice.TABLE.TBL_PESO 			
                TABLE[n].TBL_RETENCION		= WIN_FacturarInvoice.TABLE.TBL_RETENCION	
                IF WIN_FacturarInvoice.TABLE.TBL_RETENCION = 1 THEN
                    TABLE[n].TBL_RET	= Round(TABLE[n].TBL_IMPORTE * 0.04, 2)	
                END
                TABLE[n].TBL_FLETE			= WIN_FacturarInvoice.TABLE.TBL_FLETE			
                TABLE[n].TBL_IVA			= WIN_FacturarInvoice.TABLE.TBL_IVA			
                TABLE[n].TBL_IMPUESTO		= WIN_FacturarInvoice.TABLE.TBL_IMPUESTO		
                
                IF TABLE[n].TBL_NETO = 0 THEN
                    TABLE[n].TBL_NETO = TABLE[n].TBL_IMPORTE - TABLE[n].TBL_DESCUENTO + TABLE[n].TBL_IMPUESTO - TABLE[n].TBL_RET
                END
                //this.factura.notas = "INVOICE #" + WIN_FacturarInvoice.COMBO_Facturas.COL_Folio
            END
            let sInvoices = ''
            FOR EACH ROW OF WIN_FacturarInvoice.TABLE_Invoice
                IF WIN_FacturarInvoice.FAC_CHECK = False THEN CONTINUE
                IF sInvoices <> "" THEN sInvoices += ","
                sInvoices =+ WIN_FacturarInvoice.FAC_FOLIO
            END
            this.factura.notas = "INVOICE #" + sInvoices

            IF NoSpace(STC_ORIGENNOMBRE) 	= "" THEN CTPL_Remitente.Busqueda(True)
            IF NoSpace(STC_DESTINONOMBRE) 	= "" THEN CTPL_Destinatario.Busqueda(True)

            GetTotal()*/

        },
        GetTotal(){
            let nDecimales = 2

            this.factura.peso_1 = 0
            this.factura.peso_2 = 0
            this.factura.peso_3 = 0
            this.factura.peso_4 = 0
            this.factura.peso_5 = 0
            this.factura.peso_6 = 0
            this.factura.peso_7 = 0

            this.factura.tservicios = 0
            this.factura.oingresos = 0
            this.factura.descuento = 0

            if(this.factura.cservicios == true){
                this.servicios.forEach((partida)=>{
                    if(this.$ext == "auc")  nDecimales = 4 //PRUEBA DE REDONDEO PARA CFDI 3.3 CON MAS DE DOS DECIMALES EN CONCEPTOS 
                   
                    partida.importe  = Math.round(partida.unitario * partida.cantidad,nDecimales)
                    partida.impuesto = Math.round((partida.importe-partida.descuento) * (partida.iva / 100),nDecimales)
                    
                    this.factura.tservicios += partida.importe
                    if(partida.flete != "S"){ 
                        this.factura.oingresos += Math.round(partida.unitario * partida.cantidad,nDecimales)
                    }
                    partida.total = (partida.importe -partida.descuento) + partida.impuesto - partida.ret
                    this.factura.descuento += partida.descuento
                })
            }

            this.factura.pesototal = this.factura.peso_1 + this.factura.peso_2 + this.factura.peso_3 + this.factura.peso_4 + this.factura.peso_5 + this.factura.peso_6 + this.factura.peso_7
            let Seguro = this.$store.state.PARAMS.seguro
            if(Seguro > 0 && this.factura.valordeclarado > 0){
                this.factura.seguro = (this.factura.valordeclarado * (Seguro/100))
            }
            if(this.factura.cservicios != true){
                this.factura.subtotal = this.factura.flete + this.factura.seguro + this.factura.maniobras + this.factura.autopistas + this.factura.otros + this.factura.recoleccion + this.factura.reparto + this.factura.dobleoper
                if(this.factura.flete + this.factura.seguro + this.factura.maniobras + this.factura.autopistas + this.factura.recoleccion + this.factura.reparto + this.factura.dobleoper == 0 && this.factura.otros > 0 ){
                    this.factura.oingresos = this.factura.otros
                }
            }else{
                this.factura.subtotal = this.factura.tservicios
            }

      
            if(this.factura.cservicios != true){
                this.factura.iva = this.factura.subtotal * (this.factura.piva/100)
            }else{
                this.factura.iva = this.servicioTotalImpuesto
            }
            this.factura.retencion = 0
            if(this.factura.retener == true){
                if(this.factura.cservicios != true){
                    let base = this.factura.flete
                    this.factura.retencion = (base * (this.$store.state.PARAMS.retencion/100))
                }else{
                    this.factura.retencion = this.servicioTotalRetencion
                }
            }else{
                this.factura.retencion = 0
            }

            //redondear para que en el total coincidan los centavos //solicitado por WILLIE para regiotransportes montemayor el 23/07/2014
            //if gsExtension == "RTM" then
                this.factura.subtotal   = Math.round(this.factura.subtotal ,2)
                this.factura.iva        = Math.round(this.factura.iva,2)
                this.factura.retencion  = Math.round(this.factura.retencion,2)	
            //END

            if(this.$ext == "gsl" && this.factura.retnom == true && this.retenciones.length == 0 ){
                this.factura.retnom = !this.factura.retnom
                this.retnom_procedure()
            }

            this.CalcularOtrasRetenciones() //SOLICITADO POR WILLIE ENERO 11, 2018 A SOLICITUD DE TREKSA
            
            if(this.factura.bonifxcuota == undefined) this.factura.bonifxcuota =0
            if(this.factura.otrasretenciones == undefined) this.factura.otrasretenciones =0
         //  console.log('subtotal:'+ this.factura.subtotal)
         //  console.log('descuento:'+ this.factura.descuento)
         //  console.log('iva:'+ this.factura.iva)
         //  console.log('retencion:'+ this.factura.retencion)
         //  console.log('bonifxcuota:'+ this.factura.bonifxcuota)
         //  console.log('otrasretenciones:'+ this.factura.otrasretenciones)

            this.factura.total = Math.round(this.factura.subtotal - this.factura.descuento + this.factura.iva - this.factura.retencion - this.factura.bonifxcuota - this.factura.otrasretenciones, 2)
        },
        CalcularOtrasRetenciones(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "metodo", archivo: "Facturas", metodo:"CalcularOtrasRetenciones",params: {} })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }
            }).then((data)=>{
                this.factura.otrasretenciones = data
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        CargarCFDIRelacionados(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "metodo", archivo: "Facturas", metodo:"CargarCFDIRelacionados",params: {factura: this.factura} })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }
            }).then((data)=>{
                this.garrCFDIRelacionados = data
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        importarXML(){
            document.querySelector('#uploadxml').click()
        },
        dragFile(e) {
            this.xmlFile = e.dataTransfer.files;
        },
        requestUploadFile(e){
            this.xmlFile  = e.target.files
            
            let parser = new DOMParser()
            let myXML = parser.parseFromString(this.xmlFile, 'text/xml')
            console.log(myXML.getElementsByTagName('value'))
            
            console.log(myXML)
            /*
            myXML is xmlDocument <description="ARInvoices1007 FC6465"> = XMLOpen(sArchivo,fromFile)
            IF ErrorOccurred = True THEN
                Info("No se pudo abrir el archivo : "+sArchivo,ErrorInfo(errInfo))
                RETURN
            END
            this.factura.cservicios = 'S'
            //ExecuteProcess(CBOX_FAC_CSERVICIOS,trtChange)

            FAC_PEDIMENTO = myXML.Logitude.Invoices.Invoice.ShipmentNumber
            sCliente is string = myXML.Logitude.Invoices.Invoice.BillTo.Name 
            CTPL_MONEDA.LUP_CAMPO = myXML.Logitude.Invoices.Invoice.InvoiceCurrency = "USD" ? "DLS" ELSE "NAL"
            CTPL_MONEDA.Busqueda()
            dFecha is Date = FAC_FECHA
            IF CTPL_MONEDA = "NAL" THEN FAC_TIPOCAMBIO = 1 ELSE FAC_TIPOCAMBIO = GetTCambioFac("DLS",dFecha)
            FAC_FRACCION = myXML.Logitude.Invoices.Invoice.CustomerReference
            FAC_NOTAS = myXML.Logitude.Invoices.Invoice.InvoiceNotes

            COMBO_QUIENPAGA = "O"
            FAC_CLIENTE   = BusquedaCliente()
            IF FAC_CLIENTE > 0 THEN SC_CLIENTE.Busqueda()

            myDetail is xmlNode = myXML.Logitude.Invoices.Invoice.ShipmentDetails
            FAC_REMOLQUE1 	= myDetail.AMSBL
            FAC_CUOTAXTONELADA= myDetail.TotalNumberOfContainers
            FAC_OBSERVACIONES = myDetail.DescriptionOfGoods
            NAV_NAVIERA       = myDetail.MainCarriageCarrierName

            STC_ORIGENNOMBRE      = myDetail.ShipperName
            STC_ORIGENDIRECCION   = myDetail.ShipperFullAddress
            FAC_ORIGEN            = myDetail.PreCarriageFromPortName = "" ? "NA" ELSE myDetail.PreCarriageFromPortName
            FAC_RECOGEREN         = myDetail.FromPortName

            STC_DESTINONOMBRE     = myDetail.ConsigneeName
            STC_DESTINODIRECCION  = myDetail.ConsigneeFullAddress
            FAC_DESTINO			  = myDetail.OnCarriageToPortName = "" ? "NA" ELSE myDetail.OnCarriageToPortName
            FAC_ENTREGAREN        = myDetail.FinalPortName
            FAC_VIAJE			  = myDetail.BookingNumber
            NAV_LRECEPCION		  = myDetail.PickUpAddress
            NAV_LENTREGA		  = myDetail.FinalPortName
            FAC_DOLLY			  = myDetail.MainCarriageVessel
            FAC_KMS				  = myDetail.MainCarriageCarrierNumber
            CTPL_TRACTOR.LUP_CAMPO = BusquedaBarco()
            IF CTPL_TRACTOR.LUP_CAMPO > 0 THEN CTPL_TRACTOR.Busqueda()

            sCliente			  = myDetail.Notify1Name
            SC_NOTIFY.NOTIFY      = BusquedaCliente()
            IF NOTIFY > 0 THEN SC_NOTIFY.Busqueda()

            sCliente			  = myDetail.Notify2Name
            SC_NOTIFYALSO.NOTIFY_ALSO = BusquedaCliente()
            IF NOTIFY_ALSO > 0 THEN SC_NOTIFYALSO.Busqueda()

            InvoiceLine is xmlNode
            FOR EACH InvoiceLine OF myXML.Logitude.Invoices.Invoice.InvoiceLines ON InvoiceLine
                n is int = TableAddLine(TABLE)
                TBL_SERVICIO[n]		  = 16
                TBL_CANTIDAD[n]       = InvoiceLine.Quantity
                TBL_UNIDAD[n]		  = InvoiceLine.MeasurementCode
                TBL_DESCRIPCION[n]	  = InvoiceLine.Description //+ " - " + InvoiceLine.PackageType
                TBL_UNITARIO[n]	 	  = InvoiceLine.UnitPrice
                TBL_IMPORTE[n]		  = TBL_CANTIDAD[n] * TBL_UNITARIO[n]
                TBL_IVA[n]			  = InvoiceLine.TaxPercentage
                TBL_IMPUESTO[n]		  = Round(TBL_IMPORTE[n] * TBL_IVA[n] / 100, 2)
            END
            GetTotal()

            PackageLineElement is xmlNode
            FOR EACH PackageLineElement OF myXML.Logitude.Invoices.Invoice.ShipmentDetails.PackageLines ON PackageLineElement
            //	sSellos is string     = PackageLineElement.PackageMarksAndNumbers
            //	sSSello is string	  = ExtractString(sSellos,2,["Seal: ","Seal2: "],FromBeginning)+"/"+ExtractString(sSellos,2,"Seal2: ",FromBeginning)
                
                n = TableAddLine(TABLE_Naviera)
                IF n = 1 THEN
                    COL_NAV_PESO[n] = myDetail.TotalGrossWeight
                    COL_NAV_UNIDADPESO[n] = myDetail.GrossWeightUnitCode
                END
                COL_NAV_SELLO[n]	  = PackageLineElement.SealNumber
                COL_NAV_CONTENEDOR[n] = PackageLineElement.ContainerNumber
                COL_NAV_VOLUMEN[n]	  = PackageLineElement.PackageVolume
                COL_NAV_DESCRIPCION[n]= PackageLineElement.PackageType
                COL_NAV_CANTIDAD[n] = 1
            END

            FAC_PIVA = myXML.Logitude.Invoices.Invoice.InvoiceLines.InvoiceLine.TaxPercentage

            XMLClose("myXML")


                INTERNAL PROCEDURE BusquedaCliente()
                    sBusca is string = NoSpace(sCliente)
                    sBusca = Replace(sBusca,"'"," ")
                    sBusca = Replace(sBusca," ","%")
                    
                    sConsulta = "SELECT CTE_CLIENTEID FROM Clientes WHERE CTE_NOMBRE Like '%"+sBusca+"%'"
                    HExecuteSQLQuery("QRY1",ConnectionName,hQueryWithoutCorrection,sConsulta)
                    HReadFirst("QRY1")
                    nCte is int = HRetrieveItem("QRY1",1)
                    RESULT nCte
                END
                
                INTERNAL PROCEDURE BusquedaBarco()
                    sBusca is string = NoSpace(FAC_DOLLY)
                    sBusca = Replace(sBusca,"'"," ")
                    sBusca = Replace(sBusca," ","%")
                    
                    sConsulta = "SELECT VEH_NUMERO FROM Vehiculos WHERE VEH_ECONOMICO Like '%"+sBusca+"%'"
                    HExecuteSQLQuery("QRY1",ConnectionName,hQueryWithoutCorrection,sConsulta)
                    HReadFirst("QRY1")
                    nBarco is int = HRetrieveItem("QRY1",1)
                    RESULT nBarco
                END
                
                */
        },
        getTrDestinos(e){
            let tmpDest = []
            tmpDest.id = e.numid
            tmpDest.nombre = e.nombre
            this.destinos.push(e)
            this.openTrDestinos = false
        },
        opcionesServicio(){
            if(this.$refs.BTN_Cambiar.hasAttribute('disabled') == false){
                return
            }
            if(this.selectedServicio.ccosto > 0){
                return
            }

            if(this.$ext != "cst" ){
                return
            }

            let nCCosto = 0
            this.showNotification = true
            this.notificationTitle = 'Importante'
            this.notificationText = "Captura el centro de costo" //nCCosto

            if(nCCosto <= 0 ){
                return
            }

            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "metodo", 
                                        archivo: "Facturas", 
                                        metodo:"opcionesServicio",
                                        params: {pda: this.selectedServicio} })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }
            }).then((data)=>{
                if(data['msg'] != ''){
                    this.showNotification = true
                    this.notificationTitle = "Error"
                    this.notificationText = data['msg']
                }else{
                    if(data['ccosto']){
                        this.selectedServicio = data['ccosto']
                    }
                }
               
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        deleteDestinos(){
            this.destinos = this.destinos.filter((r)=>{
                return r.numid != this.selectedDestino.numid
            })
        },
        deleteMercancias(){
            this.mercancias = this.mercancias.filter((r)=>{
                return r.clave != this.selectedMercancia.clave
            })
        },
        exitMoneda(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "metodo", archivo: "Global", metodo: 'GetTCambio', 
                                        params: { fecha: this.factura.fecha, moneda: this.factura.moneda } })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data.openFicha){
                    this.openTipoCambio = true
                    this.factura.cfd = false
                }else{
                    let num = parseFloat(data.tipocambio)
                    this.factura.tipocambio = num.toFixed(4)
                }
            }).catch((err)=>{
                this.falseToken(err)
            })
        },
        receiveTC(val){
            this.openTipoCambio = false
            let num = parseFloat(val)
            this.factura.tipocambio =  num.toFixed(4)
        },
        exitRemitente(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "metodo", archivo: "Facturas", metodo: 'exitRemitente', 
                                        params: { factura: this.factura } })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.factura = data
                this.exitCliente()
            }).catch((err)=>{
                this.falseToken(err)
            })
        },
        exitDestinatario(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "metodo", archivo: "Facturas", metodo: 'exitDestinatario', 
                                        params: { factura: this.factura } })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.factura = data
                this.retnom_visible = data.retnom_visible
                this.exitCliente()
            }).catch((err)=>{
                this.falseToken(err)
            })
        },
        exitCliente(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "metodo", archivo: "Facturas", metodo: 'exitCliente', 
                                        params: { factura: this.factura, modo:this.modo } })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data.msg){
                    this.showNotification = true;
                    this.notificationTitle= "error";
                    this.notificationTexto = data.msg;
                    this.factura.cliente = 0
                }else{
                    this.factura = data.factura
                    if(data.retenciones){
                        this.factura.otrasretenciones_mostrar = data.retenciones.otrasretenciones_mostrar
                        this.retenciones = data.retenciones.retenciones
                    }
                    this.exitMoneda()
                    this.GetTotal()
                }
            }).catch((err)=>{
                this.falseToken(err)
            })

        },

        retnom_procedure(){
            this.retenciones = []
            if(this.factura.retnom == true){
                let tmp = {retencion:"RETNOM",formula:"FAC_SUBTOTAL * 0.03"}
                this.retenciones.push(tmp)
            }
            this.GetTotal()
        },
        closeToneladas(){
            this.showPreguntaToneladas = false
            return
        },
        GeneraServicioFlete(){
            this.showPreguntaToneladas = true
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "metodo", archivo: "Facturas", metodo: 'GeneraServicioFlete', 
                                        params: { factura: this.factura.serie, cfd: this.factura.cfd } })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data.msg){
                    this.showNotification = true;
                    this.notificationTitle= "error";
                    this.notificationTexto = data.msg;
                    this.factura.cfd = false
                }else{
                    console.log(data)
                    this.factura.serie = data.serie
                    this.Disabled_CFD = data.cfd
                }
                if(data.cfd){
                    this.factura.nofiscal = data.nofiscal
                }
            }).catch((err)=>{
                this.falseToken(err)
            })
        },
        BonificaxCuota(){
            let rImporte 
            this.servicio.foreach((e)=>{
                if(this.servicios.cuotasind == "S") rImporte += e.importe
            })

            if(this.factura.bonifxcuota_porc > 0 ){
                this.factura.bonifxcuota = rImporte * (this.factura.bonifxcuota_porc / 100)
            }else{
                this.factura.bonifxcuota = 0
            }

            this.GetTotal()
        },
        CalculoServicios(){
            console.log(this.selectedServicio)
            if(this.gnDecimales == 0) this.gnDecimales = 2

            this.selectedServicio.unitario = Number(this.selectedServicio.unitario)
            this.selectedServicio.importe_antes_descuento = Number(this.selectedServicio.importe_antes_descuento)
            this.selectedServicio.descuento = Number(this.selectedServicio.descuento)
            this.selectedServicio.importe = Number(this.selectedServicio.importe)
            this.selectedServicio.retencion = Number(this.selectedServicio.retencion)
            this.selectedServicio.impuesto = Number(this.selectedServicio.impuesto)
            this.selectedServicio.iva = Number(this.selectedServicio.iva)
            this.selectedServicio.neto = Number(this.selectedServicio.neto)

            this.selectedServicio.importe_antes_descuento = (this.selectedServicio.cantidad * this.selectedServicio.unitario).toFixed(this.gnDecimales)
           
            this.selectedServicio.importe = (this.selectedServicio.importe_antes_descuento - this.selectedServicio.descuento).toFixed(this.gnDecimales)
            if(this.selectedServicio.cpn_retencion == true ){
                this.selectedServicio.retencion = (this.selectedServicio.importe * (this.grRetencion/100)).toFixed(this.gnDecimales)
            }else{
                this.selectedServicio.retencion = 0
            }
            this.selectedServicio.impuesto = (this.selectedServicio.importe * (this.selectedServicio.iva / 100)).toFixed(this.gnDecimales)
            console.log((this.selectedServicio.importe + this.selectedServicio.impuesto - this.selectedServicio.retencion))
            this.selectedServicio.neto = (this.selectedServicio.importe + this.selectedServicio.impuesto - this.selectedServicio.retencion).toFixed(this.gnDecimales) 

            if(this.selectedServicio.impuesto > 0) this.selectedServicio.objeto = "02"
            console.log(this.selectedServicio)
        },
        servicioSeleccionado(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "metodo", archivo: "Facturas", metodo: 'exitServicio', 
                                        params: { servicio: this.selectedServicio.servicio } })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
                console.log(response)
            }).then((data)=>{
                this.selectedServicio = data.servicio
                this.gnDecimales = data.gnDecimales
                this.CalculoServicios()
            }).catch((err)=>{
                this.falseToken(err)
            })
        },
        exitExento(){
            this.serviciosIvaDisabled = this.selectedServicio.exento
            if(this.selectedServicio.exento) this.selectedServicio.iva = 0
            this.Calculo()
        },
        
    }
}
</script>