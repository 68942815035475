<template>
    <section>
        <SideBar v-if="!OpenMode" />
        <TopBar v-if="!OpenMode" />
        <div :class="[!OpenMode ? 'content' : '' ]">   
        <div class="main-content-header">
            <h1>Pólizas de Seguro</h1>
        </div>
        <LoadingContent v-if="isLoading" />
        <div class="row">
            <div class="col-lg-12 col-md-6">
                <div class="card mb-30" :style="{height:[contentHeight+'px']}">
                    <div class="row" :class="[!OpenMode ? 'p-4' : '']">
                        <div class="content-header col-12"> 
                            <div class="row">
                                <div class="col-md-10 col-12 text-start "></div>
                                <div class="col-md-2 col-12"><p class="p-1 pe-4 mb-0 text-end">Página {{ paginaActual }} de {{ totalPaginas }} </p></div>
                                <div class="col-md-2 offset-md-10 col-12 ps-2 pe-5 text-end pb-0 pt-0">
                                    <ul class="list-unstyled mb-0 d-flex justify-content-between pager-btns float-right">
                                        <li><button @click="setPaginacion('first')" type="button" class="pager pager-first btn btn-sm btn-outline-secondary"><ChevronDoubleLeftIcon /></button></li>
                                        <li><button @click="setPaginacion('prev')"  type="button" class="pager pager-prev  btn btn-sm btn-outline-secondary"><ChevronLeftIcon /></button></li>
                                        <li><button @click="setPaginacion('next')"  type="button" class="pager pager-next  btn btn-sm btn-outline-secondary"><ChevronRightIcon /></button></li>
                                        <li><button @click="setPaginacion('last')"  type="button" class="pager pager-last  btn btn-sm btn-outline-secondary"><ChevronDoubleRightIcon /></button></li> 
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-10 col-12">
                            <div class="table-responsive ps-2">
                                <b-table-simple class="browse" hover small caption-top responsive >
                                    <b-thead head-variant="light" class="table-header">
                                        <b-tr>
                                            <b-th class="text-nowrap">No.</b-th>
                                            <b-th class="text-nowrap">Póliza</b-th>
                                            <b-th class="text-nowrap">Inicio de Vigencia</b-th>
                                            <b-th class="text-nowrap">Fin de Vigencia</b-th>
                                            <b-th class="text-nowrap">Proveedor</b-th>
                                            <b-th class="text-nowrap">Nombre</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="(poliza,indx) in polizas" 
                                            :key="poliza.numero" 
                                            @click="selectedRegistro=poliza;selectedRegistro.indx = indx" 
                                            @dblclick="(!OpenMode ? modificar() : exportRegistry()) " 
                                            :class="{ 'selected-row': (selectedRegistro.numero == poliza.numero),'text-info':is_blue(poliza), 'text-danger': is_red(poliza) }">
                                            <b-td class="text-nowrap text-center">{{poliza.numero | leadingZeros(7)}}</b-td>
                                            <b-td class="text-nowrap">{{ poliza.poliza }}</b-td>
                                            <b-td class="text-nowrap text-end">{{ formato(poliza.vigenciaini,"YYYY-MM-DD") }}</b-td>
                                            <b-td class="text-nowrap text-end">{{ formato(poliza.vigenciafin,"YYYY-MM-DD") }}</b-td>
                                            <b-td class="text-nowrap text-center">{{ poliza.proveedor | leadingZeros(5) }}</b-td>
                                            <b-td class="text-nowrap">{{ poliza.nombre}}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </div>
                        </div> 
                        <div class="col-2 ps-2 pe-5 btn-manager" >
                            <ul class="d-block list-unstyled action-block">
                                <li v-if="!OpenMode"><router-link to="/polizas/agregar" class="btn btn-xs btn-success btn-block">Agregar</router-link></li>
                                <li v-if="!OpenMode"><button @click="modificar()" :disabled="disableBtn" type="button" class="btn btn-xs btn-primary btn-block">Cambiar</button></li>
                                <li v-if="!OpenMode"><button @click="ask()" :disabled="disableBtn" class="btn btn-xs btn-danger btn-block">Borrar</button></li>
                                <li v-if="!OpenMode"><button @click="imprimir()" class="btn btn-xs btn-secondary btn-block">Imprimir</button></li>
                                <li v-if="OpenMode"><button @click="exportRegistry()" class="btn btn-xs btn-success btn-block">Elegir</button></li>
                            </ul>
                            <div class="form-group">
                                <div class="form-check">
                                    <input class="form-check-input" v-model="conVencidas" @change="consultar()" type="checkbox" id="incVencidas">
                                    <label class="form-check-label" for="incVencidas">
                                        Inc. Vencidas
                                    </label>
                                </div>
                            </div>
                            <div class="form-group" v-show="$ext == 'tpr'">
                                <div class="form-check">
                                    <input class="form-check-input" v-model="fPropios" @change="consultar()" type="checkbox" id="propios">
                                    <label class="form-check-label" for="propios">
                                        Propios
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" v-model="fPermisionario" @change="consultar()" type="checkbox" id="permisionario">
                                    <label class="form-check-label" for="permisionario">
                                        Permisionario
                                    </label>
                                </div>
                            </div>
                        </div>
                        
                        <div class="content-footer col-12 text-start"></div>
                    </div>
                </div>
            </div>
        </div>
        <YesNoPopupVue  v-show="showNotification" @close="closeInfo" @aceptar="borrar()">
            <template v-slot:header>
                <h6 class="text-uppercase">{{notificationTitle}}</h6>
            </template>
            <template v-slot:body>
                {{notificationText}}
            </template>
        </YesNoPopupVue>
        </div>
    </section>
</template>

<script>
import YesNoPopupVue from '@/components/YesNoPopup.vue';
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronDoubleRightIcon from 'vue-material-design-icons/ChevronDoubleRight.vue';
import ChevronDoubleLeftIcon from 'vue-material-design-icons/ChevronDoubleLeft.vue';
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'
import handleBrowse from '@/mixins/handleBrowse'
import handlePopUp from '@/mixins/handlePopUp'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';

export default {
    name:'WIN_Polizas',
    props:['mode','perpage'],
    mixins:[handleGlobals,handleBrowse,handlePopUp],
    components:{SideBar,TopBar,ChevronLeftIcon,ChevronDoubleRightIcon,ChevronDoubleLeftIcon,ChevronRightIcon, LoadingContent,YesNoPopupVue},
    data(){
        return{
            polizas: [],
            conVencidas: false,
            fPropio:'',
            fPermisionario:'',
            filtroPermisionario:'',
            operador: '=',
            today: new Date()
        }
    },
    created(){
        this.today = this.formato(this.today,'YYYY-MM-DD')
        if(this.OpenMode == false){
            this.getFiltros()
        } 
    },
    methods:{
        is_red(poliza){
            if(poliza.vigenciafin != ''){
                let today = new Date()
                today = this.formato(today,'YYYY-MM-DD')
              //  console.log(this.formato(poliza.vigenciafin,'YYYY-MM-DD')+' < '+ today+ ' = '+ (this.formato(poliza.vigenciafin,'YYYY-MM-DD') < today) )
                if(this.formato(poliza.vigenciafin,'YYYY-MM-DD') < today ){
                    return true
                }
            }
            return false
        },
        is_blue(poliza){
            if(poliza.vigenciafin != ''){
              //  dFecha = dFecha - 45;
                let dFecha = new Date(this.formato(poliza.vigenciafin,'YYYY-MM-DD'))
                dFecha.setDate(this.formato(this.dFecha,'YYYY-MM-DD') - 45)
                let today = new Date()
                today = this.formato(today,'YYYY-MM-DD')
                dFecha = this.formato(dFecha,'YYYY-MM-DD')
             //   console.log(dFecha+' < '+ today+ ' = '+ (dFecha < today))
                if(dFecha < today){
                    return true
                }
            }
            return false
        },
        async getFiltros(){
                await fetch(this.$api+'/rdx', {    
                    method: "POST",
                    headers: {
                        'Content-Type' : 'application/json',
                        'Authorization' : this.$store.state.iasctratkn
                    },
                    body: JSON.stringify({accion: "filters"})
                }).then((response)=>{
                    if(response.ok){
                        return response.json()
                    }
                }).then((data)=>{
                    let reg = data['POL']
                    if(reg){
                        this.fPropio        = reg['permisionario'].dato
                        this.fPermisionario = reg['permisionario'].dato
                        this.conVencidas    = reg['vigenciafin'].dato
                        this.paginaActual   = reg['paginaActual'].dato
                        if(this.paginaActual == '') this.paginaActual = 0
                    }else{
                        this.fPropio        = false
                        this.fPermisionario = false
                        this.conVencidas    = false
                        this.paginaActual   = 1
                    }
                }).catch((err)=>{
                    this.falseToken(err)
                });
        },
        consultar(){
            this.isLoading = true
            let inactivo = false
            if(this.fpropio == true && this.fpermisionario == true){
                this.filtroPermisionario = 0
            }
            else if(this.fpropio == false && this.fpermisionario == true){
                this.filtroPermisionario = 0
                this.operador = '>'
            }else{
                inactivo = true
            }
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "browse", 
                                        archivo: "Polizas", 
                                        activarPaginas: true,
                                        orden: "numero",
                                        filtro: {
                                                permisionario: {dato: this.filtroPermisionario , operador: this.operador ,inactivo: inactivo} , 
                                                vigenciafin: {dato: this.today, inactivo: this.conVencidas, operador: '>='} , 
                                                paginaActual: {dato: 'NA'}},
                                        pagina: this.paginaActual, 
                                        paginacion: this.paginacion,
                                        registrosxpagina: this.rows })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.polizas        = data['registros'];
                this.totalRegistros = data['totalRegistros'];
                this.totalPaginas   = data['totalPaginas'];
                this.paginaActual   = data['paginaActual'];
            }).finally(()=>{
                this.isLoading = false;
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        ask(){
            this.showNotification = true
            this.notificationText =  "¿ Está seguro que desea eliminar este registro ?"
            this.notificationTitle = "Precaución"
        },
        borrar(){
            this.closeInfo()
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "borrar", archivo: "Polizas", id: this.selectedRegistro.numero })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then(()=>{
                this.polizas.splice(this.selectedRegistro.indx, 1)
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        imprimir(){

        },
        modificar(){
            this.$router.push({ name: 'CPolizas', params: {poliza: this.selectedRegistro.numero}});
        }
    }
}
</script>

