<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Maestro de Clientes</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row w-40">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-9 col-12">
                                            <form>
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-md-3 col-4 text-nowrap">Clave</label>
                                                            <div class="col-md-9 col-8">
                                                                <input type="text" ref="clave" maxlength="3" class="form-control form-control-sm "  v-model="moneda.clave" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-md-3 col-4 text-nowrap">Descripcion</label>
                                                            <div class="col-md-9 col-8">
                                                                <input type="text" ref="descripcion" maxlength="20"  class="form-control form-control-sm "  v-model="moneda.descripcion" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-md-3 col-4 text-nowrap">Abreviación</label>
                                                            <div class="col-md-9 col-8 ">
                                                                <input type="text" ref="abrev" maxlength="8" class="form-control form-control-sm "  v-model="moneda.abrev" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-md-3 col-4 text-nowrap">Tipo de Cambio</label>
                                                            <div class="col-md-9 col-8">
                                                                <input type="number" min="0" step="any" ref="tipocambio" class="form-control form-control-sm "  v-model="moneda.tipocambio" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-md-3 col-4 text-nowrap">Fecha</label>
                                                            <div class="col-md-9 col-8 ">
                                                                <input type="date" ref="fecha" class="form-control form-control-sm "  v-model="moneda.fecha" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="col-md-3 col-12">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li class="mobile-float"><button @click="setMoneda()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li  class="mobile-float"><router-link to="/moneda" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <InfoPopUpVue  v-show="showNotification" @close="closeInfo">
                        <template v-slot:header>
                            <h6 class="text-uppercase">{{notificationTitle}}</h6>
                        </template>
                        <template v-slot:body>
                            {{notificationText}}
                        </template>
                    </InfoPopUpVue>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import InfoPopUpVue from '@/components/InfoPopUp.vue';
import handleGlobals from '@/mixins/handleGlobals'
import handlePopUp from '@/mixins/handlePopUp'
import handleForm from '@/mixins/handleForm'
import LoadingContent from '@/components/LoadingSpinner.vue'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    mixins:[handleGlobals,handlePopUp,handleForm],
    components:{SideBar,TopBar,InfoPopUpVue,LoadingContent},
    data(){
        return{
            id: this.$route.params.moneda,
            modo: (this.$route.params.moneda != '' && this.$route.params.moneda != undefined ? 'Modificando' : 'Creacion'), 
            moneda: [],
            titulo: 'Agregando una Moneda',  
        }
    },
    mounted(){
        this.getMoneda();
    },
    methods:{
        async getMoneda(){
            this.isLoading = true
            let req =[]
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "inicializar",modo:this.modo,  archivo: "Monedas", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.moneda = data['res'];
                req = data['req']
            }).finally(()=>{
               this.setRequired(req)
               this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setMoneda(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: 'validar', archivo: "Monedas", modo:this.modo, id: this.id, record: this.moneda }) 
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['res'] == 'error'){
                    this.showNotification = true
                    this.notificationTitle =  'Error'
                    this.notificationText = data['msg']
                    this.notificationRef = 'clave'
                }else{
                    if(data['msg'] != ''){
                        this.showNotification = true
                        this.notificationTitle = 'Error'
                        this.notificationText = data['msg']
                        return
                    }else{
                        this.$router.push('/moneda')
                    }
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        afterClose(){
            this.$refs[this.notificationRef].focus()
        }
     
    }
}
</script>