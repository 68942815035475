<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Tipos de Transacciones</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row w-40">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-9">
                                            <form>
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-3 text-nowrap">Tipo</label>
                                                            <div class="col-9 d-flex">
                                                                <input type="text" id="tipono" ref="tipono" class="form-control form-control-sm" style="border:none; background-color:transparent;color:red !important" disabled  v-model="tipo.tipono" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-3 text-nowrap">Descripcion</label>
                                                            <div class="col-9 d-flex">
                                                                <input type="text" id="descripcion" ref="descripcion" class="form-control form-control-sm " required v-model="tipo.descripcion" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                                <div class="row ">
                                                    <fieldset class="col-12">
                                                        <legend>Cargo o Crédito</legend>
                                                        <div class="form-check col-6">
                                                            <input ref="carcre1" class="form-check-input" :value="0" v-model="tipo.carcre " :disabled="modo == 'Modificando'" type="radio" id="carcre1">
                                                            <label class="form-check-label" for="carcre1">Cargo</label>
                                                        </div>
                                                        <div class="form-check col-6">
                                                            <input ref="carcre2" class="form-check-input" :value="1" v-model="tipo.carcre " :disabled="modo == 'Modificando'" type="radio" id="carcre2">
                                                            <label class="form-check-label" for="carcre2">Crédito</label>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            
                                            </form>
                                        </div>
                                        <div class="col-3">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li><button @click="setTipoTra()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li><router-link to="/tpotransacciones" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <InfoPopUpVue  v-show="showNotification" @close="closeInfo">
                        <template v-slot:header>
                            <h6 class="text-uppercase">{{notificationTitle}}</h6>
                        </template>
                        <template v-slot:body>
                            {{notificationText}}
                        </template>
                    </InfoPopUpVue>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import InfoPopUpVue from '@/components/InfoPopUp.vue';
import handleGlobals from '@/mixins/handleGlobals'
import handlePopUp from '@/mixins/handlePopUp'
import handleForm from '@/mixins/handleForm';
import LoadingContent from '@/components/LoadingSpinner.vue'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    mixins:[handleGlobals,handlePopUp,handleForm],
    components:{SideBar,TopBar,InfoPopUpVue,LoadingContent},
    data(){
        return{
            id: this.$route.params.tipo,
            modo: (this.$route.params.tipo > 0 ? 'Modificando' : 'Creacion'), 
            tipo: {},
            titulo: 'Agregando una Transacción',  
        }
    },
    mounted(){
       this.getTpoTra();
    },
    methods:{
        async getTpoTra(){
            this.isLoading = true
            let req = []
           await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "inicializar", modo:this.modo, archivo: "TiposTra", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.tipo = data['res'];
                req = data['req']
            }).finally(()=>{
                this.setRequired(req)
                this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setTipoTra(){

            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: 'validar', archivo: "TiposTra", modo:this.modo, record: this.tipo }) 
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['res'] == 'error'){
                    this.showNotification = true
                    this.notificationTitle =  'Error'
                    this.notificationText = data['msg']
                    this.notificationRef = 'clave'
                }else{
                    this.$router.push('/tpotransacciones')
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        afterClose(){
            this.$refs[this.notificationRef].focus()
        }
     
    }
}
</script>