export default {
    data(){
        return{
            deleteText: '',
            deleteTitle:'',
            showDelete:false,
        }
    },
    methods:{
        setEliminar(){
            this.borrar()
        },
        closeDelete(){
            this.showDelete = false
            this.deleteText = ''
            this.deleteTitle = ''
        },
       
    },
   
};