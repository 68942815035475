<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Pólizas de Seguro</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row w-75">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-10 col-12">
                                            <form>
                                                <div class="row">
                                                    <div class="col-md-4 col-12 offset-md-8 d-flex">
                                                        <label class="col-6 text-nowrap text-end">Número</label>
                                                        <div class="col-4">
                                                            <input type="text" ref="numero" class="form-control text-end form-control-sm" v-model="poliza.numero" />													
                                                        </div>
                                                    </div>
                                                </div>
                                                <nav>
                                                    <div class="nav nav-underline" id="nav-tab" role="tablist">
                                                        <li class="nav-item"><a class="nav-item nav-link active" id="nav-general-tab" data-bs-toggle="tab" href="#nav-general" role="tab" aria-controls="nav-general" aria-selected="true">General</a></li>
                                                        <li class="nav-item" ><a v-if="modo == 'Modificando'"  class="nav-item nav-link" id="nav-unidad-tab" data-bs-toggle="tab" href="#nav-unidad" role="tab" aria-controls="nav-unidad" aria-selected="false">Unidades</a></li>
                                                    </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="container p-1 tab-pane fade show active" id="nav-general" role="tabpanel" aria-labelledby="nav-general-tab">
                                                        <div class="row ">
                                                            <div class="col-12 col-md-8">
                                                                <div class=" row">
                                                                    <label class="col-2 text-nowrap">Póliza</label>
                                                                    <div class="col-10 d-flex">
                                                                        <input type="text" ref="poliza" class="form-control  form-control-sm" :disabled="modo == 'Modificando'" v-model="poliza.poliza" />													
                                                                    </div>
                                                                </div>
                                                            </div>	
                                                            <div class="col-12 col-md-4">
                                                                <div class=" row">
                                                                    <label class="col-2 text-nowrap">Inciso</label>
                                                                    <div class="col-10 d-flex">
                                                                        <input type="text" ref="inciso" class="form-control text-end form-control-sm" v-model="poliza.inciso" />													
                                                                    </div>
                                                                </div>
                                                            </div>	
                                                            <div class="col-12">
                                                                <LookUpCtrl v-if="poliza.numero" :objeto="poliza" :label="'Proveedor'" :cols="[1,2,9]" :archivo="'CpProveedores'" :campo="'prove'" :descripcion="'nombre'" :model="'proveedor'" v-model="poliza.proveedor" />
                                                            </div>	
                                                        </div>	
                    
                                                        <div class="row ">
                                                            <div class="col-12 col-md-8">
                                                                <div class=" row">
                                                                    <label class="col-2 text-nowrap">Agente</label>
                                                                    <div class="col-10 d-flex">
                                                                        <input type="text" ref="agente" class="form-control form-control-sm "  v-model="poliza.agente" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12  col-md-4">
                                                                <div class=" row">
                                                                    <label class="col-md-4 col-2 text-nowrap">Vigencia</label>
                                                                    <div class="col-md-8 col-10 d-flex">
                                                                        <input type="text" ref="agente" class="form-control form-control-sm "  v-model="poliza.agente" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row ">
                                                            <div class="col-md-4 col-12">
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap">Forma de Pago</label>
                                                                    <div class="col-8 d-flex">
                                                                        <input type="text" ref="formapago" class="form-control form-control-sm " v-model="poliza.formapago" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 col-12">
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap">Inicio</label>
                                                                    <div class="col-8 d-flex">
                                                                        <input type="date" ref="vigenciaini" class="form-control form-control-sm " v-model="poliza.vigenciaini" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 col-12">
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap">Final</label>
                                                                    <div class="col-8 d-flex">
                                                                        <input type="date" ref="vigenciafin" class="form-control form-control-sm " v-model="poliza.vigenciafin" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12" >
                                                                <LookUpCtrl v-if="poliza.numero" :objeto="poliza" :label="'Permisionario'" :cols="[1,2,9]" :archivo="'CpProveedores'" :campo="'prove'" :descripcion="'nombre'" :model="'permisionario'" v-model="poliza.permisionario" />
                                                            </div>	
                                                        </div>
                                                        <div class="row pt-3">
                                                            <div class="col-12">
                                                                <b-table-simple sticky-header hover small caption-top responsive >
                                                                    <b-thead head-variant="light" class="table-header">
                                                                        <b-tr class="table-header">
                                                                            <b-th>Cobertura</b-th>
                                                                            <b-th>Suma Asegurada</b-th>
                                                                            <b-th>Deducible</b-th>
                                                                            <b-th>Prima</b-th>
                                                                        </b-tr>
                                                                    </b-thead>
                                                                    <b-tbody v-if="partidas.length > 0">
                                                                        <b-tr v-for="(pda,x) in partidas" :key="x" 
                                                                            @click="selectedPartida = pda" 
                                                                            @dblclick="openPartidas('edit')" 
                                                                            :class="{ 'selected-row': (selectedPartida.numero == pda.numero)}">
                                                                            <b-td class="text-center">{{pda.cobertura}}</b-td>
                                                                            <b-td class="text-center">{{pda.sumaaseg}}</b-td>
                                                                            <b-td class="text-center">{{pda.deducible}}</b-td>
                                                                            <b-td class="text-center">{{pda.prima}}</b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                    <b-tbody v-else>
                                                                        <b-tr  >
                                                                            <b-td class="text-center" :variant="'secondary'" colspan="4"><p class="p-4">NO HAY DATOS</p></b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12 justify-content-end d-flex">
                                                                <ul class="list-unstyled d-flex">
                                                                    <li class="pe-1"><button type="button" @click="openPartidas('add')" class="btn btn-sm btn-secondary  w-100" >Agregar</button></li>
                                                                    <li class="pe-1"><button ref="BTN_Cambiar" type="button" @click="openPartidas('edit')" class="btn btn-sm btn-secondary w-100" >Cambiar</button></li>
                                                                    <li class="pe-1"><button type="button" @click="deletePartida()" class="btn btn-sm btn-secondary w-100">Borrar</button></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="modo == 'Modificando'" class="container p-1 tab-pane fade" id="nav-unidad" role="tabpanel" aria-labelledby="nav-unidad-tab">
                                                        <div class="row pt-4">
                                                            <div class="col-12">
                                                                <b-table-simple sticky-header hover small caption-top responsive >
                                                                    <b-thead head-variant="light" class="table-header">
                                                                        <b-tr class="table-header">
                                                                            <b-th>Inciso</b-th>
                                                                            <b-th>Tipo</b-th>
                                                                            <b-th>No.</b-th>
                                                                            <b-th>Económico</b-th>
                                                                            <b-th>Año</b-th>
                                                                            <b-th>Marca</b-th>
                                                                            <b-th>Modelo</b-th>
                                                                            <b-th>Color</b-th>
                                                                            <b-th>Placa</b-th>
                                                                        </b-tr>
                                                                    </b-thead>
                                                                    <b-tbody v-if="unidades.length > 0" >
                                                                        <b-tr v-for="(unidad,x) in unidades" :key="x" >
                                                                            <b-td class="text-center">{{unidad.incisopoliza}}</b-td>
                                                                            <b-td class="text-center">{{unidad.tipo}}</b-td>
                                                                            <b-td class="text-center">{{unidad.numero}}</b-td>
                                                                            <b-td class="text-center">{{unidad.economico}}</b-td>
                                                                            <b-td class="text-center">{{unidad.ano}}</b-td>
                                                                            <b-td class="text-center">{{unidad.marca}}</b-td>
                                                                            <b-td class="text-center">{{unidad.modelo}}</b-td>
                                                                            <b-td class="text-center">{{unidad.color}}</b-td>
                                                                            <b-td class="text-center">{{unidad.placa}}</b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                    <b-tbody v-else>
                                                                        <b-tr  >
                                                                            <b-td class="text-center" :variant="'secondary'" colspan="9"><p class="p-4">NO HAY UNIDADES</p></b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </div>
                                                        </div>
                                                                                                    
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="col-md-2 col-12">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li class="mobile-float"><button @click="setPoliza()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li><router-link to="/polizas" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Partidas v-if="showPartidas" @close="showPartidas = false" @goAhead="SetPartida" :size="40">
                    <template v-slot:title>{{tituloModo}} partida</template>
                    <template v-slot:body>
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <label class="col-2 text-nowrap ">Cobertura</label>
                                    <div class="col-10">
                                        <input type="text" maxlength="30" class="form-control form-control-sm " required v-model="selectedPartida.cobertura" />		
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <label class="col-2 text-nowrap ">Suma Asegurada</label>
                                    <div class="col-8">
                                        <input type="number" class="form-control form-control-sm" required v-model="selectedPartida.sumaaseg" />		
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <label class="col-2 text-nowrap ">Deducible</label>
                                    <div class="col-8">
                                        <input type="text" class="form-control form-control-sm" required maxlength="12" v-model="selectedPartida.deducible" />		
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <label class="col-2 text-nowrap ">Prima</label>
                                    <div class="col-8">
                                        <input type="number" class="form-control form-control-sm" required v-model="selectedPartida.prima" />		
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                    </template>
                </Partidas>
    </section>
</template>

<script>
import LookUpCtrl from '@/components/LookUpCtrl.vue';
import handleGlobals from '@/mixins/handleGlobals';
import handleForm from '@/mixins/handleForm';
import LoadingContent from '@/components/LoadingSpinner.vue'
import Partidas from '@/components/PopupWindow.vue';

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    components:{SideBar,TopBar, LookUpCtrl,LoadingContent,Partidas },
    mixins:[handleGlobals,handleForm],
    data(){
        return{
            id: this.$route.params.poliza,
            modo: (this.$route.params.poliza > 0 ? 'Modificando' : 'Creacion'), 
            poliza: [],
            unidades: [],
            partidas: [],
            titulo: 'Agregando una Póliza',
            showPartidas:false,
            tituloModo: '',
            selectedPartida:{}
        }
    },
    mounted(){
        this.llenaForma();
       
    },
    methods:{
        async llenaForma(){
        
            if(this.$route.params.poliza > 0){
                await this.getPoliza()
                this.titulo = 'Cambiando una Póliza';
                this.getVehiculos()
                this.getPartidas()
            }else{
                console.log('hola')
            }
        },
        async getPoliza(){
            let req = []
            this.isLoading = true
           await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "inicializar", modo:this.modo, archivo: "Polizas", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.poliza = data['res'];
                req = data['req']
            }).finally(()=>{
               this.setRequired(req)
               this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        getVehiculos(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: 'metodo',
                                        archivo: "Polizas",
                                        metodo: 'leerDatos',
                                        params: {poliza: this.poliza.numero} })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.unidades = data
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        getPartidas(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: 'metodo',
                                        archivo: "Polizas",
                                        metodo: 'cargaPartidas',
                                        params: {poliza: this.poliza.numero} })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.partidas = data
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        
        setPoliza(){
            this.isLoading = true
            this.poliza.partidas = this.partidas
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({   accion: 'validar', archivo: "Polizas", modo:this.modo, record: this.poliza })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then(()=>{
                this.isLoading = false
                this.$router.push('/polizas')
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        openPartidas(modo){
            this.isLoading = true
            if(modo == 'add'){
                this.tituloModo = 'Agregando'
                this.selectedPartida = {}
            }else{
                this.tituloModo = 'Creando'
            }
            this.showPartidas = true
            this.isLoading = false
        },
        SetPartida(){
            this.isLoading = true
            if(this.tituloModo == 'Agregando'){
                this.partidas.push(this.selectedPartida)
            }
            this.showPartidas = false
            this.isLoading = false
        },
        deletePartida(){
            if(this.selectedPartida){
                this.partidas = this.partidas.filter(e=>e.numero != this.selectedPartida.numero)
            }
        }
    }
}
</script>