<template>
     <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Maestro de Folios Digitales</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row  w-65">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-10 col-12">
                                            <form>
                                                <div class="row">
                                                    <div class="col-md-8 col-12">
                                                        <lookUpCtrl v-if="folio.numid" :objeto="folio" :label="'Sucursal'" :cols="[3,3,6]" :archivo="'InSucursales'" :campo="'sucursal'" :descripcion="'nombre'" :model="'sucursal'" v-model="folio.sucursal" />
                                                    </div>
                                                    <div class="col-md-4 col-12">
                                                        <div class=" row">
                                                            <label class="col-3 text-nowrap">ID</label>
                                                            <div class="col-9 ">
                                                                <input type="number" ref="numid" class="form-control form-control-sm" readonly v-model="folio.numid" />													
                                                            </div>
                                                        </div>
                                                    </div>	
                                                    <div class="col-md-6 col-12">
                                                        <div class="row">
                                                            <label class="col-6 text-nowrap text-right">Documento</label>
                                                            <div class="col-6">
                                                                <select class="form-control form-control-sm" @change="BuscarConsecutivo()" v-model="folio.docto">
                                                                    <option value="FAC">Factura</option>
                                                                    <option value="NC">Nota de Crédito</option>
                                                                    <option value="NG">Nota de Cargo</option>
                                                                    <option value="CP">Carta Porte</option>
                                                                    <option value="PAG">Pago</option>
                                                                    <option value="EFIR">e.FIRMA (FIEL)</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-12">
                                                        <div class="row">
                                                            <label class="col-6 text-nowrap">Serie</label>
                                                            <div class="col-6">
                                                                <input type="text" ref="noserie" class="form-control form-control-sm" v-model="folio.noserie" />													
                                                            </div>
                                                        </div>
                                                    </div>	
                                                    <div class="col-md-12 col-12">
                                                        <div class=" row">
                                                            <label class="col-2 text-nowrap">Certificado</label>
                                                            <div class="col-10">
                                                                <input type="text" ref="certificado" class="form-control form-control-sm" readonly v-model="folio.certificado" />													
                                                            </div>
                                                        </div>
                                                    </div>	
                                                    <div class="col-md-12 col-12">
                                                        <div class=" row">
                                                            <label class="col-2 text-nowrap">Llave Privada</label>
                                                            <div class="col-10">
                                                                <input type="text" ref="llaveprivada" class="form-control form-control-sm" readonly v-model="folio.llaveprivada" />													
                                                            </div>
                                                        </div>
                                                    </div>	
                                                </div>	
                
                                                <div class="row ">
                                                    <div class="col-8">
                                                        <div class=" row">
                                                            <label class="col-3 text-nowrap">Contraseña</label>
                                                            <div class="col-9">
                                                                <input type="password" class="form-control form-control-sm " ref="password" v-model="folio.password" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <button  class="btn btn-sm btn-outline-secondary">Revisar</button>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-4">
                                                        <div class=" row">
                                                            <label class="col-6 text-nowrap">Vigencia Inicio</label>
                                                            <div class="col-6 ">
                                                                <input type="date" ref="vigenciaini" class="form-control form-control-sm " disabled v-model="folio.vigenciaini" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class=" row">
                                                            <label class="col-6 text-nowrap">Final</label>
                                                            <div class="col-6">
                                                                <input type="date" ref="vigenciafin" class="form-control form-control-sm " disabled v-model="folio.vigenciafin" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <button @click="recuperarCerificados()" class="btn btn-sm btn-outline-secondary">Recuperar</button>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-md-4 col-12">
                                                        <div class=" row">
                                                            <label class="col-6 text-nowrap">Folio Inicio</label>
                                                            <div class="col-6">
                                                                <input type="number" ref="folioini" class="form-control form-control-sm " disabled v-model="folio.folioini" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class=" row">
                                                            <label class="col-6 text-nowrap">Final</label>
                                                            <div class="col-6">
                                                                <input type="number" ref="foliofin" class="form-control form-control-sm " disabled v-model="folio.foliofin" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row ">
                                                    <div class="col-12 ">
                                                        <div class=" row">
                                                            <label class="col-2 text-nowrap">Serie CFD</label>
                                                            <div class="col-10 ">
                                                                <input type="text" ref="serie" class="form-control form-control-sm " disabled v-model="folio.serie" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-2 text-nowrap">Versión CFD</label>
                                                            <div class="col-4">
                                                                <input type="text" ref="version" class="form-control form-control-sm " disabled v-model="folio.version" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-8 ps-3 offset-2">
                                                        <div class="form-check">
                                                            <input class="form-check-input"  v-model="folio.activo "  type="checkbox" id="activo">
                                                            <label class="form-check-label" for="activo">Asignar los Folios como los Activos</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="col-md-2 col-12">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li class="mobile-float"><button @click="setFolio()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li><router-link to="/folios" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                                <li><router-link to="/folios" class="btn btn-sm btn-danger btn-block">Certificados Actuales</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <InfoPopUpVue  v-show="showNotification" @close="closeInfo">
                    <template v-slot:header>
                        <h6 class="text-uppercase">{{notificationTitle}}</h6>
                    </template>
                    <template v-slot:body>
                        {{notificationText}}
                    </template>
                </InfoPopUpVue>

            </div>
        </div>
    </section>
</template>

<script>
import LookUpCtrl from '@/components/LookUpCtrl.vue';
import InfoPopUpVue from '@/components/InfoPopUp.vue';
import handlePopUp from '@/mixins/handlePopUp';
import handleForm from '@/mixins/handleForm';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    components:{SideBar,TopBar, LookUpCtrl,InfoPopUpVue,LoadingContent},
    mixins:[handlePopUp,handleForm,handleGlobals],
    data(){
        return{
            user: JSON.parse(Buffer.from(this.$store.state.iasctratkn.split('.')[1], 'base64').toString()),
            id: this.$route.params.folio,
            modo: (this.$route.params.folio > 0 ? 'Modificando' : 'Creacion'), 
            folio: {},
            titulo: 'Agregando un Folio'
        }
    },
  
    mounted(){
        this.llenaForma();
    },
    methods:{
        async llenaForma(){
            await this.getFolio()
            if(this.$route.params.destino > 0){
                this.titulo = 'Cambiando un Folio'
                this.$refs.nombre.focus()
            }
            if(this.bDuplicate){
                this.modo = "Creacion"
            }
        },
        async getFolio(){
            this.isLoading = true

            let req = []
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn},
                body: JSON.stringify({ accion: "inicializar",modo:this.modo, archivo: "faFoliosE", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.folio = data['res'];
                req = data['req']
                this.folio.activo = this.folio.activo == 'S'
            }).finally(()=>{
               this.setRequired(req)
               this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setFolio(){
            this.folio.activo = this.folio.activo ? 'S' : 'N'

            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: 'validar', archivo: "faFoliosE", modo:this.modo, record: this.folio })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['msg'] != '' && data['closeWindow'] == false){
                    this.notificationTitle = 'Error'
                    this.notificationText = data['msg']
                    this.showNotification = true
                }else{
                    if(data['msg'] != '' && data['closeWindow'] == true){
                        this.notificationTitle = 'Aviso'
                        this.notificationText = data['msg']
                        this.showNotification = true
                    }
                    this.$router.push('/folios')
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        recuperarCerificados(){
            if(this.user.rol == 'supervisor'){
                //recuperar certificados
            }else{
                this.notificationTitle = 'Aviso'
                this.notificationText = 'No puedes utilizar esta opción si no eres supervisor'
                this.showNotification = true
            }
        },
        BuscarConsecutivo(){
            let req = []
            this.isLoading = true
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn},
                body: JSON.stringify({ accion: "metodo",metodo: "BuscarConsecutivo", params: {folio:this.folio} })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                console.log(data)
                req = data['req']
            }).finally(()=>{
               this.setRequired(req)
               this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            })
        },
        afterClose(){
            this.$refs[this.notificationRef].focus()
        },
      
    }
}
</script>