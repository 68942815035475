<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Catálogo de Servicios</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row w-65">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-10">
                                            <form>
                                                <div class="row ">
                                                    <label class="col-2 text-nowrap">Clave</label>
                                                    <div class="col-3 d-flex">
                                                        <input ref="clave" type="number" class="form-control form-control-sm" required v-model="servicio.clave" />													
                                                    </div>
                                                </div>	
                                                <div class="row ">
                                                    <label class="col-2 text-nowrap">Descripción</label>
                                                    <div class="col-10 d-flex"> 
                                                        <input ref="ciudad" type="text" maxlength="50" class="form-control form-control-sm" required v-model="servicio.descripcion" />													
                                                    </div>
                                                </div>	
                                                
                                                <div class="row">
                                                    <label class="col-2 text-nowrap ">Clasificación</label>
                                                    <div class="col-8">
                                                        <select ref="clasificacion" class="form-control form-control-sm" v-model="servicio.clasificacion">
                                                            <option value="NOA">No APLICA</option>
                                                            <option value="IMP">IMPORTACION</option>
                                                            <option value="EXP">EXPORTACION</option>
                                                            <option value="IYE">IMPORT/EXPORT</option>
                                                            <option value="FRO">FRONTERIZO</option>
                                                            <option value="LOC">LOCA</option>
                                                            <option value="AME">MVTS.AMERICANOS</option>
                                                            <option value="FOR">FORANE</option>
                                                            <option value="VAC">VACIO</option>
                                                            <option value="5TA">CANCELADO/5TA</option>
                                                            <option value="INT">MOV INTERNO</option>
                                                        </select>
                                                    </div>
                                                </div>
                                               
                                                <LookUpCtrl v-if="servicio.clave" :label="'Concepto'" :cols="[2,2,8]" :archivo="'faConceptosFactura'" :objeto="servicio" :campo="'numid'" :descripcion="'descripcion'" :model="'concepto'" v-model="servicio.concepto" />
                                               
                                                <div class="row ">
                                                    <label class="col-2 text-nowrap">Unidad</label>
                                                    <div class="col-6 d-flex">
                                                        <input ref="unidad" type="text" class="form-control form-control-sm" v-model="servicio.unidad" />													
                                                    </div>
                                                </div>	
                                                <div class="row">
                                                    <div class="col-4">
                                                        <div class="row ">
                                                            <label class="col-6 text-nowrap">IVA %</label>
                                                            <div class="col-6 ">
                                                                <input ref="longitud" type="text" class="form-control form-control-sm"  v-model="servicio.piva" />													
                                                            </div>
                                                        </div>	
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-check">
                                                            <input class="form-check-input" ref="retencion"  v-model="servicio.retencion"  type="checkbox" id="retencion">
                                                            <label class="form-check-label" for="retencion">Retención</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-check" v-if="$ext == 'tal' || $ext == 'test'">
                                                            <input class="form-check-input" ref="cuotasind"  v-model="servicio.cuotasind" type="checkbox" id="cuotasind">
                                                            <label class="form-check-label" for="cuotasind">Cuota Sindical</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-4">
                                                        <div class="row ">
                                                            <label class="col-6 text-nowrap">PUnitario</label>
                                                            <div class="col-6">
                                                                <input ref="punitario" type="text" class="form-control form-control-sm"  v-model="servicio.punitario" />													
                                                            </div>
                                                        </div>	
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-check">
                                                            <input class="form-check-input" ref="flete"  v-model="servicio.flete"  type="checkbox" id="flete">
                                                            <label class="form-check-label" for="flete">Flete</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="row ">
                                                            <label class="col-4 text-nowrap">Decimales</label>
                                                            <div class="col-8">
                                                                <input ref="decimales" type="number" class="form-control form-control-sm"  v-model="servicio.decimales" />													
                                                            </div>
                                                        </div>	
                                                    </div>                                
                                                </div>	
                                                <LookUpCtrl v-if="servicio.clave" :label="'Cve SAT'"        :cols="[2,2,8]" :archivo="'satProdServ'" :objeto="servicio" :campo="'clave'" :descripcion="'descripcion'" :model="'cvesat'" v-model="servicio.cvesat" />
                                                <LookUpCtrl v-if="servicio.clave" :label="'Cve Unidad'"     :cols="[2,2,8]" :archivo="'satUnidades'" :objeto="servicio" :campo="'clave'" :descripcion="'nombre'" :model="'cveunidad'" v-model="servicio.cveunidad" />
                                                <LookUpCtrl v-if="servicio.clave" :label="'Cta Ingresos'"   :cols="[2,2,8]" :archivo="'glCatalogo'" :objeto="servicio" :campo="'cuenta'" :descripcion="'descripcion'" :model="'cuentagl'"  v-model="servicio.cuentagl" />
                                            </form>
                                        </div>
                                        <div class="col-2">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li><button @click="setServicio()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li><router-link to="/servicios" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import LookUpCtrl from '@/components/LookUpCtrl.vue';
import handleForm from '@/mixins/handleForm';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    components:{SideBar, TopBar,LookUpCtrl,LoadingContent },
    mixins:[handleForm,handleGlobals],
    data(){
        return{
            id: this.$route.params.servicio,
            modo: (this.$route.params.servicio > 0 ? 'Modificando' : 'Creacion'), 
            servicio: {},
            titulo: 'Agregando un Servicio',
        }
    },
    mounted(){
        this.llenaForma();
    },
    methods:{
        async llenaForma(){
            await this.getServicio();
        },
        getServicio(){
            let req = []
            this.isLoading = true
             fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "inicializar", modo:this.modo, archivo: "faServicios", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json()
                }   
            }).then((data)=>{
                this.servicio = data['res']
                req = data['req']
            }).finally(()=>{
                this.setRequired(req)
                this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setServicio(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: 'validar', archivo: "faServicios", modo:this.modo, record: this.servicio }) 
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['res'] == 'error'){
                    this.showNotification = true
                    this.notificationTitle =  'Error'
                    this.notificationText = data['msg']
                    this.notificationRef = 'clave'
                }else{
                    this.$router.push('/servicios')
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        afterClose(){
            this.$refs[this.notificationRef].focus()
        }
    }
}
</script>