<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Maestro de Casetas</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row w-65">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-10">
                                            <form>
                                                <div class="row ">
                                                    <label class="col-2 text-nowrap">Num ID</label>
                                                    <div class="col-3">
                                                        <input ref="numid" type="number" class="form-control form-control-sm" required disabled v-model="caseta.numid" />													
                                                    </div>
                                                </div>	
                                                <div class="row ">
                                                    <label class="col-2 text-nowrap">Clave</label>
                                                    <div class="col-5"> 
                                                        <input ref="clave" type="text" maxlength="20" class="form-control form-control-sm" required v-model="caseta.clave" />													
                                                    </div>
                                                </div>	
                                                <div class="row ">
                                                    <label class="col-2 text-nowrap">Descripción</label>
                                                    <div class="col-10">
                                                        <input ref="descripcion" maxlength="120" type="text" class="form-control form-control-sm" v-model="caseta.descripcion" />													
                                                    </div>
                                                </div>	
                                                <div class="row ">
                                                    <div class="col-6">
                                                        <div class="row ">
                                                            <label class="col-4 text-nowrap">Pos Longitud</label>
                                                            <div class="col-8">
                                                                <input ref="poslongitud" type="number" class="form-control form-control-sm" v-model="caseta.poslongitud" />													
                                                            </div>
                                                        </div>	
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="row ">
                                                            <label class="col-4 text-nowrap">Pos Latitud</label>
                                                            <div class="col-8">
                                                                <input ref="poslatitud" type="number" class="form-control form-control-sm" v-model="caseta.poslatitud" />													
                                                            </div>
                                                        </div>	
                                                    </div>
                                                </div>	
                                                <div class="row ">
                                                    <label class="col-2 text-nowrap">PIVA</label>
                                                    <div class="col-5"> 
                                                        <input ref="piva" type="number" class="form-control form-control-sm"  v-model="caseta.piva" />													
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-4">
                                                        <div class="row ">
                                                            <label class="col-3 text-nowrap">2 Ejes</label>
                                                            <div class="col-9">
                                                                <input  type="number" class="form-control form-control-sm" v-model="caseta.p2ejes" />													
                                                            </div>
                                                        </div>	
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="row ">
                                                            <label class="col-3 text-nowrap">3 Ejes</label>
                                                            <div class="col-9">
                                                                <input type="number" class="form-control form-control-sm" v-model="caseta.p3ejes" />													
                                                            </div>
                                                        </div>	
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="row ">
                                                            <label class="col-3 text-nowrap">4 Ejes</label>
                                                            <div class="col-9">
                                                                <input type="number" class="form-control form-control-sm" v-model="caseta.p4ejes" />													
                                                            </div>
                                                        </div>	
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="row ">
                                                            <label class="col-3 text-nowrap">5 Ejes</label>
                                                            <div class="col-9">
                                                                <input type="number" class="form-control form-control-sm" v-model="caseta.p5ejes" />													
                                                            </div>
                                                        </div>	
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="row ">
                                                            <label class="col-3 text-nowrap">6 Ejes</label>
                                                            <div class="col-9">
                                                                <input type="number" class="form-control form-control-sm" v-model="caseta.p6ejes" />													
                                                            </div>
                                                        </div>	
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="row ">
                                                            <label class="col-3 text-nowrap">7 Ejes</label>
                                                            <div class="col-9">
                                                                <input type="number" class="form-control form-control-sm" v-model="caseta.p7ejes" />													
                                                            </div>
                                                        </div>	
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="row ">
                                                            <label class="col-3 text-nowrap">8 Ejes</label>
                                                            <div class="col-9">
                                                                <input  type="number" class="form-control form-control-sm" v-model="caseta.p8ejes" />													
                                                            </div>
                                                        </div>	
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="row ">
                                                            <label class="col-3 text-nowrap">9 Ejes</label>
                                                            <div class="col-9">
                                                                <input  type="number" class="form-control form-control-sm" v-model="caseta.p9ejes" />													
                                                            </div>
                                                        </div>	
                                                    </div>
                                                </div>	
                                            </form>
                                        </div>
                                        <div class="col-2">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li><button @click="setCaseta()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li><router-link to="/casetas" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import handleForm from '@/mixins/handleForm';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    components:{SideBar, TopBar,LoadingContent },
    mixins:[handleForm,handleGlobals],
    data(){
        return{
            id: this.$route.params.caseta,
            modo: (this.$route.params.caseta > 0 ? 'Modificando' : 'Creacion'), 
            caseta: [],
            titulo: 'Agregando una Caseta',
        }
    },
    mounted(){
        this.llenaForma();
    },
    methods:{
        async llenaForma(){
            await this.getCaseta();
            this.titulo = this.$route.params.caseta > 0 ? 'Cambiando una Caseta' :  'Agregando una Caseta'
        },
        getCaseta(){
            let req = []
            this.isLoading = true
             fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "inicializar", modo:this.modo, archivo: "Casetas", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json()
                }   
            }).then((data)=>{
                this.caseta = data['res']
                req = data['req']
            }).finally(()=>{
                this.setRequired(req)
                this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setCaseta(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: 'validar', archivo: "Casetas", modo:this.modo, record: this.caseta }) 
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['res'] == 'error'){
                    this.showNotification = true
                    this.notificationTitle =  'Error'
                    this.notificationText = data['msg']
                    this.notificationRef = 'clave'
                }else{
                    this.$router.push('/casetas')
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        afterClose(){
           
            this.$refs[this.notificationRef].focus()
        }
    }
}
</script>