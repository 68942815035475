<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Maestro de Vehiculos</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row w-65">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-10">
                                            <form>
                                                <div class="row ">
                                                    <div class="col-12 col-md-2 offset-md-10">
                                                        <div class=" row">
                                                            <label class="col-4 text-nowrap">Tipo</label>
                                                            <div class="col-8 ">
                                                                <input type="number" ref="tipo" class="form-control text-end form-control-sm" readonly v-model="vehiculo.tipo" />													
                                                            </div>
                                                        </div>
                                                    </div>	
                                                </div>	
                                                <nav>
                                                    <div class="nav nav-underline" id="nav-tab" role="tablist">
                                                        <li class="nav-item"><a class="nav-item nav-link active" id="nav-general-tab" data-bs-toggle="tab" href="#nav-general" role="tab" aria-controls="nav-general" aria-selected="true">Información de la Unidad</a></li>
                                                        <li class="nav-item"><a class="nav-item nav-link" id="nav-adicionales-tab" data-bs-toggle="tab" href="#nav-adicionales" role="tab" aria-controls="nav-adicionales" aria-selected="false">Adicionales</a></li>
                                                        <li class="nav-item"><a class="nav-item nav-link" id="nav-cartaporte-tab" data-bs-toggle="tab" href="#nav-cartaporte" role="tab" aria-controls="nav-cartaporte" aria-selected="false">Carta Porte</a></li>
                                                        <li class="nav-item"><a class="nav-item nav-link" id="nav-notas-tab" data-bs-toggle="tab" href="#nav-notas" role="tab" aria-controls="nav-notas" aria-selected="false">Notas</a></li>
                                                        <li class="nav-item"><a class="nav-item nav-link" id="nav-doctos-tab" data-bs-toggle="tab" href="#nav-doctos" role="tab" aria-controls="nav-doctos" aria-selected="false">Doctos</a></li>
                                                    </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="container p-1 tab-pane fade show active" id="nav-general" role="tabpanel" aria-labelledby="nav-general-tab">
                                                        <div class="row ">
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Número</label>
                                                                    <div class="col-9 ">
                                                                        <input type="number"  ref="numero" class="form-control  form-control-sm" v-model="vehiculo.numero" />													
                                                                    </div>
                                                                </div>
                                                            </div>	
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap">Económico</label>
                                                                    <div class="col-8">
                                                                        <input type="text"  ref="economico" class="form-control form-control-sm" v-model="vehiculo.economico" />													
                                                                    </div>
                                                                </div>
                                                            </div>	
                                                            <div class="col-4">
                                                                <LookUpCtrl v-if="vehiculo.numero" :objeto="vehiculo" :label="'Sucursal'" :cols="[3,3,1]" :hide-descripcion="true" :archivo="'InSucursales'" :campo="'sucursal'" :descripcion="'nombre'" :model="'sucursal'" :reference="'sucursal'" v-model="vehiculo.sucursal" />
                                                            </div>	
                                                        </div>	
                    
                                                        <div class="row ">
                                                            <div class="col-8">
                                                                <LookUpCtrl v-if="vehiculo.numero" :objeto="vehiculo" :label="'Tipo Unidad'" :cols="[2,2,8]" :archivo="'TpoUnidad'"  :campo="'clave'" :descripcion="'descripcion'" :model="'tipounidad'" @onexit="buscaTipo()" :reference="'tipounidad'" v-model="vehiculo.tipounidad" />
                                                            </div>
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">GPS Id</label>
                                                                    <div class="col-6 ">
                                                                        <input type="text"  ref="gpsid" class="form-control form-control-sm" v-model="vehiculo.gpsid" />													
                                                                    </div>
                                                                </div>
                                                            </div>	
                                                        </div>
                                                        <div class="row ">
                                                            <div class="col-4 border p-3">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Año</label>
                                                                    <div class="col-9 ">
                                                                        <input type="text"  ref="ano" class="form-control form-control-sm "  v-model="vehiculo.ano" />		
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Marca</label>
                                                                    <div class="col-9 ">
                                                                        <input type="text"  ref="marca" class="form-control form-control-sm "  v-model="vehiculo.marca" />		
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Modelo</label>
                                                                    <div class="col-9 ">
                                                                        <input type="text"  ref="modelo" class="form-control form-control-sm "  v-model="vehiculo.modelo" />		
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Color</label>
                                                                    <div class="col-9">
                                                                        <input type="text"  ref="color" class="form-control form-control-sm "  v-model="vehiculo.color" />		
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Placa</label>
                                                                    <div class="col-9 ">
                                                                        <input type="text"  ref="placa" class="form-control form-control-sm "  v-model="vehiculo.placa" />		
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Serie</label>
                                                                    <div class="col-9 ">
                                                                        <input type="text"  ref="serie" class="form-control form-control-sm "  v-model="vehiculo.serie" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-8 p-3">
                                                                <LookUpCtrl v-if="vehiculo.numero" :objeto="vehiculo" :label="'Operador'" :cols="[2,2,8]" :archivo="'Operadores'" :campo="'numero'" :descripcion="'nombre'" :model="'operador'" :reference="'operador'" v-model="vehiculo.operador" />
                                                                
                                                                <div class="row">
                                                                    <label class="col-4 text-nowrap text-end">Tipo de Combustible</label>
                                                                    <div class="col-8">
                                                                        <select  ref="tipo_combu" class="form-control form-control-sm" v-model="vehiculo.tipo_combu">
                                                                            <option value=""></option>
                                                                            <option v-for="(tpo, key) in tpoCombustibles" :key="key" :value="tpo.clave">{{tpo.descr}}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12 form-check">
                                                                        <input class="form-check-input"  ref="millas"  v-model="cbox_millas"  type="checkbox" id="millas">
                                                                        <label class="form-check-label" for="millas">Captura en Millas</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row pt-3">
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap">Referencia</label>
                                                                    <div class="col-8">
                                                                        <input type="text"  ref="referencia" class="form-control form-control-sm "  v-model="vehiculo.referencia" />		
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap">Ubicación</label>
                                                                    <div class="col-8">
                                                                        <input type="text"  ref="ubicacion" class="form-control form-control-sm "  v-model="vehiculo.ubicacion " />		
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-6 text-nowrap">Rendimiento Std</label>
                                                                    <div class="col-6">
                                                                        <input type="number"  ref="rendimientostd" class="form-control text-end form-control-sm "  v-model="vehiculo.rendimientostd " />		
                                                                    </div>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap">Factor</label>
                                                                    <div class="col-5 ">
                                                                        <input type="text"  ref="factor" readonly class="form-control form-control-sm text-end"  v-model="vehiculo.factor " />		
                                                                    </div>
                                                                    <label class="col-3 text-nowrap">Kms/Lto</label>
                                                                </div>
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap">Peso</label>
                                                                    <div class="col-5 ">
                                                                        <input type="number"  ref="peso"  class="form-control form-control-sm text-end"  v-model="vehiculo.peso " />		
                                                                    </div>
                                                                    <label class="col-3 text-nowrap">Tons.</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-4 text-nowrap">Marcador</label>
                                                                    <div class="col-5">
                                                                        <input type="number" ref="marcador" class="form-control text-end form-control-sm "  v-model="vehiculo.marcador " />		
                                                                    </div>
                                                                    <label class="col-3 text-nowrap">Kms.</label>
                                                                </div>
                                                                <div class="row">
                                                                    <label class="col-4 text-nowrap text-end">Estatus</label>
                                                                    <div class="col-8">
                                                                        <select  ref="estatus" class="form-control form-control-sm" v-model="vehiculo.estatus">
                                                                            <option value="D">Disponible</option>
                                                                            <option value="P">Programado</option>
                                                                            <option value="T">Tránsito</option>
                                                                            <option value="M">Mantenimiento</option>
                                                                            <option value="F">Fuera de Servicio</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <label class="col-4 text-nowrap text-end">Activo</label>
                                                                    <div class="col-8">
                                                                        <select  ref="activoinactivo" class="form-control form-control-sm" v-model="vehiculo.activoinactivo">
                                                                            <option value="A">Activo</option>
                                                                            <option value="I">Inactivo</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <div class="row">
                                                                    <label class="col-4 text-nowrap text-end">Modalidad</label>
                                                                    <div class="col-8">
                                                                        <select  ref="modalidad" class="form-control form-control-sm" v-model="vehiculo.modalidad">
                                                                            <option value="REG">Regular</option>
                                                                            <option value="HAZ">Hazmat</option>
                                                                            <option value="VOL">Voluminoso</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <label class="col-4 text-nowrap text-end">Utilización</label>
                                                                    <div class="col-8">
                                                                        <select  ref="utilizacion" class="form-control form-control-sm" v-model="vehiculo.utilizacion">
                                                                            <option value="L">Local</option>
                                                                            <option value="F">Foráneo</option>
                                                                            <option value="T">Transfer</option>
                                                                            <option value="B">B1</option>
                                                                            <option value="U">Utilitario</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    <div class="container p-1 tab-pane fade" id="nav-adicionales" role="tabpanel" aria-labelledby="nav-adicionales-tab">
                                                        
                                                        <div class="row">
                                                            <div class=" col-6">
                                                                <div class="row">
                                                                    <div class=" col-12">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap">Fecha de Compra</label>
                                                                            <div class="col-8 ">
                                                                                <input  ref="fecha_comp" type="date" class="form-control form-control-sm"  v-model="ctrl_fecha_comp" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>	
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap">Costo</label>
                                                                            <div class="col-8 ">
                                                                                <input  ref="costo" type="number" class="form-control text-end form-control-sm"  v-model="vehiculo.costo" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap">Costo Activo</label>
                                                                            <div class="col-8 ">
                                                                                <input  ref="costo_acti" type="number" class="form-control text-end form-control-sm"  v-model="vehiculo.costo_acti" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap">Costo Inactivo</label>
                                                                            <div class="col-8 ">
                                                                                <input  ref="costo_inac" type="number" class="form-control text-end form-control-sm"  v-model="vehiculo.costo_inac" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap">Carrier</label>
                                                                            <div class="col-8 ">
                                                                                <input  ref="carrier" type="text" class="form-control  form-control-sm"  v-model="vehiculo.carrier" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class=" col-12">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <h5 class="font-italic">Remolques Asignados:</h5>
                                                                    </div>	
                                                                </div>
                                                           
                                                                <lookUpCtrl v-if="vehiculo.numero" :objeto="vehiculo" :label="'Remolque 1'" :cols="[2,2,8]" :archivo="'Vehiculos'" :campo="'numero'" :descripcion="'descripcion'" :reference="'remolque1'" :model="'remolque1'"  v-model="vehiculo.remolque1" />
                                                                <lookUpCtrl v-if="vehiculo.numero" :objeto="vehiculo" :label="'Dolly'" :cols="[2,2,8]" :archivo="'Vehiculos'" :campo="'numero'" :descripcion="'descripcion'"      :reference="'dolly'" :model="'dolly'"  v-model="vehiculo.dolly" />
                                                                <lookUpCtrl v-if="vehiculo.numero" :objeto="vehiculo" :label="'Remolque 2'" :cols="[2,2,8]" :archivo="'Vehiculos'" :campo="'numero'" :descripcion="'descripcion'" :reference="'remolque2'" :model="'remolque2'"  v-model="vehiculo.remolque2" />
                                                                <lookUpCtrl v-if="vehiculo.numero" :objeto="vehiculo" :label="'Propietario'" :cols="[2,2,8]" :archivo="'Propietarios'" :campo="'clave'" :descripcion="'nombre'"   :reference="'propietario'" :model="'propietario'"  v-model="vehiculo.propietario" />
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="container p-1 tab-pane fade" id="nav-cartaporte" role="tabpanel" aria-labelledby="nav-cartaporte-tab">
                                                        
                                                        <div class="row">
                                                            <div class="col-12"><p>Pólizas de Seguros</p></div>
                                                            <div class="col-12">
                                                                <LookUpCtrl v-if="vehiculo.numero" :label="'Resp. Civil'"    :cols="[2,2,8]" :archivo="'Polizas'" :objeto="vehiculo" :campo="'numero'" :descripcion="'poliza'" :model="'polizaseguro'" :reference="'polizaseguro'" v-model="vehiculo.polizaseguro" />
                                                            </div>	
                                                            <div class="col-12">
                                                                <LookUpCtrl v-if="vehiculo.numero" :label="'Medio Ambiente'" :cols="[2,2,8]" :archivo="'Polizas'" :objeto="vehiculo" :campo="'numero'" :descripcion="'poliza'" :model="'polizamedambiente'" :reference="'polizamedambiente'" v-model="vehiculo.polizamedambiente" />
                                                            </div>	
                                                        </div>
                                                        
                                                        <div class="row">
                                                            <div class="col-12 font-italic pt-5">
                                                                <p>Permiso SCT</p>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="row">
                                                                    <label class="col-2 text-nowrap">Tipo</label>
                                                                    <div class="col-10">
                                                                        <select  ref="sctpermisotipo" class="form-control form-control-sm" v-model="vehiculo.sctpermisotipo">
                                                                            <option value=""></option>
                                                                            <option value="TPAF01">TPAF01 - Autotransporte Federal de carga general.</option>
                                                                            <option value="TPAF02">TPAF02 - Transporte privado de carga.</option>
                                                                            <option value="TPAF03">TPAF03 - Autotransporte Federal de Carga Especializada de materiales y residuos peligrosos.</option>
                                                                            <option value="TPAF04">TPAF04 - Transporte de automóviles sin rodar en vehículo tipo góndola.</option>
                                                                            <option value="TPAF05">TPAF05 - Transporte de carga de gran peso y/o volumen de hasta 90 toneladas.</option>
                                                                            <option value="TPAF06">TPAF06 - Transporte de carga especializada de gran peso y/o volumen de más 90 toneladas.</option>
                                                                            <option value="TPAF07">TPAF07 - Transporte Privado de materiales y residuos peligrosos.</option>
                                                                            <option value="TPAF08">TPAF08 - Autotransporte internacional de carga de largo recorrido.</option>
                                                                            <option value="TPAF09">TPAF09 - Autotransporte internacional de carga especializada de materiales y residuos peligrosos de largo recorrido.</option>
                                                                            <option value="TPAF10">TPAF10 - Autotransporte Federal de Carga General cuyo ámbito de aplicación comprende la franja fronteriza con Estados Unidos.</option>
                                                                            <option value="TPAF11">TPAF11 - Autotransporte Federal de Carga Especializada cuyo ámbito de aplicación comprende la franja fronteriza con Estados Unidos.</option>
                                                                            <option value="TPAF12">TPAF12 - Servicio auxiliar de arrastre en las vías generales de comunicación.</option>
                                                                            <option value="TPAF13">TPAF13 - Servicio auxiliar de servicios de arrastre, arrastre y salvamento, y depósito de vehículos en las vías generales de comunicación.</option>
                                                                            <option value="TPAF14">TPAF14 - Servicio de paquetería y mensajería en las vías generales de comunicación.</option>
                                                                            <option value="TPAF15">TPAF15 - Transporte especial para el tránsito de grúas industriales con peso máximo de 90 toneladas.</option>
                                                                            <option value="TPAF16">TPAF16 - Servicio federal para empresas arrendadoras servicio público federal.</option>
                                                                            <option value="TPAF17">TPAF17 - Empresas trasladistas de vehículos nuevos.</option>
                                                                            <option value="TPAF18">TPAF18 - Empresas fabricantes o distribuidoras de vehículos nuevos.</option>
                                                                            <option value="TPAF19">TPAF19 - Autorización expresa para circular en los caminos y puentes de jurisdicción federal con configuraciones de tractocamión doblemente articulado.</option>
                                                                            <option value="TPAF20">TPAF20 - Autotransporte Federal de Carga Especializada de fondos y valores.</option>
                                                                            <option value="TPTM01">TPTM01 - Permiso temporal para navegación de cabotaje</option>
                                                                            <option value="TPTA01">TPTA01 - Concesión y/o autorización para el servicio regular nacional y/o internacional para empresas mexicanas</option>
                                                                            <option value="TPTA02">TPTA02 - Permiso para el servicio aéreo regular de empresas extranjeras</option>
                                                                            <option value="TPTA03">TPTA03 - Permiso para el servicio nacional e internacional no regular de fletamento</option>
                                                                            <option value="TPTA04">TPTA04 - Permiso para el servicio nacional e internacional no regular de taxi aéreo</option>
                                                                            <option value="TPXX00">TPXX00 - Permiso no contemplado en el catálogo.</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>	
                                                            <div class="col-12">
                                                                <div class="row">
                                                                    <label class="col-2 text-nowrap">Número</label>
                                                                    <div class="col-10 ">
                                                                        <input  ref="sctpermisonum" type="text" class="form-control  form-control-sm"  v-model="vehiculo.sctpermisonum" />													
                                                                    </div>
                                                                </div>
                                                            </div>	
                                                        </div>
                                                        
                                                    </div>
                                                    <div  class="container p-1 tab-pane fade" id="nav-notas" role="tabpanel" aria-labelledby="nav-notas-tab">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class=" row">
                                                                    <label class="col-1 text-nowrap">Notas</label>
                                                                    <div class="col-11 ">
                                                                        <textarea  ref="notas" rows="15" class="form-control form-control-sm"  v-model="vehiculo.notas" ></textarea>													
                                                                    </div>
                                                                </div>
                                                            </div>	
                                                        </div>
                                                        
                                                    </div>
                                                    <div  class="container p-1 tab-pane fade" id="nav-doctos" role="tabpanel" aria-labelledby="nav-doctos-tab">
                                                        <div class="row ">
                                                            <div class="col-12 form-group " style="padding-bottom:6rem">
                                                                <b-table-simple>
                                                                    <b-thead>
                                                                        <b-tr class="table-header">
                                                                            <b-th></b-th>
                                                                            <b-th>Documento</b-th>
                                                                            <b-th>Agregado</b-th>
                                                                        </b-tr>
                                                                    </b-thead>
                                                                    <b-tbody>
                                                                        <b-tr v-for="docto in doctos" :key="docto.EDOQ_REFERENCIA" >
                                                                            <b-td class="text-center">{{ docto.EDOQ_REFERENCIA }}</b-td>
                                                                            <b-td class="text-center">{{ docto.EDOQ_REFERENCIA }}</b-td>
                                                                            <b-td class="text-center">{{ docto.EDOQ_REFERENCIA }}</b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="col-2">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li><button @click="setVehiculo()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li><router-link to="/vehiculos" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import LookUpCtrl from '@/components/LookUpCtrl.vue';
import handleGlobals from '@/mixins/handleGlobals';
import handleForm from '@/mixins/handleForm';
import LoadingContent from '@/components/LoadingSpinner.vue'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    components:{SideBar,TopBar, LookUpCtrl,LoadingContent },
    mixins:[handleGlobals,handleForm],
    data(){
        return{
            id: this.$route.params.vehiculo,
            modo: (this.$route.params.vehiculo > 0 ? 'Modificando' : 'Creacion'), 
            vehiculo: [],
            tpoCombustibles: [],
            doctos: [],
            titulo: 'Agregando un Vehiculo',
        }
    },
    computed:{
        cbox_millas:{
            get(){
                return this.vehiculo.millas == 'S' ? true : false
            },
            set(val){
                return val 
            }  
        },
        ctrl_fecha_comp(){
            return this.formato(this.vehiculo.fecha_comp,"YYYY-MM-DD")
        },
    },
    mounted(){
        this.llenaForma();
    },
    methods:{
        async llenaForma(){
            this.getTpoCombustible()
            await this.getVehiculo()
            if(this.$route.params.vehiculo > 0){
                this.titulo = 'Cambiando un Vehiculo'
            }else{
                this.vehiculo.sucursal = this.$store.state.gnSucursal
            }
        },
        async getVehiculo(){
            this.isLoading = true
            let req = []
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "inicializar",modo:this.modo, archivo: "Vehiculos", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.vehiculo =data['res'];
                req = data['req']
            }).finally(()=>{
               this.setRequired(req)
               this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setVehiculo(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: 'validar', archivo: "Vehiculos", modo:this.modo, record: this.vehiculo })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then(()=>{
                this.$router.push('/vehiculos')
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        getTpoCombustible(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "browse", 
                                        archivo: "TpoComb", 
                                        activarPaginas: false,
                                        filtro:{},
                                        orden:"clave", 
                                        pagina:"todo", 
                                        paginacion: "",
                                        registrosxpagina: 0 })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.tpoCombustibles = data['registros'];
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        buscaTipo(){
            console.log('corre')
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "metodo",metodo:'getTipo', archivo: "Vehiculos", params:{tpounidad: this.vehiculo.tpounidad} })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.vehiculo.tipo = data;
            }).catch((err)=>{
                this.falseToken(err)
            });
        }
    }
}
</script>