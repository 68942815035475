<template>
    <section>
        <SideBar v-if="!OpenMode" />
        <TopBar v-if="!OpenMode" />
        <div :class="[!OpenMode ? 'content' : '' ]">    
        <div class="main-content-header">
            <h1>Catálogo de Monedas</h1>
        </div>
        <LoadingContent v-if="isLoading" />
        <div class="row">
            <div class="col-lg-12 col-md-6">
                <div class="card mb-30" :style="{height:[contentHeight+'px']}">
                    <div class="row " :class="[!OpenMode ? 'p-4' : '']">
                        <div class="content-header  col-12"> 
                            <div class="row">
                                <div class="col-md-10 col-12 text-start "></div>
                                <div class="col-md-2 col-12"><p class="p-1 pe-4 mb-0 text-end">Página {{ paginaActual }} de {{ totalPaginas }} </p></div>
                                <div class="col-md-2 offset-md-10 col-12 ps-2 pe-5 text-end pb-0 pt-0">
                                    <ul class="list-unstyled mb-0 d-flex justify-content-between pager-btns float-right">
                                        <li><button @click="setPaginacion('first')" type="button" class="pager pager-first btn btn-sm btn-outline-secondary"><ChevronDoubleLeftIcon /></button></li>
                                        <li><button @click="setPaginacion('prev')"  type="button" class="pager pager-prev  btn btn-sm btn-outline-secondary"><ChevronLeftIcon /></button></li>
                                        <li><button @click="setPaginacion('next')"  type="button" class="pager pager-next  btn btn-sm btn-outline-secondary"><ChevronRightIcon /></button></li>
                                        <li><button @click="setPaginacion('last')"  type="button" class="pager pager-last  btn btn-sm btn-outline-secondary"><ChevronDoubleRightIcon /></button></li> 
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-10 col-12">
                            <div class="table-responsive ps-2">
                                <b-table-simple class="browse" hover small caption-top responsive >
                                    <b-thead head-variant="light" class="table-header">
                                        <b-tr>
                                            <b-th class="text-nowrap">clave</b-th>
                                            <b-th class="text-nowrap">Descripción</b-th>
                                            <b-th class="text-nowrap">Abrev</b-th>
                                            <b-th class="text-nowrap text-end">Tipo Cambio</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="(moneda,indx) in monedas" :key="moneda.clave" 
                                        @click="selectedRegistro=moneda;selectedRegistro.indx = indx" 
                                        @dblclick="(!OpenMode ? modificar() : exportRegistry()) " :class="{ 'selected-row': (selectedRegistro.clave == moneda.clave) }">
                                            <b-td class="text-nowrap text-center">{{moneda.clave}}</b-td>
                                            <b-td class="text-nowrap">{{ moneda.descripcion }}</b-td>
                                            <b-td class="text-nowrap text-center">{{ moneda.abrev }}</b-td>
                                            <b-td class="text-nowrap text-end">{{ moneda.tipocambio | tipoCambio}}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </div>
                        </div> 
                        <div class="col-2 ps-2 pe-5 btn-manager" >
                            <ul class="d-block list-unstyled action-block">
                                <li v-if="!OpenMode"><router-link to="/moneda/agregar" class="btn btn-xs btn-success btn-block">Agregar</router-link></li>
                                <li v-if="!OpenMode"><button @click="modificar()" :disabled="disableBtn" type="button" class="btn btn-xs btn-primary btn-block">Cambiar</button></li>
                                <li v-if="!OpenMode"><button @click="ask()" :disabled="disableBtn" class="btn btn-xs btn-danger btn-block">Borrar</button></li>
                                <li v-if="!OpenMode"><button @click="imprimir()" class="btn btn-xs btn-secondary btn-block">Imprimir</button></li>
                                <li v-if="OpenMode"><button @click="exportRegistry()" class="btn btn-xs btn-success btn-block">Elegir</button></li>
                            </ul>
                        </div>
                        
                        <div class="content-footer col-12 text-start"></div>
                    </div>
                </div>
            </div>
        </div>
        <DeleteReg  v-show="showDelete" @close="closeDelete" @aceptar="monedas = borrar('Monedas',monedas)">
            <template v-slot:header>
                <h6 class="text-uppercase">{{notificationTitle}}</h6>
            </template>
            <template v-slot:body>
                {{notificationText}}
            </template>
        </DeleteReg>
        </div>
    </section>
</template>

<script>
import DeleteReg from '@/components/YesNoPopup.vue';
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronDoubleRightIcon from 'vue-material-design-icons/ChevronDoubleRight.vue';
import ChevronDoubleLeftIcon from 'vue-material-design-icons/ChevronDoubleLeft.vue';
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'
import handleBrowse from '@/mixins/handleBrowse'
import handlePopUp from '@/mixins/handlePopUp'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    name:'WIN_Monedas',
    props:['mode','perpage'],
    mixins:[handleGlobals,handleBrowse,handlePopUp],
    components:{SideBar,TopBar,ChevronLeftIcon,ChevronDoubleRightIcon,ChevronDoubleLeftIcon,ChevronRightIcon, LoadingContent,DeleteReg},
    data(){
        return{
            monedas: []
        }
    },
    created(){
        if(this.OpenMode == false){
            this.getFiltros()
        } 
    },
    methods:{
        async getFiltros(){
                await fetch(this.$api+'/rdx', {    
                    method: "POST",
                    headers: {
                        'Content-Type' : 'application/json',
                        'Authorization' : this.$store.state.iasctratkn
                    },
                    body: JSON.stringify({accion: "filters"})
                }).then((response)=>{
                    if(response.ok){
                        return response.json()
                    }
                }).then((data)=>{
                    let reg = data['MON']
                    if(reg){
                      
                        this.paginaActual  = reg['paginaActual'].dato
                        if(this.paginaActual == '') this.paginaActual = 0
                    }else{
                       
                        this.paginaActual  = 1
                    }
                }).catch((err)=>{
                    this.falseToken(err)
                });
        },
        async consultar(){
            this.isLoading = true
            
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "browse", 
                                        archivo: "Monedas", 
                                        activarPaginas: true,
                                        orden: "clave",
                                        filtro: {paginaActual: {dato: 'NA'}},
                                        pagina: this.paginaActual, 
                                        paginacion: this.paginacion,
                                        registrosxpagina: this.rows })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.monedas        = data['registros'];
                this.totalRegistros = data['totalRegistros'];
                this.totalPaginas   = data['totalPaginas'];
                this.paginaActual   = data['paginaActual'];
            }).finally(()=>{
                this.isLoading = false;
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        
        imprimir(){

        },
        modificar(){
            this.$router.push({ name: 'CMoneda', params: {moneda: this.selectedRegistro.clave}});
        }
    }
}
</script>

