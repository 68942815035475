<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Maestro de Sucursales</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row w-65">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-9 col-12">
                                            <form>
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-md-2 col-4 text-nowrap">Sucursal</label>
                                                            <div class="col-md-5 col-8">
                                                                <input type="number" ref="sucursal" class="form-control form-control-sm "  v-model="sucursal.sucursal" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-md-2 col-4 text-nowrap">Nombre</label>
                                                            <div class="col-md-10 col-8">
                                                                <input type="text" ref="nombre" class="form-control form-control-sm "  v-model="sucursal.nombre" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-md-2 col-4 text-nowrap">Calle</label>
                                                            <div class="col-md-10 col-8">
                                                                <input type="text" ref="calle"  class="form-control form-control-sm "  v-model="sucursal.calle" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-md-2 col-4 text-nowrap">Colonia</label>
                                                            <div class="col-md-5 col-8">
                                                                <input type="text" ref="colonia" class="form-control form-control-sm "  v-model="sucursal.colonia" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-md-2 col-4 text-nowrap">Ciudad</label>
                                                            <div class="col-md-5 col-8">
                                                                <input type="text" ref="ciudad" class="form-control form-control-sm "  v-model="sucursal.ciudad" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-md-2 col-4 text-nowrap">Estado</label>
                                                            <div class="col-md-5 col-8">
                                                                <input type="text" ref="estado" class="form-control form-control-sm "  v-model="sucursal.estado" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-md-8 col-12">
                                                        <div class=" row">
                                                            <label class="col-md-3 col-4 text-nowrap">Pais</label>
                                                            <div class="col-md-9 col-8">
                                                                <input type="text" ref="pais" class="form-control form-control-sm "  v-model="sucursal.pais" />		
                                                            </div>
                                                        </div>
                                                        <div class=" row">
                                                            <label class="col-md-3 col-4 text-nowrap">CP</label>
                                                            <div class="col-md-9 col-8">
                                                                <input type="text" ref="cp" class="form-control form-control-sm "  v-model="sucursal.cp" />		
                                                            </div>
                                                        </div>
                                                        <div class=" row">
                                                            <label class="col-md-3 col-4 text-nowrap">Teléfono</label>
                                                            <div class="col-md-9 col-8">
                                                                <input type="text" ref="telefono" class="form-control form-control-sm "  v-model="sucursal.telefono" />		
                                                            </div>
                                                        </div>
                                                        <div class=" row">
                                                            <label class="col-md-3 col-4 text-nowrap">Fax</label>
                                                            <div class="col-md-9 col-8">
                                                                <input type="text" ref="fax" class="form-control form-control-sm "  v-model="sucursal.fax" />		
                                                            </div>
                                                        </div>
                                                        <div class=" row">
                                                            <label class="col-md-3 col-4 text-nowrap">RFC</label>
                                                            <div class="col-md-9 col-8">
                                                                <input type="text" ref="rfc" class="form-control form-control-sm "  v-model="sucursal.rfc" />		
                                                            </div>
                                                        </div>
                                                        <div class=" row">
                                                            <label class="col-md-3 col-4 text-nowrap">Régimen Fiscal</label>
                                                            <div class="col-md-9 col-8">
                                                                <input type="text" ref="regimen" class="form-control form-control-sm "  v-model="sucursal.regimen" />		
                                                            </div>
                                                        </div>
                                                        <div class=" row">
                                                            <div class="col-md-6 col-12">
                                                                <div class="row">
                                                                    <label class="col-md-7 col-4 text-nowrap">Bodega Principal</label>
                                                                    <div class="col-md-5 col-8">
                                                                        <input type="text" ref="bodegaprincipal" class="form-control form-control-sm "  v-model="sucursal.bodegaprincipal" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-12">
                                                                <div class="row">
                                                                    <label class="col-4 text-nowrap">Estatus</label>
                                                                    <div class="col-8">
                                                                        <input type="text" ref="estatus" class="form-control form-control-sm "  v-model="sucursal.estatus" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-12">
                                                        <div class=" row" v-if="muestra_prefijo">
                                                            <label class="col-4 text-nowrap text-end">Prefijo</label>
                                                            <div class="col-8">
                                                                <input type="text" ref="prefijo" class="form-control form-control-sm "  v-model="sucursal.prefijo" />		
                                                            </div>
                                                        </div>
                                                        <div class=" row p-3">
                                                            <img class="img-fluid "  style="width:100%;height:100px" :src="sucursal.logo" :alt="sucursal.logo" />
                                                            <button type="button" ref="logo" class="btn btn-outline-secondary btn-block">Seleccionar Logotipo</button>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                
                                            </form>
                                        </div>
                                        <div class="col-md-3 col-12">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li class="mobile-float"><button @click="setSucursal()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li><router-link to="/sucursales" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <InfoPopUpVue  v-show="showNotification" @close="closeInfo">
                        <template v-slot:header>
                            <h6 class="text-uppercase">{{notificationTitle}}</h6>
                        </template>
                        <template v-slot:body>
                            {{notificationText}}
                        </template>
                    </InfoPopUpVue>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import InfoPopUpVue from '@/components/InfoPopUp.vue';
import handleGlobals from '@/mixins/handleGlobals'
import handlePopUp from '@/mixins/handlePopUp'
import handleForm from '@/mixins/handleForm';
import LoadingContent from '@/components/LoadingSpinner.vue'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    mixins:[handleGlobals,handlePopUp,handleForm],
    components:{SideBar,TopBar,InfoPopUpVue,LoadingContent},
    data(){
        return{
            id: this.$route.params.sucursal,
            modo: (this.$route.params.sucursal != '' && this.$route.params.sucursal != undefined ? 'Modificando' : 'Creacion'), 
            sucursal: {},
            titulo: 'Agregando una Sucursal',  
            muestra_prefijo:false,
            muestra_regimen:false,
            version:''
        }
    },
    mounted(){
        if(this.$store.state.gsExtension == "FNG"){
            this.muestra_prefijo = true
        }
       
        if(this.sucursal.version == "3.3"){
           // COMBO_REGIMEN..Visible = true
            this.muestra_regimen = false
        }
        if(this.modo == 'Modificando'){
            this.getSucursal()
        }
    },
   
    methods:{
        async getSucursal(){
            let req = []
            this.isLoading = true
           await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "inicializar",modo:this.modo,  archivo: "InSucursales", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.sucursal = data['res'];
                req = data['req']
            }).finally(()=>{
               this.setRequired(req)
               this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setSucursal(){
           
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: 'validar', archivo: "InSucursales", modo:this.modo, record: this.sucursal }) 
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['res'] == 'error'){
                    this.showNotification = true
                    this.notificationTitle =  'Error'
                    this.notificationText = data['msg']
                    this.notificationRef = 'sucursal'
                }else{
                    this.$router.push('/sucursales')
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        afterClose(){
            this.$refs[this.notificationRef].focus()
        },
      
       
    }
}
</script>