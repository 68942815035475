<template>
    <transition name="popup-fade">
      <div class="popup-backdrop" >
          <div class="popup" role="dialog" aria-labelledby="PopupTitulo" aria-describedby="PopupDescripcion" :class="[size != '' ? 'w-'+size : '']">
              <header class="popup-header">
                  <slot name="header"></slot>
                  <button type="button" class="btn-close" @click="close" aria-label="Cerrar">🗙</button>
              </header>
  
              <section class="popup-body lookup" id="PopupDescripcion">
                  <slot name="body">
                      <component :is="dynamicComponent" :params="params"  />
                  </slot>
              </section>
  
              <footer class="popup-footer">
                  <slot name="footer"></slot>
                  <div class="row">
                      <div class="col-12 justify-content-end d-flex">
                        <button type="button" class="btn btn-sm btn-outline-danger" aria-label="Cerrar" @click="close">Cerrar</button>
                      </div>
                  </div>
              </footer>
          </div>
      </div>
    </transition>
  </template>
  
  <script>
    export default {
      name: 'ModalComponent', 
      props: ['component','size','params'],
      methods: {
        close() {
          this.$emit('close');
        },
     /*   elegir(e){
          this.$emit('receiveSelected',e)
        },*/
      },
      computed: {
        dynamicComponent() {
          if (this.$props.component) {
            return  () => ({
              // The component to load (should be a Promise)
              component: import(`../pages/browse/${this.$props.component}.vue`),
              // A component to use while the async component is loading
              //loading: LoadingComponent,
              // A component to use if the load fails
              //error: ErrorComponent,
              // Delay before showing the loading component. Default: 200ms.
              delay: 200,
              // The error component will be displayed if a timeout is
              // provided and exceeded. Default: Infinity.
              timeout: 3000
            })
            // () => import();
          }
          return null;
        }
      }
    };
  </script>