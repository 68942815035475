<template>
     <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Condiciones de Pago</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row  w-65">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-10 col-12">
                                            <form>
                                                <div class="row">
                                                    <div class="col-md-12 col-12">
                                                        <div class=" row">
                                                            <label class="col-3 text-nowrap">Condición No.</label>
                                                            <div class="col-3 ">
                                                                <input type="number" ref="condno" class="form-control form-control-sm" :readonly="modo == 'Modificando'" v-model="condicion.condno" />													
                                                            </div>
                                                        </div>
                                                    </div>	
                                                    <div class="col-md-12 col-12">
                                                        <div class="row">
                                                            <label class="col-3 text-nowrap">Descripción</label>
                                                            <div class="col-9">
                                                                <input type="text" ref="descripcion" class="form-control form-control-sm" v-model="condicion.descripcion" />													
                                                            </div>
                                                        </div>
                                                    </div>	
                                                    <div class="col-md-12 col-12">
                                                        <div class=" row">
                                                            <label class="col-3 text-nowrap">Plazo</label>
                                                            <div class="col-3">
                                                                <input type="number" ref="plazo"  class="form-control form-control-sm"  v-model="condicion.plazo" />													
                                                            </div>
                                                        </div>
                                                    </div>	
                                                    <div class="col-md-12 col-12">
                                                        <div class="row">
                                                            <label class="col-3 text-nowrap text-right">Cve. SAT</label>
                                                            <div class="col-6">
                                                                <select class="form-control form-control-sm" v-model="condicion.cvesat">
                                                                    <option value="PUE">PUE - Pago en una sola exhibición</option>
                                                                    <option value="PPD">PPD - Pago en parcialidades o diferido</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>	
                                            </form>
                                        </div>
                                        <div class="col-md-2 col-12">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li class="mobile-float"><button @click="setCondicion()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li><router-link to="/condiciones" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <InfoPopUpVue  v-show="showNotification" @close="closeInfo">
                    <template v-slot:header>
                        <h6 class="text-uppercase">{{notificationTitle}}</h6>
                    </template>
                    <template v-slot:body>
                        {{notificationText}}
                    </template>
                </InfoPopUpVue>

            </div>
        </div>
    </section>
</template>

<script>
import InfoPopUpVue from '@/components/InfoPopUp.vue';
import handlePopUp from '@/mixins/handlePopUp';
import handleForm from '@/mixins/handleForm';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    components:{SideBar,TopBar,InfoPopUpVue,LoadingContent},
    mixins:[handlePopUp,handleForm,handleGlobals],
    data(){
        return{
            id: this.$route.params.condicion,
            modo: (this.$route.params.condicion > 0 ? 'Modificando' : 'Creacion'), 
            condicion: {},
            titulo: 'Agregando una Condicion'
        }
    },
    mounted(){
        this.llenaForma();
    },
    methods:{
        async llenaForma(){
            await this.getCondicion()
            if(this.$route.params.condicion > 0){
                this.titulo = 'Cambiando una Condicion'
                this.$refs.descripcion.focus()
            }
        },
        async getCondicion(){
            let req = []
            this.isLoading = true
             fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "inicializar", modo:this.modo, archivo: "Condiciones", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json()
                }   
            }).then((data)=>{
                this.condicion = data['res']
                req = data['req']
            }).finally(()=>{
                this.setRequired(req)
                this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setCondicion(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: 'validar', archivo: "Condiciones", modo:this.modo, record: this.condicion }) 
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['res'] == 'error'){
                    this.showNotification = true
                    this.notificationTitle =  'Error'
                    this.notificationText = data['msg']
                    this.notificationRef = 'descripcion'
                }else{
                    this.$router.push('/condiciones')
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        afterClose(){
            this.$refs[this.notificationRef].focus()
        }
    }
}
</script>