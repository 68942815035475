<template>
    <section>
        <SideBar v-if="!OpenMode" />
        <TopBar v-if="!OpenMode" />
        <div :class="[!OpenMode ? 'content' : '' ]">   
        <div class="main-content-header">
            <h1>Archivo de Remisiones</h1>
        </div>
        <LoadingContent v-if="isLoading" />
        <div class="row">
            <div class="col-lg-12 col-md-6 col-12">
                <div class="card mb-30" :style="{height:[contentHeight+'px']}">
                    <div class="row ps-2 " id="browse-age">
                        <div class="col-12 content-header">
                            <div class="row">
                                <div class="col-6 text-start"></div>
                                <div class="col-md-6 col-12"><p class="p-1 pe-4 text-end">Página {{ paginaActual }} de {{ totalPaginas }} </p></div>
                                <div class="col-md-4 col-12"></div>
                                <div class="col-md-2 col-6 ps-4">
                                    <div class="form-group">
                                        <div class="form-check">
                                            <input class="form-check-input" v-model="filtroSucursales" @change="consultar()" type="checkbox" id="sucursales">
                                            <label class="form-check-label" for="sucursales">
                                                Todas las Sucursales
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-12 ps-4 pe-4 text-end"></div>
                                <div class="col-md-2 col-12 ps-4 pe-4 text-end">
                                    <div class="form-group d-flex">
                                        <label class="text-nowrap mb-0 pe-1">Mes Actual</label>
                                        <select @change="getFiltroMes(); (filtroxmes >= 13 ? muestraTotalesTabla = false : muestraTotalesTabla)" v-model="filtroxmes" class="form-control form-control-sm" >
                                            <option value="1">Enero</option>
                                            <option value="2">Febrero</option>
                                            <option value="3">Marzo</option>
                                            <option value="4">Abril</option>
                                            <option value="5">Mayo</option>
                                            <option value="6">Junio</option>
                                            <option value="7">Julio</option>
                                            <option value="8">Agosto</option>
                                            <option value="9">Septiembre</option>
                                            <option value="10">Octubre</option>
                                            <option value="11">Noviembre</option>
                                            <option value="12">Diciembre</option>
                                            <option value="13">Todo el Año</option>
                                            <option value="14">Año Anterior</option>
                                            <option value="15">Año Antepasado</option>
                                            <option value="17">Ultimos 3 Meses</option>
                                            <option value="18">Ultimos 6 Meses</option>
                                            <option value="16">Todo</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-2 col-12 ps-2 pe-5 text-end">
                                    <ul class="list-unstyled mb-0 d-flex justify-content-between pager-btns float-right">
                                        <li><button @click="setPaginacion('first')" type="button" class="pager pager-first btn btn-sm btn-outline-secondary"><ChevronDoubleLeftIcon /></button></li>
                                        <li><button @click="setPaginacion('prev')"  type="button" class="pager pager-prev  btn btn-sm btn-outline-secondary"><ChevronLeftIcon /></button></li>
                                        <li><button @click="setPaginacion('next')"  type="button" class="pager pager-next  btn btn-sm btn-outline-secondary"><ChevronRightIcon /></button></li>
                                        <li><button @click="setPaginacion('last')"  type="button" class="pager pager-last  btn btn-sm btn-outline-secondary"><ChevronDoubleRightIcon /></button></li> 
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-10 col-12 ">
                            <div class="table-responsive ps-2">
                                <b-table-simple class="browse" hover small caption-top responsive >
                                    <b-thead head-variant="light"  class="table-header">
                                        <b-tr>
                                            <b-th class="nf"></b-th>
                                            <b-th class="nf">D</b-th>
                                            <b-th>Cliente</b-th>
                                            <b-th>Nombre</b-th>
                                            <b-th>Fecha</b-th>
                                            <b-th>Suc</b-th>
                                            <b-th>Folio</b-th>
                                            <b-th>Factura</b-th>
                                            <b-th>Tipo de Viaje</b-th>
                                            <b-th>Ruta</b-th>
                                            <b-th>Descripción</b-th>
                                            <b-th>Origen</b-th>
                                            <b-th>Destino</b-th>
                                            <b-th>Operador</b-th>
                                            <b-th>Nombre</b-th>
                                            <b-th>Viaje</b-th>
                                            <b-th>Unidad</b-th>
                                            <b-th>Económico</b-th>
                                            <b-th>Placas</b-th>
                                            <b-th>Remolque</b-th>
                                            <b-th>Toneladas</b-th>
                                            <b-th>Tarifa</b-th>
                                            <b-th>Flete</b-th>
                                            <b-th>Seguro</b-th>
                                            <b-th>Maniobras</b-th>
                                            <b-th>Autopistas</b-th>
                                            <b-th>Recolección</b-th>
                                            <b-th>Reparto</b-th>
                                            <b-th>Doble Oper.</b-th>
                                            <b-th>Otros</b-th>
                                            <b-th class="text-end">Sub Total</b-th>
                                            <b-th class="text-end">IVA</b-th>
                                            <b-th class="text-end">Retención</b-th>
                                            <b-th class="text-end">Total</b-th>
                                            <b-th class="text-end">T.Cambio</b-th>
                                            <b-th>Cancelada</b-th>
                                            <b-th>Liquidación</b-th>
                                            <b-th>Impresiones</b-th>
                                            <b-th>Fecha Cambio</b-th>
                                            <b-th>Usuario</b-th>
                                            <b-th>Pedimento</b-th>
                                            <b-th>CS</b-th>
                                            <b-th>Observaciones</b-th>
                                            <b-th>Orden Serv.</b-th>
                                            <b-th>Folio Fiscal</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="(remision,v) in remisiones" 
                                            :key="v" 
                                            class="cursor-pointer"
                                            @contextmenu.prevent="selectedRegistro = remision; muestraPartidas()"
                                            :class="{'selected-row': (selectedRegistro.folio == remision.folio), 
                                                    'son-text-danger': (remision.cancelada == 'S'), 
                                                    'text-blue': (remision.uuid  == '') }" 
                                            @click="selectedRegistro = remision; selectedRegistro.indx = v; disabled=false; setSelected()" 
                                            @dblclick="modificar()">


                                            <b-td class="text-secondary m-auto pe-1">
                                                <button  @click="selectedRegistro = remision; muestraPartidas()" type="button" class="btn btn-sm btn-outline-secondary"><PlusIcon /></button>
                                            </b-td>
                                            <b-td class="text-secondary m-auto pe-1"><FileDocumentOutlineIcon v-if="remision.doctos > 0 " /></b-td>
                                            <b-td>{{ remision.cliente| leadingZeros(5)}}</b-td>
                                            <b-td class="text-start text-nowrap">{{ remision.cte_nombre }}</b-td>
                                            <b-td class="text-nowrap">{{ formato(remision.fecha,"MMM-DD-YYYY") }}</b-td>
                                            <b-td >{{ remision.sucursal | leadingZeros(3)}}</b-td>
                                            <b-td class="text-center text-nowrap" >{{ remision.folio | leadingZeros(7)}}</b-td>
                                            <b-td class="text-center text-nowrap" style="background-color: #D7EDFE;">{{ remision.factura | leadingZeros(7)}}</b-td>
                                            <b-td class="text-start text-nowrap">{{ remision.tipoviaje }}</b-td>
                                            <b-td class="text-center text-nowrap">{{ remision.ruta }}</b-td>
                                            <b-td class="text-start text-nowrap">{{ remision.ruta_descripcion }}</b-td>
                                            <b-td class="text-start text-nowrap">{{ remision.origen }}</b-td>
                                            <b-td class="text-start text-nowrap">{{ remision.destino }}</b-td>
                                            <b-td>{{ remision.operador| leadingZeros(5)}}</b-td>
                                            <b-td class="text-start text-nowrap">{{ remision.ope_nombre }}</b-td>
                                            <b-td class="text-start text-nowrap">{{ remision.viaje }}</b-td>
                                            <b-td>{{ remision.tractor| leadingZeros(6)}}</b-td>
                                            <b-td class="text-start text-nowrap">{{ remision.economico }}</b-td>
                                            <b-td class="text-start text-nowrap">{{ remision.placas }}</b-td>
                                            <b-td>{{ remision.remolque| leadingZeros(6)}}</b-td>
                                            <b-td class="text-end text-nowrap">{{ parseFloat(remision.toneladas) | toCurrency }}</b-td>
                                            <b-td class="text-end text-nowrap">{{ parseFloat(remision.tarifa) | toCurrency }}</b-td>
                                            <b-td class="text-end text-nowrap">{{ parseFloat(remision.flete) | toCurrency }}</b-td>
                                            <b-td class="text-end text-nowrap">{{ parseFloat(remision.seguro) | toCurrency }}</b-td>
                                            <b-td class="text-end text-nowrap">{{ parseFloat(remision.maniobras) | toCurrency }}</b-td>
                                            <b-td class="text-end text-nowrap">{{ parseFloat(remision.autopistas) | toCurrency }}</b-td>
                                            <b-td class="text-end text-nowrap">{{ parseFloat(remision.recoleccion) | toCurrency }}</b-td>
                                            <b-td class="text-end text-nowrap">{{ parseFloat(remision.reparto) | toCurrency }}</b-td>
                                            <b-td class="text-end text-nowrap">{{ parseFloat(remision.dobleoper) | toCurrency }}</b-td>
                                            <b-td class="text-end text-nowrap">{{ parseFloat(remision.otros) | toCurrency }}</b-td>
                                            <b-td class="text-end text-nowrap">{{ parseFloat(remision.subtotal) | toCurrency }}</b-td>
                                            <b-td class="text-end text-nowrap">{{ parseFloat(remision.iva) | toCurrency }}</b-td>
                                            <b-td class="text-end text-nowrap">{{ parseFloat(remision.retencion) | toCurrency }}</b-td>
                                            <b-td class="text-end text-nowrap" style="background-color: #D7F7FE;">{{ parseFloat(remision.total) | toCurrency | important}}</b-td>
                                            <b-td class="text-end text-nowrap" 
                                                :class="{'bg-magenta': ((remision.moneda == 'NAL' && remision.tipocambio != 1 ) || (remision.moneda == 'DLS' && remision.tipocambio == 1 ) )}">{{ parseFloat(remision.tipocambio)  | tipoCambio }}</b-td>
                                            <b-td>{{ remision.cancelada}}</b-td>
                                            <b-td>{{ remision.liquidacion | leadingZeros(7) }}</b-td>
                                            <b-td >{{ remision.impresiones| leadingZeros(4)  }}</b-td>
                                            <b-td class="text-start text-nowrap">{{ formato(remision.fechacambio,"MMM-DD-YYYY") }}</b-td>
                                            <b-td class="text-start text-nowrap">{{ remision.usuario }}</b-td>
                                            <b-td class="text-start text-nowrap">{{ remision.pedmiento }}</b-td>
                                            <b-td class="text-start text-nowrap">{{ remision.cservicios }}</b-td>
                                            <b-td class="text-start text-nowrap">{{ remision.observaciones }}</b-td>
                                            <b-td class="text-start text-nowrap">{{ remision.ordenserv | leadingZeros(7)}}</b-td>
                                            <b-td class="text-start text-nowrap">{{ remision.uuid }}</b-td>
                                        </b-tr>		
                                    </b-tbody>
                                </b-table-simple>
                            </div>
                            
                        </div>
                        
                        <div class="col-2 ps-2 pe-5 btn-manager">
                            <ul class="d-block list-unstyled action-block">
                                <li><router-link to="/remisiones/agregar" class="btn btn-xs btn-success btn-block"><PlusIcon class="d-md-none" /><span class="d-none d-md-block">Agregar</span></router-link></li>
                                <li><button @click="modificar()" :disabled="disabled" class="btn btn-xs btn-primary btn-block"><FileEditOutlineIcon class="d-md-none" /><span class="d-none d-md-block">Cambiar</span></button></li>
                                <li><button @click="ValidaBorrar()" :disabled="disabled" class="btn btn-xs btn-danger btn-block"><DeleteOutlineIcon class="d-md-none" /><span class="d-none d-md-block">Borrar</span></button></li>
                                <li><button @click="imprimir()" class="btn btn-xs btn-secondary btn-block"><PrinterIcon class="d-md-none" /><span class="d-none d-md-block">Imprimir</span></button></li>
                            </ul>

                            <div style="width: 100px;text-align: left;float: right;">
                                <div class="form-check">
                                    <input class="form-check-input" v-model="filtroEstado" @change="consultar()" value="P" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked>
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        Pendientes
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" v-model="filtroEstado" @change="consultar()"  value="T" type="radio" name="flexRadioDefault" id="flexRadioDefault2" >
                                    <label class="form-check-label" for="flexRadioDefault2">
                                        Todos
                                    </label>
                                </div>
                            </div>

                            <ul class="d-block list-unstyled action-block pt-3">
                                <li><button @click="duplicar()" :disabled="disabled" class="btn btn-xs btn-outline-secondary btn-block"><FileEditOutlineIcon class="d-md-none" /><span class="d-none d-md-block">Duplicar</span></button></li>
                                <li><button @click="cancelar()" :disabled="disabled || selectedRegistro.CANCELADA == 'S'" class="btn btn-xs btn-outline-secondary btn-block"><DeleteOutlineIcon class="d-md-none" /><span class="d-none d-md-block">Cancelar</span></button></li>
                                <li><button  class="btn btn-xs btn-outline-secondary btn-block"><PrinterIcon class="d-md-none" /><span class="d-none d-md-block">Opciones</span></button></li>
                                <li><button  class="btn btn-xs btn-outline-secondary btn-block"><PrinterIcon class="d-md-none" /><span class="d-none d-md-block">Asignar</span></button></li>
                            </ul>
                        </div>
                        <div class="content-footer col-12"></div>
                        <PartidasPopUp v-show="showPartidas" @close="closePopup">
                            <template v-slot:header>
                                <h6 class="text-uppercase">Partidas de Factura: {{ facturaPartida }}</h6>
                            </template>
                            <template v-slot:body>
                                <b-table-simple hover caption-top striped class="popupPartidas">
                                    <b-thead head-variant="light">
                                        <b-tr>
                                            <b-th>Pda</b-th>
                                            <b-th>Artículo</b-th>
                                            <b-th>Descripción</b-th>
                                            <b-th>Unidad</b-th>
                                            <b-th>Cantidad</b-th>
                                            <b-th class="text-end">Precio</b-th>
                                            <b-th class="text-end">Importe</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="partida in partidas" :key="partida.partida">
                                            <b-td>{{partida.partida}}</b-td>
                                            <b-td>{{partida.servicio}}</b-td>
                                            <b-td>{{partida.descripcion }}</b-td>
                                            <b-td>{{partida.unidad }}</b-td>
                                            <b-td class="text-end">{{partida.cantidad }}</b-td>
                                            <b-td class="text-end">{{ parseFloat(partida.unitario) | toCurrency}}</b-td>
                                            <b-td class="text-end"><b>{{ parseFloat(partida.importe) | toCurrency}}</b></b-td>
                                        </b-tr>
                                    </b-tbody>
                                    <b-tfoot>
                                        <b-tr>
                                            <b-td colspan="6" class="text-end">Total: </b-td>
                                            <b-td class="text-end"><b>{{ totalPartidas | toCurrency }}</b></b-td>
                                        </b-tr>
                                    </b-tfoot>
                                </b-table-simple>
                            </template>
                        </PartidasPopUp>
                        <InfoPopUp  v-show="showNotification" @close="closeInfo" :size="25">
                            <template v-slot:header>
                                <h6 class="text-uppercase">{{notificationTitle}}</h6>
                            </template>
                            <template v-slot:body>
                                {{notificationText}}
                            </template>
                        </InfoPopUp>
                        <YesNoPopupVue  v-show="showDelete" @close="closeDelete" @aceptar="setEliminar()">
                            <template v-slot:header>
                                <h6 class="text-uppercase">{{deleteTitle}}</h6>
                            </template>
                            <template v-slot:body>
                                {{deleteText}}
                            </template>
                        </YesNoPopupVue>
                        <CancelarFacturaPopUp v-if="showCancelarFactura" @close="closePopup" @goAhead="creaCancelacion()" :size="35">
                            <template v-slot:header>
                                <h6 class="text-uppercase">Cancelar Factura</h6>
                            </template>
                            <template v-slot:body >
                                <div class="p-2" >
                                    <div class="row" >
                                        <div class="col-7">
                                            <fieldset class="border p-2">
                                                <legend>Docto a Cancelar</legend>
                                                
                                                <div class=" row">
                                                    <div class="col-7">
                                                        <div class=" row">
                                                            <label class="col-md-5 text-nowrap">Sucursal</label>
                                                            <div class="col-md-7">
                                                                <input type="number" disabled class="form-control form-control-sm "  v-model="cancelaFac.sucursal" />		
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="row">
                                                            <label class="col-md-5 text-nowrap">Factura</label>
                                                            <div class="col-md-7">
                                                                <input type="number" disabled class="form-control form-control-sm "  v-model="cancelaFac.factura" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-5">
                                                        <div class="row">
                                                            <label class="col-md-12 text-nowrap">Fecha</label>
                                                            <div class="col-md-12">
                                                                <input type="date" disabled class="form-control form-control-sm "  v-model="cancelaFac.fecha" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <fieldset class="border p-2">
                                                <legend>Opciones</legend>
                                                <div class="rowd-block">
                                                    <div class="form-check">
                                                        <input class="form-check-input" value="S" v-model="cancelaFac.opciones " @change="execute_opciones()"  type="radio" id="opciones1">
                                                        <label class="form-check-label" for="opciones1">Solo Cancelar la Factura</label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input" value="C" v-model="cancelaFac.opciones " @change="execute_opciones()" type="radio" id="opciones2">
                                                        <label class="form-check-label" for="opciones2">Cancelar y Copiar Factura</label>
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <div class="row pt-2 ps-2">
                                                <div class="col-12">
                                                    <div class="form-check">
                                                        <input class="form-check-input" :disabled="cancelaFac.uuid_disabled"  v-model="cancelaFac.uuid "  type="checkbox" id="cancelauuid">
                                                        <label class="form-check-label" for="cancelauuid">Cancelar Folio Fiscal</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-5">
                                            <div class=" row">
                                                <label class="col-md-3 col-3 text-nowrap">Fecha</label>
                                                <div class="col-md-6 col-9 ps-0 ">
                                                    <input type="date" class="form-control form-control-sm " @blur="execute_cancelaFac_efecha()" v-model="cancelaFac.efecha" />		
                                                </div>
                                            </div>
                                            <div class=" row ps-3">
                                                <div class="form-check">
                                                    <input class="form-check-input"  v-model="cancelaFac.ncredito" @change="execute_cancelaFac_ncredito()" type="checkbox" id="ncredito">
                                                    <label class="form-check-label" for="ncredito">Genera N. Crédito</label>
                                                    <button class="btn btn-outline-secondary selector" @click="showCancelaFacEdit=true" v-if="edit_visible"><FileEditOutlineIcon /></button>
                                                </div>
                                            </div>
                                            <div class=" row">
                                                <div class="col-4">
                                                    <input type="number" :disabled="gr_cfdnc_disabled" class="form-control form-control-sm " @blur="execute_cancelaFac_serienc()" v-model="cancelaFac.serienc" />		
                                                </div>
                                                <div class="col-5">
                                                    <input type="number" :disabled="gr_cfdnc_disabled" class="form-control form-control-sm "  v-model="cancelaFac.folionc" />		
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-check">
                                                        <input class="form-check-input" :disabled="gr_cfdnc_disabled"  v-model="cancelaFac.cfdnc" @change="execute_cancelaFac_cfd()"  type="checkbox" id="cfd1">
                                                        <label class="form-check-label" for="cfd1">CFD</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class=" row">
                                                <label class="col-12 text-nowrap">Factura a Copiar</label>
                                                <div class="col-4">
                                                    <input type="number" :disabled="gr_cfd_disabled" class="form-control form-control-sm " @blur="execute_cancelaFac_serie()" v-model="cancelaFac.serie" />		
                                                </div>
                                                <div class="col-5">
                                                    <input type="number" :disabled="gr_cfd_disabled" class="form-control form-control-sm "  v-model="cancelaFac.folio" />		
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-check">
                                                        <input class="form-check-input" :disabled="cancelaFac.cfd_disabled || gr_cfd_disabled"  v-model="cancelaFac.cfd"  type="checkbox" id="cfd2">
                                                        <label class="form-check-label" for="cfd2">CFD</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" v-if="muestraMotivo">
                                                <label class="col-3 text-nowrap ">Motivo</label>
                                                <div class="col-9">
                                                    <select class="form-control form-control-sm"  v-model="cancelaFac.motivo">
                                                        <option value="FD">Factura Duplicada</option>
                                                        <option value="ET">Error en Tarifa</option>
                                                        <option value="EC">Error en Captura</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </CancelarFacturaPopUp>
                        <PrintComponentVue v-if="printPreview"  @close="closePrint">
                            <template v-slot:header>
                                <div class="row margin-print">
                                    <div class="col-3 text-center">
                                        <img src="@/assets/logo.png" class="img-fluid" alt="logo" />
                                    </div>
                                    <div class="col-6">
                                        <p class="cia-print">{{cia}}</p>
                                        <h3 class="titulo-print">Reporte de Facturas</h3>
                                    </div>
                                    <div class="col-3 fecha-print">
                                        <p class="text-end">{{today}}</p>
                                        <p class="text-end">{{now}}</p>
                                    </div>
                                </div>
                            </template>
                            <template  v-if="reporte == 'RPT_Factura'" v-slot:body>
                                <div class="row">
                                    <div class="col-12">
                                        <b-table-simple hover small caption-top responsive  >
                                            <b-thead head-variant="light">
                                                <b-tr>
                                                    <b-th>Cant</b-th>
                                                    <b-th>Descripción</b-th>
                                                    <b-th>Unitario</b-th>
                                                    <b-th>Importe</b-th>
                                                </b-tr>
                                            </b-thead>
                                            <b-tbody>
                                                <b-tr v-for="(reg,v) in totales" :key="v" class="cursor-pointer">
                                                    <b-td style="background-color:	#DDE9BA">{{ v }}</b-td>
                                                    <b-td >{{ reg.Facturas }}</b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:footer>
                                <div class="row">
                                    <div class="col-8">
                                        <div class="row">
                                            <div class="col-4 pt-5">Total en letra</div>
                                            <div class="col-8 text-end">{{selectedregistro.totalEnLetra}}</div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="row">
                                            <div class="col-4"><b>Subtotal</b></div>
                                            <div class="col-8 text-end">{{selectedRegistro.subtotal}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4"><b>IVA 16%</b></div>
                                            <div class="col-8 text-end">{{selectedRegistro.iva}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4"><b>TOTAL</b></div>
                                            <div class="col-8 text-end">{{selectedRegistro.total}}</div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <hr>
                                        <span class="text-uppercase">observaciones</span>
                                        <p>{{selectedRegistro.observaciones}}</p>
                                    </div>
                                </div>
                            </template>
                        </PrintComponentVue>
                        <BusquedaVue v-if="busquedaVisible" v-hotkey="keymap" :campos="camposFiltro" @applyFilter="aplicaFiltro()"></BusquedaVue>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
</template>

<script>
    import handleBrowse from '@/mixins/handleBrowse'
    import handlePopUp from '@/mixins/handlePopUp'
    import handleDelete from '@/mixins/handleDelete'
    import handleGlobals from '@/mixins/handleGlobals'
    import BusquedaVue from '@/components/Busqueda.vue'
   
    import moment from 'moment'
    import PartidasPopUp from '@/components/InfoPopUp.vue'
    import InfoPopUp from '@/components/InfoPopUp.vue'
    import CancelarFacturaPopUp from '@/components/PopupWindow.vue'
    import LoadingContent from '@/components/LoadingSpinner.vue'

    import FileDocumentOutlineIcon from 'vue-material-design-icons/FileDocumentOutline.vue';
    import PlusIcon from 'vue-material-design-icons/Plus.vue';
    import PrinterIcon from 'vue-material-design-icons/Printer.vue';
    import DeleteOutlineIcon from 'vue-material-design-icons/DeleteOutline.vue';
    import FileEditOutlineIcon from 'vue-material-design-icons/FileEditOutline.vue';
    import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
    import ChevronDoubleRightIcon from 'vue-material-design-icons/ChevronDoubleRight.vue';
    import ChevronDoubleLeftIcon from 'vue-material-design-icons/ChevronDoubleLeft.vue';
    import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';

    import YesNoPopupVue from '@/components/YesNoPopup.vue'
    import PrintComponentVue from '@/components/PrintComponent.vue'
    
    import SideBar from '@/components/SideBarNav.vue';
    import TopBar from '@/components/TopBarNav.vue';
    export default {
        name:'win_fafacturas',
        mixins:[handlePopUp,handleBrowse,handleDelete,handleGlobals],
        props:['mode','perpage'],
        components:{SideBar,TopBar,BusquedaVue,PrintComponentVue,InfoPopUp,CancelarFacturaPopUp, YesNoPopupVue, PartidasPopUp, LoadingContent, PrinterIcon, FileEditOutlineIcon, DeleteOutlineIcon, FileDocumentOutlineIcon, PlusIcon, ChevronLeftIcon, ChevronDoubleRightIcon, ChevronDoubleLeftIcon, ChevronRightIcon },
        data(){
            return{
                remisiones: [],
                partidas:[],
                cancelaFac:{},
                gr_cfdnc_disabled:false,
                showVerificaComprobante:false,
                muestraTotalesTabla: false,
                showCancelarFactura:false,
                totales: [],
                muestraEntregadas:false,
                noRegistradas: [],
                visible_MOTIVOCAN:false,
                filtroFactura:'',
                filtroxmes:16,
                filtroxestatus:'T',
                filtroSucursales: false,
                showPartidas: false,
                actualizaTotales: false,
                statusActivo: '',
                Visible_BTN_Finvoice: false,
                muestraMotivo:false,
                gr_cfd_disabled:false,
                reporteLang:'es',
                reporte: ''
            }
        },
     
        computed:{
            totalPartidas(){
                let sum = 0;
                this.partidas.forEach(el => {
                   // console.log(el.importe);
                    sum += parseFloat(el.importe);                
                });
                return sum;
            },
            facturaPartida(){
                return this.selectedRegistro.factura;
            },
            gnAno(){
                let fecha = new Date()
                return moment(fecha).format('YYYY')
            },
            basePath(){
                return process.env.VUE_APP_BASE_SUBFOLDER
            },
            filtro(){
                let ftr = { fechas:  {  dato:this.filtroxmes,campo:'fecha',inactivo:false },
                            sucursal:{  dato:(this.filtroSucursales == false ? (this.$store.state.PARAMS.sucursal == 0 ? 1 : this.$store.state.PARAMS.sucursal) : 0),inactivo: (this.filtroSucursales == false)  }, 
                            factura: {  dato:this.filtroFactura, inactivo: (this.filtroFactura == 0 || this.filtroFactura == '' ? true : false) }, 
                            estatus: {  dato:this.filtroxestatus, inactivo: (this.filtroxestatus == 'T' || this.filtroxestatus == ''  ? true : false) }}
                return ftr
            }
        },
        async created(){
            this.getParams()
            this.getSessionData()
            if(this.OpenMode == false){
                this.getFiltros()
            } 
            
            this.actualizaTotales   = true            
            
            if(this.filtroxmes == '' || this.filtroxmes < 1){
                this.filtroxmes = new Date().getMonth()+1
            }
        },
        mounted(){
            this.getFiltroMes()
           
            let extTmp = ["her","tra","thr"];
            if(extTmp.includes(this.$ext) ){
                this.visible_MOTIVOCAN = true
            }

            if(this.$ext == "lmx" || this.$store.state.PARAMS.ACTIVARDIVISIONES == "S"){
                this.Visible_BTN_Finvoice = true;
            }
        },
        methods:{
            setSelected(){
                this.$store.state.selectedRegistro = this.selectedRegistro
            },
            closePopup() {
                this.showPartidas = false
                this.showVerificaComprobante = false
            },
            buscaxFactura(){
                this.selectedRegistro = {}
                this.consultar()
            },
            getFiltroMostrar(){
                this.consultar()
            },
            getFiltroMes(){
                if(this.filtroxmes == 16){
                    this.actualizaTotales  = false
                }
                this.consultar()
            },
            async getFiltros(){
                await fetch(this.$api+'/rdx', {    
                    method: "POST",
                    headers: {
                        'Content-Type' : 'application/json',
                        'Authorization' : this.$store.state.iasctratkn
                    },

                    body: JSON.stringify({accion: "filters"})
                }).then((response)=>{
                    if(response.ok){
                        return response.json()
                    }
                }).then((data)=>{
                    let reg = data['FAC']
                    console.log(reg)
                    if(reg){
                        this.filtroxmes         = reg['mes'].dato
                        this.filtroSucursales   = reg['sucursal'].dato
                        this.filtroxestatus     = reg['estatus'].dato
                        this.filtroFactura      = reg['factura'].dato
                        this.paginaActual       = reg['paginaActual'].dato
                        if(this.paginaActual == '') this.paginaActual = 0
                    }else{
                        this.filtroxmes         = new Date().getMonth()+1
                        this.filtroSucursales   = false 
                        this.filtroxestatus     = 'T'
                        this.filtroFactura      = 0
                        this.paginaActual       = 1
                    }
                }).catch((err)=>{
                this.falseToken(err)
            });
            },
            async consultar(){
                this.remisiones = []
                this.isLoading = true
               
                await fetch(this.$api+'/rdx', {    
                    method: "POST",
                    headers: {
                        'Content-Type' : 'application/json',
                        'Authorization' : this.$store.state.iasctratkn
                    },
                    body: JSON.stringify({
                                        accion: "browse", 
                                        archivo: "Remision", 
                                        activarPaginas: true,
                                        orden: "folio", 
                                        filtro:{
                                            fechas:  {  dato:this.filtroxmes, campo:'fecha',inactivo:false},
                                            sucursal:{  dato:(this.filtroSucursales == false ? (this.$store.state.PARAMS.sucursal == undefined ? 1 : this.$store.state.PARAMS.sucursal) : 0), inactivo: this.filtroSucursales }, 
                                            estatus: {  dato:this.filtroxestatus, inactivo: (this.filtroxestatus == 'T' ? true : false) },
                                            paginaActual: {  dato: 'NA'}},
                                        pagina: this.paginaActual, 
                                        paginacion: this.paginacion,
                                        registrosxpagina: this.rows})
                }).then((response)=>{
                    if(response.ok){
                        return response.json()
                    }   
                }).then((data)=>{
                    this.remisiones     = data['registros']
                    this.totalRegistros = data['totalRegistros']
                    this.totalPaginas   = data['totalPaginas']
                    this.paginaActual   = data['paginaActual']

                }).finally(()=>{
                    this.isLoading = false;
                    if(this.filtroxmes <= 12){
                        this.calculaTotales(this.filtro)
                    }
                }).catch((err)=>{
                    this.falseToken(err)
                });
            },
            calculaTotales(){
                fetch(this.$api+'/rdx', {    
                        method: "POST",
                        headers: {
                            'Content-Type' : 'application/json',
                            'Authorization' : this.$store.state.iasctratkn
                        },
                        body: JSON.stringify({
                                            accion: "metodo", 
                                            archivo: "Facturas", 
                                            metodo: "CalcularTotales",
                                            params: this.filtro
                                            })
                }).then((response)=>{
                    if(response.ok){
                        return response.json()
                    }   
                }).then((data)=>{
                    this.totales = data['totales']
                    this.noRegistradas = data['noRegistradas']
                }).catch((err)=>{
                    this.falseToken(err)
                });
            },
            ValidaBorrar(){
                if(this.selectedRegistro.notascredito > 0 || this.selectedRegistro.notascargo > 0){
                    this.notificationTitle = 'Error al eliminar factura',
                    this.notificationText = 'No se puede borrar una factura cuando ya tiene notas asignadas.'
                    this.showNotification = true
                    return
                }

                if(this.selectedRegistro.esdigital == "S" && this.selectedRegistro.uuid != ""){
                    if(!this.selectedRegistro.pac == ""){ //solo facturas timbradas con con servicios de fussion
                        this.notificationTitle = 'Error al eliminar factura',
                        this.notificationText = 'Esta factura ya se timbró, no puedes borrar una factura timbrada.'
                        this.showNotification = true
                        return
                    }
                }
                this.deleteTitle = 'Advertencia'
                this.deleteText = '¿ Está seguro que desea eliminar este registro ?'
                this.showDelete = true
            },
            borrar(){
                let tmpId = this.selectedRegistro.factura
                fetch(this.$api+'/rdx',{
                            method: "POST",
                            headers: {
                                'Content-Type' : 'application/json',
                                'Authorization' : this.$store.state.iasctratkn
                            },
                            body: JSON.stringify({
                                        accion: "borrar", 
                                        archivo: "Facturas",
                                        params: {factura: this.selectedRegistro,
                                                sucursal: this.$store.state.PARAMS.SUCURSAL,
                                                ano: this.$store.state.gnAno}
                            })
                    }).then((response)=>{
                        if(response.ok){
                            return response.json()
                        }   
                    }).then((res)=>{
                        if(res == true){
                            this.closeDelete();
                            console.log(tmpId)
                            this.facturas = this.facturas.filter((e)=> {
                                return e.factura != tmpId
                            })
                            console.log(this.facturas)
                        }
                    }).finally(()=>{   
                        this.selectedRegistro = {}
                    }).catch((err)=>{
                this.falseToken(err)
            });
            },
            imprimir(){
                // preview the Table report
                if(this.selectedRegistro.esdigital == "S"){
                  //  Open(WIN_CrearCFD,FAC_SUCURSAL,"FAC",FAC_FACTURA,true)
                  console.log('s')
                }else{
                    fetch(this.$api+'/rdx', {    
                            method: "POST",
                            headers: {
                                'Content-Type' : 'application/json',
                                'Authorization' : this.$store.state.iasctratkn
                            },
                            body: JSON.stringify({
                                                accion: "metodo", 
                                                archivo: "Facturas", 
                                                metodo: "Imprimir",
                                                params: {factura: this.selectedRegistro }
                                                })
                    }).then((response)=>{
                        if(response.ok){
                            return response.json()
                        }   
                    }).then((data)=>{
                        if(data['popup'] != ''){
                            this.popupMenu(data['popup']);
                        }else{
                            this.reporteLang = data['lang']
                            this.reporte = data['reporte']
                            this.printPreview = true
                        }
                    }).catch((err)=>{
                        this.falseToken(err)
                    });
                }
            },
            cancelar(){
                if(this.selectedRegistro.importepagado > 0 || this.selectedRegistro.notascredito > 0){ 
                    this.notificationTitle = "Error"
                    this.notificationText = "No se puede Cancelar una factura cuando ya tiene pagos o notas de crédito."
                    this.showNotification = true
                    return 
                }
             
                if(this.selectedRegistro.cancelada != "S"){
                    fetch(this.$api+'/rdx',{
                            method: "POST",
                            headers: {
                                'Content-Type' : 'application/json',
                                'Authorization' : this.$store.state.iasctratkn
                            },
                            body: JSON.stringify({
                                        accion: "metodo", 
                                        archivo: "Facturas",
                                        metodo:'verificaCancelacion',
                                        params: {factura: this.selectedRegistro}
                            })
                    }).then((response)=>{
                        if(response.ok){
                            return response.json()
                        }   
                    }).then((res)=>{
                        if(res['msg'] != ''){
                            this.notificationTitle = "Error"
                            this.notificationText = res['msg']
                            this.showNotification = true
                        }else{
                            this.muestraMotivo = res['muestaMotivo']
                            this.cancelaFac.sucursal = this.selectedRegistro.sucursal
                            this.cancelaFac.factura = this.selectedRegistro.factura
                            this.cancelaFac.fecha = this.selectedRegistro.fecha
                            this.cancelaFac.ncredito = 'S'
                            this.cancelaFac.folio = res['FacturaIni']
                            this.cancelaFac.efecha = this.formato(new Date(),'YYYY-MM-DD')
                            this.cancelaFac.cfdnc = res['CFDNC']
                            this.cancelaFac.cfdnc_disabled = res['CFDNC_Disabled']
                            this.gr_cfd_disabled = true

                            if(res['LocNC'] == "R"){
                                this.cancelaFac.ncredito_disabled =  true
                                this.cancelaFac.opciones_disabled =  true
                                this.notificationTitle = 'Advertencia'
                                this.notificationText ="Esta factura viene de remisiones por lo que solo puedes generar una Nota de Crédito. Si lo que deseas es Borrar la Factura, la opción sería:       Operación -> Eliminar Facturas Generadas";
                                this.showNotification = true
                                return
                            }
                            this.cancelaFac.serienc = res['serienc']
                            this.cancelaFac.nSerieDFac = res['nSerieDFac']
                            this.cancelaFac.nSerieDNC = res['nSerieDNC']
                            this.cancelaFac.numero_nc = res['numero_nc']
                            this.cancelaFac.numero_fac = res['numero_fac']

                            //usarse en backend
                            this.cancelaFac.LocNC = res['LocNC']
                            this.cancelaFac.sConcepto = res['sConcepto']

                            this.GetNumero("NC")
                           
                            this.$store.state.PARAMS.CFDDEFAULT = res['CFDDEFAULT']
                            this.$store.state.PARAMS.NCEDITARCANCEL = res['NCEDITARCANCEL']
                            this.cancelaFac.edit_visible= this.$store.state.PARAMS.NCEDITARCANCEL
                            this.cancelaFac.uuid_visible = res['cancelarUUID']
                            this.execute_cancelaFac_ncredito()

                            //gsCFD,gnFolioNC,gsCFDNC
	
                            this.showCancelarFactura = true
                        }
                    }).catch((err)=>{
                        this.falseToken(err)
                    });
                }
            },
            execute_cancelaFac_efecha(){
                if(this.cancelaFac.efecha < this.selectedRegistro.fecha || this.cancelaFac.efecha > this.formato(new Date(),"YYYY-MM-DD")){
                    this.notificationTitle = 'Error'
                    this.notificationText = "La Fecha de cancelación esta fuera de rango, no puede ser anterior a la fecha de factura ni mayor a la del dia de hoy.";
                    this.cancelaFac.efecha = this.formato(new Date(),"YYYY-MM-DD")
                }

                let nPeriodo = this.formato(this.selectedRegistro.fecha,"YYYYMM")
                let nPerCanc = this.formato(this.cancelaFac.efecha,"YYYYMM")
                if(nPeriodo != nPerCanc ){
                    this.cancelaFac.ncredito = true
                }
            },
            execute_cancelaFac_ncredito(){
                let nPeriodo = this.formato(this.selectedRegistro.fecha,"YYYYMM")
                let nPerCanc = this.formato(this.cancelaFac.efecha,"YYYYMM")

                if(nPeriodo != nPerCanc ){
                    this.cancelaFac.ncredito = true
                    this.notificationText = "No puedes cancelar una factura sin nota de crédito si no es del mismo mes.";
                    this.notificationTitle = "Error"
                    this.showNotification = true
                }
                if(this.cancelaFac.ncredito == true){
                    this.gr_cfdnc_disabled = false
                    this.cancelaFac.cfdnc_disabled = false

                    if(this.$store.state.PARAMS.CFDDEFAULT == true ){
                        this.cancelaFac.cfdnc = true
                        this.cancelaFac.cfdnc_disabled = true
                    }
                    if(this.cancelaFac.cfdnc == true ){ 	
                        this.cancelaFac.serienc = this.GetSerie("NC","S")	
                    }else{	
                        this.cancelaFac.serienc = this.GetSerie("NC","N")
                    }
                    this.GetNumero("NC")
                }else{
                    this.gr_cfdnc_disabled = true
                    this.cancelaFac.serienc = 0
                    this.cancelaFac.folionc = 0
                    this.cancelaFac.cfdnc = false	
                }
                this.cancelaFac.uuid_disabled = this.cancelaFac.ncredito
            },
            execute_cancelaFac_serienc(){
                if(this.cancelaFac.nSerieDNC > 0 && this.cancelaFac.serienc == this.cancelaFac.nSerieDNC){
                    this.cancelaFac.cfdnc = true
                }else{
                    this.cancelaFac.cfdnc = false	
                }
                this.GetNumero("NC")
            },
            execute_cancelaFac_cfd(){
                if(this.cancelaFac.cfd == true){
                    this.cancelaFac.serie = this.GetSerie("FAC","S")	
                }else{
                    this.cancelaFac.serie = this.GetSerie("FAC","N")	
                }
                this.GetNumero("FAC")
            },
            execute_cancelaFac_cfdnc(){
                if(this.cancelaFac.cfdnc == true){
                    this.cancelaFac.serienc = this.GetSerie("NC","S")	
                }else{
                    this.cancelaFac.serienc = this.GetSerie("NC","N")
                }		
                this.GetNumero("NC")
            },
            execute_cancelaFac_serie(){
                if(this.cancelaFac.nSerieDFac > 0 && this.cancelaFac.serie == this.cancelaFac.nSerieDFac){
                    this.cancelaFac.cfd = true
                }else{
                    this.cancelaFac.cfd = false	
                }
                this.GetNumero("FAC")
            },
            execute_opciones(){
                if(this.cancelaFac.opciones == 'S'){
                        this.gr_cfd_disabled = true
                        this.cancelaFac.serie = 0
                        this.cancelaFac.folio = 0
                        this.cancelaFac.cfd = false
                }else{
                    this.gr_cfd_disabled = false
                    this.cancelaFac.cfd_disabled = false

                    if(this.$store.state.PARAMS.CFDDEFAULT == true ){
                        this.cancelaFac.cfd = true
                        this.cancelaFac.cfd_disabled = true
                    }	
                    if(this.cancelaFac.cfd == true ){ 
                        this.cancelaFac.serie = this.GetSerie("FAC","S")	
                    }else{
                        this.cancelaFac.serie = this.GetSerie("FAC","N")	
                    }

                   this.GetNumero("FAC")
                }
            },
            GetNumero(Tipo){
                fetch(this.$api+'/rdx', {    
                        method: "POST",
                        headers: {
                            'Content-Type' : 'application/json',
                            'Authorization' : this.$store.state.iasctratkn
                        },
                        body: JSON.stringify({
                                            accion: "metodo", 
                                            archivo: "Facturas", 
                                            metodo: "GetNumero",
                                            params: {cancelaFac: this.cancelaFac,tipo:Tipo}})
                }).then((response)=>{
                    if(response.ok){
                        return response.json()
                    }   
                }).then((data)=>{
                    if(Tipo == 'FAC'){
                        if(data['msg'] != ''){
                            this.showNotification = true
                            this.notificationTitle = 'error'
                            this.notificationText = data['msg']
                        }else{
                            this.cancelaFac.cfd  = data['cfd']
                            this.cancelaFac.serie_disabled = data['serie_disabled']
                        }
                    }else{
                        if(data['msg'] != ''){
                            this.showNotification = true
                            this.notificationTitle = 'error'
                            this.notificationText = data['msg']
                        }else{
                            this.cancelaFac.cfdnc = data['cfdnc']
                            this.cancelaFac.serienc_disabled = data['serienc_disabled']
                        }
                    }
                }).catch((err)=>{
                    this.falseToken(err)
                });
            },
            creaCancelacion(){
                fetch(this.$api+'/rdx', {    
                        method: "POST",
                        headers: {
                            'Content-Type' : 'application/json',
                            'Authorization' : this.$store.state.iasctratkn
                        },
                        body: JSON.stringify({
                                            accion: "metodo", 
                                            archivo: "Facturas", 
                                            metodo: "procedeCancelacion",
                                            params: {factura:this.selectedRegistro, cancelaFac: this.cancelaFac}})
                }).then((response)=>{
                    if(response.ok){
                        return response.json()
                    }   
                }).then((data)=>{
                    if(data['res'] == true){
                        console.log('true')
                        //let gsMotivoCancelacionHER = data['gsMotivoCancelacionHER']
                        //let cancelarUUID = data['cancelarUUID']
                    }else{
                        this.showNotification = true
                        this.notificationTitle = 'error'
                        this.notificationText = data['msg']
                    }
                }).catch((err)=>{
                    this.falseToken(err)
                });
            },
            duplicar(){
                this.$router.push({ name: 'DFacturas', params: {factura: this.selectedRegistro.factura}})
            },
            valida(){
                if(this.selectedRegistro == []){
                    return
                }
                if(this.selectedRegistro.uuid == ""){
                    this.showNotification = true
                    this.notificationTitle = "Advertencia"
                    this.notificationText = "El Documento no tiene folio fiscal que revisar"
                    return
                }
                //todo:
                //HReadSeekFirst(Clientes,CTE_CLIENTEID,FAC_CLIENTE,hLimitParsing)
                this.showVerificaComprobante = true
                //Open(WIN_ValidadorCFDI,NoSpace(FAC_UUID),NoSpace(Clientes.CTE_RFC),INIRead(gsExtension, "RFC","", gsDatos + "\GLOBAL.INI"))
            },
            validaComprobante(){
                if(this.selectedRegistro.esdigital != "S"){
                    this.showNotification = true
                    this.notificationTitle = "Error"
                    this.notificationText = "La Factura no es digital."
                    return
                }
                /*
                sXMLFile is string = CompleteDir(gsDirComprobantes)+gsExtension+"FAC"+NumToString(FAC_SUCURSAL,"03d")+NumToString(FAC_FACTURA,"07d")+".xml"
                if(fFileExist(sXMLFile) == false ){
                    this.showNotification = true
                    this.notificationTitle = "Error"
                    this.notificationText = "No se ha creado el comprobante digital para esta remision."
                    return
                }

                ToClipboard(sXMLFile)
                // Run the address passed in parameter
                ShellExecute("https://www.consulta.sat.gob.mx/SICOFI_WEB/ModuloECFD_Plus/ValidadorComprobantes/Validador.html")
                IF ErrorOccurred THEN 
                    Error(ErrorInfo())
                END
                */
            },
            modificar(){
                this.$router.push({ name: 'CFacturas', params: {factura: this.selectedRegistro.factura}})
            },
            muestraPartidas(){
                this.showPartidas = false
                this.partidas = []
                this.showPartidas = true
                this.isLoading = true
                fetch(this.$api+'/rdx',{
                            method: "POST",
                            headers: {
                                'Content-Type' : 'application/json',
                                'Authorization' : this.$store.state.iasctratkn
                            },
                            body: JSON.stringify({
                                        accion: "metodo", 
                                        archivo: "Facturas", 
                                        metodo: "getPartidas",
                                        params: { factura: { dato: this.selectedRegistro.factura },
                                                sucursal: { dato: this.selectedRegistro.sucursal}}
                            })
                    }).then((response)=>{
                        if(response.ok){
                            return response.json()
                        }   
                    }).then((data)=>{
                        this.partidas = data
                    }).finally(()=>{
                        this.isLoading = false
                }).catch((err)=>{
                    this.falseToken(err)
                });
            },
            GetSerie(tipo,digital){
                let serie = fetch(this.$api+'/rdx',{
                                        method: "POST",
                                        headers: {
                                            'Content-Type' : 'application/json',
                                            'Authorization' : this.$store.state.iasctratkn
                                        },
                                        body: JSON.stringify({
                                                    accion: "metodo", 
                                                    archivo: "Facturas", 
                                                    metodo: "readGetSerie",
                                                    params: {   tipo: tipo,
                                                                digital: digital}
                                        })
                            }).then((response)=>{
                                if(response.ok){
                                    return response.json()
                                }   
                            }).then((data)=>{
                                return  data
                            }).catch((err)=>{
                                this.falseToken(err)
                            });
                return serie
            }
        }
    }
</script>
