<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Maestro de Clientes</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-10">
                                            <form>
                                                <div class="row ">
                                                    <div class="col-12 col-md-2 offset-md-10">
                                                        <div class=" row">
                                                            <label class="col-4 text-nowrap">Sucursal</label>
                                                            <div class="col-8 d-flex">
                                                                <input type="number" class="form-control text-end form-control-sm" readonly v-model="cliente.sucursal" />													
                                                            </div>
                                                        </div>
                                                    </div>	
                                                </div>	
                                                <nav>
                                                    <div class="nav nav-underline" id="nav-tab" role="tablist">
                                                        <li class="nav-item"><a class="nav-item nav-link active" id="nav-general-tab" data-bs-toggle="tab" href="#nav-general" role="tab" aria-controls="nav-general" aria-selected="true">General</a></li>
                                                        <li class="nav-item"><a class="nav-item nav-link" id="nav-creditos-tab" data-bs-toggle="tab" href="#nav-creditos" role="tab" aria-controls="nav-creditos" aria-selected="false">Créditos</a></li>
                                                        <li class="nav-item"><a class="nav-item nav-link" id="nav-observaciones-tab" data-bs-toggle="tab" href="#nav-observaciones" role="tab" aria-controls="nav-observaciones" aria-selected="false">Observaciones</a></li>
                                                        <li class="nav-item"><a class="nav-item nav-link" id="nav-complementos-tab" data-bs-toggle="tab" href="#nav-complementos" role="tab" aria-controls="nav-complementos" aria-selected="false">Complementos</a></li>
                                                        <li class="nav-item"><a class="nav-item nav-link" id="nav-bancos-tab" data-bs-toggle="tab" href="#nav-bancos" role="tab" aria-controls="nav-bancos" aria-selected="false">Bancos</a></li>
                                                        <li class="nav-item"><a class="nav-item nav-link" id="nav-doctos-tab" data-bs-toggle="tab" href="#nav-doctos" role="tab" aria-controls="nav-doctos" aria-selected="false">Doctos</a></li>
                                                    </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="container p-1 tab-pane fade show active" id="nav-general" role="tabpanel" aria-labelledby="nav-general-tab">
                                                        
                                                        <div class="row ">
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Cliente</label>
                                                                    <div class="col-6 d-flex">
                                                                        <input type="number" ref="clienteid" class="form-control text-end form-control-sm" :disabled="!State_CLIENTEID"  v-model="cliente.clienteid" />													
                                                                    </div>
                                                                </div>
                                                            </div>	
                                                            <div class="col-8" v-show="Visible_division">
                                                                <LookUpCtrl :label="'División de'" :objeto="cliente" :cols="[2,3,7]" :archivo="'Clientes'" :campo="'clienteid'" :descripcion="'nombre'" :model="'division'" v-model="cliente.division" />
                                                            </div>	
                                                        </div>	
                    
                                                        <div class="row ">
                                                            <div class="col-12">
                                                                <div class=" row">
                                                                    <label class="col-1 text-nowrap">Nombre</label>
                                                                    <div class="col-11 d-flex">
                                                                        <input type="text" ref="nombre" maxlength="70" class="form-control form-control-sm "  v-model="cliente.nombre" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row ">
                                                            <div class="col-12">
                                                                <div class=" row">
                                                                    <label class="col-1 text-nowrap">Dirección</label>
                                                                    <div class="col-11 d-flex">
                                                                        <input type="text" ref="direccion" maxlength="95" class="form-control form-control-sm "  v-model="cliente.direccion" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Num Int.</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="text" ref="numint" maxlength="10" class="form-control form-control-sm "  v-model="cliente.numint" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Num Exterior</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="text" ref="numext" maxlength="10" class="form-control form-control-sm "  v-model="cliente.numext " />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">RFC</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="text" ref="rfc" maxlength="15" @blur="EsClienteExtranjero()" class="form-control form-control-sm "  v-model="cliente.rfc " />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row ">
                                                            <div class="col-12">
                                                                <div class=" row">
                                                                    <label class="col-1 text-nowrap">Entre Calles</label>
                                                                    <div class="col-11 d-flex">
                                                                        <input type="text" ref="referencia" maxlength="30" class="form-control form-control-sm "  v-model="cliente.referencia" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row ">
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Colonia</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="text" ref="colonia" maxlength="30" class="form-control form-control-sm "  v-model="cliente.colonia" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-8"> 
                                                                <LookUpCtrl :label="'SAT'" :objeto="cliente" :cols="[1,2,2]" :archivo="'satColonias'" :hideDescripcion="true" :campo="'cvecolonia'" :model="'cvecolonia'" v-model="cliente.cvecolonia" />
                                                            </div>
                                                        </div>
                                                        <div class="row ">
                                                            <div class="col-12">
                                                                <LookUpCtrl :label="'Ciudad'" :objeto="cliente" :cols="[1,2,9]" :archivo="'Ciudades'" :campo="'clave'" :descripcion="'ciudad'" :model="'numciudad'" v-model="cliente.numciudad" />
                                                            </div>
                                                        </div>
                                                        <div class="row ">
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Ciudad</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="text" ref="ciudad" maxlength="50" class="form-control form-control-sm "  v-model="cliente.ciudad" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Cod Postal</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="number" ref="cpostal" class="form-control form-control-sm "  v-model="cliente.cpostal " />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row ">
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Estado</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="text" ref="estado" maxlength="20" class="form-control form-control-sm " v-model="cliente.estado" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">País</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="text" ref="pais" maxlength="20"  class="form-control form-control-sm " v-model="cliente.pais" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row ">
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Contacto</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="text" ref="contacto" maxlength="30"  class="form-control form-control-sm "  v-model="cliente.contacto" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">CCosto</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="number" ref="ccosto" class="form-control form-control-sm "  v-model="cliente.ccosto" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row " style="padding-bottom:1.5px">
                                                            <div class="col-12">
                                                                <div class=" row">
                                                                    <label class="col-1 text-nowrap">Email</label>
                                                                    <div class="col-11 d-flex">
                                                                        <textarea ref="correo" class="form-control form-control-sm " maxlength="512" style="min-height:10vh"  rows="2" v-model="cliente.correo" ></textarea>		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        
                                                        </div>

                                                        <div class="row ">
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Teléfono</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="text" ref="telefono" class="form-control form-control-sm " maxlength="30"   v-model="cliente.telefono" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <div class=" row">
                                                                    <label class="col-3 text-nowrap">Fax</label>
                                                                    <div class="col-9 d-flex">
                                                                        <input type="text" ref="fax" class="form-control form-control-sm " maxlength="15"  v-model="cliente.fax" />		
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    
                                                        <div class=" row">
                                                            <div class="col-12">
                                                                <LookUpCtrl :label="'Grupo'" :objeto="cliente" :cols="[1,2,9]" :archivo="'faGrupos'" :campo="'grupo'" :descripcion="'descripcion'" :model="'grupo'" v-model="cliente.grupo" />
                                                            </div>
                                                        </div>
                                                

                                                        <div class="row ">
                                                            <div class="col-4 offset-md-3">
                                                                <div class="row ">
                                                                    <div class="pb-1 col-12">
                                                                        <input ref="obsoleto" class="form-check-input" type="checkbox" v-model="cliente.obsoleto"  id="CBOX_obsoleto">
                                                                        <label class="form-check-label ps-2" for="CBOX_obsoleto">Obsoleto</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-5 ps-5">
                                                                <div class=" row">
                                                                    <label class="col-2 text-nowrap">Tipo</label>
                                                                    <div class="col-10 ">
                                                                        <div class="form-check form-check-inline">
                                                                            <input class="form-check-input" v-model="cliente.pfm" type="radio" name="RADIO_PFM" id="pfm1" value="F">
                                                                            <label class="form-check-label" for="pfm1">Persona Física</label>
                                                                        </div>
                                                                        <div class="form-check form-check-inline">
                                                                            <input  class="form-check-input" v-model="cliente.pfm" type="radio" name="RADIO_PFM" id="pfm2" value="M">
                                                                            <label class="form-check-label" for="pfm2">Persona Moral</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class=" row" v-show="false">
                                                                    <label class="col-2 text-nowrap">Clasif.</label>
                                                                    <div class="col-10 ">
                                                                        <div class="form-check form-check-inline">
                                                                            <input  class="form-check-input" :disabled="!State_Tipo" v-model="cliente.tipo" type="radio" name="RADIO_TIPO" id="tipo1" value="C">
                                                                            <label class="form-check-label" for="tipo1">Cliente</label>
                                                                        </div>
                                                                        <div class="form-check form-check-inline">
                                                                            <input  class="form-check-input" :disabled="!State_Tipo" v-model="cliente.tipo" type="radio" name="RADIO_TIPO" id="tipo2" value="D">
                                                                            <label class="form-check-label" for="tipo2">Destinatario</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="container p-1 tab-pane fade" id="nav-creditos" role="tabpanel" aria-labelledby="nav-infoad-tab">
                                                        <div class="border w-50 p-3 mb-3">
                                                            <fieldset>
                                                                <div class="row">
                                                                    <div class=" col-12">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap">Cond. Pago</label>
                                                                            <div class="col-8 d-flex">
                                                                                <select ref="condicion" class="form-control form-control-sm" v-model="cliente.condicion">
                                                                                    <option v-for="cond in condiciones" :key="cond.condno" :value="cond.condno">
                                                                                        {{cond.descripcion}}
                                                                                    </option>
                                                                                </select>													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>	
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap">Moneda</label>
                                                                            <div class="col-8 d-flex">
                                                                                <select ref="condicion" class="form-control form-control-sm" v-model="cliente.moneda">
                                                                                    <option v-for="(moneda,x) in monedas" :key="x" :value="moneda.clave">
                                                                                        {{moneda.descripcion}}
                                                                                    </option>
                                                                                </select>	
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap">Día de Pago</label>
                                                                            <div class="col-8 d-flex">
                                                                                <input ref="diaspago" type="text" maxlength="20"  class="form-control text-end form-control-sm"  v-model="cliente.diaspago" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap">Tasa IVA</label>
                                                                            <div class="col-8 d-flex">
                                                                                <input ref="tasaiva" type="number"  class="form-control text-end form-control-sm"  v-model="cliente.tasaiva" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap">Comisión</label>
                                                                            <div class="col-8 d-flex">
                                                                                <input ref="comision" type="number"  class="form-control text-end form-control-sm"  v-model="cliente.comision" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                        <div class="border w-50 p-3">
                                                            <fieldset>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap">Límite de Crédito</label>
                                                                            <div class="col-8 d-flex">
                                                                                <input ref="limite" type="number" step="any" min="0" class="form-control text-end form-control-sm"  v-model="cliente.limite" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap">Dias para Vencimiento</label>
                                                                            <div class="col-8 d-flex">
                                                                                <input ref="diaspvenc" type="number" class="form-control text-end form-control-sm"  v-model="cliente.diaspvenc" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap">Saldo</label>
                                                                            <div class="col-8 d-flex">
                                                                                <input ref="saldo" type="number" step="any" min="0" class="form-control text-end form-control-sm" readonly v-model="cliente.saldo" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap">Vencido</label>
                                                                            <div class="col-8 d-flex">
                                                                                <input ref="vencido" type="number" step="any" min="0" class="form-control text-end form-control-sm" readonly v-model="cliente.vencido" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label class="col-4 text-nowrap">Antig. Vencidos</label>
                                                                            <div class="col-8 d-flex">
                                                                                <input ref="diasvencido" type="number"  class="form-control text-end form-control-sm" readonly v-model="cliente.diasvencido" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                    <div class="container p-1 tab-pane fade" id="nav-observaciones" role="tabpanel" aria-labelledby="nav-direntregas-tab">
                                                        <div class="row ">
                                                            <div class="col-12 form-group">
                                                                <label class="text-nowrap">Observaciones</label>
                                                                <textarea ref="observaciones" class="form-control form-control-sm" maxlength="999" style="min-height:20vh" rows="3" name="" v-model="cliente.observaciones "></textarea>													
                                                            </div>	
                                                        </div>	
                                                        <div class="row ">
                                                            <div class="col-12 form-group">
                                                                <label class="text-nowrap">Requerimientos</label>
                                                                <textarea ref="requerimientos" class="form-control form-control-sm" maxlength="1024" placeholder="requerimientos de carga:" style="min-height:20vh" rows="3" name="" v-model="cliente.requerimientos "></textarea>													
                                                            </div>	
                                                        </div>	
                                                        <div class="row">
                                                            <div class="col-md-6 col-12">
                                                                <div class="form-check">
                                                                    <input class="form-check-input"  v-model="cliente.faconvertmn"  type="checkbox" id="faconvertmn">
                                                                    <label class="form-check-label" for="faconvertmn">Conversión de DLS a M.N. (Tráfico)</label>
                                                                </div>
                                                            </div>	
                                                            <div class="col-md-6 col-12">
                                                                <div class="form-check">
                                                                    <input class="form-check-input"  v-model="cliente.mailcfdiauto"  type="checkbox" id="mailcfdiauto">
                                                                    <label class="form-check-label" for="mailcfdiauto">Envio autómatico de comprobantes</label>
                                                                </div>
                                                            </div>	
                                                            <div class="col-md-6 col-12">
                                                                <div class=" row">
                                                                    <label class="col-2 text-nowrap">Localidad</label>
                                                                    <div class="col-10">
                                                                        <input ref="localidad" type="text" maxlength="20" class="form-control text-end form-control-sm" v-model="cliente.localidad" />													
                                                                    </div>
                                                                </div>
                                                            </div>	
                                                            <div class="col-md-6 col-12">
                                                                <div class=" row ms-2">
                                                                    <div class="pb-1 col-12">
                                                                        <input class="form-check-input" type="checkbox" v-model="cliente.sinimptocero"  id="sinimptocero">
                                                                        <label class="form-check-label" for="sinimptocero">
                                                                            Excluir impuesto en cero en comprobantes
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>	
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class=" row">
                                                                    <label class="col-1 text-nowrap">Asunto CFD</label>
                                                                    <div class="col-11 ">
                                                                        <input ref="asunto_cfd" type="text" maxlength="50" placeholder="Información que debe llevar en Asunto al mandar por correo el CFD" class="form-control form-control-sm" v-model="cliente.asunto_cfd" />													
                                                                    </div>
                                                                </div>
                                                            </div>	
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class=" row">
                                                                    <label class="col-1 text-nowrap">Forma de Pago</label>
                                                                    <div class="col-11 d-flex">
                                                                        <select ref="metodopago" class="form-control form-control-sm" v-model="cliente.metodopago">
                                                                            <option v-for="(metodo,i) in metodospago" :key="i" :value="i+'-'+metodo">{{i}}-{{metodo}}</option>
                                                                        </select>	
                                                                    </div>
                                                                </div>
                                                            </div>	
                                                        </div>
                                                        <div class="row">
                                                            <div class=" col-12">
                                                                <div class="row">
                                                                    <label class="col-1 text-nowrap text-right">Uso CFDI</label>
                                                                    <div class="col-11">
                                                                        <select ref="usocfdi" class="form-control form-control-sm" v-model="cliente.usocfdi">
                                                                            <option v-for="(claves, key) in clavesusocfdi" :key="key" :value="key">{{claves}}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                               
                                                        <div class="row">
                                                            <div class="col-md-6 col-12">
                                                                    <div class="row">
                                                                        <label class="col-2 text-nowrap text-right">Cve. País</label>
                                                                        <div class="col-10">
                                                                            <select ref="cvepais" class="form-control form-control-sm" v-model="cliente.cvepais">
                                                                                <option v-for="(clave, key) in clavespaises" :key="key" :value="clave">{{clave}}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                            </div>	
                                                            <div class="col-md-6 col-12 ">
                                                                <div class=" row" v-if="Visible_REGIDTRIB">
                                                                    <label class="col-2 text-nowrap">NumRegIdTrib</label>
                                                                    <div class="col-10">
                                                                        <input ref="regidtrib" type="text" maxlength="20" class="form-control text-end form-control-sm" v-model="cliente.regidtrib" />													
                                                                    </div>
                                                                </div>
                                                            </div>	
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class=" row">
                                                                    <label class="col-1 text-nowrap">Régimen Fiscal</label>
                                                                    <div class="col-11 d-flex">
                                                                        <select ref="regimenfiscal" class="form-control text-end form-control-sm" v-model="cliente.regimenfiscal">
                                                                            <option></option>
                                                                        </select>	
                                                                    </div>
                                                                </div>
                                                            </div>	
                                                        </div>	
                                                    </div>
                                                    <div  class="container p-1 tab-pane fade" id="nav-complementos" role="tabpanel" aria-labelledby="nav-complementos-tab">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <LookUpCtrl :label="'Addenda'" :objeto="cliente" :cols="[1,10,1]" :archivo="'Addendas'" :campo="'numid'" :hideDescripcion="true" :descripcion="'numid'" :model="'addenda'" v-model="cliente.addenda" />
                                                            </div>	
                                                        </div>
                                                        <div class="row ">
                                                            <div class="col-12 form-group " style="padding-bottom:6rem">
                                                                <p>Seleccione los complementos a incluir en los comprobantes del cliente</p>
                                                                <b-table-simple>
                                                                    <b-thead>
                                                                        <b-tr class="table-header">
                                                                            <b-th></b-th>
                                                                            <b-th>Complemento</b-th>
                                                                        </b-tr>
                                                                    </b-thead>
                                                                    <b-tbody>
                                                                        <b-tr v-for="comp in complementos" :key="comp.COM_CLAVE" >
                                                                            <b-td class="text-center">
                                                                                <div class="pb-1 col-12">
                                                                                    <input class="form-check-input" type="checkbox" v-model="comp.COM_CHECK"  id="CBOX_obsoleto">
                                                                                </div>
                                                                            </b-td>
                                                                            <b-td class="text-center">{{ comp.COM_DESC }}</b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div  class="container p-1 tab-pane fade" id="nav-bancos" role="tabpanel" aria-labelledby="nav-bancos-tab">
                                                        <div class="row ">
                                                            <div class="col-12 form-group " style="padding-bottom:6rem">
                                                                <b-table-simple>
                                                                    <b-thead>
                                                                        <b-tr class="table-header">
                                                                            <b-th>Nombre</b-th>
                                                                            <b-th>RFC</b-th>
                                                                            <b-th>Cuentas</b-th>
                                                                        </b-tr>
                                                                    </b-thead>
                                                                    <b-tbody>
                                                                        <b-tr v-for="banco in bancos" :key="banco.BNUMID" >
                                                                            <b-td class="text-center">{{ banco.BNOMBRE }}</b-td>
                                                                            <b-td class="text-center">{{ banco.BRFC }}</b-td>
                                                                            <b-td class="text-center">{{ banco.BCUENTA }}</b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <ul class="list-unstyled d-flex">
                                                                    <li class="pe-1"><button type="button" id="openPartidas" class="btn btn-sm btn-secondary  w-100" >Agregar</button></li>
                                                                    <li class="pe-1"><button type="button" class="btn btn-sm btn-secondary w-100" id="modifyPartida">Cambiar</button></li>
                                                                    <li class="pe-1"><button type="button" id="deletePartidas" class="btn btn-sm btn-secondary w-100">Borrar</button></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div  class="container p-1 tab-pane fade" id="nav-doctos" role="tabpanel" aria-labelledby="nav-doctos-tab">
                                                        <div class="row ">
                                                            <div class="col-12 form-group " style="padding-bottom:6rem">
                                                                <b-table-simple>
                                                                    <b-thead>
                                                                        <b-tr class="table-header">
                                                                            <b-th></b-th>
                                                                            <b-th>Documento</b-th>
                                                                            <b-th>Agregado</b-th>
                                                                        </b-tr>
                                                                    </b-thead>
                                                                    <b-tbody>
                                                                        <b-tr v-for="docto in doctos" :key="docto.EDOQ_REFERENCIA" >
                                                                            <b-td class="text-center">{{ docto.EDOQ_REFERENCIA }}</b-td>
                                                                            <b-td class="text-center">{{ docto.EDOQ_REFERENCIA }}</b-td>
                                                                            <b-td class="text-center">{{ docto.EDOQ_REFERENCIA }}</b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="col-2">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li><button @click="setCliente()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li><router-link to="/clientes" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import LookUpCtrl from '@/components/LookUpCtrl.vue';
import handleForm from '@/mixins/handleForm';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    components:{SideBar,TopBar, LookUpCtrl,LoadingContent },
    mixins:[handleForm,handleGlobals],
    data(){
        return{
            id: this.$route.params.cliente,
            modo: (this.$route.params.cliente > 0 ? 'Modificando' : 'Creacion'), 
            cliente: {},
            condiciones: [],
            monedas:[],
            metodospago: [],
            clavespaises: [],
            clavesusocfdi: [],
            complementos: [],
            bancos: [],
            doctos: [],
            titulo: 'Agregando un Cliente',
            State_Tipo: false,
            State_CLIENTEID:false,
            Visible_REGIDTRIB:false,
            Visible_division:false
        }
    },
    
    mounted(){
        this.Get_SAT_MetodosPago()
        this.Get_SAT_ClavesPaises()
        this.Get_SAT_ClavesUsoCFDI()
        this.getMonedas()
        this.llenaForma()
    },
    methods:{
        async llenaForma(){
            await this.getCondicionesPago();
            await this.getCliente();
            if(this.$route.params.cliente > 0){
                this.titulo = 'Cambiando un Cliente';
                
                if(this.cliente.metodopago != null ){
                    this.cliente.metodopago = this.GetClave_SAT_MetodPago()
                }
                this.EsClienteExtranjero()
                await this.getBancos()
            }
          
        },
        async getMonedas(){
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "browse", 
                                        archivo: "Monedas", 
                                        activarPaginas: false,
                                        filtro:{paginaActual:{dato:'NA'}},
                                        orden:"clave", 
                                        pagina:"todo", 
                                        paginacion: "",
                                        registrosxpagina: 0 })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.monedas = data['registros'];
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        async getBancos(){
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "metodo", 
                                        archivo: "BancosClientes", 
                                        metodo: "cargaBancos",
                                        params: {cliente: this.cliente.clienteid}
                                    })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.bancos = data;
            });
        },
        async getCliente(){
            let req = []
            this.isLoading = true
           await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "inicializar", modo:this.modo, archivo: "Clientes", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.cliente = data['res'];
                this.cliente.sinimptocero = this.cliente.sinimptocero == 'S'
                this.Visible_division = this.cliente.visible_division
                this.State_CLIENTEID = !this.disabled_clienteid
                this.complementos = this.cliente.arrComplementos
                console.log(data)
                req = data['req']
            }).finally(()=>{
               this.setRequired(req)
               this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setCliente(){
            this.isLoading = true
            this.cliente.sinimptocero   = this.cliente.sinimptocero == true ? 'S' : 'N'

            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: 'validar', archivo: "Clientes", modo:this.modo, record: this.cliente }) 
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then(()=>{
                this.isLoading = false
                this.$router.push('/clientes')
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        async getCondicionesPago(){
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "browse", 
                                        archivo: "Condiciones", 
                                        activarPaginas: false,
                                        filtro:{paginaActual:{dato:'NA'}},
                                        orden:"condno", 
                                        pagina:"todo", 
                                        paginacion: "",
                                        registrosxpagina: 0 })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.condiciones = data['registros'];
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        EsClienteExtranjero(){
            if(this.cliente.rfc == "XEXX010101000"){
                //this.cliente.CVEPAIS..Visible = TRUE
                this.Visible_REGIDTRIB = true
            }else{
                //this.cliente.CVEPAIS..Visible = FALSE
                this.Visible_REGIDTRIB = false	
            }
        },
        openAddenda(){

        },
        async GetClave_SAT_MetodPago(){
            let res = ''
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "metodo", 
                                        archivo: "Global", 
                                        metodo: "GetClave_SAT_MetodPago",
                                        params: {metodo: this.cliente.metodopago, descripcion: false, completo:true}
                                       })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                res = data
            }).catch((err)=>{
                this.falseToken(err)
            });
            return res
        },  
        async Get_SAT_MetodosPago(){
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "metodo", 
                                        archivo: "Global", 
                                        metodo: "Get_SAT_MetodosPago",
                                        params: {}
                                       })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.metodospago = data
            }).catch((err)=>{
                this.falseToken(err)
            });
        },       
        async Get_SAT_ClavesPaises(){
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "metodo", 
                                        archivo: "Global", 
                                        metodo: "Get_SAT_ClavesPaises",
                                        params: {}
                                       })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.clavespaises = data
            }).catch((err)=>{
                this.falseToken(err)
            });
        },       
        async Get_SAT_ClavesUsoCFDI(){
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "metodo", 
                                        archivo: "Global", 
                                        metodo: "Get_SAT_ClavesUsoCFDI",
                                        params: {clave: ''}
                                       })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.clavesusocfdi = data
            }).catch((err)=>{
                this.falseToken(err)
            });
        }       
    }
}
</script>