<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Maestro de Geo Zonas</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row w-65">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-10">
                                            <form>
                                                <nav>
                                                    <div class="nav nav-underline" id="nav-tab" role="tablist">
                                                        <li class="nav-item"><a class="nav-item nav-link active" id="nav-general-tab" data-bs-toggle="tab" href="#nav-general" role="tab" aria-controls="nav-general" aria-selected="true">General</a></li>
                                                        <li class="nav-item"><a class="nav-item nav-link" id="nav-ubicacion-tab" data-bs-toggle="tab" href="#nav-ubicacion" role="tab" aria-controls="nav-ubicacion" aria-selected="false">Ubicación</a></li>
                                                    </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="container p-1 tab-pane fade show active" id="nav-general" role="tabpanel" aria-labelledby="nav-general-tab">
                                                        <div class="row ">
                                                            <div class=" col-12">
                                                                <div class="row">
                                                                    <div class=" col-12">
                                                                        <div class=" row">
                                                                            <label class="col-1 text-nowrap">ID</label>
                                                                            <div class="col-3 ">
                                                                                <input  ref="id" type="text" class="form-control form-control-sm"  v-model="zona.id" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>	
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class=" row">
                                                                            <label class="col-1 text-nowrap">Nombre</label>
                                                                            <div class="col-8 ">
                                                                                <input  ref="nombre" type="text" class="form-control form-control-sm"  v-model="zona.nombre" />													
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="row">
                                                                            <label class="col-1 text-nowrap text-right">Tipo</label>
                                                                            <div class="col-5">
                                                                                <select class="form-control form-control-sm" v-model="zona.tipo">
                                                                                    <option value="ZONA">ZONA</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>	
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12 pt-4">
                                                                        <p class="text-info">*** Trazar en la pestaña de ubicación la geozona</p>
                                                                    </div>	
                                                                </div>
                                                            </div>
                                                        </div>	
                                                    </div>
                                                    <div class="container p-1 tab-pane fade" id="nav-ubicacion" role="tabpanel" aria-labelledby="nav-ubicacion-tab">
                                                        
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class=" row">
                                                                    <label class="col-1 text-nowrap">Dirección</label>
                                                                    <div class="col-6">
                                                                        <input  ref="nombre" type="text" class="form-control text-end form-control-sm"  v-model="zona.direccion" />													
                                                                    </div>
                                                                    <button class="col-1 btn btn-outline-secondary btn-sm">c</button>
                                                                    <div class="form-check col-4">
                                                                        <input class="form-check-input"  v-model="cbox_inlcuir"  type="checkbox" id="string">
                                                                        <label class="form-check-label" for="string">Inlcuir Nombre</label>
                                                                    </div>
                                                                </div>
                                                            </div>	
                                                            <div class="col-12">
                                                                <div class=" row">
                                                                    <p class="col-12 text-nowrap">Utilizar botón derecho del ratón para marcar los puntos. <br>Cuando se hayan marcado todos los puntos dar clic en el botón de trazar</p>
                                                                </div>
                                                            </div>	
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <iframe 
                                                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230187.00306170405!2d-100.4679084471983!3d25.649011980502056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86629531b437f8f5%3A0xa3d3d3ca6ac89894!2sMonterrey%2C%20N.L.%2C%20M%C3%A9xico!5e0!3m2!1ses-419!2sus!4v1691555963260!5m2!1ses-419!2sus" 
                                                                    width="600" 
                                                                    height="450" 
                                                                    style="border:0;" 
                                                                    allowfullscreen="" 
                                                                    loading="lazy" 
                                                                    referrerpolicy="no-referrer-when-downgrade">
                                                                </iframe>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="col-2">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li><button @click="setZona()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li><router-link to="/gpsgeozonas" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import handleGlobals from '@/mixins/handleGlobals';
import handleForm from '@/mixins/handleForm';
import LoadingContent from '@/components/LoadingSpinner.vue'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    components:{SideBar,TopBar,LoadingContent },
    mixins:[handleGlobals,handleForm],
    data(){
        return{
            id: this.$route.params.zona,
            modo: (this.$route.params.zona != '' ? 'Modificando' : 'Creacion'), 
            zona: {},
            titulo: 'Agregando una Zona',
        }
    },
    computed:{
        cbox_inlcuir:{
            get(){
                return this.zona.inlcuir == 'S' ? true : false
            },
            set(val){
                return val 
            }  
        }
    },
    mounted(){
        this.llenaForma();
    },
    methods:{
        async llenaForma(){
            await this.getZona()
            if(this.$route.params.zona != ''){
                this.titulo = 'Cambiando una Zona'
            }
        },
        async getZona(){
            let req = []
            this.isLoading = true
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "inicializar",modo:this.modo, archivo: "gpsGeoZones", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.zona =data['res'];
                req = data['req']
            }).finally(()=>{
               this.setRequired(req)
               this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setZona(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: 'validar', archivo: "gpsGeoZones", modo:this.modo, record: this.zona })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then(()=>{
                this.$router.push('/gpsgeozonas')
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
       
    }
}
</script>