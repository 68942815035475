<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Tipos de Unidad</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row w-40">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-9">
                                            <form>
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-3 text-nowrap">Clave</label>
                                                            <div class="col-9 d-flex">
                                                                <input type="text" id="clave" ref="clave" class="form-control form-control-sm " :disabled="this.modo == 'Modificando'" required v-model="tipo.clave" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-3 text-nowrap">Descripcion</label>
                                                            <div class="col-9 d-flex">
                                                                <input type="text" id="descripcion" ref="descripcion" class="form-control form-control-sm " required v-model="tipo.descripcion" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-3 text-nowrap">Capacidad</label>
                                                            <div class="col-9 d-flex">
                                                                <input type="text" ref="capacidad" class="form-control form-control-sm " required v-model="tipo.capacidad" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class="row">
                                                            <label class="col-3 text-nowrap text-end">Tipo</label>
                                                            <div class="col-9">
                                                                <select ref="tipo" @change="createCombo(); gr_subtipo_visible = (tipo.tipovor != 'VEH')"  class="form-control form-control-sm" v-model="tipo.tipovor">
                                                                    <option value="VEH">Vehículo</option>
                                                                    <option value="REM">Remolque</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="row" v-show="gr_subtipo_visible">
                                                            <label class="col-3 text-nowrap text-end">SubTipo Rem.</label>
                                                            <div class="col-6">
                                                                <select ref="subtiporem" @change="createCombo()" class="form-control form-control-sm" v-model="tipo.subtiporem">
                                                                    <option value=""></option>
                                                                    <option value="CTR001">CTR001 - Caballete</option>
                                                                    <option value="CTR002">CTR002 - Caja</option>
                                                                    <option value="CTR003">CTR003 - Caja Abierta</option>
                                                                    <option value="CTR004">CTR004 - Caja Cerrada</option>
                                                                    <option value="CTR005">CTR005 - Caja De Recolección Con Cargador Frontal</option>
                                                                    <option value="CTR006">CTR006 - Caja Refrigerada</option>
                                                                    <option value="CTR007">CTR007 - Caja Seca</option>
                                                                    <option value="CTR008">CTR008 - Caja Transferencia</option>
                                                                    <option value="CTR009">CTR009 - Cama Baja o Cuello Ganso</option>
                                                                    <option value="CTR010">CTR010 - Chasis Portacontenedor</option>
                                                                    <option value="CTR011">CTR011 - Convencional De Chasis</option>
                                                                    <option value="CTR012">CTR012 - Equipo Especial</option>
                                                                    <option value="CTR013">CTR013 - Estacas</option>
                                                                    <option value="CTR014">CTR014 - Góndola Madrina</option>
                                                                    <option value="CTR015">CTR015 - Grúa Industrial</option>
                                                                    <option value="CTR016">CTR016 - Grúa </option>
                                                                    <option value="CTR017">CTR017 - Integral</option>
                                                                    <option value="CTR018">CTR018 - Jaula</option>
                                                                    <option value="CTR019">CTR019 - Media Redila</option>
                                                                    <option value="CTR020">CTR020 - Pallet o Celdillas</option>
                                                                    <option value="CTR021">CTR021 - Plataforma</option>
                                                                    <option value="CTR022">CTR022 - Plataforma Con Grúa</option>
                                                                    <option value="CTR023">CTR023 - Plataforma Encortinada</option>
                                                                    <option value="CTR024">CTR024 - Redilas</option>
                                                                    <option value="CTR025">CTR025 - Refrigerador</option>
                                                                    <option value="CTR026">CTR026 - Revolvedora</option>
                                                                    <option value="CTR027">CTR027 - Semicaja</option>
                                                                    <option value="CTR028">CTR028 - Tanque</option>
                                                                    <option value="CTR029">CTR029 - Tolva</option>
                                                                    <option value="CTR030">CTR030 - Tractor</option>
                                                                    <option value="CTR031">CTR031 - Volteo</option>
                                                                    <option value="CTR032">CTR032 - Volteo Desmontable</option>
                                                                </select>
                                                            </div>
                                                            <label class="col-3 text-nowrap text-start p-0">* claves del SAT</label>
                                                        </div>
                                                        <div class=" row">
                                                            <label class="col-3 text-nowrap text-end">Ejes</label>
                                                            <div class="col-4">
                                                                <select ref="ejes" class="form-control form-control-sm" v-model="tipo.ejes">
                                                                    <option v-for="(eje, key) in ejes" :key="key" :value="eje">{{eje}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class=" row">
                                                            <label class="col-3 text-nowrap text-end">Llantas</label>
                                                            <div class="col-4">
                                                                <select ref="llantas" class="form-control form-control-sm" v-model="tipo.llantas">
                                                                    <option v-for="(llanta, key) in llantas" :key="key" :value="llanta">{{llanta}}</option>
                                                                </select>
                                                            </div>
                                                            <div class="form-check col-5">
                                                                <input ref="combustible" class="form-check-input"  v-model="tipo.combustible "  type="checkbox" id="combustible">
                                                                <label class="form-check-label" for="combustible">Combustible</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <hr style="color:#000">
                                                    </div>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-3 text-nowrap">Factor Cargado</label>
                                                            <div class="col-4 d-flex">
                                                                <input ref="cargado_factor" type="number" class="form-control form-control-sm "  v-model="tipo.cargado_factor" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class=" row">
                                                            <label class="col-3 text-nowrap">Factor Vacío</label>
                                                            <div class="col-4 d-flex">
                                                                <input ref="vacio_factor" type="number" class="form-control form-control-sm "  v-model="tipo.vacio_factor" />		
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                            </form>
                                        </div>
                                        <div class="col-3">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li><button @click="setTipoUnidad()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li><router-link to="/tpounidad" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <InfoPopUpVue  v-show="showNotification" @close="closeInfo">
                        <template v-slot:header>
                            <h6 class="text-uppercase">{{notificationTitle}}</h6>
                        </template>
                        <template v-slot:body>
                            {{notificationText}}
                        </template>
                    </InfoPopUpVue>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import InfoPopUpVue from '@/components/InfoPopUp.vue';
import handleGlobals from '@/mixins/handleGlobals'
import handlePopUp from '@/mixins/handlePopUp'
import handleForm from '@/mixins/handleForm';
import LoadingContent from '@/components/LoadingSpinner.vue'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    mixins:[handleGlobals,handlePopUp,handleForm],
    components:{SideBar,TopBar,InfoPopUpVue,LoadingContent},
    data(){
        return{
            id: this.$route.params.tipo,
            modo: (this.$route.params.tipo != '' && this.$route.params.tipo != undefined ? 'Modificando' : 'Creacion'), 
            tipo: {},
            ejes:[],
            llantas:[],
            titulo: 'Agregando un Tipo de Unidad',  
            gr_subtipo_visible: true
        }
    },
    mounted(){
        if(this.modo == 'Modificando'){
            this.getTpoUnidad();
        }
    },
    
    methods:{
        createCombo(){
            if(this.tipo.tipovor == "REM"){
                this.ejes = [1,2,3]
            }else{
                this.ejes = [0,2,3,4,5,6]
            }

            if(this.tipo.tipovor == "REM"){
                this.llantas = [2,4,6,8,10,12]
            }else{	
                this.llantas = [0,4,6,8,10,12,16,20]
            }
        },
        async getTpoUnidad(){
            this.isLoading = true
            let req = []
           await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "inicializar", modo:this.modo, archivo: "TpoUnidad", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.tipo = data['res'];
                req = data['req']
                this.tipo.combustible = this.tipo.combustible == 'S'
            }).finally(()=>{
                this.setRequired(req)
                this.createCombo()
                this.isLoading = false
                if(this.tipo.tipovor == "VEH" ){
                    this.gr_subtipo_visible = false
                }else{
                    this.gr_subtipo_visible = true
                }


            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setTipoUnidad(){
            this.tipo.combustible = this.tipo.combustible ? 'S' : 'N'
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: 'validar', archivo: "TpoUnidad", modo:this.modo, record: this.tipo }) 
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['res'] == 'error'){
                    this.showNotification = true
                    this.notificationTitle =  'Error'
                    this.notificationText = data['msg']
                    this.notificationRef = 'clave'
                }else{
                    this.$router.push('/tpounidad')
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        afterClose(){
            this.$refs[this.notificationRef].focus()
        }
     
    }
}
</script>