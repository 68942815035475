export default {
   data(){
    return{
        showNotification:false,
        notificationTitle: '',
        notificationText: '',
        notificationRef:'',
        printPreview:false,
    }
   },
    methods:{
        closeInfo(){
            this.showNotification = false
            this.notificationTitle =''
            this.notificationText = ''
            
            if(this.notificationRef != ''){
                this.afterClose()
            }
        },
        closePrint(){
            this.printPreview = false
        }
    },
   
};