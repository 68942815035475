<template>
     <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Maestro de Folios Digitales</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row  w-65">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-10 col-12">
                                            <form>
                                                <div class="row">
                                                    
                                                    <div class="col-md-4 col-12">
                                                        <div class=" row">
                                                            <label class="col-3 text-nowrap">Num ID</label>
                                                            <div class="col-9 ">
                                                                <input type="number" ref="numid" class="form-control form-control-sm" readonly v-model="concepto.numid" />													
                                                            </div>
                                                        </div>
                                                    </div>	
                                                   
                                                    <div class="col-md-6 col-12">
                                                        <div class="row">
                                                            <label class="col-6 text-nowrap">Descripción</label>
                                                            <div class="col-6">
                                                                <input type="text" ref="descripcion" class="form-control form-control-sm" v-model="concepto.descripcion" />													
                                                            </div>
                                                        </div>
                                                    </div>	
                                                </div>	       
                                            </form>
                                        </div>
                                        <div class="col-md-2 col-12">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li class="mobile-float"><button @click="setConcepto()" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li><router-link to="/conceptos" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <InfoPopUpVue  v-show="showNotification" @close="closeInfo">
                    <template v-slot:header>
                        <h6 class="text-uppercase">{{notificationTitle}}</h6>
                    </template>
                    <template v-slot:body>
                        {{notificationText}}
                    </template>
                </InfoPopUpVue>

            </div>
        </div>
    </section>
</template>

<script>
import InfoPopUpVue from '@/components/InfoPopUp.vue';
import handlePopUp from '@/mixins/handlePopUp';
import handleForm from '@/mixins/handleForm';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    components:{SideBar,TopBar,InfoPopUpVue,LoadingContent},
    mixins:[handlePopUp,handleForm,handleGlobals],
    data(){
        return{
            user: JSON.parse(Buffer.from(this.$store.state.iasctratkn.split('.')[1], 'base64').toString()),
            id: this.$route.params.concepto,
            modo: (this.$route.params.concepto > 0 ? 'Modificando' : 'Creacion'), 
            concepto: {},
            titulo: 'Agregando un Concepto de Factura'
        }
    },
  
    mounted(){
        this.llenaForma();
    },
    methods:{
        async llenaForma(){
            if(this.$route.params.concepto > 0){
                this.titulo = 'Cambiando un Concepto de Factura'
             //   this.$refs.nombre.focus()
            }
        },
        async getConcepto(){
            this.isLoading = true

            let req = []
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn},
                body: JSON.stringify({ accion: "inicializar",modo:this.modo, archivo: "faConceptosFactura", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.concepto = data['res'];
                req = data['req']
            }).finally(()=>{
               this.setRequired(req)
               this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setConcepto(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: 'validar', archivo: "faConceptosFactura", modo:this.modo, record: this.concepto })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['msg'] != '' && data['closeWindow'] == false){
                    this.notificationTitle = 'Error'
                    this.notificationText = data['msg']
                    this.showNotification = true
                }else{
                    if(data['msg'] != '' && data['closeWindow'] == true){
                        this.notificationTitle = 'Aviso'
                        this.notificationText = data['msg']
                        this.showNotification = true
                    }
                    this.$router.push('/conceptos')
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        afterClose(){
            this.$refs[this.notificationRef].focus()
        },
      
    }
}
</script>