
export default {
    data(){
        return{
            
        }
    },
    
    methods:{
        setRequired(req){
            req.forEach((r)=>{
                try {
                    this.$refs[r].setAttribute('required','')
                } catch (error) {
                    try{
                        document.querySelector('#'+r).setAttribute('required','')
                    }
                    catch(error){
                        //
                    }
                }
            })
        }        
    },
};