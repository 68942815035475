<template>
    <section>
        <SideBar v-if="!OpenMode" />
        <TopBar v-if="!OpenMode" />
        <div :class="[!OpenMode ? 'content' : '' ]">   
        <div class="main-content-header">
            <h1>Notificaciones</h1>
        </div>
        <LoadingContent v-if="isLoading" />
        <div class="row">
            <div class="col-lg-12 col-md-6">
                <div class="card mb-30" :style="{height:[contentHeight+'px']}">
                    <div class="row " :class="[!OpenMode ? 'p-4' : '']">
                        <div class="content-header  col-12">  </div>

                        <div class="col-md-10 col-12">
                            <div class="table-responsive ps-2">
                                <b-table-simple class="browse" hover small caption-top responsive >
                                    <b-thead head-variant="light" class="table-header">
                                        <b-tr>
                                            <b-th class="text-nowrap">No.</b-th>
                                            <b-th class="text-nowrap">Póliza</b-th>
                                            <b-th class="text-nowrap">Inicio de Vigencia</b-th>
                                            <b-th class="text-nowrap">Fin de Vigencia</b-th>
                                            <b-th class="text-nowrap">Proveedor</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="(n,indx) in notificaciones" :key="indx" @click="selectedRegistro=n;selectedRegistro.indx = indx" :class="{ 'selected-row': (selectedRegistro.id == n.id) }">
                                            <b-td class="text-nowrap">
                                                <div class="form-check check-noti" >
                                                    <input class="form-check-input" @change="check(n)" :value="n.visto" type="checkbox">
                                                </div>
                                            </b-td>
                                            <b-td class="text-nowrap text-center">{{n.id | leadingZeros(3)}}</b-td>
                                            <b-td class="text-nowrap">{{ n.titulo }}</b-td>
                                            <b-td class="text-nowrap">{{ n.texto }}</b-td>
                                            <b-td class="text-nowrap"><button class="btn btn-success p-1">ir a enlace</button></b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </div>
                        </div> 
                        <div class="col-2 ps-2 pe-5 " v-show="false" >
                            <ul class="d-block list-unstyled action-block">
                                <li v-if="!OpenMode"><router-link to="/polizas/agregar" class="btn btn-xs btn-success btn-block">Agregar</router-link></li>
                                <li v-if="!OpenMode"><button @click="modificar()" :disabled="disableBtn" type="button" class="btn btn-xs btn-primary btn-block">Cambiar</button></li>
                                <li v-if="!OpenMode"><button @click="ask()" :disabled="disableBtn" class="btn btn-xs btn-danger btn-block">Borrar</button></li>
                            </ul>
                        </div>
                        
                        <div class="content-footer col-12 text-start"></div>
                    </div>
                </div>
            </div>
        </div>
        <YesNoPopupVue  v-show="showNotification" @close="closeInfo" @aceptar="borrar()">
            <template v-slot:header>
                <h6 class="text-uppercase">{{notificationTitle}}</h6>
            </template>
            <template v-slot:body>
                {{notificationText}}
            </template>
        </YesNoPopupVue>
        </div>
    </section>
</template>

<script>
import YesNoPopupVue from '@/components/YesNoPopup.vue';

import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'
import handleBrowse from '@/mixins/handleBrowse'
import handlePopUp from '@/mixins/handlePopUp'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    name:'WIN_Notificaciones',
    props:['mode','perpage'],
    mixins:[handleGlobals,handleBrowse,handlePopUp],
    components:{ SideBar,TopBar,LoadingContent,YesNoPopupVue},
    data(){
        return{
            notificaciones: []
        }
    },
      
    methods:{
        consultar(){
            this.isLoading = true
            
            fetch('https://portal-beta.ingavanzada.com/notificaciones/php/notificaciones.php', {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({  accion: "get"})
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.notificaciones = data;
                
            }).finally(()=>{
                this.isLoading = false;
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        check(notificacion){
            fetch('https://portal-beta.ingavanzada.com/notificaciones/php/notificaciones.php', {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({  accion: "check",id:notificacion.id, val: (notificacion.visto ? 'S' : 'N')})
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                console.log(data)  
            }).catch((err)=>{
                this.falseToken(err)
            });
        }
    }
}
</script>

