export default {
    data(){
        return{
            newSelectedFilter:false,
            paginaActual: 0,
            paginacion: '',
            totalRegistros: 0,
            totalPaginas: 0,
            selectedRegistro: this.$store.state.selectedRegistro,
            disabled:true,
            OpenMode: false,
            windowHeight: 0,
			contentHeight:600,
            rows: 0,
            print:false,
            busquedaVisible:false,
            orientacionRPT:'v',
            activoVisible:false,
            camposFiltro: [],
            showDelete:false
        }
    },
    created(){
        this.OpenMode = (this.$props.mode == 'lookup' ? true : false)
    },
    mounted(){
        this.handleResize()
        this.consultar();
        this.fillCamposBusqueda()
    },
    unmounted() {
       //window.removeEventListener('resize', this.handleResize());
       window.onresize = this.handleResize;
    },
    computed:{
        keymap(){
            return {
                // 'esc+ctrl' is OK.
                'ctrl+f10': this.toggle,
                'ctrl+f3': this.toggleBusqueda,
                'f1':this.help
            }
        },
        disableBtn(){
            return Object.keys(this.selectedRegistro).length == 0 ? true : false
        }
    },
    methods:{
        ask(){
            this.showDelete = true
            this.notificationText =  "¿ Está seguro que desea eliminar este registro ?"
            this.notificationTitle = "Precaución"
        },
        
        borrar(file,arreglo){
            this.closeDelete()
            this.isLoading = true
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "borrar", archivo: file, registro: this.selectedRegistro })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                console.log( data);
                if(data != '' && data != true){
                     this.openError(data)
                }else if(data == true){
                    arreglo.splice(this.selectedRegistro.indx, 1)
                }
            }).finally(()=>{
                this.isLoading = false
            })
        
            return arreglo
        },
        openError(error){
            this.notificationText = error
            this.notificationTitle = "Error"
            this.showNotification = true
        },
        closeDelete(){
            this.showDelete = false
        },
        fillCamposBusqueda(){
            let cols = document.querySelector('.browse thead tr:last-child').childNodes
            cols.forEach((col)=>{
                if(col.innerText != '' && !col.classList.contains('nf')) {
                    this.camposFiltro.push(col.innerText)
                }
            })
        },
        aplicaFiltro(){
            this.busquedaVisible = false
        },
        toggleDetallesTrafico(){
            this.showInformativo
        },
        toggleBusqueda() {
            this.busquedaVisible = !this.busquedaVisible
            console.log(this.busquedaVisible)
        },
        help(){
            this.busquedaVisible = !this.busquedaVisible
        },
        toggle() {
            this.activoVisible = !this.activoVisible
        },
        handleResize() {
            this.windowHeight = window.innerHeight;

            if(document.querySelector('.content-header')){
                //window.addEventListener('resize',this.handleResize())
                window.onresize = this.handleResize;
                
                this.contentHeight = this.windowHeight - (200)
               
                let rowHeight = 20 
                let contentHeaderHeight = document.querySelector('.content-header').clientHeight + 24 //24px top padding
                let contentFooterHeight = document.querySelector('.content-footer').clientHeight + (rowHeight * 2) //padding extra 
                let tableHeaderHeight = document.querySelector('thead.table-header').clientHeight
            // console.log(this.contentHeight+'-'+contentHeaderHeight+'-'+tableHeaderHeight+'-'+contentFooterHeight+'/'+rowHeight)
                if(this.$props.mode == 'lookup'){
                    this.rows = this.$props.perpage
                }else{
                    this.rows = Math.abs(this.contentHeight - contentHeaderHeight - tableHeaderHeight - contentFooterHeight ) / rowHeight 
                }    
            // console.log(this.rows)
            }  
        },
        setPaginacion(page){
            this.selectedRegistro = []
            this.paginacion = page
            this.consultar()
        },
        savePage(){
           /* let tmpv = JSON.parse(localStorage.getItem('filtros'))
            this.$store.dispatch('saveFiltros',tmpv)
            let campo = 'paginaActual'
            let unico = tmpv[archivo.toLowerCase()]
            unico[campo.toLowerCase()] = paginaActual
            tmpv[archivo.toLowerCase()] = unico
            this.$store.dispatch('saveFiltros',tmpv)
            */
        },
        exportRegistry(){
            this.$emit('sendSelected', this.selectedRegistro)
        },
        async getGlobals(){
            await fetch(this.$api,{
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({
                            accion: "metodo", 
                            archivo: "Global", 
                            metodo: "INIParms",
                            params: {}
                    })
                }).then((response)=>{
                    if(response.ok){
                        return response.json()
                    }   
                }).then((data)=>{
                    this.$store.state.PARAMS = data
                })
        },
        
    },
   
};