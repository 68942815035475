<template>
    <transition name="popup-fade">
        <div class="popup-backdrop screen">
            <div class="popup popup-screen" role="dialog" aria-labelledby="PopupTitulo" aria-describedby="PopupDescripcion">
                <header class="popup-header">
                    Previsualización de Reporte
                    <button type="button" class="btn-close" @click="close" aria-label="Cerrar">🗙</button>
                </header>
                
                <section class="popup-body" id="PopupDescripcion">
                    <div class="row " style="background-color:#EEEEF8">
                        <div class="col-12" style="background-color:#ffffff" >
                          <ul class="list-unstyled d-flex">
                            <li><button class="btn btn-sm btn-outline-default"><MagnifyPlusIcon /></button></li>
                            <li><button class="btn btn-sm btn-outline-default"><MagnifyMinusIcon /></button></li>
                          </ul>
                        </div>
                       
                        <div class="col-10 offset-1"  >
                          <slot name="body">
                              <component v-if="component != ''" :orientacion="orientacion" :is="dynamicComponent" :registro="registro" :filtros="filtros" @print="printDoc" />
                          </slot>
                        </div>
                    </div>
                </section>

            </div>
        </div>
    </transition>
</template>

<script>
import MagnifyPlusIcon from 'vue-material-design-icons/MagnifyPlus.vue';
import MagnifyMinusIcon from 'vue-material-design-icons/MagnifyMinus.vue';
export default {
  name: 'PrintComponent',
  props: ['component','registro','filtros','orientacion'],
  components:{MagnifyPlusIcon,MagnifyMinusIcon},
  computed:{
    dynamicComponent() {
        if (this.$props.component != '') {
          return  () => ({
            // The component to load (should be a Promise)
            component: import(`@/pages/reportes/${this.$props.component}.vue`),
            // A component to use while the async component is loading
            //loading: LoadingComponent,
            // A component to use if the load fails
            //error: ErrorComponent,
            // Delay before showing the loading component. Default: 200ms.
            delay: 200,
            // The error component will be displayed if a timeout is
            // provided and exceeded. Default: Infinity.
            timeout: 3000
          })
          // () => import();
        }
        return null;
      }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    printDoc(){
      this.$emit('print');  
    }
  }
};
</script>s