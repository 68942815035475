<template>
    <transition name="popup-fade">
      <div class="popup-backdrop">
          <div class="popup w-25"  role="dialog" aria-labelledby="PopupTitulo" aria-describedby="PopupDescripcion" >
              <header class="popup-header">Genera Factura
                  <button type="button" class="btn-close" @click="close" aria-label="Cerrar">🗙</button>
              </header>

                <section class="popup-body" id="PopupDescripcion">
                    <div class="row">
                        <label class="col-3">Factura</label>
                        <div class="col-3">
                            <input type="number" v-model="edt.serie" class="form-control form-control-sm" />
                        </div>
                        <div class="col-3">
                            <input type="number" v-model="edt.folio" class="form-control form-control-sm" />
                        </div>
                        <div class="col-3">
                            <div class="form-check">
                                <input class="form-check-input"  v-model="edt.cfdi" type="checkbox" id="edt_cfdi">
                                <label class="form-check-label" for="edt_cfdi">CFDI</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-9">
                            <div class="form-check">
                                <input class="form-check-input"  v-model="edt.cservicios" type="checkbox" id="edt_cservicios">
                                <label class="form-check-label" for="edt_cservicios">Catálogo de Servicios</label>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-check">
                                <input class="form-check-input"  v-model="edt.nofiscal" type="checkbox" id="edt_nofiscal">
                                <label class="form-check-label" for="edt_nofiscal">NF</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-check">
                                <input class="form-check-input"  v-model="edt.editafactura" type="checkbox" id="edt_editafactura">
                                <label class="form-check-label" for="edt_editafactura">Edita Factura</label>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 border">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-check">
                                        <input class="form-check-input"  v-model="edt.conversion" type="checkbox" id="edt_Conversion">
                                        <label class="form-check-label" for="edt_Conversion">Conversión de DLS a M.N.</label>
                                    </div>
                                </div>
                                <label class="col-3">Tipo Cambio</label>
                                <div class="col-3">
                                    <input type="number" v-model="edt.tipocambio" class="form-control form-control-sm" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

              <footer class="popup-footer">
                  
                  <div class="row">
                      <div class="col-12 text-end justify-content-middle">
                          <button type="button" class="btn btn-sm btn-outline-danger" aria-label="Ok" @click="close">Salir</button>
                          <button type="button" class="btn btn-sm btn-outline-success" aria-label="Ok" @click="GeneraFactura()">Generar</button>
                      </div>
                  </div>
              </footer>
          </div>
      </div>
    </transition>
</template>

<script>
    export default {
    name: 'WIN_GeneraFactura',
    params:['datos'],
    data(){
        return{
            folio: 0,
            edt: []
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        GeneraFactura(){
            fetch(this.$api, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({  accion: "metodo", 
                                        metodo: 'GeneraFactura' , 
                                        archivo: "Trafico", 
                                        params: {   data:  this.edt,
                                                    trafico:this.datos.trafico,
                                                    partida:this.datos.partida.partida }})
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then(()=>{
               
            });
        },
        GetTCambio(){
            fetch(this.$api, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({  accion: "metodo", 
                                        metodo: 'GetTCambio' , 
                                        archivo: "Global", 
                                        params: {    }})
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then(()=>{
               
            });
        }
    },
};
</script>